import React, { forwardRef } from "react";
import Draggable from 'react-draggable';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCog, faTableCells, faTrash, faHistory, faCamera, faEdit, faCheck, faExpand, faAdd, faWarning, faTimeline, faEye, faLink } from '@fortawesome/free-solid-svg-icons'
import DefaultButton from "../../../tools/DefaultButton";
import LayoutHelper from "../../../../helper/LayoutHelper";
import Colors from "../../../../constants/Colors";
import EssentialStyle from "../../../../style/EssentialStyle";
import LoadingPage from "../../../../pages/LoadingPage";
import UserAvatar from "../../../tools/UserAvatar";
import { confirmAlert } from "react-confirm-alert";
import CustomConfirm from "../../../tools/CustomConfirm";
import TooManyAvatars from "../../../tools/TooManyAvatars";
import TextEditor from '../../../tools/TextEditor/TextEditor';
import { Col, Form, InputGroup, Tab } from "react-bootstrap";
import DataHelper from "../../../../helper/DataHelper";
import Select from 'react-select';
import CanvasHistory from "./CanvasHistory";
import { toast } from "react-toastify";
import domtoimage from 'dom-to-image';
import UploadCanvasFiles from "./UploadCanvasFiles";
import moment from "moment";
import Sig from "../../../../api/Sig";
import DatePicker from "react-datepicker";
import SessionHelper from "../../../../helper/SessionHelper";
import TimelineMarcos from "./TimelineMarcos";
import CustomTooltip from "../../../tools/CustomTooltip";
import DefaultLoader from "../../../tools/DefaultLoader";
import Modal from "../../../tools/Modal";
import Tabs from "../../../tools/Tabs";
import ModalVinculaMarco from "./ModalVinculaMarco";
import DatePickerHelper from "../../../../helper/DatePickerHelper";
export default class CanvasView extends React.Component {

    state = {
        step: 0,
        loading: true,
        projeto: {},
        iniciativa_objetivo: {},
        canvas: [],
        participants: [],
        name: '',
        colabs: [],
        objetivos: [],
        showHistory: false,
        showEdit: null,
        showCanvas: false,
        print: false,
        orcamento: [],
        marcos: [],
        total: 0,
        width: 100,
        isSmallScreen: LayoutHelper.isSmallScreen(),
        timelineStart: 0,
        timelineEnd: 0,
        loadingMarco: false,
        modalAddPlano: false,
    }

    async componentDidMount() {
        window.addEventListener('resize', this.resize);
        this.handleUrlParams();
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.resize);
    }

    resize = () => {
        this.setState({ isSmallScreen: LayoutHelper.isSmallScreen() });
    }

    handleUrlParams = () => {
        window.parent.postMessage({ type: 'get_url' }, '*');

        const listenerFunction = async (event) => {
            if (event.data.type === 'return_url') {
                const urlObj = new URL(event.data.url);
                const queryParams = new URLSearchParams(urlObj.search);
                const canvasId = queryParams.get('id');

                if (canvasId) {
                    await this.loadData(false, canvasId);
                } else {
                    await this.loadData(false, false);
                }

                window.removeEventListener('message', listenerFunction);
            }
        };

        window.addEventListener('message', listenerFunction);
    }

    async loadData(silent, canvasId = false) {
        if (!silent) {
            this.setState({ loading: true });
        }

        const request = (await Sig.request('GET', 'pe/canvas/getCanvas', { id: canvasId ? canvasId : this.props.id, relations: 1 })) || {};
        let colabs = (await Sig.request('GET', 'config/colaborador/getColaboradores')) || [];
        let progresso = await Sig.request('GET', 'pe/projeto/getTiposProgresso', { projeto: null, canvas: canvasId ? canvasId : this.props.id }) || [];
        let planosVinculados = await Sig.request('GET', 'pe/projeto/getPlanosComVinculo', { projeto: null, canvas: canvasId ? canvasId : this.props.id, relations: true }) || [];

        let canvas = request.canvas || {},
            participants = request.participantes || [],
            objetivos = request.objetivos || [],
            orcamento = request.orcamento || [],
            marcos = request.marcos || [],
            responsavel = request.responsavel || {},
            projeto = request.projeto || {},
            iniciativa_objetivo = request.iniciativa_objetivo || {},
            podeEditarCanvas = request.podeEditarCanvas || false,
            podeExcluirCanvas = request.podeExcluirCanvas || false,
            tiposProgresso = progresso.tipos || [],
            tipoProgresso = progresso.atual || progresso.default,
            planos = planosVinculados.planos || [];

        if (objetivos && objetivos.length) {
            for (let index = 0; index < objetivos.length; index++) {
                objetivos[index].valor = objetivos[index].valor.replace(/<\/?[^>]+(>|$)/g, "");
            }
        }

        canvas.id_responsavel = DataHelper.formatSelectData([responsavel], 'id', 'nome')[0];

        if (participants && participants.length) {
            let selectedParticipantsIds = participants.map(p => (p.id));
            let selectedParticipants = [];

            colabs.forEach(participant => {
                if (selectedParticipantsIds.includes(participant.id)) {
                    selectedParticipants.push(participant);
                }
            });

            canvas.participants = DataHelper.formatSelectData(selectedParticipants, 'id', 'nome');

        } else {
            canvas.participants = [];
        }

        if (orcamento && orcamento.length) {
            let total = 0;
            orcamento.forEach(item => {
                total += Number(item.valor);
            });
            this.setState({ total });
        }

        this.setState({ projeto, participants, canvas, colabs, objetivos, orcamento, marcos, iniciativa_objetivo, podeEditarCanvas, podeExcluirCanvas, tiposProgresso, tipoProgresso, planos }, () => {
            this.setState({ loading: false });
            window.parent.postMessage({ type: 'add_url_param', param: 'id', value: this.state.canvas.id }, '*');
        });
    }

    async handleValueChange(value, field, endInteraction = true) {
        if (value && field) {
            let updateValue = value;

            if (Array.isArray(value)) {
                updateValue = value.map(v => (v.value));
            }

            if (typeof value === 'object' && value.value) {
                updateValue = value.value;
            }

            var request = await Sig.request('POST', 'pe/canvas/updateByField', { id: this.props.id, field, value: updateValue });

            var canvas = this.state.canvas;

            if (request.canvas) {
                canvas[field] = value;
                this.setState({ canvas });
            } else {
                request = await Sig.request('GET', 'pe/canvas/getCanvas', { id: this.props.id, relations: 0 });
                if (request.canvas) {
                    canvas[field] = request.canvas[field];
                    this.setState({ canvas, showEdit: null });
                }
                return;
            }
        }

        if (endInteraction) this.setState({ showEdit: null });
    }

    async handleOrcamentoValueChange(key, value, field) {
        let total = 0;
        this.state.orcamento.forEach(orcamento => {
            total += Number(orcamento.valor);
        });

        this.setState({ total });

        if (value && field) {

            let item = this.state.orcamento[key];

            var request = await Sig.request('POST', 'pe/canvas/editOrcamentoItem',
                {
                    id: item.id,
                    updateValue: value,
                    field: field
                }
            );
            if (request.itemOrcamento == undefined) {
                request = await Sig.request('GET', 'pe/canvas/getCanvas', { id: this.props.id, relations: 1 });
                if (request.orcamento) {
                    this.setState({ orcamento: request.orcamento });
                }
            }
        }
    }

    async handleMarcosValueChange(key, value, field) {
        if (value && field) {
            let marcos = this.state.marcos;
            marcos[key][field] = value;

            var request = await Sig.request('POST', 'pe/canvas/editMarcosItem',
                {
                    id: marcos[key].id,
                    updateValue: value,
                    field: field
                }
            );
            if (request.itemMarcos == undefined) {
                request = await Sig.request('GET', 'pe/canvas/getCanvas', { id: this.props.id, relations: 1 });
                if (request.marcos) {
                    this.setState({ marcos: request.marcos });
                    return;
                }
            }

            this.setState({ marcos });
        }
    }

    verificaScroll(elemento) {
        const temScrollVertical = elemento.scrollHeight > elemento.clientHeight;

        if (temScrollVertical) {
            return true;
        } else {
            return false;
        }
    }

    // Função assíncrona para a lógica de ajuste de largura
    adjustWidthAsync = async (width) => {
        let hasScrollActive = true;
        let loop = 0;
        let canvas = document.getElementById('canvas');

        while (hasScrollActive && loop < 20) {
            loop += 1;
            const scrollableElements = canvas.querySelectorAll('.visualizationComponent');
            hasScrollActive = false;

            for (let element of scrollableElements) {
                if (this.verificaScroll(element)) {
                    hasScrollActive = true;

                    await this.setStateAsync({ width: width + 10 });
                    width += 10; // Atualiza a variável local width para o próximo loop
                    break; // Sai do loop for, mas o while tentará novamente
                }
            }

            if (!hasScrollActive) {
                break; // Sai do loop while se não houver elementos com scroll
            }
        }

    };

    // Função para envolver setState em uma promessa
    setStateAsync = (newState) => {
        return new Promise(resolve => this.setState(newState, resolve));
    };

    print = async () => {
        if (!this.state.print) {
            this.setState({ print: true }, () => {
                this.adjustWidthAsync(this.state.width);

                setTimeout(async () => {
                    let canvas = document.getElementById('canvas');
                    if (canvas) {
                        try {

                            let png = await domtoimage.toPng(canvas, { bgcolor: 'rgb(247, 247, 247)' })

                            var link = document.createElement('a');
                            link.download = `${this.state.projeto.nome}.png`;
                            link.href = png;
                            link.click();
                            link.remove();
                        } catch (error) {
                            toast.error('Houve um problema ao gerar a imagem');
                        }
                    }

                    this.setState({ print: false, width: 100 });
                }, 1000);

            });
        }
    };

    remove = () => {
        confirmAlert({
            customUI: ({ onClose }) => (
                <CustomConfirm
                    title={'Excluir este Canvas?'}
                    message={<p style={{ marginTop: 10, marginBottom: 10 }}>Essa ação não pode ser desfeita</p>}
                    buttons={[
                        {
                            label: 'Remover',
                            color: SessionHelper.getColor(),
                            textColor: Colors.light,
                            onClick: async () => { await this.props.remove(this.props.id); onClose(); this.props.callback(); }
                        },
                        {
                            label: 'Cancelar',
                            onClick: () => { onClose(); }
                        },
                    ]}
                />
            )
        });
    }

    removeOrcamentoItem = (id, descricao) => {
        confirmAlert({
            customUI: ({ onClose }) => (
                <CustomConfirm
                    title={'Excluir este item?'}
                    message={<p style={{ marginTop: 10, marginBottom: 10 }}>Essa ação não pode ser desfeita</p>}
                    buttons={[
                        {
                            label: 'Remover',
                            color: SessionHelper.getColor(),
                            textColor: Colors.light,
                            onClick: async () => {

                                await Sig.request('POST', 'pe/canvas/removeOrcamentoItem',
                                    {
                                        id: id,
                                    });
                                this.loadData(true);
                                onClose();
                            },
                        },
                        {
                            label: 'Cancelar',
                            onClick: () => { onClose(); }
                        },
                    ]}
                />
            )
        })
    }

    removeMarcosItem = (id, descricao) => {
        var message = "Essa ação não pode ser desfeita";

        var marco = this.state.marcos.find(marco => marco.id == id);
        if( marco && marco.vinculo){
            message = "Caso exista uma Ação de Marco criada para este Projeto, ela também será excluída! " + message;
        }

        confirmAlert({
            customUI: ({ onClose }) => (
                <CustomConfirm
                    title={'Excluir este item?'}
                    message={<p style={{ marginTop: 10, marginBottom: 10 }}>{message}</p>}
                    buttons={[
                        {
                            label: 'Remover',
                            color: SessionHelper.getColor(),
                            textColor: Colors.light,
                            onClick: async () => {
                                await Sig.request('POST', 'pe/canvas/removeMarcosItem',
                                    {
                                        id: id,
                                    });
                                this.loadData(true);
                                onClose();
                            }
                        },
                        {
                            label: 'Cancelar',
                            onClick: () => { onClose(); }
                        },
                    ]}
                />
            )
        });
    }

    closeModal(loadPage) {
        this.setState({modalAddPlano: false});
        
        if (loadPage) {
            this.loadData(false, this.state.canvas.id);
        }
    }

    renderNameObjetivo() {

        if (!this.state?.iniciativa_objetivo?.valor) return <></>;
        
        return (
            <div style={{ fontSize: 12 }}>
                <span style={{ fontWeight: '600', marginRight: 5 }}>Objetivo Estratégico:</span>
                <span style={{ fontWeight: '400' }}>{DataHelper.removeHtmlTags(this.state?.iniciativa_objetivo?.valor || "")}</span>
            </div>
        )
    }

    renderHeader() {
        return (
            <div style={{ ...EssentialStyle.rowSpaceBetween, height: '100%', padding: 10, backgroundColor: 'white', boxShadow: Colors.boxShadow, border: '0.3px solid lightgrey', borderRadius: 8, flexDirection: this.state.isSmallScreen ? 'column' : 'row' }}>
                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', width: 'auto' }}>
                    <div style={{ fontWeight: '600', fontSize: 18 }}>{this.state.projeto.nome}</div>
                    {this.renderNameObjetivo()}
                </div>
                <div style={{ display: this.state.print ? 'none' : 'flex', flexDirection: 'row', alignItems: 'center', width: 'auto', marginTop: this.state.isSmallScreen ? 25 : 0 }}>
                    <DefaultButton tooltip={!this.state.podeExcluirCanvas ? 'Sem Permissão para Exlcuir Canvas de Projeto' : 'Remover'} tooltipPlacement={'left'} leftIcon={<FontAwesomeIcon icon={faTrash} />} color={Colors.secondaryButton} textColor={Colors.dark} loading={this.state.loading} disabled={!this.state.podeExcluirCanvas} onClick={async () => { await this.remove(); }} style={{ marginRight: 8 }} />
                    <DefaultButton tooltip={'Histórico'} tooltipPlacement={'left'} leftIcon={<FontAwesomeIcon icon={faHistory} />} color={Colors.secondaryButton} textColor={Colors.dark} loading={this.state.loading} onClick={() => { this.setState({ showHistory: true }) }} style={{ marginRight: 8 }} />
                    <DefaultButton tooltip={'Exportar'} tooltipPlacement={'left'} leftIcon={<FontAwesomeIcon icon={faCamera} />} color={Colors.secondaryButton} textColor={Colors.dark} loading={this.state.loading} onClick={() => { this.print() }} style={{ marginRight: 8 }} />
                    <DefaultButton tooltip={'Listar Canvas'} tooltipPlacement={'left'} leftIcon={<FontAwesomeIcon icon={faTableCells} />} color={Colors.secondaryButton} textColor={Colors.dark} loading={this.state.loading} onClick={() => { window.parent.postMessage({ type: 'delete_url_param', param: 'id' }, '*'); this.props.listCallback(); }} style={{ marginRight: 8 }} />
                    <DefaultButton
                        style={{ width: 38, height: 38 }}
                        fontSize={14}
                        color={Colors.secondaryButton}
                        textColor={Colors.dark}
                        link={"https://advis.freshdesk.com/support/solutions/articles/63000283269-canvas-de-projeto"} />
                </div>
            </div>
        );
    }

    async clickSave(field, title) {
        let allowedFiels = [
            'justificativa',
            'indicadores',
            'retorno_esperado',
            'requisitos',
            'restricoes',
            'responsavel',
            'partes_envolvidas',
            'recursos',
            'marcos',
            'orcamento'
        ];

        if (!allowedFiels.includes(field)) return this.setState({ showEdit: null });

        this.handleValueChange(this.state.canvas[field], field, title);
    }

    renderCanvasQuadrant(canvasQuadrantObj) {
        let { title, field, visualizationComponent, editComponent, marginTop = 5 } = canvasQuadrantObj;
        let height = this.getQuadrantHeight(canvasQuadrantObj.field);

        let innerContent = {};
        let style = {
            padding: '5px 10px 5px 10px',
            backgroundColor: this.state.showEdit ? this.state.showEdit === title ? Colors.white : 'transparent' : Colors.white,
            boxShadow: Colors.boxShadow,
            borderRadius: 5,
            marginTop: marginTop,
            border: '0.3px solid lightgrey',
            overflowY: this.state.print ? 'hidden' : 'auto',
            overflowX: 'hidden',
            filter: this.state.showEdit ? this.state.showEdit === title ? 'none' : 'blur(1px)' : 'none',
            transform: this.state.showEdit ? this.state.showEdit === title ? 'none' : 'scale(0.7)' : 'none',
            transition: 'transform 0.3s ease',
            zIndex: this.state.showEdit ? this.state.showEdit === title ? 11 : 10 : 10
        };
        let measuredHeight = Number(height.replace(/[^\d.]/, ''));

        innerContent = !this.state.print && this.state.showEdit === title ? editComponent : visualizationComponent;

        let marginTopCalc = marginTop > 0 ? marginTop : 5;
        style = {
            ...style,
            minHeight: `calc(${height} - ${marginTopCalc}px)`,
            maxHeight: `calc(${height} - ${marginTopCalc}px)`,
            height: `calc(${height} - ${marginTopCalc}px)`
        };

        if (measuredHeight <= 10) style = { ...style, display: 'flex', flexDirection: 'row' };
        if (measuredHeight <= 10 && ['equipe', 'responsavel'].includes(canvasQuadrantObj.field)) style = { ...style, alignItems: "center" };

        innerContent = <div style={{ display: 'inherit', flexDirection: 'inherit', justifyContent: 'inherit', alignItems: 'inherit' }} className="revert-font-size" >{innerContent}</div>

        return (
            <div className={'visualizationComponent'} style={style} key={`canvas-quadrant-${canvasQuadrantObj.field}`}>
                <div
                    onMouseEnter={() => { this.setState({ hoverQuadrant: true, hoverKey: title }) }}
                    onMouseLeave={() => { this.setState({ hoverQuadrant: false, hoverKey: null }) }}
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'flex-start',
                        alignItems: 'center',
                        alignSelf: (measuredHeight <= 10 && ['equipe', 'responsavel'].includes(canvasQuadrantObj.field)) && ((this.state.hoverQuadrant && this.state.hoverKey === title) || this.state.isSmallScreen) ? "start" : "inherit",
                        minWidth: (measuredHeight <= 10 && this.state.hoverQuadrant && this.state.hoverKey === title) || this.state.isSmallScreen ? '160px' : 'fit-content',
                        height: (this.state.hoverQuadrant && this.state.hoverKey === title) || this.state.isSmallScreen ? 60 : this.state.showEdit && this.state.showEdit === title ? 36 : 24,
                        transition: 'height 0.3s ease'
                    }}
                >
                    {(this.state.hoverQuadrant && this.state.hoverKey === title) || this.state.isSmallScreen || this.state.showEdit === title ?
                        this.state.showEdit && this.state.showEdit === title ?
                            <div style={EssentialStyle.rowFlexStart}>
                                <DefaultButton
                                    tooltip={'Concluir'}
                                    tooltipPlacement={'right'}
                                    leftIcon={<FontAwesomeIcon icon={faCheck} />}
                                    color={Colors.success}
                                    textColor={"white"}
                                    loading={this.state.loading}
                                    onClick={async () => { this.clickSave(canvasQuadrantObj.field, canvasQuadrantObj.title); }}
                                    style={{ marginRight: 8 }} />
                                {
                                    field != 'orcamento' && field != 'marcos' ?
                                        <DefaultButton
                                            tooltip={'Expandir'}
                                            tooltipPlacement={'right'}
                                            leftIcon={<FontAwesomeIcon icon={faExpand} />}
                                            color={Colors.secondaryButton}
                                            textColor={Colors.dark}
                                            loading={this.state.loading}
                                            onClick={async () => {
                                                this.renderEditQuadrantExpanded(title, innerContent);
                                            }}
                                            style={{ marginRight: 8 }}
                                        />
                                        : field == 'marcos' ?
                                            <>
                                                <DefaultButton
                                                    tooltip={'Timeline'}
                                                    tooltipPlacement={'right'}
                                                    leftIcon={<FontAwesomeIcon icon={faTimeline} />}
                                                    color={Colors.secondaryButton}
                                                    textColor={Colors.dark}
                                                    loading={this.state.loading}
                                                    onClick={async () => {
                                                        this.renderTimelineMarcos();
                                                    }}
                                                    style={{ marginRight: 8 }}
                                                />

                                                {!this.state?.iniciativa_objetivo?.valor && 
                                                    <CustomTooltip tooltip={"Metodologia de Progresso dos Projetos"}>
                                                        <Select
                                                        styles={EssentialStyle.selectStyle}
                                                        placeholder={"Escolha uma Metodologia"}
                                                        isSearchable
                                                        options={this.state.tiposProgresso}
                                                        onChange={(e) => {this.changeTipoProgresso(e.value)}}
                                                        value={this.state.tiposProgresso.find(tipo => tipo.value == this.state.tipoProgresso)}
                                                    />
                                                    </CustomTooltip>}
                                            </>
                                            : 
                                            <></>
                                }
                            </div>
                            :
                            <div style={EssentialStyle.rowFlexStart}>
                                <DefaultButton
                                    leftIcon={<FontAwesomeIcon icon={faEdit} />}
                                    color={Colors.secondaryButton}
                                    textColor={Colors.dark}
                                    tooltip={!this.state.podeEditarCanvas ? 'Sem Permissão para Editar Canvas de Projeto' : null}
                                    title={`Editar`}
                                    loading={this.state.loading}
                                    disabled={!this.state.podeEditarCanvas}
                                    onClick={async () => { this.setState({ showEdit: title }) }}
                                    style={{ marginRight: 8 }} />
                                <DefaultButton
                                    tooltip={'Expandir'}
                                    tooltipPlacement={'right'}
                                    leftIcon={<FontAwesomeIcon icon={faExpand} />}
                                    color={Colors.secondaryButton}
                                    textColor={Colors.dark}
                                    loading={this.state.loading}
                                    onClick={async () => {
                                        this.renderEditQuadrantExpanded(title, innerContent);
                                    }}
                                    style={{ marginRight: 8 }}
                                />
                                {field == 'marcos' &&
                                    <>
                                        <DefaultButton
                                            tooltip={'Timeline'}
                                            tooltipPlacement={'right'}
                                            leftIcon={<FontAwesomeIcon icon={faTimeline} />}
                                            color={Colors.secondaryButton}
                                            textColor={Colors.dark}
                                            loading={this.state.loading}
                                            onClick={async () => {
                                                this.renderTimelineMarcos();
                                            }}
                                            style={{ marginRight: 8 }}
                                        />
                                    </>
                                }
                            </div>
                        : <b>{title}:</b>}
                </div>
                {innerContent}
            </div>
        );
    }

    changeTipoProgresso = async (tipo) => {
        var request = await Sig.request('GET', 'pe/projeto/updateTipoProgresso', { projeto: null, canvas: this.props.id, value: tipo });

        if (request.projeto) {
            this.setState({ tipoProgresso: tipo });
        }
    }

    getModalBtns(onClose) {
        let buttons = [
            {
                label: 'Voltar',
                color: Colors.dark,
                textColor: 'white',
                onClick: async () => { this.setState({ showEdit: null }); onClose(); }
            }
        ];

        if (this.state.showEdit) {
            buttons = [
                {
                    label: 'Concluir',
                    color: Colors.success,
                    textColor: 'white',
                    onClick: async () => { this.setState({ showEdit: null }); onClose(); }
                },
                {
                    label: 'Cancelar',
                    color: Colors.error,
                    textColor: 'white',
                    onClick: () => { this.setState({ showEdit: null }); onClose(); }
                },
            ]
        }

        return buttons;
    }

    renderEditQuadrantExpanded = (title, innerContent) => {
        confirmAlert({
            customUI: ({ onClose }) => (
                <CustomConfirm
                    title={title}
                    message={<div style={{ minHeight: '30vh', maxHeight: '65vh', padding: 20, overflowY: 'auto' }}>{innerContent}</div>}
                    buttons={this.getModalBtns(onClose)}
                />
            )
        });
    }

    getTimelineMarcosBtns(onClose) {
        let buttons = [
            {
                label: 'Voltar',
                color: Colors.dark,
                textColor: 'white',
                onClick: async () => { this.setState({ showEdit: null }); onClose(); }
            }
        ];

        if (this.state.showEdit) {
            buttons = [
                {
                    label: 'Concluir',
                    color: Colors.success,
                    textColor: 'white',
                    onClick: async () => { this.setState({ showEdit: "Marcos e Entregas" }); this.saveMarcos(); onClose(); }
                },
                {
                    label: 'Cancelar',
                    color: Colors.error,
                    textColor: 'white',
                    onClick: () => { this.setState({ showEdit: "Marcos e Entregas" }); this.fetchMarcos(); onClose(); }
                },
            ]
        }

        return buttons;
    }

    renderTimelineMarcos() {
        confirmAlert({
            customUI: ({ onClose }) => (
                <CustomConfirm
                    title={'Timeline de Marcos e Entregas'}
                    message={
                        <TimelineMarcos
                            marcos={[...this.state.marcos]}
                            changeMarcosCallback={(marcos) => this.changeMarcosCallback(marcos)}
                            edit={this.state.showEdit ? true : false}
                            id_pe_projeto_canvas={this.props.id}
                            granularidade={"mes"}
                            timelineCanvas={true}
                        />
                    }
                    widthPercentage={95}
                    buttons={this.getTimelineMarcosBtns(onClose)}
                />
            )
        });
    }

    changeMarcosCallback(marcos) {
        this.setState({ marcos });
    }

    async saveMarcos() {
        var request = await Sig.request('POST', 'pe/canvas/updateMarcos', {
            marcos: JSON.stringify(this.state.marcos),
            id: this.props.id
        });
        if (request && request.marcos && request.marcosSorted) {
            this.setState({ marcos: request.marcosSorted });
        }
    }

    async fetchMarcos() {
        var request = await Sig.request('GET', 'pe/canvas/getCanvas', { id: this.props.id, relations: 1 });
        if (request.marcos) {
            this.setState({ marcos: request.marcos, loadingMarco: false });
        }
    }

    getColumnWidth(column) {
        let inLeft = ['Justificativa', 'Indicadores e Metas', 'Retorno e Metas'];
        let inMiddle = ['Requisitos', 'Restrições e Riscos'];
        let inRight = ['Responsável', 'Partes Envolvidas', 'Equipe', 'Recursos', 'Marcos e Entregas', 'Orçamento'];

        let defaultValues = {
            left: '60%',
            right: '40%'
        }

        if (this.state.showEdit) {
            if (inLeft.includes(this.state.showEdit) || inMiddle.includes(this.state.showEdit)) {
                defaultValues.left = '70%';
                defaultValues.right = '30%';
            }

            if (inRight.includes(this.state.showEdit)) {
                defaultValues.left = '50%';
                defaultValues.right = '50%';
            }
        }

        return defaultValues[column] ? defaultValues[column] : '0%';
    }

    getQuadrantHeight(quadrant) {
        let defaultValues = {
            justificativa: {
                height: '40%'
            },
            indicadores: {
                height: '30%'
            },
            retorno_esperado: {
                height: '30%'
            },
            requisitos: {
                height: '50%'
            },
            restricoes: {
                height: '50%'
            },
            responsavel: {
                height: '10%'
            },
            partes_envolvidas: {
                height: '10%'
            },
            equipe: {
                height: '10%'
            },
            recursos: {
                height: '10%'
            },
            marcos: {
                height: '32%'
            },
            orcamento: {
                height: '28%'
            }
        }

        if (this.state.showEdit === 'Partes Envolvidas') {
            defaultValues.partes_envolvidas.height = '40%';
            defaultValues.marcos.height = '15%';
            defaultValues.orcamento.height = '15%';
        }

        if (this.state.showEdit === 'Recursos') {
            defaultValues.recursos.height = '40%';
            defaultValues.marcos.height = '15%';
            defaultValues.orcamento.height = '15%';
        }

        if (this.state.showEdit === 'Responsável') {
            defaultValues.responsavel.height = '65%';
            defaultValues.marcos.height = '10%';
            defaultValues.orcamento.height = '10%';
            defaultValues.equipe.height = '5%';
            defaultValues.partes_envolvidas.height = '5%';
            defaultValues.recursos.height = '5%';
        }

        if (this.state.showEdit === 'Equipe') {
            defaultValues.equipe.height = '65%';
            defaultValues.marcos.height = '10%';
            defaultValues.orcamento.height = '10%';
            defaultValues.responsavel.height = '5%';
            defaultValues.partes_envolvidas.height = '5%';
            defaultValues.recursos.height = '5%';
        }

        if (this.state.showEdit === 'Marcos e Entregas') {
            defaultValues.marcos.height = '65%';
            defaultValues.equipe.height = '10%';
            defaultValues.orcamento.height = '10%';
            defaultValues.responsavel.height = '5%';
            defaultValues.partes_envolvidas.height = '5%';
            defaultValues.recursos.height = '5%';
        }

        if (this.state.showEdit === 'Orçamento') {
            defaultValues.orcamento.height = '65%';
            defaultValues.equipe.height = '10%';
            defaultValues.marcos.height = '10%';
            defaultValues.responsavel.height = '5%';
            defaultValues.partes_envolvidas.height = '5%';
            defaultValues.recursos.height = '5%';
        }

        if (this.state.showEdit === 'Justificativa') {
            defaultValues.justificativa.height = '60%';
            defaultValues.indicadores.height = '20%';
            defaultValues.retorno_esperado.height = '20%';
        }

        if (this.state.showEdit === 'Indicadores e Metas') {
            defaultValues.indicadores.height = '60%';
            defaultValues.justificativa.height = '20%';
            defaultValues.retorno_esperado.height = '20%';
        }

        if (this.state.showEdit === 'Retorno Esperado') {
            defaultValues.retorno_esperado.height = '60%';
            defaultValues.justificativa.height = '20%';
            defaultValues.indicadores.height = '20%';
        }

        if (this.state.showEdit === 'Requisitos') {
            defaultValues.requisitos.height = '65%';
            defaultValues.restricoes.height = '35%';
        }

        if (this.state.showEdit === 'Restrições e Riscos') {
            defaultValues.restricoes.height = '65%';
            defaultValues.requisitos.height = '35%';
        }

        return defaultValues[quadrant] ? defaultValues[quadrant].height : '0%';
    }

    renderDatePicker(disabled, value, onChangeCallback, minDate = new Date(2000, 0, 1), maxDate = moment().add(10, 'years').toDate()) {
        const CustomInput = forwardRef(({ value, onClick, onChange }, ref) => (
            <Form.Control
                onClick={onClick}
                ref={ref}
                value={value}
                onChange={onChange}
                disabled={disabled}
            />
        ));
        
        let date = moment().toDate();
        if (value) date = moment(value).toDate();

        return (
            <DatePicker
                wrapperClassName="w-100"
                selected={date}
                type="date"
                minDate={minDate}
                maxDate={maxDate}
                onChange={(event) => {
                    let changedDate = moment(event);
                    if(changedDate.isValid())
                        onChangeCallback(changedDate);
                }}
                dateFormat="dd/MM/YYYY"
                customInput={<CustomInput />}
                portalId="calendar-portal"
                renderCustomHeader={
                    (props) => DatePickerHelper.renderCustomDatePickerHeader(props, minDate, maxDate)
                }
            />
        )
    };

    getMarcosPesos() {
        let peso = 0;

        this.state.marcos.forEach(marco => {
            peso += Number(marco.peso);
        });

        return peso;
    }

    hasPlanoProgressoDistintos() {
        let planostiposProgresso = this.state.planos.map(p => p.tipo_progresso);
        return planostiposProgresso.some(tipo => parseInt(tipo) != parseInt(this.state.tipoProgresso));
    }

    orcamentoDiferentePlanos() {
        let orcamentoPrevistoPlanos = this.state.planos.reduce((total, item) => total + (Number(item.orcamento_previsto) || 0), 0);
        let orcamentoPrevistoCanvas = this.state.orcamento.reduce((total, item) => total + (Number(item.valor) || 0), 0);
        
        return orcamentoPrevistoPlanos != orcamentoPrevistoCanvas;
    }

    getOrcamentosValue() {
        let planos = this.state.planos.reduce((total, item) => total + (Number(item.orcamento_previsto) || 0), 0);
        let canvas = this.state.orcamento.reduce((total, item) => total + (Number(item.valor) || 0), 0);

        planos = planos.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' });
        canvas = canvas.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' });

        return { planos, canvas };
    }

    openMarcoLink = async (marco) => {

        let stateMarco = this.state.marcos.find(m => m.id == marco.id);

        if (stateMarco.vinculo) {
            window.parent.postMessage({ type: '_blank', url: `/planoAcao/detalhar/${marco.vinculo}` }, '*');
        } else {
            this.setState({modalAddPlano: marco.id});
        }
    }

    renderMarcoLink(marco) {
        let aStyle = { ...EssentialStyle.rowFlexEnd, width: '30px', cursor: 'pointer' };

        if (this.state.loadingMarco == marco.id) {
            return (
                <div style={{...aStyle, cursor: 'not-allowed' }}>
                    <DefaultLoader size={14} />
                </div>
            );
        }

        let tooltipTitle = 'Criar Ações do Marco ou Vincular com Plano de Ação já existente';
        let content = (
            <a
                onClick={() => { this.openMarcoLink(marco) }}
                style={aStyle}
            >
                <FontAwesomeIcon
                    icon={faAdd}
                />
            </a>
        );

        if (marco.vinculo) {
            tooltipTitle = 'Ver e editar ações de Marco';
            content = (
                <a
                    onClick={() => { this.openMarcoLink(marco) }}
                    style={aStyle}
                >
                    <FontAwesomeIcon
                        icon={faEye}
                    />
                </a>
            );
        }

        return (
            <CustomTooltip
                tooltip={tooltipTitle}
            >
                {content}
            </CustomTooltip>
        )
    }

    getCanvasQuadrants() {
        let totalPeso = this.state.marcos.reduce((total, marco) => total + Number(marco.peso), 0);

        return {
            justificativa: {
                title: 'Justificativa',
                field: 'justificativa',
                visualizationComponent: <div style={{ overflowY: 'auto', overflowX: 'unset', wordBreak: "break-word" }} dangerouslySetInnerHTML={{ __html: this.state.canvas.justificativa }} />,
                editComponent: <TextEditor
                    onChange={(value) => { this.state.canvas.justificativa = value; }}
                    defaultValue={this.state.canvas.justificativa} height={'auto'} width={'100%'} />
            },
            indicadores: {
                title: 'Indicadores e Metas',
                field: 'indicadores',
                visualizationComponent: <div style={{ overflowY: 'auto', overflowX: 'unset', wordBreak: "break-word" }} dangerouslySetInnerHTML={{ __html: this.state.canvas.indicadores }} />,
                editComponent: <TextEditor
                    onChange={(value) => { this.state.canvas.indicadores = value; }}
                    defaultValue={this.state.canvas.indicadores} height={'auto'} width={'100%'} />
            },
            retorno_esperado: {
                title: 'Retorno Esperado',
                field: 'retorno_esperado',
                visualizationComponent: <div style={{ overflowY: 'auto', overflowX: 'unset', wordBreak: "break-word" }} dangerouslySetInnerHTML={{ __html: this.state.canvas.retorno_esperado }} />,
                editComponent: <TextEditor
                    onChange={(value) => { this.state.canvas.retorno_esperado = value; }}
                    defaultValue={this.state.canvas.retorno_esperado} height={'auto'} width={'100%'} />
            },
            requisitos: {
                title: 'Requisitos',
                field: 'requisitos',
                visualizationComponent: <div style={{ overflowY: 'auto', overflowX: 'unset', wordBreak: "break-word" }} dangerouslySetInnerHTML={{ __html: this.state.canvas.requisitos }} />,
                editComponent: <TextEditor
                    onChange={(value) => { this.state.canvas.requisitos = value; }}
                    defaultValue={this.state.canvas.requisitos} height={'auto'} width={'100%'} />
            },
            restricoes: {
                title: 'Restrições e Riscos',
                field: 'restricoes',
                visualizationComponent: <div style={{ overflowY: 'auto', overflowX: 'unset', wordBreak: "break-word" }} dangerouslySetInnerHTML={{ __html: this.state.canvas.restricoes }} />,
                editComponent: <TextEditor
                    onChange={(value) => { this.state.canvas.restricoes = value; }}
                    defaultValue={this.state.canvas.restricoes} height={'auto'} width={'100%'} />
            },
            responsavel: {
                title: 'Responsável',
                field: 'responsavel',
                visualizationComponent: <span style={{ paddingLeft: 10 }}>{this.state.canvas?.id_responsavel?.value ? <UserAvatar id={this.state.canvas?.id_responsavel?.value} showName /> : ''}</span>,
                editComponent: <div style={{ height: 'auto' }}>
                    <Select
                        onChange={(value) => {
                            this.handleValueChange(value, 'id_responsavel', false);
                        }}
                        value={this.state.canvas.id_responsavel} options={DataHelper.formatSelectData(this.state.colabs, 'id', 'nome')} placeholder={'Selecione o Responsável'} noOptionsMessage={DataHelper.getSelectEmptyMessage} isClearable isSearchable />
                </div>,
                marginTop: 0
            },
            partes_envolvidas: {
                title: 'Partes Envolvidas',
                field: 'partes_envolvidas',
                visualizationComponent: <div style={{ overflowX: 'unset', wordBreak: "break-word", paddingLeft: 10 }} dangerouslySetInnerHTML={{ __html: this.state.canvas.partes_envolvidas }} />,
                editComponent: <TextEditor
                    onChange={(value) => { this.state.canvas.partes_envolvidas = value; }}
                    defaultValue={this.state.canvas.partes_envolvidas} height={'auto'} width={'100%'} />
            },
            equipe: {
                title: 'Equipe',
                field: 'equipe',
                visualizationComponent:
                    <div style={{ paddingLeft: 15 }}>
                        <TooManyAvatars participantsTotal={this.state.canvas.participants.length} participantsVisiveis={10} arrayParticipants={this.state.canvas.participants} />
                    </div>,
                editComponent: <Select
                    value={this.state.canvas.participants} options={DataHelper.formatSelectData(this.state.colabs, 'id', 'nome')} placeholder={'Selecione os Participantes'} noOptionsMessage={DataHelper.getSelectEmptyMessage} isClearable isMulti isSearchable
                    onChange={(value) => {
                        this.handleValueChange(value, 'participants', false);
                    }}
                />
            },
            recursos: {
                title: 'Recursos',
                field: 'recursos',
                visualizationComponent: <div style={{ overflowX: 'unset', wordBreak: "break-word", paddingLeft: 10 }} dangerouslySetInnerHTML={{ __html: this.state.canvas.recursos }} />,
                editComponent: <TextEditor
                    onChange={(value) => { this.state.canvas.recursos = value; }}
                    defaultValue={this.state.canvas.recursos}
                    height={'auto'}
                    width={'100%'} />

            },
            marcos: {
                title: 'Marcos e Entregas',
                field: 'marcos',
                visualizationComponent: <div>
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                        {
                            ((this.state.marcos.length > 0 && this.getMarcosPesos() < 100) || (this.hasPlanoProgressoDistintos())) &&
                            <div style={{ ...EssentialStyle.rowSpaceBetween, padding: 0, marginBottom: 8, marginTop: 8, borderRadius: 5, backgroundColor: Colors.danger, paddingLeft: 10, paddingRight: 10 }}>
                                <a style={{ color: Colors.white }}>
                                    <FontAwesomeIcon icon={faWarning} style={{ marginRight: 5 }}/>
                                    {this.getMarcosPesos() < 100 ? 'Soma abaixo de 100%' : ''}
                                    {this.getMarcosPesos() < 100 && this.hasPlanoProgressoDistintos() ? ' e ' : ''}
                                    {this.hasPlanoProgressoDistintos() ? 'Planos com Metodologia de Progresso Distintas' : ''}
                                </a>                          
                            </div>
                        }
                        {
                            [...this.state.marcos].map((marco, key) => {
                                return (
                                    <div key={`canvas-mardo-${marco.id}`} style={{ ...EssentialStyle.rowSpaceBetween, padding: 0, marginBottom: 8, borderRadius: 5 }}>
                                        <a style={{ width: 'calc(100% - 270px)' }}>•<b>{marco.descricao || 'Sem Descrição'} </b></a>
                                        <a style={{ width: '200px' }}>{marco.data_inicio ? moment(marco.data_inicio).format('DD/MM/YYYY') : 'Sem Data de Início'} → {marco.data_fim ? moment(marco.data_fim).format('DD/MM/YYYY') : 'Sem Data de Fim'}</a>
                                        <a style={{ ...EssentialStyle.rowFlexEnd, width: '40px' }}>{marco.peso || 0}%</a>
                                        {this.renderMarcoLink(marco)}
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>,
                editComponent: <div>
                    <Form.Group>
                        {
                            [...this.state.marcos].map((marco, key) => {
                                let maxPeso = 100 - (totalPeso - Number(marco.peso));

                                return (
                                    <div key={`canvas-marco-edit-${marco.id}`} style={{ ...EssentialStyle.columnCenter, borderBottom: '1px solid #ddd', paddingBottom: 20, paddingTop: 20 }}>
                                        <div style={{ ...EssentialStyle.rowSpaceEvenly }}>
                                            <div style={{ ...EssentialStyle.rowSpaceBetween, width: 'calc(100% - 80px)', marginBottom: 16 }}>
                                                <Form.Group className="w-50" style={{ marginRight: 8 }}>
                                                    <Form.Label>Descrição</Form.Label>
                                                    <Form.Control placeholder="Descrição" value={marco.descricao}
                                                        onBlur={(event) => {
                                                            this.handleMarcosValueChange(key, event.target.value, 'descricao')
                                                        }}
                                                        onChange={(event) => {
                                                            let marcos = this.state.marcos;
                                                            marcos[key].descricao = event.target.value;
                                                            this.setState({ marcos });
                                                        }}
                                                    />
                                                </Form.Group>
                                                <InputGroup className="w-25" style={{ marginRight: 8 }}>
                                                    <Form.Label>Data de Início</Form.Label>
                                                    {this.renderDatePicker(
                                                        marco.vinculo ? true : false,
                                                        marco.data_inicio,
                                                        (value) => { this.handleMarcosValueChange(key, DataHelper.getDefaultDbDateFormat(value), 'data_inicio') },
                                                        moment().subtract(100, 'years').toDate(),
                                                        marco.data_fim ? moment(marco.data_fim).toDate() : moment().add(100, 'years').toDate(),
                                                    )}
                                                </InputGroup>
                                                <InputGroup className="w-25">
                                                    <Form.Label>Data de Fim</Form.Label>
                                                    {this.renderDatePicker(
                                                        marco.vinculo ? true : false,
                                                        marco.data_fim,
                                                        (value) => { this.handleMarcosValueChange(key, DataHelper.getDefaultDbDateFormat(value), 'data_fim') },
                                                        marco.data_inicio ? moment(marco.data_inicio).toDate() : moment().subtract(100, 'years').toDate(),
                                                    )}
                                                </InputGroup>
                                            </div>

                                            <DefaultButton
                                                tooltip={'Remover'}
                                                tooltipPlacement={'left'}
                                                leftIcon={<FontAwesomeIcon icon={faTrash} />}
                                                color={Colors.secondaryButton}
                                                textColor={Colors.dark}
                                                loading={this.state.loading}
                                                style={{ marginTop: 20 }}
                                                onClick={async () => { this.removeMarcosItem(marco.id, marco.descricao || 'Sem Descrição') }}
                                            />
                                        </div>

                                        <div style={{ ...EssentialStyle.rowSpaceEvenly, width: '100%' }}>
                                            <Form.Label style={{ marginTop: 5, width: 110 }}>% do projeto</Form.Label>
                                            <InputGroup style={{ minWidth: 200, width: 'calc(100% - 170px)', marginRight: 10 }}>
                                                <Form.Control
                                                    type="range"
                                                    min="0"
                                                    max={maxPeso}
                                                    step="1"
                                                    value={Number(marco.peso || 0)}
                                                    onMouseUp={(event) => {
                                                        this.handleMarcosValueChange(key, event.target.value, 'peso')
                                                    }}
                                                    onTouchEnd={(event) => {
                                                        this.handleMarcosValueChange(key, event.target.value, 'peso')
                                                    }}
                                                    onChange={(event) => {
                                                        let marcos = this.state.marcos;
                                                        marcos[key].peso = event.target.value;
                                                        this.setState({ marcos });
                                                    }}
                                                />
                                            </InputGroup>
                                            <Form.Label style={{ marginTop: 5, width: 50 }}>{Number(marco.peso || 0)}%</Form.Label>
                                        </div>
                                    </div>

                                )
                            })
                        }
                    </Form.Group>

                    <DefaultButton
                        leftIcon={<FontAwesomeIcon icon={faAdd} />}
                        color={Colors.success}
                        textColor={Colors.white}
                        title={'Adicionar Marco'}
                        loading={this.state.loading}
                        onClick={async () => {
                            await Sig.request('POST', 'pe/canvas/addMarcosItem',
                                {
                                    id_pe_projeto_canvas: this.props.id
                                }
                            );

                            await this.loadData(true);
                        }}
                        style={{ marginRight: 8, marginTop: 16 }}
                    />
                </div>
            },
            orcamento: {
                title: 'Orçamento',
                field: 'orcamento',
                visualizationComponent: <div>
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                        {
                            (this.state.orcamento?.length > 0 && this.orcamentoDiferentePlanos()) &&
                            <div style={{ ...EssentialStyle.rowSpaceBetween, padding: 0, marginBottom: 8, marginTop: 8, borderRadius: 5, backgroundColor: Colors.danger, paddingLeft: 10, paddingRight: 10 }}>
                                <a style={{ color: Colors.white }}>
                                    <FontAwesomeIcon icon={faWarning} style={{ marginRight: 5 }}/>
                                    <span>{'Orçamento diferente do previsto:'}</span>
                                    <div style={{ ...EssentialStyle.columnStart }}>
                                        <b>Marcos: {this.getOrcamentosValue()['planos']}</b>
                                        <b>Canvas: {this.getOrcamentosValue()['canvas']}</b>
                                    </div>
                                </a>
                            </div>
                        }
                        {

                            this.state.orcamento.map((orcamento, key) => {
                                return (
                                    <div key={`canvas-orcamento-${key}`} style={{ ...EssentialStyle.rowSpaceBetween, padding: 0, marginBottom: 8, borderRadius: 5 }}>
                                        <a style={{ width: 'calc(100% - 240px)' }}>•<b>{orcamento.descricao || 'Sem Descrição'}</b></a>
                                        <a style={{ ...EssentialStyle.rowFlexEnd, width: '40px' }}>{Number(orcamento.valor || 0).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</a>
                                    </div>
                                )
                            })
                        }
                    </div>
                    <div style={{ padding: 0, borderTop: '1px solid #ddd', marginTop: 10, paddingTop: 10 }}>
                        <div style={{ ...EssentialStyle.rowSpaceBetween, padding: 0, marginBottom: 8, borderRadius: 5 }}>
                            <a style={{ width: 'calc(100% - 240px)' }}><b>TOTAL</b></a>
                            <a style={{ ...EssentialStyle.rowFlexEnd, width: '40px' }}><b>{Number(this.state.total || 0).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</b></a>
                        </div>
                        <UploadCanvasFiles id={this.props.id} print={this.state.print} limit={10} field={'orcamento'} hideAdd />
                    </div>
                </div>,
                editComponent: <div>
                    <Form.Group>
                        {
                            this.state.orcamento.map((orcamento, key) => {
                                return (
                                    <div key={`canvas-orcamento-edit-${key}`} style={{ ...EssentialStyle.rowFlexStart, borderBottom: '1px solid #ddd', paddingBottom: 20, paddingTop: 20 }}>
                                        <InputGroup className="mb-3">
                                            <Form.Control placeholder="Descrição" defaultValue={orcamento.descricao}
                                                onBlur={(event) => {

                                                    this.handleOrcamentoValueChange(key, event.target.value, 'descricao')
                                                }}

                                                onChange={(event) => {
                                                    this.state.orcamento[key].descricao = event.target.value;
                                                }}
                                            />

                                        </InputGroup>
                                        <div style={{ marginLeft: 4, marginRight: 4 }} />
                                        <InputGroup className="mb-3">
                                            <InputGroup.Text>R$</InputGroup.Text>
                                            <Form.Control aria-label="Valor" type="number" min={0.00} step={0.01} defaultValue={Number(this.state.orcamento[key].valor || 0).toFixed(2)}
                                                onBlur={(event) => {
                                                    this.handleOrcamentoValueChange(key, Number(event.target.value).toFixed(2), 'valor')

                                                }}
                                                onChange={(event) => {
                                                    this.state.orcamento[key].valor = event.target.value;
                                                }}
                                            />
                                        </InputGroup>
                                        <div style={{ marginLeft: 8 }} />
                                        <div>
                                            <DefaultButton tooltip={'Remover'} tooltipPlacement={'left'} leftIcon={<FontAwesomeIcon icon={faTrash} />} color={Colors.secondaryButton} textColor={Colors.dark} loading={this.state.loading} onClick={async () => { this.removeOrcamentoItem(orcamento.id, orcamento.descricao || 'Sem Descrição') }} style={{ marginRight: 8 }} />
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </Form.Group>
                    <DefaultButton
                        leftIcon={<FontAwesomeIcon icon={faAdd} />}
                        color={Colors.secondaryButton}
                        textColor={Colors.dark}
                        loading={this.state.loading}
                        title={'Adicionar Item de Orçamento'}
                        onClick={async () => {
                            await Sig.request('POST', 'pe/canvas/addOrcamentoItem',
                                {
                                    id_pe_projeto_canvas: this.props.id
                                }
                            );
                            await this.loadData(true);

                        }}
                        style={{ marginRight: 8, marginTop: 16, marginBottom: 16 }}
                    />
                    <UploadCanvasFiles id={this.props.id} limit={10} field={'orcamento'} />
                </div>
            },
        }
    }

    renderCanvasMobile(quadrants) {
        return (
            <div id="canvas" style={{ display: 'flex', flexDirection: 'column', marginTop: 10, padding: this.state.print ? 10 : 0 }}>
                {this.renderHeader()}
                {Object.keys(quadrants).map((key) => (this.renderCanvasQuadrant({ ...quadrants[key], height: '200px' })))}
                {this.renderModalAddPlano()}
            </div >
        )
    }

    getPrintHeight() {
        let quadrants = this.getCanvasQuadrants();
        let tallestHeight = 0;

        let layout = [
            [
                'justificativa',
                'indicadores',
                'retorno_esperado'
            ],
            [
                'requisitos',
                'restricoes'
            ],
            [
                'responsavel',
                'partes_envolvidas',
                'equipe',
                'recursos',
                'marcos',
                'orcamento'
            ]
        ];

        let layoutWidths = [
            '36%',
            '24%',
            '40%'
        ];

        for (let column of layout) {
            let columnHeight = 0;
            let columnWidth = window.innerWidth * (parseInt(layoutWidths[layout.indexOf(column)]) / 100);

            for (let quadrant of column) {
                if (quadrants[quadrant].visualizationComponent?.props?.dangerouslySetInnerHTML?.__html?.length > 0) {
                    let htmlLength = quadrants[quadrant].visualizationComponent.props.dangerouslySetInnerHTML.__html.length;

                    let charWidth = 0.6 * 18;
                    let lineHeight = 24;
                    let charsPerLine = columnWidth / charWidth;

                    let lines = Math.ceil(htmlLength / charsPerLine);

                    columnHeight += lines * lineHeight;
                } else {
                    columnHeight += (window.innerHeight * 0.85) * 0.10;
                }

            }

            if (columnHeight > tallestHeight) tallestHeight = columnHeight;
        }

        return tallestHeight * 2;
    }

    renderCanvasDesktop(quadrants) {
        return (
            <div id="canvas" style={{
                display: 'flex',
                flexDirection: 'row',
                width: !this.state.print ? '100%' : `${this.state.width}%`,
                height: !this.state.print ? '100vh' : `${this.state.width}vh`,
            }}>
                <div style={{ display: 'flex', flexDirection: 'column', width: this.getColumnWidth('left'), height: '100%', minHeight: '100%' }}>
                    <div style={{ display: 'flex', flexDirection: 'column', height: 'calc(12% - 5px)', width: '100%' }}>
                        {this.renderHeader()}
                    </div>

                    <div style={{ display: 'flex', flexDirection: 'row', width: '100%', height: '88%' }}>
                        <div style={{ display: 'flex', flexDirection: 'column', width: '60%' }}>
                            {this.renderCanvasQuadrant(quadrants.justificativa)}
                            {this.renderCanvasQuadrant(quadrants.indicadores)}
                            {this.renderCanvasQuadrant(quadrants.retorno_esperado)}
                        </div>
                        <div style={{ display: 'flex', flexDirection: 'column', width: '40%', marginLeft: 5 }}>
                            {this.renderCanvasQuadrant(quadrants.requisitos)}
                            {this.renderCanvasQuadrant(quadrants.restricoes)}
                        </div>
                    </div>
                </div>

                <div style={{ display: 'flex', flexDirection: 'column', width: this.getColumnWidth('right'), marginLeft: 5, height: '100%' }}>
                    <div style={{ display: 'flex', flexDirection: 'column', height: '100%', width: '100%', minHeight: '100%' }}>
                        {this.renderCanvasQuadrant(quadrants.responsavel)}
                        {this.renderCanvasQuadrant(quadrants.partes_envolvidas)}
                        {this.renderCanvasQuadrant(quadrants.equipe)}
                        {this.renderCanvasQuadrant(quadrants.recursos)}
                        {this.renderCanvasQuadrant(quadrants.marcos)}
                        {this.renderCanvasQuadrant(quadrants.orcamento)}
                    </div>
                </div>

                {this.renderModalAddPlano()}
            </div >
        )
    }

    renderCanvas() {
        const quadrants = this.getCanvasQuadrants();

        return this.state.isSmallScreen ? this.renderCanvasMobile(quadrants) : this.renderCanvasDesktop(quadrants);
    }

    historyCallback = () => {
        this.setState({ showHistory: false });
    }

    renderModalAddPlano() {

        if (!this.state.modalAddPlano) return null;
        return (
            <ModalVinculaMarco tipoProgresso={this.state.tipoProgresso} idMarco={this.state.modalAddPlano} closeModal={(loadPage) => { this.closeModal(loadPage) } }/>
        )
    }

    render() {
        if (this.state.showHistory) return <CanvasHistory id={this.props.id} historyCallback={this.historyCallback} />

        return this.state.loading ? <LoadingPage /> : (<div style={{ margin: 0, padding: 0, width: '100%', height: '100%' }}>{this.renderCanvas()}</div>);
    }
}