import React from "react";

import './ListTiposResultados.css';
import SessionHelper from "../../../../../helper/SessionHelper";
import IdiomaHelper from "../../../../../helper/IdiomaHelper";

const HEATMAP = 1;

export default class ListTiposResultados extends React.Component {
    state = {
        tipos: [
            {
                id: HEATMAP, nome: IdiomaHelper.getStruct({
                    'pt': 'Mapa de Calor',
                    'en': 'Heat Map',
                    'es': 'Mapa de Calor',
                })
            },
        ],
    }

    render() {
        return (
            <div className="listTiposContainer">
                <div className="listTipos">
                    {this.state.tipos.map((tipo, index) => (
                        <div
                            key={`tipo-list-${tipo.id}`}
                            className={`tipo`}
                            style={{ backgroundColor: this.props.selected == tipo.id ? SessionHelper.getColor() : '#808080' }}
                            onClick={() => this.props.onSelectTipo(tipo.id)}
                        >
                            {tipo.nome[this.props.lang]}
                        </div>
                    ))}
                </div>
            </div>
        )
    }
}