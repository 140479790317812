import { faSitemap, faUnlink, faAward, faBolt, faBook, faBookmark, faBriefcase, faCamera, faChartSimple, faCheck, faCircleDown, faCircleUp, faCrosshairs, faCrown, faDollarSign, faEarthAmericas, faFlag, faFont, faGift, faHandshake, faHeart, faHistory, faHourglass, faIcons, faLightbulb, faPen, faPlus, faMinus, faRocket, faStar, faTableCells, faThumbsDown, faThumbsUp, faTimes, faTrash, faUsers, faCog, faEye, faArrowLeft, faAd, faAdd, faInfoCircle, faLink, faArrowsAlt, faArrowsLeftRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export default class DiagramHelper {
    static getIcons() {
        return [faCheck, faStar, faHeart, faBolt, faGift, faCircleUp, faCircleDown, faBook, faBookmark, faThumbsUp, faThumbsDown, faBriefcase, faHandshake, faEarthAmericas, faUsers, faChartSimple, faFlag, faRocket, faAward, faCrown, faCrosshairs, faLightbulb, faDollarSign, faHourglass];
    }

    static getColorPickerColors() {
        return [
            '#4d4d4d', '#999999', '#ffffff', '#f44e3b', '#fe9200', '#fcdc00', '#dbdf00', '#a4dd00', '#68cccc', '#73d8ff', '#aea1ff', '#fda1ff',
            '#333333', '#808080', '#cccccc', '#d33115', '#e27300', '#fcc400', '#b0bc00', '#68bc00', '#16a5a5', '#009ce0', '#7b64ff', '#ab149e',
            '#000000', '#666666', '#b3b3b3', '#9f0500', '#c45100', '#fb9e00', '#808900', '#194d33', '#0c797d', '#0062b1', '#653294', '#ab149f',
            'transparent',
        ];
    }

    static getScaledProportions(w, h, considerEditingInterface) {
        let contentSizeWidth = w;
        let contentSizeHeight = h;
        if(considerEditingInterface === null){
            considerEditingInterface = true;
        }

        if (contentSizeHeight > 0 && contentSizeWidth > 0) {
            const width = window.innerWidth - (considerEditingInterface ? DiagramHelper.scaleSizeByPercent(200, 100) : 0);
            const height = window.innerHeight - (considerEditingInterface ? DiagramHelper.scaleSizeByPercent(100, 100) : 0);

            let hCalc = (height * 100) / contentSizeHeight, wCalc = (width * 100) / contentSizeWidth;
            let desiredZoom = hCalc > wCalc ? wCalc : hCalc;

            return { width: parseInt(contentSizeWidth), height: parseInt(contentSizeHeight), zoom: parseInt(desiredZoom) };
        }

        return { width: parseInt(contentSizeWidth), height: parseInt(contentSizeHeight), zoom: 100 };
    }


    static scaleSizeByPercent(size, zoom) {
        return (size / (zoom / 100));
    }

    static getDiagramResolution() {
        return { width: 2580, height: 1150 };
    }

    static ordenaSwots(strs){
        let arr1=[]; 
        let arr2=[];
        let arr3=[];
        let arr4=[];

        let arrayFinal = [];

        strs.forEach((item) => {
        if (item.label[0] === 'F' && item.label[1] === 'o') {
            arr1.push(item);
        }else if(item.label[0] === 'F' && item.label[1] === 'r'){
            arr2.push(item);   
        }else if(item.label[0] === 'O'){
            arr3.push(item);
        }else{
            arr4.push(item);
        }});

        arr1 = arr1.sort((a, b) => a.label.localeCompare(b.label)); 
        arr2 = arr2.sort((a, b) => a.label.localeCompare(b.label));
        arr3 = arr3.sort((a, b) => a.label.localeCompare(b.label));
        arr4 = arr4.sort((a, b) => a.label.localeCompare(b.label));            
       
        arrayFinal = arrayFinal.concat(arr1);
        arrayFinal = arrayFinal.concat(arr2);
        arrayFinal = arrayFinal.concat(arr3);
        arrayFinal = arrayFinal.concat(arr4);

        return arrayFinal;  
    
    }
}
