import React from "react";
import EssentialStyle from "../../style/EssentialStyle";
import Header from "../../components/modules/inicio/Header";
import Sig from "../../api/Sig";
import UserDashboard from "../../components/modules/inicio/UserDashboard";
import EmptyDashboard from "../../components/modules/inicio/EmptyDashboard";
import InicioConfigModal from "../../components/modules/inicio/InicioConfigModal";
import DefaultLoader from "../../components/tools/DefaultLoader";

export default class HomePage extends React.Component {

    state = {
        loading: false,
        viewMode: 'user',
        userId: null,
        numberSubordinados: [],
        numberSubordinados: 0,
        viewUserConfig: true,
        configModal: false,
        reloadEquipe: 0,
        reload: 0,
    }

    async componentDidMount() {
    }

    reloadEquipe = () => {
        this.setState({ reloadEquipe: this.state.reloadEquipe + 1 });
    }

    reload = () => {
        this.setState({ reload: this.state.reload + 1 });
    }

    renderViewMode() {
        switch (this.state.viewMode) {
            case 'user':
                return <UserDashboard
                    userId={this.state.userId}
                    numberSubordinados={this.state.numberSubordinados}
                    viewUserConfig={this.state.viewUserConfig}
                    reloadEquipe={this.state.reloadEquipe}
                    reload={this.state.reload}
                />;
            default:
                return <EmptyDashboard />;
        }
    }

    numberSubordinados(numberSubordinados) {
        this.setState({ numberSubordinados });
    }

    onSelectColaborador = async (id) => {
        this.setState({ userId: id });
    }

    onChangeUserConfig = async (bool) => {
        this.setState({ viewUserConfig: bool });
    }

    toggleConfigModal = async () => {
        this.setState({ configModal: !this.state.configModal });
    }

    render() {
        return (
            <div style={{
                ...EssentialStyle.column,
                width: '100%',
                height: '100vh',
            }}>
                <Header
                    onSelectColaborador={this.onSelectColaborador}
                    onChangeUserConfig={this.onChangeUserConfig}
                    toggleConfigModal={this.toggleConfigModal}
                    numberSubordinados={(numberSubordinados) => { this.numberSubordinados(numberSubordinados) }}
                    reloadEquipe={() => { this.reloadEquipe() }}
                />
                {this.renderViewMode()}
                {this.state.configModal &&
                    <InicioConfigModal
                        onClose={this.toggleConfigModal}
                        userId={this.state.userId}
                        viewUserConfig={this.state.viewUserConfig}
                        reloadCallback={this.reload}
                    />
                }
            </div>
        );
    }
}