import React from 'react';
import Select from 'react-select';
import DefaultButton from '../components/tools/DefaultButton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import Colors from '../constants/Colors';
import EssentialStyle from '../style/EssentialStyle';

export default class DatePickerHelper {

    static renderCustomDatePickerHeader(props, minDate = new Date(2000, 0, 1), maxDate = new Date(new Date().getFullYear() + 10, 11, 31)) {
        const months = [
            "Janeiro",
            "Fevereiro",
            "Março",
            "Abril",
            "Maio",
            "Junho",
            "Julho",
            "Agosto",
            "Setembro",
            "Outubro",
            "Novembro",
            "Dezembro",
        ];

        var {
            date,
            decreaseMonth,
            increaseMonth,
            changeMonth,
            changeYear,
            prevMonthButtonDisabled,
            nextMonthButtonDisabled,
        } = props;

        // Generate monthsOptions based on the selected year
        const selectedYear = date.getFullYear();
        const monthsOptions = months
            .map((month, index) => ({ value: index, label: month }))
            .filter(option => 
                (selectedYear > minDate.getFullYear() || option.value >= minDate.getMonth()) &&
                (selectedYear < maxDate.getFullYear() || option.value <= maxDate.getMonth())
            );

        const yearsOptions = Array.from({ length: maxDate.getFullYear() - minDate.getFullYear() + 1 }, (_, i) => {
            const year = minDate.getFullYear() + i;
            return { value: year, label: year };
        });

        // Ensure the selected month is within the valid range
        if (selectedYear === minDate.getFullYear() && date.getMonth() < minDate.getMonth()) {
            changeMonth(minDate.getMonth());
        }
        if (selectedYear === maxDate.getFullYear() && date.getMonth() > maxDate.getMonth()) {
            changeMonth(maxDate.getMonth());
        }

        // Disable month navigation buttons appropriately
        const isPrevMonthDisabled = prevMonthButtonDisabled || (selectedYear === minDate.getFullYear() && date.getMonth() <= minDate.getMonth());
        const isNextMonthDisabled = nextMonthButtonDisabled || (selectedYear === maxDate.getFullYear() && date.getMonth() >= maxDate.getMonth());

        return (
            <div
                style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    gap: 5,
                }}
            >
                <DefaultButton
                    leftIcon={<FontAwesomeIcon icon={faChevronLeft} />}
                    style={{ padding: 0, width: 30, height: 30, backgroundColor: 'transparent', color: Colors.dark, border: "unset" }}
                    onClick={decreaseMonth}
                    disabled={isPrevMonthDisabled}
                />

                <Select
                    value={monthsOptions.find((option) => option.label === months[date.getMonth()])}
                    onChange={(value) => changeMonth(value.value)}
                    options={monthsOptions}
                    styles={{
                        control: (base, state) => ({
                            ...base,
                            ...EssentialStyle.titleBoxHomePage,
                            border: 'none',
                            boxShadow: state.isFocused ? 'none' : null,
                            backgroundColor: "transparent",
                            width: "100px",
                            padding: "0px",
                            placeContent: 'center',
                            display: 'flex',
                        }),
                        container: (base, state) => ({
                            ...base,
                        }),
                        valueContainer: (base, state) => ({
                            ...base,
                            width: 'calc(100% - 20px)',
                            padding: '0px',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center'
                        }),
                        singleValue: (base, state) => ({
                            ...base,
                            margin: '0px',
                            textAlign: 'center',
                            width: '100%'
                        }),
                        indicatorsContainer: (base, state) => ({
                            ...base,
                            height: "100%",
                            display: 'flex',
                            alignItems: 'center'
                        }),
                        dropdownIndicator: (base, state) => ({
                            ...base,
                            padding: 0,
                        }),
                        input: (base, state) => ({
                            ...base,
                            margin: '0px',
                            padding: '0px',
                            width: 0, // Hide the input element
                        }),
                        menu: (base, state) => ({
                            ...base,
                            width: '100%',
                            minWidth: '150px',
                            right: 0,
                            marginRight: 8,
                            margin: 0,
                            borderRadius: 4,
                            boxShadow: '0px 2px 2px 2px  rgba(50, 50, 50, 0.1)',
                        }),
                    }}
                    components={{ IndicatorSeparator: null }}
                />

                <Select
                    value={yearsOptions.find((option) => option.value === date.getFullYear())}
                    onChange={(value) => changeYear(value.value)}
                    options={yearsOptions}
                    styles={{
                        control: (base, state) => ({
                            ...base,
                            ...EssentialStyle.titleBoxHomePage,
                            border: 'none',
                            boxShadow: state.isFocused ? 'none' : null,
                            backgroundColor: "transparent",
                            width: "70px",
                            padding: "0px",
                            placeContent: 'center',
                            display: 'flex',
                        }),
                        container: (base, state) => ({
                            ...base,
                        }),
                        valueContainer: (base, state) => ({
                            ...base,
                            width: 'calc(100% - 20px)',
                            padding: '0px',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center'
                        }),
                        singleValue: (base, state) => ({
                            ...base,
                            margin: '0px',
                            textAlign: 'center',
                            width: '100%'
                        }),
                        indicatorsContainer: (base, state) => ({
                            ...base,
                            height: "100%",
                            display: 'flex',
                            alignItems: 'center'
                        }),
                        dropdownIndicator: (base, state) => ({
                            ...base,
                            padding: 0,
                        }),
                        input: (base, state) => ({
                            ...base,
                            margin: '0px',
                            padding: '0px',
                            width: 0, // Hide the input element
                        }),
                        menu: (base, state) => ({
                            ...base,
                            width: '100%',
                            minWidth: '150px',
                            right: 0,
                            marginRight: 8,
                            margin: 0,
                            borderRadius: 4,
                            boxShadow: '0px 2px 2px 2px  rgba(50, 50, 50, 0.1)',
                        }),
                    }}
                    components={{ IndicatorSeparator: null }}
                />

                <DefaultButton
                    leftIcon={<FontAwesomeIcon icon={faChevronRight} />}
                    style={{ padding: 0, width: 30, height: 30, backgroundColor: 'transparent', color: Colors.dark, border: "unset" }}
                    onClick={increaseMonth}
                    disabled={isNextMonthDisabled}
                />
            </div>
        );
    }
}