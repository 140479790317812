import React from "react";
import EssentialStyle from "../../style/EssentialStyle";
import Constants from "../../constants/Api";
import IdiomaHelper from "../../helper/IdiomaHelper";
const gateway = Constants.getSigEndPoint();

export default class UnderConstruction extends React.Component {
    state = {
        defaultTexts: IdiomaHelper.getStruct({
            'pt': 'Módulo em construção. Em breve você poderá visualizar esta página!',
            'en': 'Module under construction. Soon you will be able to view this page!',
            'es': 'Módulo en construcción. ¡Pronto podrás ver esta página!',
        })
    }

    render() {
        let alt = IdiomaHelper.getStruct({
            'pt': 'Dois homens carregando pedaços de madeira para construir uma casa.',
            'en': 'Two men carrying pieces of wood to build a house.',
            'es': 'Dos hombres llevando piezas de madera para construir una casa.',
        });

        return (
            <div style={{
                ...EssentialStyle.columnCenter,
                width: '100%',
                height: this.props.height ? this.props.height : '100%',
            }}>
                <img
                    src={`${gateway}/img/undraw/undraw_construction.svg`}
                    alt={alt[IdiomaHelper.getIdioma()]}
                    style={{
                        height: '85%',
                        maxHeight: 250,
                        marginBottom: 40,
                    }}
                />
                <span style={{ maxWidth: '60vw' }}>
                    {this.props.title || this.state.defaultTexts[IdiomaHelper.getIdioma()]}
                </span>
            </div>
        );
    }
}