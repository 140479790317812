import React from "react";
import LoadingPage from "../LoadingPage";
import LayoutHelper from "../../helper/LayoutHelper";
import ProjetoGestaoView from "../../components/modules/pe/projeto/ProjetoGestaoView/ProjetoGestaoView";

export default class ProjetoPageGestao extends React.Component {
    constructor(props) {
        super(props);
    }

    state = {
        loading: true,
        isSmallScreen: LayoutHelper.isSmallScreen(),
        id: null,
    }

    async componentDidMount() {
        this.handleUrlParams();
        window.addEventListener('resize', this.verifyScreen);
        window.addEventListener('message', this.handleMessage);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.verifyScreen);
        window.removeEventListener('message', this.handleMessage);
    }

    handleMessage = (event) => {
        if (event.data.type === 'popstate') {
            if (event.data.url === this.state.lastPopState) {
                this.setState({ showDetailsModal: null, lastPopState: null });
                return;
            }

            this.setState({ lastPopState: event.data.url });
            this.handleUrlParams();
        }
    }

    verifyScreen = () => {
        this.setState({ isSmallScreen: LayoutHelper.isSmallScreen() });
    }

    handleUrlParams = () => {
        window.parent.postMessage({ type: 'get_url' }, '*');

        const listenerFunction = async (event) => {
            if (event.data.type === 'return_url') {
                const urlObj = new URL(event.data.url);
                const queryParams = new URLSearchParams(urlObj.search);
                window.removeEventListener('message', listenerFunction);

                // Extract the last segment from the pathname
                const pathSegments = urlObj.pathname.split('/');
                const lastSegment = pathSegments[pathSegments.length - 1];

                this.setState({ id: lastSegment }, () => this.setState({ loading: false }));
            }
        };

        window.addEventListener('message', listenerFunction);
    }

    render() {

        if (this.state.loading) return <LoadingPage />
        return <ProjetoGestaoView id={this.state.id} isSmallScreen={this.state.isSmallScreen}/>
    }
}