import React from "react";
import Sig from "../../../../../api/Sig";
import DefaultLoader from "../../../../tools/DefaultLoader";
import { ProgressBar } from 'react-bootstrap'
import SwotHelper from "../../../../../helper/pe/SwotHelper";
import Colors from "../../../../../constants/Colors"; 
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';

import './ProjetoFatoresSowtCard.css';
import EllipsisText from "../../../../tools/EllipsisText";
import CustomTooltip from "../../../../tools/CustomTooltip";

export default class ProjetoFatoresSowtCard extends React.Component {
    state = {
        loading: true,
        item: null,
        quadrante: null,
        swot: null,
        escala: null,
        progress: 100,
        shake: false,
        hover: false 
    }

    async componentDidMount() {
        await this.loadItem();
    }

    async loadItem() {
        this.setState({ loading: true });

        let { item, quadrante } = (await Sig.request('POST', 'pe/projeto/getQuadranteItens', { id: this.props.fator.id}));
        let { swot, escala } = await Sig.request('GET', `pe/swot/getSwot?id=${this.props.fator.id_pe_swot}`);
        let progress = await SwotHelper.getGutItemValue(this.props.fator.id_pe_swot, item.id);
        this.setState(
            { 
                color: Colors.swot[SwotHelper.getSwotKey(quadrante.nome)], 
                item, 
                quadrante, 
                swot, 
                escala, 
                progress, 
                loading: false
            }, ()  => { 
                this.setProgressBarColor();
            }
        );
    }

    setProgressBarColor() {
        if (this.progressBar) {
            const inner = this.progressBar.querySelector(".progress-bar");
            if (inner) inner.style.backgroundColor = this.state.color;
        }
    }

    shake(){
        this.setState({ shake: true });
        setTimeout(() => this.setState({ shake: false }), 500);
    }

    toggleHover = (state) => {
        this.setState({ hover: state });
    };

    renderProgressGUT() {
        
        const progressBar = () => (
            <div className={'board'}>
                <div style={{ height: '100%', width: '100%', backgroundColor: 'white', borderRadius: 5}} >
                    <ProgressBar 
                        min={0} 
                        max={this.state.escala} 
                        animated={this.state.loading}
                        now={this.state.progress} 
                        label={this.state.progress}
                        ref={ref => this.progressBar = ref}
                        style={{ height: '100%', borderRadius: 5}}
                        /> 
                </div>
            </div>
        )

        const alertProgress = () => (
            <div className={"board"}>
                <div className={"barWarning"}>
                    <FontAwesomeIcon icon={faExclamationTriangle} style={{color: Colors.danger}}/> Priorização não preenchida
                </div>
            </div>
            
        )

        return this.state.progress > 0 ? progressBar() : alertProgress();
    }

    render() {

        if (this.state.loading) return <div className={'fatoresSwotCard'} style={{ paddingTop: 30, height:'15vh' }}><DefaultLoader /></div>
        return (
            <div className={`fatoresSwotCard ${this.state.shake ? 'shake' : ''}`}
                onClick={() => { this.shake() }}
                onMouseEnter={() => { if (this.state.item.descricao.length > 30) this.toggleHover(true) }}
                onMouseLeave={() => { if (this.state.item.descricao.length > 30) this.toggleHover(false) }}
            >
                <div className={'board'}>
                    <div className={`quadrante`} style={{ backgroundColor: this.state.color }}>
                        { this.state.quadrante.nome }
                    </div>
                </div>

                <div className={'text'}>
                    {this.state.hover
                        ? <span style={{margin: 5, textAlign: 'center'}}>{ this.state.item.descricao }</span> 
                        : <EllipsisText text={this.state.item.descricao} />     
                    }
                </div>

                {this.props.viewGut && this.renderProgressGUT()}
            </div>
        );
    }
}