import React from "react";
import {forwardRef} from 'react';
import Select from 'react-select';
import { components } from "react-select";
import ReactDOM from "react-dom";
import Sig from "../../../api/Sig";
import EssentialStyle from "../../../style/EssentialStyle";
import Constants from "../../../constants/Api";
import Colors from "../../../constants/Colors";
import { Form, InputGroup, Dropdown } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendar, faChevronDown, faChevronUp, faClose, faEllipsis, faCheck, faMagic, faUndo, faQuestionCircle, faCheckCircle, faTimesCircle, } from '@fortawesome/free-solid-svg-icons';
import DataHelper from "../../../helper/DataHelper";
import moment from "moment";
import DatePicker from "react-datepicker";
import UserAvatar from "../../tools/UserAvatar";
import PlanoAcaoHelper from "../../../helper/planoAcao/PlanoAcaoHelper";
import DefaultButton from "../../tools/DefaultButton";
import TextEditor from "../../tools/TextEditor/TextEditor";
import DefaultLoader from "../../tools/DefaultLoader";
import CustomTooltip from "../../tools/CustomTooltip";
import UploadFiles from "../../tools/UploadFiles";
import { confirmAlert } from "react-confirm-alert";
import CustomConfirm from "../../tools/CustomConfirm";
import SessionHelper from "../../../helper/SessionHelper";
import { toast } from "react-toastify";
import PlanoAcaoEtapaDependencia from "./PlanoAcaoEtapaDependencia";
import PlanoAcaoEtapaComentarioList from "./PlanoAcaoEtapaComentarioList";
import PlanoAcaoEtapaHistoricoList from "./PlanoAcaoEtapaHistoricoList";
import Tabs from "../../tools/Tabs";
import CurrencyInput from 'react-currency-input-field';
import DatePickerHelper from "../../../helper/DatePickerHelper";

const gateway = Constants.getSigEndPoint();

export default class PlanoAcaoEtapaModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isSmallScreen: this.props.isSmallScreen,
            etapa: null,
            idEtapa: this.props.idEtapa,
            loading: true,
            etapaOriginal: null,
            reloadHistorico: false,
            activeTab: 'comentarios',
            cloneEtapaModal: false,
            colaboradoresParaClone: [],

            loadingGptComo: false,
            loadingTextComo: false,
            listTextComo: [],

            loadingGptPorque: false,
            loadingTextPorque: false,
            listTextPorque: [],
        };
    }

    async componentDidMount() {
        this.setState({loading: true});
        await this.loadData();
    }

    pushToHistory = (idEtapa) => {
        window.parent.postMessage({
            type: 'push_history',
            path: `/planoAcao/detalhar/${this.props.codigoPlano}`,
            params: { etapa: idEtapa }
        }, '*');
    }

    getLinkEtapa = () => {
        const origin = window.location.ancestorOrigins[0] ? window.location.ancestorOrigins[0] : window.location.origin.replace('client.', "");
        return origin + "/planoAcao/detalhar/" + this.props.codigoPlano + "?etapa=" + this.state.etapa.id;
    }

    loadData = async (keepChanges = false, keepDates = false) => {
        if(this.state.idEtapa){
            const response = await Sig.request('GET', 'planoAcao/getEtapaCompleta', { idEtapa: this.state.idEtapa });
            if(response && response.status === 200){
                this.setState({
                    etapa: keepChanges ? keepDates ? this.state.etapa : {...this.state.etapa, data_inicio: response.etapa.data_inicio, data_fim: response.etapa.data_fim} : response.etapa,
                    etapaOriginal: response.etapa,
                    loading: false,
                });
            } else {
                this.props.detailsModalCallback();
            }
        }
    }

    getGptEtapa = async (campo) => {
        
        if (campo == 'como') {
            this.setState({ loadingGptComo: true });
        } else {
            this.setState({ loadingGptPorque: true });
        }

        let id_etapa = this.state.idEtapa;

        let oQue = DataHelper.removeHtmlTags(this.state.etapa.atividade);
        let porQue = DataHelper.removeHtmlTags(this.state.etapa.justificativa);
        let como = DataHelper.removeHtmlTags(this.state.etapa.descricao);

        let data = await Sig.request('GET', 'planoAcao/getComoPorqueEtapa', {id_etapa, oQue, porQue, como, isComo: campo == 'como'});

        if (campo == 'como') {
            this.setState({ loadingTextComo: true, listTextComo: [...this.state.listTextComo, this.state.etapa.descricao] }, () => {
                this.setState({ 
                    etapa: { ...this.state.etapa, descricao: data.text },
                    loadingGptComo: false, 
                    loadingTextComo: false 
                });
            });

        } else {
            this.setState({ loadingTextPorque: true, listTextPorque: [...this.state.listTextPorque, this.state.etapa.justificativa] }, () => {
                this.setState({ 
                    etapa: { ...this.state.etapa, justificativa: data.text },
                    loadingGptPorque: false, 
                    loadingTextPorque: false 
                });
            });
        }

    }

    backLatestDescription(campo) {
        if (campo == 'como') {
            let listTextComo = [...this.state.listTextComo];
            let text = listTextComo.pop();

            this.setState({ loadingTextComo: true, listTextComo }, () => {
                this.setState({ 
                    etapa: { ...this.state.etapa, descricao: text },
                    loadingTextComo: false,
                });
            });
        } else {
            let listTextPorque = [...this.state.listTextPorque];
            let text = listTextPorque.pop();

            this.setState({ loadingTextPorque: true, listTextPorque }, () => {
                this.setState({ 
                    etapa: { ...this.state.etapa, justificativa: text },
                    loadingTextPorque: false,
                });
            });
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.isSmallScreen !== this.props.isSmallScreen) {
            this.setState({
                isSmallScreen: this.props.isSmallScreen
            });
        }
        if(prevProps.idEtapa !== this.props.idEtapa) {
            this.setState({
                loading: true,
                idEtapa: this.props.idEtapa,
            }, () => this.loadData());

            if(this.props.idEtapa) this.pushToHistory(prevProps.idEtapa);
        }

        if(!this.state.cloneEtapaModal && this.state.colaboradoresParaClone.length > 0) {
            this.setState({colaboradoresParaClone: []});
        }
    }

    saveEtapa = async () => {
        if(this.state.etapa.id){
            const response = await Sig.request('POST', 'planoAcao/updateEtapa', {
                etapa: JSON.stringify(this.state.etapa)
            });
            if(response && response.status === 200){
                this.setState({
                    etapaOriginal: response.etapa,
                    etapa: response.etapa,
                }, () => {
                    this.reloadHistorico();
                    this.props.updateEtapasCallback();
                });
            } 
            // tratar erro?
        } 
    }

    renderTextEditor = (fieldName) => {
        const fields = [
            { 
                value: 'atividade', 
                getter: () => {return this.state.etapa.atividade},
                setter: (atividade) => this.setState({ etapa: { ...this.state.etapa, atividade } }),  
                placeholder: 'O que será feito?' 
            },
            { 
                value: 'descricao', 
                getter: () => {return this.state.etapa.descricao},
                setter: (descricao) => this.setState({ etapa: { ...this.state.etapa, descricao } }), 
                placeholder: 'Como será feito?' 
            },
            { 
                value: 'justificativa', 
                getter: () => {return this.state.etapa.justificativa},
                setter: (justificativa) => this.setState({ etapa: { ...this.state.etapa, justificativa } }), 
                placeholder: 'Por que será feito?' 
            },
        ];

        const hide = (fieldName === 'descricao' && this.state.loadingTextComo) || (fieldName === 'justificativa' && this.state.loadingTextPorque);

        const field = fields.find(f => f.value === fieldName);

        return(
            <div 
                style={{ backgroundColor: Colors.white, height: 'auto', minHeight: 60, width: '100%' }}
                  
            >
                { !hide && <TextEditor 
                    defaultValue={field.getter()}
                    placeholder={field.placeholder}
                    onChange={field.setter}
                    mentions={DataHelper.formatMentionData(this.props.participantes, 'id', 'nome')}
                    minHeight={90}
                />}
            </div>
        );
    }

    renderDatePicker = (value, onChangeCallback, minDate = moment().subtract(10, 'years').toDate(), maxDate = moment().add(10, 'years').toDate()) => {
        const CustomPicker = forwardRef(({ onClick, date }, ref) => {
            return (<div style={{
                ...EssentialStyle.rowFlexCenter,
                fontSize: 14,
                color: Colors.dark,
                fontWeight: 'bold',
                cursor: 'pointer',
                backgroundColor: Colors.secondaryButton,
                padding: 1,
                height: '100%',
                width: this.state.isSmallScreen ? 110 : 120,
                textAlign: 'center',
                borderRadius: 4,
                paddingLeft: 5,
                marginRight: 8
            }}>
                <FontAwesomeIcon icon={faCalendar} />
                <Form.Control
                    onChange={() => { }}
                    onClick={onClick}
                    ref={ref => this.periodoPicker = ref}
                    style={{
                        marginLeft: 2,
                        fontSize: 14,
                        color: Colors.dark,
                        fontWeight: 'bold',
                        cursor: 'pointer',
                        boxShadow: 'none',
                        backgroundColor: Colors.secondaryButton,
                        padding: 1,
                        height: '100%',
                        textAlign: 'center',
                    }}
                    value={date} 
                    readOnly
                />
            </div>);
    });

        let date = moment().toDate();
        if (value) date = moment(value).toDate();

        return (
            <DatePicker
                wrapperClassName="w-100"
                selected={date}
                type="date"
                minDate={minDate}
                maxDate={maxDate}
                onChange={(event) => {
                    let changedDate = moment(event);
                    onChangeCallback(changedDate);
                }}
                dateFormat="dd/MM/YYYY"
                customInput={<CustomPicker date={moment(date).format('DD/MM/YYYY')}/>}
                renderCustomHeader={(props) =>  
                    DatePickerHelper.renderCustomDatePickerHeader(props, minDate, maxDate)
                }
            />
        )
    };

    renderStatusSelector = () => {
        const DropdownIndicator = (props) => {
            const { selectProps } = props;
            const isMenuOpen = selectProps.menuIsOpen;

            return (
                <components.DropdownIndicator {...props}>
                    <div style={{
                        ...EssentialStyle.rowFlexStart,
                        backgroundColor: PlanoAcaoHelper.getEtapaStatus()[this.state.etapa.status].backgroundColor,
                        color: PlanoAcaoHelper.getEtapaStatus()[this.state.etapa.status].color,
                        fontSize: 14,
                        fontWeight: 600,
                        paddingLeft: 8,
                        paddingRight: 8,
                        paddingBottom: 2,
                        paddingTop: 2,
                        borderRadius: 4,
                        cursor: 'pointer'
                    }}>
                        {isMenuOpen ? <FontAwesomeIcon icon={faChevronUp} /> : <FontAwesomeIcon icon={faChevronDown} />}
                        <div style={{ marginRight: 8 }} />
                            {this.state.etapa.status > -1 ? PlanoAcaoHelper.getEtapaStatus()[this.state.etapa.status].label.toUpperCase() : 'Status'}
                        </div>
                </components.DropdownIndicator>
            );
        };

        return (
            <div
                style={{
                    ...EssentialStyle.rowFlexStart,
                    fontSize: 14
                }}
            >
                <Select
                    ref={ref => this.selectStatus = ref}
                    styles={{
                        control: (base, state) => ({
                            ...base,
                            ...EssentialStyle.titleBoxHomePage,
                            border: 'none',
                            width: '100%',
                            boxShadow: state.isFocused ? 'none' : null,
                            backgroundColor: "transparent",
                            padding: "0px",
                            justifyContent: "flex-start"
                        }),
                        container: (base, state) => ({
                            ...base,
                            width: '100%',
                            height: '100%',
                        }),
                        valueContainer: (base, state) => ({
                            ...base,
                            display: 'contents'
                        }),
                        indicatorsContainer: (base, state) => ({
                            ...base,
                            height: "100%"
                        }),
                        dropdownIndicator: (base, state) => ({
                            ...base,
                            padding: '0px',
                        }),
                        menu: (base, state) => ({
                            ...base,
                            width: '100%',
                            minWidth: '150px',
                            right: 0,
                            marginRight: 8,
                            margin: 0,
                            borderRadius: 4,
                            boxShadow: '0px 2px 2px 2px  rgba(50, 50, 50, 0.1)',
                        }),
                    }}
                    value={this.state.etapa.status ? this.state.etapa.status : null}
                    options={PlanoAcaoHelper.getEtapaStatus()}
                    formatOptionLabel={({ label, value }) => (
                        <div style={{
                            ...EssentialStyle.rowFlexStart,
                            paddingLeft: 8,
                            fontWeight: 600,
                            width: '100%',
                            cursor: 'pointer',
                            color: PlanoAcaoHelper.getEtapaStatus()[value].backgroundColor,
                        }}>
                            {label}
                        </div>
                    )}
                    placeholder={''}
                    noOptionsMessage={DataHelper.getSelectEmptyMessage}
                    isSearchable={false}
                    components={{ DropdownIndicator, IndicatorSeparator: null }}
                    onChange={async (value) => {
                        this.setState({
                            etapa: { ...this.state.etapa, status: value.value.toString()}
                        }, async () => {
                            if (this.selectStatus) this.selectStatus.blur();

                        });
                    }}
                />

            </div>
        );
    }
        
    renderSelectResponsavel = () => {

        const singleValue = ({
            children,
            ...props
          }) => (
            <components.SingleValue {...props}>
                {children}
            </components.SingleValue>
          );

        return (
            <div
                style={{
                    ...EssentialStyle.rowFlexStart,
                    fontSize: 14,
                    width: '100%',
                }}
            >
                <Select
                    menuPosition={'absolute'}
                    ref={ref => this.selectResponsavel = ref}
                    styles={{
                        container: (base, state) => ({
                            ...base,
                            width: '100%',
                        }),
                        control: (base, state) => ({
                            ...base,
                            ...EssentialStyle.titleBoxHomePage,
                            width: '100%',
                            backgroundColor: "transparent",
                            marginRight: 8
                        }),
                        SingleValue: (base, state) => ({
                            ...base,
                            ...EssentialStyle.titleBoxHomePage,
                            width: '100%',
                            backgroundColor: "transparent",
                            marginRight: 8
                        })
                    }}
                    value={DataHelper.formatSelectedData(this.state.etapa.colaborador, 'id', 'nome')}
                    options={DataHelper.formatSelectData(this.props.participantes, 'id', 'nome')}
                    formatOptionLabel={({ label, value }) => (
                        <div style={{ ...EssentialStyle.rowFlexStart, paddingLeft: 8, cursor: 'pointer' }}>
                            <UserAvatar user={this.props.participantes.find(p => p.id == value)} showNameAdaptative showName />
                        </div>
                    )}
                    placeholder={''}
                    noOptionsMessage={DataHelper.getSelectEmptyMessage}
                    isSearchable={false}
                    components={{ SingleValue: singleValue, IndicatorSeparator: null }}
                    onChange={async (value) => {
                        const novoColaborador = this.props.participantes.find(p => p.id == value.value);
                        this.setState({
                            etapa: { ...this.state.etapa, id_colaborador: value.value, colaborador: { ...this.state.etapa.colaborador, id: novoColaborador.id, nome: novoColaborador.nome, avatar: novoColaborador.avatar }
                             }
                        }, async () => {
                            if (this.selectResponsavel) this.selectResponsavel.blur();
                        });
                    }}
                />
            </div>
        );
    }        

    renderSelectParticipantes = () => {

        const participantes = this.state.etapa.participantes ? this.state.etapa.participantes.filter(p => p.id != this.state.etapa.id_colaborador) : [];
        const options = this.props.participantes.filter(p => p.id != this.state.etapa.id_colaborador);

        const MultiValue = ({
            children,
            ...props
          }) => (
            <components.MultiValue {...props}>
                {children}
            </components.MultiValue>
          );

        return (
            <div
                style={{
                    ...EssentialStyle.rowFlexStart,
                    fontSize: 14,
                    width: '100%',
                }}
            >
                <Select
                    menuPosition={'absolute'}
                    ref={ref => this.selectMulti = ref}
                    styles={{
                        container: (base, state) => ({
                            ...base,
                            width: '100%',
                        }),
                        control: (base, state) => ({
                            ...base,
                            ...EssentialStyle.titleBoxHomePage,
                            width: '100%',
                            backgroundColor: "transparent",
                            marginRight: 8,
                            maxHeight: 200,
                            overflowY: 'auto'
                        })
                    }}
                    value={ participantes && participantes.length > 0 ? DataHelper.formatSelectData(participantes, 'id', 'nome') : null }
                    options={DataHelper.formatSelectData(options, 'id', 'nome')}
                    formatOptionLabel={({ label, value }) => (
                        <div style={{ ...EssentialStyle.rowFlexStart, paddingLeft: 8, cursor: 'pointer' }}>
                            <UserAvatar user={this.props.participantes.find(p => p.id == value)} showNameAdaptative showName />
                        </div>
                    )}
                    isClearable 
                    isMulti
                    placeholder={''}
                    noOptionsMessage={DataHelper.getSelectEmptyMessage}
                    isSearchable={false}
                    components={{ MultiValue, IndicatorSeparator: null }}
                    onChange={async (value) => {
                        this.setState({
                            etapa: {...this.state.etapa, participantes: this.props.participantes.filter(p => value.map(v => v.value).includes(p.id))}
                        }, async () => {
                            if (this.selectMulti) this.selectMulti.blur();
                        });
                    }}
                />
            </div>
        );
    }        
    
    renderSelectPrioridade = () => {

        return (
            <div
                style={{
                    ...EssentialStyle.rowFlexStart,
                    fontSize: 14
                }}
            >
                <Select
                    ref={ref => this.selectPrioridade = ref}
                    styles={{
                        container: (base, state) => ({
                            ...base,
                            width: '100%',
                        }),
                        control: (base, state) => ({
                            ...base,
                            ...EssentialStyle.titleBoxHomePage,
                            width: '100%',
                            backgroundColor: "transparent",
                            marginRight: 8
                        })
                    }}
                    value={DataHelper.formatSelectedData(this.state.etapa, 'prioridade', 'prioridade_text')}
                    options={PlanoAcaoHelper.getEtapaPrioridades()}
                    formatOptionLabel={({ label, value }) => (
                        <div style={{ ...EssentialStyle.rowFlexStart, paddingLeft: 8, cursor: 'pointer' }}>
                            <div style={{ marginRight: 8 }}>{PlanoAcaoHelper.getPrioridadeIcon()[label]}</div> {label}
                        </div>
                    )}
                    placeholder={''}
                    noOptionsMessage={DataHelper.getSelectEmptyMessage}
                    isSearchable={false}
                    closeMenuOnSelect={true}
                    components={{ IndicatorSeparator: null }}
                    onChange={async (value) => {
                        this.setState({
                            etapa: {...this.state.etapa, prioridade: value.value.toString(), prioridade_text: value.label}
                        }, async () => {
                            if (this.selectPrioridade) this.selectPrioridade.blur();
                        });
                    }}
                />

            </div>
        );
    }

    handleTabChange = (tab) => {
        this.setState({ activeTab: tab.name })
    }

    renderHistorico = () => {
        return (
            <PlanoAcaoEtapaHistoricoList
                idEtapa={this.state.idEtapa}
                participantes={this.props.participantes}
                reload={this.state.reloadHistorico}
                reloadedCallback={() => { this.setState({ reloadHistorico: false }) }}
            />
        );
    }

    renderComentarios = () => {
        return (
            <div id="pa-comentarios" style={{ ...EssentialStyle.columnStart, width: '100%', height: 'auto', backgroundColor: Colors.white }}>
                <PlanoAcaoEtapaComentarioList idEtapa={this.state.idEtapa} participantes={this.props.participantes} />
            </div>
        )
    }

    renderOrcamento = () => {
        if(this.state.etapa.orcamento_previsto == null && this.state.etapa.orcamento_realizado == null) return null;
        return(
            <div style={{width: "100%", height: "auto", padding: "0px 10px", marginBottom: "10px"}}>
                <Form.Label style={{marginBottom: 2}}>Orçamento</Form.Label>
                <div style={EssentialStyle.rowSpaceBetween}>
                    <Form.Group style={{flex: 0.5, height: "auto"}}>
                        <Form.Label style={{fontSize: "0.9rem"}}>Previsto</Form.Label>  
                        <InputGroup className="mb-3">
                            <InputGroup.Text>R$</InputGroup.Text>
                            <CurrencyInput
                                style={{borderBottomRightRadius: 8, borderTopRightRadius: 8, paddingLeft: 10, border: "1px solid #ced4da", outline: "none", width: "calc(100% - 45px)"}}
                                name="orcamento-previsto"
                                placeholder="Orçamento Previsto"
                                defaultValue={this.state.etapa.orcamento_previsto}
                                decimalsLimit={2}
                                decimalScale={2}
                                onValueChange={(value, name, values) => {
                                    this.setState({etapa: {...this.state.etapa, orcamento_previsto: values.float}})
                                }}
                            />
                        </InputGroup>
                    </Form.Group>
                    <Form.Group style={{flex: 0.5, height: "auto", padding: "0px 10px"}}>
                        <Form.Label style={{fontSize: "0.9rem"}}>Realizado</Form.Label>  
                        <InputGroup className="mb-3">
                            <InputGroup.Text>R$</InputGroup.Text>
                            <CurrencyInput
                                style={{borderBottomRightRadius: 8, borderTopRightRadius: 8, paddingLeft: 10, border: "1px solid #ced4da", outline: "none", width: "calc(100% - 45px)"}}
                                name="orcamento-realizado"
                                placeholder="Orçamento Realizado"
                                defaultValue={this.state.etapa.orcamento_realizado}
                                decimalsLimit={2}
                                decimalScale={2}
                                onValueChange={(value, name, values) => {
                                    this.setState({etapa: {...this.state.etapa, orcamento_realizado: values.float}})
                                }}
                            />
                        </InputGroup>
                    </Form.Group>
                </div> 
            </div>
        )
    }

    cloneEtapa = async () => {
        if(this.state.colaboradoresParaClone.length > 0){
            const response = await Sig.request(
                'POST',
                'planoAcao/cloneEtapa',
                {
                    id_etapa: this.state.etapa.id,
                    responsaveis: JSON.stringify(this.state.colaboradoresParaClone.map(c => c.id))
                }
            );
            if(response && response.status === 200){
                this.closeClonarEtapaModal();
                this.props.detailsModalCallback();
                this.props.updateEtapasCallback();
            }
        } else {
            toast.error("Selecione ao menos um responsável para clonar a etapa");
        }
    }

    renderClonarEtapaModal = () => {

        if(!this.state.cloneEtapaModal) {
            return null;
        }

        const selectColaboradores = 
        <div
            style={{...EssentialStyle.rowFlexStart,fontSize: 14,width: '100%'}}>
            <Select
                menuPosition={'fixed'}
                ref={ref => this.selectColaboradoresClone = ref}
                styles={{
                    container: (base, state) => ({
                        ...base,
                        width: '100%',
                    }),
                    control: (base, state) => ({
                        ...base,
                        ...EssentialStyle.titleBoxHomePage,
                        width: '100%',
                        backgroundColor: "transparent",
                        marginRight: 8,
                        maxHeight: 200,
                        overflowY: 'auto'
                    })
                }}
                value={ this.state.colaboradoresParaClone ? DataHelper.formatSelectData(this.state.colaboradoresParaClone, 'id', 'nome') : null }
                options={DataHelper.formatSelectData(this.props.participantes, 'id', 'nome')}
                formatOptionLabel={({ label, value }) => (
                    <div style={{ ...EssentialStyle.rowFlexStart, paddingLeft: 8, cursor: 'pointer' }}>
                        <UserAvatar user={this.props.participantes.find(p => p.id == value)} showNameAdaptative showName />
                    </div>
                )}
                isClearable 
                isMulti
                placeholder={''}
                noOptionsMessage={DataHelper.getSelectEmptyMessage}
                isSearchable={false}
                components={{ IndicatorSeparator: null }}
                onChange={async (value) => {
                    this.setState({
                        colaboradoresParaClone:  this.props.participantes.filter(p => value.map(v => v.value).includes(p.id))
                    }, async () => {
                        if (this.selectColaboradoresClone) this.selectColaboradoresClone.blur();
                    });
                }}
            />
        </div>

        return (
            <div style={{ ...EssentialStyle.columnStart, width: '350px', minHeight: '150px', height: "auto",maxHeight: "300px", padding: 15, backgroundColor: Colors.white, position: "absolute", alignSelf: "center", top: "30%", borderRadius: 8, boxShadow: `0px 2px 3px 3px  rgba(50, 50, 50, 0.1)`, zIndex: 999 }}>
                <div style={{ ...EssentialStyle.columnCenter, width: '100%', height: '100%'}}>
                    <span style={{marginBottom: "10px"}}>Selecione a quem atribuir a tarefa clonada</span>
                    {selectColaboradores}
                    <div style={{ ...EssentialStyle.rowFlexEnd, width: '100%', height: 'auto', marginTop: 10 }}>
                        <DefaultButton title={'Clonar'} color={Colors.success} textColor={Colors.white} onClick={this.cloneEtapa} />
                        <DefaultButton title={'Cancelar'} color={Colors.error} textColor={Colors.white} onClick={() => this.setState({ cloneEtapaModal: false })} />
                    </div>
                </div>
            </div>
        )
    }

    openClonarEtapaModal = () => {
        this.setState({ cloneEtapaModal: true });
    }

    closeClonarEtapaModal = () => {
        this.setState({ cloneEtapaModal: false });
    }

    deleteEtapa = async () => {
        const response = await Sig.request('POST', 'planoAcao/deleteEtapa', { id_etapa: this.state.etapa.id });
        if(response && response.status === 200){
            this.props.detailsModalCallback();
            this.props.updateEtapasCallback();
        }
    }

    deleteEtapaModal = () => { 
        return (
            confirmAlert({
                customUI: ({ onClose }) => (
                    <CustomConfirm
                        title={'Excluir esta etapa?'}
                        message={<p style={{ marginTop: 10, marginBottom: 10 }}>Essa ação não pode ser desfeita</p>}
                        buttons={[
                            {
                                label: 'Remover',
                                color: SessionHelper.getColor(),
                                textColor: Colors.light,
                                onClick: () => { this.deleteEtapa(); onClose(); }
                            },
                            {
                                label: 'Cancelar',
                                onClick: () => { onClose(); }
                            },
                        ]}
                    />
                )
            })
        )
    }

    renderOptions = () => {
        const portalTarget = document.getElementById('root');
        return (
            <Dropdown className="optionsModalEtapa">
                <CustomTooltip style={{ height: 'auto' }} placement={"auto"} tooltip={"Opções"}>
                    <Dropdown.Toggle
                        onChange={() => {}}
                        style={{ 
                                backgroundColor: "transparent", 
                                color: Colors.dark, 
                                border: 'none',
                                zIndex: 25
                        }}
                    > 
                        <FontAwesomeIcon icon={faEllipsis} />
                    </Dropdown.Toggle>
                </CustomTooltip>
                {ReactDOM.createPortal(
                    <Dropdown.Menu style={{padding: 2, color: Colors.dark}}> 
                        <Dropdown.Item 
                            key={`copy-link-btn`} 
                            onClick={ async () => { 
                                try {
                                    await window.navigator.clipboard.writeText(this.getLinkEtapa());
                                    alert("Link copiado para a área de transferência");
                                } catch (e){
                                }
                                }}
                        >
                                <span style={{ fontSize: 14 }}>Compartilhar Etapa</span>
                        </Dropdown.Item>
                        <Dropdown.Item 
                            key={`clone-etapa-btn`} 
                            onClick={ this.openClonarEtapaModal }
                        >
                                <span style={{ fontSize: 14 }}>Clonar Etapa</span>
                        </Dropdown.Item>
                        <Dropdown.Item 
                            key={`delete-etapa-btn`} 
                            onClick={ this.deleteEtapaModal }
                        >
                                <span style={{ fontSize: 14 }}>Excluir Etapa</span>
                        </Dropdown.Item>
                    </Dropdown.Menu>
                , portalTarget)}
            </Dropdown>
        );
    }

    reloadHistorico = () => {
        if(!this.state.reloadHistorico && this.state.activeTab === 'historico') {
            this.setState({ reloadHistorico: true });
        }
    }

    uploadCallback = async (file) => {
        this.reloadHistorico();
        this.loadData(true, true) 
    }

    removeFileCallback = async (file) => {
        this.reloadHistorico();
        this.loadData(true, true)
    }

    renderAnexos = () => {
        return (
            <UploadFiles 
                uploadCallback={this.uploadCallback} 
                removeFileCallback={this.removeFileCallback} 
                id={this.props.idEtapa} 
                action={"planoAcao/etapa"} 
                limit={10}
            />
        );
    }

    renderDependecias = () => {
        return (
            <PlanoAcaoEtapaDependencia
                id={this.props.idEtapa}
                participantes={this.props.participantes}
                codigoPlano={this.props.codigoPlano}
                detailsModalCallback={this.props.detailsModalCallback}
                updateEtapasCallback={this.props.updateEtapasCallback}
                updateModalCallback={(keepDates) => { this.reloadHistorico(); this.loadData(true, keepDates); }}
            />
        );
    }

    render() {

        if(!this.state.etapa) return null;

        var changed = false;

        if(this.state.etapaOriginal && this.state.etapa) 
        {
            changed = true;
            if(this.state.etapa.id == this.state.etapaOriginal.id && this.state.etapa.data_inicio == this.state.etapaOriginal.data_inicio && 
                this.state.etapa.data_fim == this.state.etapaOriginal.data_fim && this.state.etapa.atividade == this.state.etapaOriginal.atividade && 
                this.state.etapa.descricao == this.state.etapaOriginal.descricao && this.state.etapa.justificativa == this.state.etapaOriginal.justificativa && 
                this.state.etapa.status == this.state.etapaOriginal.status && this.state.etapa.prioridade == this.state.etapaOriginal.prioridade && 
                this.state.etapa.id_colaborador == this.state.etapaOriginal.id_colaborador && JSON.stringify(this.state.etapa.participantes) == JSON.stringify(this.state.etapaOriginal.participantes) &&
                this.state.etapa.orcamento_previsto == this.state.etapaOriginal.orcamento_previsto && this.state.etapa.orcamento_realizado == this.state.etapaOriginal.orcamento_realizado &&
                this.state.etapa.anexos == this.state.etapaOriginal.anexos)
            {
                changed = false;
            }
        }

        const el = document.getElementById('root');

        const footer = () => {
            const tabs = [
                { name: 'comentarios', title: 'Comentários' },
                { name: 'historico', title: 'Histórico' },
            ];

            return (
                <div style={{ ...EssentialStyle.columnStart, height: 'auto', width: '100%', borderRadius: 8, backgroundColor: Colors.white}}>
                    <div style={{width: "auto"}}>
                        <Tabs 
                            activeTab={this.state.activeTab}
                            tabs={tabs}
                            primaryBackgroundColor={Colors.white}
                            primaryColor={Colors.dark}
                            onTabChange={this.handleTabChange}
                            isSmallScreen={this.state.isSmallScreen} 
                            style={EssentialStyle.rowFlexStart}
                            tabStyle={{ 
                                width: 'auto', 
                                outline: 'unset', 
                                borderColor: "unset", 
                                boxShadow: 'unset', 
                                borderBottomLeftRadius: 0, 
                                borderBottomRightRadius: 0, 
                                zIndex: 1, 
                                border: "unset", 
                                zIndex: 0,
                            }}
                            activeTabStyle={{ 
                                border: `1px solid ${Colors.homePage.lightGrey}`, 
                                borderBottom: "unset",
                            }}
                        />
                    </div>
                    <div style={{ ...EssentialStyle.rowFlexEnd, backgroundColor: Colors.white, borderRadius: 8, width: "100%", padding: "0px 15px", border: '1px solid ' + Colors.homePage.lightGrey, borderTopLeftRadius: 0, marginTop: "-1px" }}>
                        {this.state.activeTab === 'comentarios' ? this.renderComentarios() : this.renderHistorico()}
                    </div>
                </div>
            );
        };

        const modal = () => {

            if(this.state.loading) {
                return (
                    <div style={{ ...EssentialStyle.columnCenter, position: "fixed", right: "2vw", top: "2.5vh", width: this.state.isSmallScreen ? '95vw' : '35vw', height: '95vh', backgroundColor: Colors.white, zIndex: 20, borderRadius: 8, boxShadow: `0px 2px 5px 5px  rgba(50, 50, 50, 0.1)` }}>
                        <DefaultLoader color={Colors.dark} size={32}/>
                    </div>
                )
            }
            return (
                <div style={{ ...EssentialStyle.columnStart, position: "fixed", right: "2vw", top: "2.5vh", minWidth: this.state.isSmallScreen ? 385 : 550, width: this.state.isSmallScreen ? '95vw' : '40vw', height: '95vh', backgroundColor: Colors.white, zIndex: 20, borderRadius: 8, boxShadow: `0px 2px 5px 5px  rgba(50, 50, 50, 0.1)`, paddingBottom: 15 }}>
                    <div style={{ ...EssentialStyle.rowFlexEnd, backgroundColor: Colors.white, borderRadius: 8, width:"100%", height: 25, gap: 5, padding: "0px 15px", margin: "15px 0px" }}>
                            <div style={{ ...EssentialStyle.rowFlexStart, gap: 5, width: "auto", height: "100%", flexGrow: 1 }}>
                                {
                                    changed ? 
                                    <DefaultButton title={'Salvar'} tooltipPlacement={"auto"} color={Colors.success} textColor={Colors.white} onClick={this.saveEtapa} />
                                    : null
                                }
                            </div>
                            <div style={{ ...EssentialStyle.rowFlexStart, gap: 5, width: "auto", height: "100%" }}>
                                {this.props.hasApproval &&
                                    <CustomTooltip
                                        tooltip={this.state.etapa.aprovacao_avaliador == 1 ? 'Etapa Aprovada' : this.state.etapa.aprovacao_avaliador == 2 ? 'Etapa Rejeitada' : 'Etapa não Avaliada'}
                                        style={{ ...EssentialStyle.rowFlexStart, width: '100%' }}
                                    >
                                        <FontAwesomeIcon
                                            icon={this.state.etapa.aprovacao_avaliador == 1 ? faCheckCircle : this.state.etapa.aprovacao_avaliador == 2 ? faTimesCircle : faQuestionCircle}
                                            style={{ marginRight: 10, color: this.state.etapa.aprovacao_avaliador == 1 ? Colors.success : this.state.etapa.aprovacao_avaliador == 2 ? Colors.error : Colors.primary }}
                                        />
                                    </CustomTooltip>
                                }
                                {this.renderOptions()}
                                <DefaultButton title={''} tooltip={"Fechar"} tooltipPlacement={"auto"} leftIcon={<FontAwesomeIcon icon={faClose} />} color={"transparent"} textColor={Colors.dark} onClick={() => this.props.detailsModalCallback()} />
                            </div>
                    </div>
                    <div style={{ ...EssentialStyle.columnStart, width: '100%', height: 'calc(100% - 25px)', overflowY: 'auto', padding: 15, paddingTop: 0, paddingRight: this.state.isSmallScreen ? 15 : 0, scrollbarGutter: "stable" }}>
                        <div style={{...EssentialStyle.rowSpaceBetween, width: "100%", height: "auto", padding: "0px 10px", alignItems: "start", flexWrap: "wrap", justifyContent: this.state.isSmallScreen ? "space-evenly" : "space-between"}}>
                            <div style={{...(this.state.isSmallScreen ? EssentialStyle.rowFlexCenter : EssentialStyle.rowFlexStart)}}>
                                <Form.Group style={EssentialStyle.columnStart}>
                                    <Form.Label style={{fontSize: 10, marginBottom: 0}}>Início</Form.Label>
                                    {this.renderDatePicker(
                                        this.state.etapa.data_inicio, 
                                        (date) => { 
                                            this.setState({ etapa: {...this.state.etapa, data_inicio : moment(date).format('YYYY-MM-DD')} });
                                        }, 
                                        moment().subtract(100, 'years').toDate(),
                                        this.state.etapa.data_fim ? moment(this.state.etapa.data_fim).toDate() : moment().add(100, 'years').toDate())}
                                </Form.Group>
                                <Form.Group style={EssentialStyle.columnStart}>
                                    <Form.Label style={{fontSize: 10, marginBottom: 0}}>Fim</Form.Label>
                                    {this.renderDatePicker(
                                        this.state.etapa.data_fim, 
                                        (date) => {
                                            this.setState({ etapa: {...this.state.etapa, data_fim: moment(date).format('YYYY-MM-DD')} });
                                        }, 
                                        this.state.etapa.data_inicio ? moment(this.state.etapa.data_inicio).toDate() : moment().subtract(100, 'years').toDate())}
                                </Form.Group>
                            </div>
                            <Form.Group style={this.state.isSmallScreen ? EssentialStyle.columnCenter : EssentialStyle.columnStart}>
                                <Form.Label style={{fontSize: 10, marginBottom: 0}}>Status</Form.Label>
                                {this.renderStatusSelector()}
                            </Form.Group>
                        </div>
                        <Form.Group style={{width: "100%", height: "auto", padding: "0px 10px"}}>
                            <Form.Label>O quê?</Form.Label>
                            {this.renderTextEditor("atividade")}
                        </Form.Group>
                        <Form.Group style={{width: "100%", height: "auto", padding: "0px 10px"}}>
                            <div style={EssentialStyle.rowSpaceBetween}>
                                <Form.Label>
                                    Como?
                                    {
                                        this.state.listTextComo.length > 0 && <FontAwesomeIcon   
                                            icon={faUndo} 
                                            className={`backComoIcon hoverMouse`} 
                                            style={{marginLeft: 10, borderRadius: '50%'}} 
                                            onClick={() => {
                                                const iconElement = document.querySelector('.backComoIcon');
                                                iconElement.classList.add('rotateClockwise');
                                                setTimeout(() => {
                                                    iconElement.classList.remove('rotateClockwise');
                                                    this.backLatestDescription('como');
                                                }, 1000);
                                                
                                            }}
                                        />
                                    }
                                </Form.Label>
                                {DataHelper.removeHtmlTags(this.state.etapa.atividade) && <FontAwesomeIcon 
                                    icon={faMagic} 
                                    style={{marginRight: 10, padding: 3, borderRadius: '50%'}} 
                                    className={ this.state.loadingGptComo ? 'rotating' : 'hoverMouse' }
                                    onClick={() => {
                                        !this.state.loadingGptComo && this.getGptEtapa("como");
                                    }}
                                />}
                            </div>
                            {this.renderTextEditor("descricao")}
                        </Form.Group>
                        <Form.Group style={{width: "100%", height: "auto", padding: "0px 10px"}}>
                            <div style={EssentialStyle.rowSpaceBetween}>
                                <Form.Label>
                                    Por quê?
                                    {
                                        this.state.listTextPorque.length > 0 && <FontAwesomeIcon   
                                            icon={faUndo} 
                                            className={`backPorqueIcon hoverMouse`} 
                                            style={{marginLeft: 10, borderRadius: '50%'}} 
                                            onClick={() => {
                                                const iconElement = document.querySelector('.backPorqueIcon');
                                                iconElement.classList.add('rotateClockwise');
                                                setTimeout(() => {
                                                    iconElement.classList.remove('rotateClockwise');
                                                    this.backLatestDescription('porque');
                                                }, 1000);
                                                
                                            }}
                                        />
                                    }
                                </Form.Label>
                                {DataHelper.removeHtmlTags(this.state.etapa.atividade) && <FontAwesomeIcon 
                                    icon={faMagic} 
                                    style={{marginRight: 10, padding: 3, borderRadius: '50%'}} 
                                    className={ this.state.loadingGptPorque ? 'rotating' : 'hoverMouse' }
                                    onClick={() => {
                                        !this.state.loadingGptPorque && this.getGptEtapa("porque");
                                    }}
                                />}
                            </div>
                            {this.renderTextEditor("justificativa")}
                        </Form.Group>
                        <Form.Group style={{width: "100%", height: "auto", padding: "0px 10px", marginBottom: "10px"}}>
                            <Form.Label>Prioridade</Form.Label>
                            {this.renderSelectPrioridade()}    
                        </Form.Group>
                        {this.renderOrcamento()}
                        <Form.Group style={{width: "100%", height: "auto",  padding: "0px 10px", marginBottom: "10px"}}>
                            <Form.Label>Responsável</Form.Label>
                            {this.renderSelectResponsavel()}    
                        </Form.Group>
                        <Form.Group style={{width: "100%", height: "auto", padding: "0px 10px", marginBottom: "10px"}}>
                            <Form.Label>Participantes</Form.Label>
                            {this.renderSelectParticipantes()}    
                        </Form.Group>
                        <Form.Group style={{width: "100%", height: "auto", padding: "0px 10px", marginBottom: "10px"}}>
                            <Form.Label>Dependências</Form.Label>
                            {this.renderDependecias()}
                        </Form.Group>
                        <Form.Group style={{width: "100%", height: "auto", padding: "0px 10px", marginBottom: "10px"}}>
                            <Form.Label>Anexos</Form.Label>
                            {this.renderAnexos()}
                        </Form.Group>
                        <div style={{width: "100%", height: "auto", padding: "10px", marginBottom: "10px"}}>
                            {footer()}
                        </div>
                    </div>
                    {this.renderClonarEtapaModal()}
                </div>
            );
        }

        return (
            ReactDOM.createPortal(
                modal(),
                el
            )
        );
    }
}