import React from "react";
import Sig from "../../../../../api/Sig";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLink } from '@fortawesome/free-solid-svg-icons';
import DefaultLoader from "../../../../tools/DefaultLoader";
import EllipsisText from "../../../../tools/EllipsisText";
import Colors from "../../../../../constants/Colors";
import GaugeCircle from "../../../../tools/GaugeCircle/GaugeCircle";

import './ProjetoChartResumo.css';


export default class ProjetoChartResumo extends React.Component {
    
    state = {
        loading: true,
        loadingPerformance: true,
        id: this.props.id || null,
        projeto: this.props.projeto || null,
        iniciativaNome: this.props.iniciativaNome || null,
        progress: 0,
    }

    componentDidMount() {
        this.loadProjeto();
    }

    componentDidUpdate(prevProps) {
        if (this.props.id !== prevProps.id) {
            this.setState({ id: this.props.id });
            this.loadProjeto();
        }

        if (this.props.projeto !== prevProps.projeto) {
            this.setState({ projeto: this.props.projeto });
        }

        if (this.props.iniciativaNome !== prevProps.iniciativaNome) {
            this.setState({ iniciativaNome: this.props.iniciativaNome });
        }
    }

    loadProjeto = async() => {
        this.setState({ loading: true, loadingPerformance: true });

        if (this.state.id) {
            let { projeto, status_text } = await Sig.request('GET', 'pe/projeto/getProjeto', { id: this.state.id, relations: 1 });
            this.setState({ projeto, status_text });
        } else {
            let { status_text } = await Sig.request('GET', 'pe/projeto/getStatusProjeto', { id: this.state.projeto.id });
            this.setState({ status_text });
        }

        this.setState({ loading: false }, () => {this.loadingPerformance()});
    }

    loadingPerformance = async() => {
        if (this.state.projeto) {
            let performance = await Sig.request('GET', 'pe/projeto/getProgress', { id: this.state.projeto.id });
            this.setState({
                progress: performance.progress,
            }, () => { this.setState({ loadingPerformance: false }) });
        } else {
            this.setState({ loadingPerformance: false });
        }
    }

    renderLoading() {
        return (
            <div className="projeto-chart-resumo">
                <DefaultLoader />
            </div>
        );
    }

    renderChart() {

        const renderGauge = () =>{

            let color;
            
            if (this.state.loadingPerformance) {
                color = Colors.homePage.grey;
            } else {
                color = Colors.planoAcao.status.backgroundColor[this.state.status_text];
            }

            return (
                <GaugeCircle
                    min={0}
                    max={100}
                    color={color}
                    progress={this.state.loadingPerformance ? 0 : this.state.progress}
                    title={''}
                />
            );
        }

        let nome = (
            <div className={"line-name"} style={{ display: this.state.projeto && this.state.projeto.nome ? '' : 'none'}}>
                <EllipsisText text={this.state.projeto.nome} />
            </div>
        )

        let gauge = (
            <div className="line-gauge" style={{
                height: `calc(100% - ${this.props.nome ? '22px' : '0px'} - ${this.props.link ? '22px' : '0px'}`,
                display: this.props.gauge ? 'flex' : 'none',
            }} >
                {renderGauge()}
            </div>
        );

        let link = (
            <div className="line-link" style={{display: this.props.link ? 'flex' : 'none'}}>
                <FontAwesomeIcon
                    icon={faLink}
                    style={{
                        marginRight: 10,
                        marginLeft: 10,
                        cursor: 'pointer'
                    }}
                    onClick={() => { 
                        window.parent.postMessage({ 
                            type: '_blank', 
                            url: `/pe/projeto/gestao/${this.props.projeto.id}` 
                        }, '*') 
                    }}
                />
            </div>
        );

        return (
            <div 
                className={`projeto-chart-resumo ${this.props.onClick ? 'clickable' : ''}`}
                onClick={() => { if (this.props.onClick) { this.props.onClick(); } }}
            >
                {nome}
                {gauge}
                {link}
            </div>
        );
    }

    render() {
        return this.state.loading ? this.renderLoading() : this.renderChart()
    }
}