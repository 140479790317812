import React from "react";

import EssentialStyle from "../../../../style/EssentialStyle";
import LayoutHelper from "../../../../helper/LayoutHelper";
import LoadingPage from "../../../../pages/LoadingPage";
import DataHelper from "../../../../helper/DataHelper";
import Colors from "../../../../constants/Colors";

import ListRelatorios from "../ListRelatorios/ListRelatorios";
import CriacaoRelatorio from "../CriacaoRelatorio/CriacaoRelatorio";
import Relatorio from "./Relatorio/Relatorio";
import DiagnosticoHelper from "../../../../helper/diagnostico/DiagnosticoHelper";
import DefaultButton from "../../../tools/DefaultButton";
import Select from 'react-select';
import { Form } from "react-bootstrap";

import './RelatoriosProcesso.css';
import Tabs from "../../../tools/Tabs";
import IdiomaHelper from "../../../../helper/IdiomaHelper";

export default class RelatoriosProcesso extends React.Component {

    state = {
        loading: false,
        activeTabRelatorio: 'publicados',
        tabsRelatorios: [
            {
                name: 'publicados', 
                title: {
                    'pt': 'Publicados',
                    'en': 'Published',
                    'es': 'Publicados'
                }
            }
        ],
        idProcesso: this.props.idProcesso,
        idPeriodoAvaliacao: null,
        periodos: [],
        lang: IdiomaHelper.getIdioma(),
        idRelatorioOpen: null,
        isEmpresaAvaliadora: false,
        isSmallScreen: LayoutHelper.isSmallScreen(),
    }

    async componentDidMount() {
        this.loadData();
        window.addEventListener('resize', this.verifyScreen);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.verifyScreen);
    }

    async loadData() {
        this.setState({ loading: true });

        let periodos = await DiagnosticoHelper.getPeriodosAvaliacao(this.state.idProcesso);
        let idPeriodoAvaliacao = periodos.length > 0 ? periodos[0].id : null;
        let isEmpresaAvaliadora = await DiagnosticoHelper.isEmpresaAvaliadora(this.state.idProcesso);

        if (isEmpresaAvaliadora) {
            this.setState({
                tabsRelatorios: [
                    ...this.state.tabsRelatorios,
                    {
                        name: 'emElaboracao', title: IdiomaHelper.getStruct({
                            'pt': 'Em Elaboração',
                            'en': 'Being Developed',
                            'es': 'En Elaboración'
                        })
                    },
                    {
                        name: 'modelos',
                        title: IdiomaHelper.getStruct({
                            'pt': 'Modelos',
                            'en': 'Templates',
                            'es': 'Modelos'
                        })
                    },
                ],
            });
        }

        await this.loadNumRelatorios(idPeriodoAvaliacao, isEmpresaAvaliadora);

        this.setState({ periodos, isEmpresaAvaliadora, idPeriodoAvaliacao, loading: false });
    }

    async loadNumRelatorios(idPeriodoAvaliacao = this.state.idPeriodoAvaliacao, isEmpresaAvaliadora = this.state.isEmpresaAvaliadora) {

        let paramsRelatorios = {
            idPeriodoAvaliacao: idPeriodoAvaliacao,
            relations: 0,
            lang: this.state.lang
        }

        if(isEmpresaAvaliadora) {
            paramsRelatorios.avaliador = true;
            paramsRelatorios.avaliado = false;
        } else {
            paramsRelatorios.avaliador = false;
            paramsRelatorios.avaliado = true;
        }

        let numRelatorios = await DiagnosticoHelper.getNumRelatorios(
            paramsRelatorios.idPeriodoAvaliacao, 
            paramsRelatorios.avaliador, 
            paramsRelatorios.avaliado, 
            paramsRelatorios.relations, 
            paramsRelatorios.lang
        );

        let activeTabRelatorio = this.state.activeTabRelatorio;

        if (numRelatorios.publicados > 0) {
            activeTabRelatorio = 'publicados';
        } else if (numRelatorios.emElaboracao > 0 && isEmpresaAvaliadora) {
            activeTabRelatorio = 'emElaboracao';
        } else {
            activeTabRelatorio = 'publicados';
        }

        this.setState({ activeTabRelatorio });
    }

    verifyScreen = () => {
        this.setState({ isSmallScreen: LayoutHelper.isSmallScreen() });
    }

    openRelatorioCallback = (idRelatorio) => {
        this.setState({ idRelatorioOpen: idRelatorio });
    }

    renderPage() {
        return (
            <div className="relatorioPage">
                {this.renderHeader()}
                {this.state.idPeriodoAvaliacao ? this.renderBody() : DiagnosticoHelper.renderPeriodoNotSelected(this.state.lang)}
            </div>
        );
    }

    renderHeader() {

        let localStyle = {
            boxShadow: Colors.boxShadow,
            ...(this.state.isSmallScreen ? EssentialStyle.columnCenter : EssentialStyle.rowSpaceBetween)
        }

        return (
            <div className="header" style={localStyle}>
                <div className="left" style={{ width: this.state.isSmallScreen ? '100%' : '' }}>
                    <Form.Group>
                        <Select
                            styles={{ control: (base) => ({ ...base, border: 'none', minWidth: '40vh', marginRight: 2 }) }}
                            options={DataHelper.formatSelectData(this.state.periodos, 'id', 'nome')}
                            value={DataHelper.formatSelectData(this.state.periodos, 'id', 'nome').find(option => option.value === this.state.idPeriodoAvaliacao)}
                            onChange={(valueSelected) => { this.setState({ idPeriodoAvaliacao: valueSelected.value }) }}
                            placeholder={'Selecione um Período de Avaliação'}
                            noOptionsMessage={DataHelper.getSelectEmptyMessage}
                            isSearchable
                            menuPortalTarget={document.body}
                        />
                    </Form.Group>

                    <Select
                        styles={{ control: (base) => ({ ...base, border: 'none', width: '9rem' }) }}
                        options={IdiomaHelper.getIdiomasSelect()}
                        value={IdiomaHelper.getIdiomasSelect().find(option => option.value === this.state.lang)}
                        onChange={(valueSelected) => { this.setState({ lang: valueSelected.value }, () => { IdiomaHelper.setIdioma(valueSelected.value) }) }}
                        noOptionsMessage={DataHelper.getSelectEmptyMessage}
                        isSearchable
                        menuPortalTarget={document.body}
                    />

                </div>

                <div className="right" style={{ width: this.state.isSmallScreen ? '100%' : '' }}>

                    {this.renderTabs('activeTabRelatorio', 'tabsRelatorios')}

                    <DefaultButton
                        style={{ width: 38, height: 38, marginLeft: 8 }}
                        fontSize={14}
                        color={Colors.secondaryButton}
                        textColor={Colors.dark}
                        link={"https://advis.freshdesk.com/support/solutions"} />
                </div>
            </div>
        )
    }

    renderTabs(itemState, itensTabs) {
        if(!this.state.isEmpresaAvaliadora) return <div />;

        return (
           <Tabs 
                activeTab={this.state[itemState]}
                tabs={this.state[itensTabs]}
                isSmallScreen={this.state.isSmallScreen}
                onTabChange={(tab) => {this.setState({ [itemState]: tab.name })}}
                tabStyle={{ width: this.state.isSmallScreen ? '32vw' : 150 }}
                lang={this.state.lang}
            />
        )
    }

    renderBody() {
        return (
            <div className="body">
                {this.renderActiveTab(this.state.activeTabRelatorio)}
            </div>
        )
    }

    renderActiveTab(tab) {
        switch (tab) {
            case 'publicados':
            case 'emElaboracao':
                return (
                    <ListRelatorios
                        isEmpresaAvaliadora={this.state.isEmpresaAvaliadora}
                        idPeriodoAvaliacao={this.state.idPeriodoAvaliacao}
                        status={tab == 'emElaboracao' ? 0 : 1}
                        lang={this.state.lang}
                        openRelatorio={(idRelatorioOpen) => this.openRelatorioCallback(idRelatorioOpen)}
                    />
                );
            case 'modelos':
                return (
                    <CriacaoRelatorio idPeriodoAvaliacao={this.state.idPeriodoAvaliacao} lang={this.state.lang} />
                );
            default:
                return <div />;
        }
    }

    render() {

        if (this.state.idRelatorioOpen) return <Relatorio id={this.state.idRelatorioOpen} closeRelatorio={() => this.openRelatorioCallback(null)} lang={this.state.lang} />
        return this.state.loading ? <LoadingPage /> : this.renderPage();
    }
}