import React from "react";
import './PlanoAcaoVerificacaoEficacia.css';

import UserAvatar from "../../../tools/UserAvatar";
import moment from "moment";
import { Form, Row, Col } from "react-bootstrap";
import Select from 'react-select';
import TextEditor from "../../../tools/TextEditor/TextEditor";
import DataHelper from "../../../../helper/DataHelper";
import Sig from "../../../../api/Sig";
import DefaultLoader from "../../../tools/DefaultLoader";
import { toast } from "react-toastify";
import DefaultButton from "../../../tools/DefaultButton";
import Colors from "../../../../constants/Colors";
import EssentialStyle from "../../../../style/EssentialStyle";

export default class PlanoAcaoVerificacaoEficacia extends React.Component {
    state = {
        loading: true,
        codigo: this.props.codigo,
        verificacaoEficacia: null,
        edit: false,

        optionsEficacia: null,
        eficacia: null,
        optionsImplantacao: null,
        implantacao: null,
        optionsQualidade: null,
        qualidade: null,
        optionsRiscos: null,
        riscos: null,
        justificativa: '',
    }

    async componentDidMount() {
        await this.loadData();
    }

    async componentDidUpdate(prevProps) {

        if (prevProps.atualizaEficacia !== this.props.atualizaEficacia) {
            await this.loadData();
        }
    }

    async loadData() {
        this.setState({ loading: true });
        let data = await Sig.request('GET', 'planoAcao/getVerificacaoEficacia', { codigo: this.state.codigo });
        let edit = data.verificacaoEficacia == null;
        let canEdit = data.canEdit;

        let optionsEficacia = data.eficacia;
        let optionsImplantacao = data.implantacao;
        let optionsQualidade = data.qualidade;
        let optionsRiscos = data.riscos;

        let eficacia = null;
        let implantacao = null;
        let qualidade = null;
        let riscos = null;
        let justificativa = '';

        if (data.verificacaoEficacia) {
            eficacia = optionsEficacia.find(item => item.value == data.verificacaoEficacia.eficaz);
            implantacao = optionsImplantacao.find(item => item.value == data.verificacaoEficacia.implantado);
            qualidade = optionsQualidade.find(item => item.value == data.verificacaoEficacia.sistema_qualidade);
            riscos = optionsRiscos.find(item => item.value == data.verificacaoEficacia.riscos);
            justificativa = data.verificacaoEficacia.justificativa;
        }

        this.setState({
            verificacaoEficacia: data.verificacaoEficacia,
            optionsEficacia,
            eficacia,
            optionsImplantacao,
            implantacao,
            optionsQualidade,
            qualidade,
            optionsRiscos,
            riscos,
            justificativa,
            edit,
            canEdit
        }, () => {
            this.setState({ loading: false });
        });
    }

    async save() {
        let { codigo, eficacia, implantacao, qualidade, riscos, justificativa } = this.state;

        if (!codigo || !eficacia || !implantacao || !qualidade || !riscos || !justificativa) {
            return toast.error('Preencha todos os campos da Verificação de Eficácia');
        } else {
            Sig.request('POST', 'planoAcao/saveVerificacaoEficacia', { codigo, eficacia: eficacia.value, implantacao: implantacao.value, qualidade: qualidade.value, riscos: riscos.value, justificativa }).then(() => {
                this.loadData();
                this.props.updateEficacia();
            });
        }
    }

    renderView() {

        return (
            <div className="containerEficacia">
                <Form style={{ width: '100%' }}>
                    <Row>
                        <Form.Group as={Col} md={2} xxl={3} className={'formGroup'}>
                            <div className={"label"}>Implantação: </div>
                            <div className={"value"}>{this.state.implantacao?.label}</div>
                        </Form.Group>

                        <Form.Group as={Col} md={2} xxl={3} className={'formGroup'}>
                            <div className={"label"}>Eficácia: </div>
                            <div className={"value"}>{this.state.eficacia?.label}</div>
                        </Form.Group>

                        <Form.Group as={Col} md={4} xxl={3} className={'formGroup'}>
                            <div className={"label"}>Avaliação de Riscos e Oportunidades: </div>
                            <div className={"value"}>{this.state.riscos?.label}</div>
                        </Form.Group>

                        <Form.Group as={Col} md={4} xxl={3} className={'formGroup'}>
                            <div className={"label"}>Mudanças no Sistema de Gestão da Qualidade: </div>
                            <div className={"value"}>{this.state.qualidade?.label}</div>
                        </Form.Group>

                        <Form.Group as={Col} md={2} xxl={2}>
                            <div className={"label"}>Avaliador: </div>
                            <div style={{ ...EssentialStyle.rowFlexCenter }}>
                                <UserAvatar id={this.state.verificacaoEficacia?.id_colaborador} />
                            </div>
                        </Form.Group>

                        <Form.Group as={Col} md={8} xxl={8}>
                            <div className={"label"}>Justificativa: </div>
                            <div className={"value"} dangerouslySetInnerHTML={{ __html: this.state.justificativa }}></div>
                        </Form.Group>

                        <Form.Group as={Col} md={2} xxl={2}>
                            <div className={"label"}>Data: </div>
                            <div className={"value"}>
                                {this.state.verificacaoEficacia?.data_verificacao
                                    ? moment(this.state.verificacaoEficacia.data_verificacao).format("DD/MM/YYYY HH:mm")
                                    : ""}
                            </div>
                        </Form.Group>
                    </Row>

                    <div className="contentActions">
                        {this.state.canEdit && <DefaultButton
                            title={"Editar"}
                            color={Colors.success}
                            onClick={() => { this.setState({ edit: true }) }}
                        />}
                    </div>
                </Form>
            </div>
        );
    }

    renderEdit() {

        return (
            <div className="containerEficacia">
                <Form style={{ width: '100%' }}>
                    <Row>
                        <Form.Group as={Col} md={6} xxl={3} className={'formGroup'}>
                            <Form.Label>Implantação</Form.Label>
                            <Select
                                options={this.state.optionsImplantacao}
                                placeholder={'Selecione ...'} noOptionsMessage={DataHelper.getSelectEmptyMessage}
                                value={this.state.implantacao}
                                onChange={(value) => { this.setState({ implantacao: value }) }}
                            />
                        </Form.Group>

                        <Form.Group as={Col} md={6} xxl={3} className={'formGroup'}>
                            <Form.Label>Eficácia</Form.Label>
                            <Select
                                options={this.state.optionsEficacia}
                                placeholder={'Selecione ...'} noOptionsMessage={DataHelper.getSelectEmptyMessage}
                                value={this.state.eficacia}
                                onChange={(value) => { this.setState({ eficacia: value }) }}
                            />
                        </Form.Group>

                        <Form.Group as={Col} md={6} xxl={3} className={'formGroup'}>
                            <Form.Label>Avaliação de Riscos e Oportunidades</Form.Label>
                            <Select
                                options={this.state.optionsRiscos}
                                placeholder={'Selecione ...'} noOptionsMessage={DataHelper.getSelectEmptyMessage}
                                value={this.state.riscos}
                                onChange={(value) => { this.setState({ riscos: value }) }}
                            />
                        </Form.Group>

                        <Form.Group as={Col} md={6} xxl={3} className={'formGroup'}>
                            <Form.Label>Mudanças no Sistema de Gestão da Qualidade</Form.Label>
                            <Select
                                options={this.state.optionsQualidade}
                                placeholder={'Selecione ...'} noOptionsMessage={DataHelper.getSelectEmptyMessage}
                                value={this.state.qualidade}
                                onChange={(value) => { this.setState({ qualidade: value }) }}
                            />
                        </Form.Group>

                        <Form.Group as={Col} md={12} xxl={12} className={'formGroup'}>
                            <Form.Label>Justificativa</Form.Label>
                            <TextEditor
                                defaultValue={this.state.justificativa || ""}
                                onChange={(value) => { this.setState({ justificativa: value }) }}
                                placeholder={`Justifique a sua Verificação de Eficácia`}
                            />
                        </Form.Group>
                    </Row>

                    <div className="contentActions">
                        <DefaultButton
                            title={"Salvar"}
                            color={Colors.success}
                            onClick={() => { this.save() }}
                        />

                        <DefaultButton
                            title={"Cancelar"}
                            color={Colors.error}
                            onClick={() => { this.loadData() }}
                        />
                    </div>
                </Form>
            </div>
        );
    }

    renderLoading() {
        return (
            <div className="loading">
                <DefaultLoader title={"Carregando Verificação de Eficácia ..."} />
            </div>
        );
    }

    render() {

        if (this.state.loading) return this.renderLoading();
        if (this.state.edit) return this.renderEdit();
        return this.renderView();
    }
}