import React from "react";

import Sig from "../../../../../api/Sig";

import DefaultLoader from "../../../../tools/DefaultLoader";
import OrcamentoProjetos from "../OrcamentoProjetos/OrcamentoProjetos";
import TimelineMarcos from "../../canvas/TimelineMarcos";
import PlanoAcaoCardRelatorio from "../../../planoAcao/PlanoAcaoRelatorio/PlanoAcaoCardRelatorio";
import CustomTooltip from "../../../../tools/CustomTooltip";
import DefaultButton from "../../../../tools/DefaultButton";
import EssentialStyle from "../../../../../style/EssentialStyle";
import moment from "moment";
import ModalVinculaMarco from "../../canvas/ModalVinculaMarco";
import Tabs from "../../../../tools/Tabs";
import Colors from "../../../../../constants/Colors";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationTriangle, faCalendar, faAdd } from "@fortawesome/free-solid-svg-icons";

import './DetalhamentoMarcosCollapsable.css';
export default class DetalhamentoMarcosCollapsable extends React.Component {

    state = {
        loading: this.props.loading,
        idProjeto: this.props.idProjeto || null,
        projeto: this.props.projeto || null,
        marcosCanvas: this.props.marcosCanvas || null,
        orcamento: this.props.orcamento || null,
        canvas: this.props.canvas || null,
        activeTab: this.props.activeTab || 'lista',
        activeTimelineTab: this.props.activeTimelineTab || 'mes',
        modalAddPlano: false,
    }

    componentDidMount() {
        this.loadData();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.loading !== this.props.loading || prevProps.idProjeto !== this.props.idProjeto) {
            this.setState({ loading: this.props.loading, idProjeto: this.props.idProjeto }, () => this.loadData());
        }
    }

    loadData = async () => {
        if (this.state.idProjeto) {
            this.setState({ loading: true });
            await this.loadMarcos();
        }
    }

    loadMarcos = async () => {
        this.setState({ loading: true });
        let { projeto, canvas, marcosCanvas, orcamento } = await Sig.request('GET', 'pe/projeto/getProjeto', { id: this.state.idProjeto, relations: 1 });
        this.setState({ projeto, canvas, marcosCanvas, orcamento, loading: false });
    }

    renderLoading() {
        return (
            <div className="DetalhamentoMarcosCollapsable">
                <div className="loading">
                    <DefaultLoader />
                </div>
            </div>
        )
    }

    renderDetalhamentoMarcos() {
        return (
            <div className="DetalhamentoMarcosCollapsable">
                {
                    this.state.canvas ?
                    <>
                        {this.renderMenu()}
                        {this.renderCollapsable()}
                        {this.renderModalAddPlano()}
                    </>
                    :
                    <div style={{ ...EssentialStyle.rowFlexCenter, gap: 5, height: 50, width: "100%" }}>
                        <div style={{ fontSize: 18, color: Colors.homePage.darkGrey, fontWeight: 500 }}>Sem Canvas Vinculado!</div>
                    </div>
                }
            </div>
        )
    }

    renderMenu() {
        return (
            this.state.canvas && this.state.marcosCanvas && this.state.marcosCanvas.length > 0 &&
                <div className={"menu"}>
                    <Tabs
                        tabs={[
                            { name: 'lista', title: 'Lista' },
                            { name: 'timeline', title: 'Timeline' },
                            {name: 'orcamento', title: 'Orçamento'},
                        ]}
                        style={{ alignSelf: 'flex-start' }}
                        activeTab={this.state.activeTab}
                        onTabChange={(tab) => this.setState({ activeTab: tab.name })}
                    />
                    {this.state.activeTab === 'timeline' &&
                        <Tabs
                            tabs={[
                                { name: 'ano', title: 'Ano' },
                                { name: 'mes', title: 'Mês' },
                                { name: 'semana', title: 'Semana' },
                            ]}
                            style={{ alignSelf: 'flex-end' }}
                            activeTab={this.state.activeTimelineTab}
                            onTabChange={(tab) => this.setState({ activeTimelineTab: tab.name })}
                        />
                    }
                </div>
        )
    }

    renderCollapsable() {
        if (this.state.activeTab === 'timeline') return this.renderTimeline();
        if (this.state.activeTab === 'orcamento') return this.renderOrcamento();
        return this.renderList();
    }

    renderOrcamento() {
        return (
            <OrcamentoProjetos 
                orcamento={this.state.orcamento}
                planos={this.state.marcosCanvas.filter(marco => marco.planoAcao).flatMap(marco => marco.planoAcao)}
                isSmallScreen={this.props.isSmallScreen}
            />
        )
    }

    renderTimeline() {
        return (
            <>
                <TimelineMarcos
                    marcos={[...this.state.marcosCanvas]}
                    changeMarcosCallback={() => { }}
                    id_pe_projeto_canvas={this.state.canvas.id}
                    softBorder
                    qtdMonthsEnd={16}
                    qtdMonthsStart={6}
                    granularidade={this.state.activeTimelineTab}
                    style={{ height: "auto", minHeight: "unset", maxHeight: "450px", width: "100%", maxWidth: "100%", borderRadius: 8, padding: 0 }}
                />
                {this.props.hasMarginBottom && <div style={{minHeight: 90}}>&nbsp;</div>}
            </>
        )
    }

    renderList() {
        return (
            this.state.canvas && this.state.marcosCanvas && this.state.marcosCanvas.length > 0 ? 
            
                <>
                    {this.state.marcosCanvas.map((marco, index) => {
                        return (
                            <div key={"marco-" + index} style={{ display: "flex", minWidth: "100%", maxWidth: "100%" }}>
                                {marco.planoAcao ?
                                    <PlanoAcaoCardRelatorio key={marco.planoAcao.id} plano={marco.planoAcao} onClick={(codigo) => window.parent.postMessage({ type: '_blank', url: `/planoAcao/detalhar/${codigo}` }, '*')} isSmallScreen={this.state.isSmallScreen} noMargin />
                                    :
                                    this.renderMarcoCard(marco)
                                }
                            </div>
                        )
                    })}
                    {this.props.hasMarginBottom && <div style={{minHeight: 90}}>&nbsp;</div>}                </>
                :
                <div style={{ ...EssentialStyle.rowFlexCenter, gap: 5, height: 50, width: "100%" }}>
                    <div style={{ fontSize: 18, color: Colors.homePage.darkGrey, fontWeight: 500 }}>Nenhum Marco definido!</div>
                </div>
        )
    }

    renderMarcoCard(marco) {
        return (
            <div style={{ ...EssentialStyle.card, ...EssentialStyle.rowFlexStart, gap: 10, width: '100%', padding: 10, borderRadius: 8, height: 90 }}>
                <div style={{ ...EssentialStyle.rowFlexStart, gap: 5, width: "100%" }}>
                    <div style={{ ...EssentialStyle.rowFlexStart, gap: 5, flex: 1, width: "30%" }}>
                        <CustomTooltip tooltip={"Sem Plano de Ação Vinculado"} placement="right">
                            <FontAwesomeIcon icon={faExclamationTriangle} style={{ marginLeft: 10 }} className={'icon warning'} />
                        </CustomTooltip>
                        <div style={{ fontSize: 18, color: Colors.homePage.darkGrey, fontWeight: 500, textOverflow: "ellipsis", whiteSpace: "nowrap", overflow: "hidden" }}>{marco.descricao}</div>
                    </div>
                    <div style={{ ...EssentialStyle.rowFlexCenter, gap: 5, width: "auto" }}>
                        {this.renderPeriodoMarco(marco)}
                        <DefaultButton
                            tooltip={"Vincular Plano de Ação"}
                            color={"transparent"}
                            textColor={Colors.homePage.darkGrey}
                            leftIcon={<FontAwesomeIcon icon={faAdd} />}
                            onClick={() => { this.setState({ modalAddPlano: marco.id }) }}
                        />
                    </div>
                </div>
            </div>
        )
    }

    renderPeriodoMarco(marco) {
        return (
            <div style={{ ...EssentialStyle.rowFlexStart, marginLeft: 2, backgroundColor: Colors.tag, color: Colors.dark, fontSize: 12, padding: 5, borderRadius: 5, fontWeight: 600 }}>
                <FontAwesomeIcon icon={faCalendar} style={{ color: Colors.dark, fontSize: 14, marginRight: 4 }} />
                <span>{marco.data_inicio != 0 ? moment(marco.data_inicio).format('DD/MM/YY') : "- -"}{marco.data_fim ? `~ ${moment(marco.data_fim).format('DD/MM/YY')}` : ``}</span>
            </div>
        );
    }

    renderModalAddPlano() {

        if (!this.state.modalAddPlano) return null;

        const closeModal = async () => {
            this.setState({ modalAddPlano: false }); 
            await this.props.callbakLoadProjeto();
        }
        
        return (
            <ModalVinculaMarco 
                tipoProgresso={this.state.projeto.tipo_progresso} 
                idMarco={this.state.modalAddPlano} 
                closeModal={async () => closeModal()}
            />
        )
    }

    render() {
        if(this.state.loading) return this.renderLoading();
        return this.renderDetalhamentoMarcos();
    }
}