import React from "react";
import Sig from "../../../../api/Sig";

import DataHelper from "../../../../helper/DataHelper";
import SwotHelper from "../../../../helper/pe/SwotHelper";
import Colors from "../../../../constants/Colors";
import LoadingPage from "../../../../pages/LoadingPage";
import EssentialStyle from "../../../../style/EssentialStyle";

import { toast } from "react-toastify";
import { InputGroup, Form } from "react-bootstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEye, faEyeSlash, faStar, faExclamation, faPlusSquare, faCheck, faTimes } from '@fortawesome/free-solid-svg-icons'


import DefaultLoader from "../../../tools/DefaultLoader";
import TooManyAvatars from "../../../tools/TooManyAvatars";
import EllipsisText from "../../../tools/EllipsisText";
import DefaultButton from "../../../tools/DefaultButton";
import ProgressBarCustom from "../../../tools/ProgressBar/ProgressBarCustom";


export default class projetoTableView extends React.Component {
    state = {
        step: 0,
        loading: true,
        loadingTable: true,
        loadingInfos: true,
        idMapa: this.props.id,
        mapaRequest: null,
        mapa: null,
        perspectivas: [],
        perspectivasOriginals: [],
        visibilidade: 'oculto', // or 'visivel'
        height: 110,

        nFatores: null, 
        nResponsaveisProjetos: null, 
        nIniciativas: null, 
        nProjetos: null, 
        nObjetivos: null, 
        fatores: [], 
        responsaveisProjetos: [],

        hoverFator: false,
        hoverObjetivo: false,
        hoverIniciativa: false,

        creatingCanvas: false,
        creatingCanvasProjeto: false,

        searchText: '',
        
        scrollIsOn: null,
    }

    componentDidUpdate(prevProps, prevState) {

        if (this.state.scrollIsOn === null){
            this.verifyScroll();
        } else if (this.state.scrollIsOn === false || this.state.scrollIsOn === true){
            if(this.state.scrollIsOn !== prevState.scrollIsOn){
                this.verifyScroll(); 
            }
        }
    }

    async componentDidMount() {
        window.addEventListener('resize', this.verifyScroll);
        await this.loadMapa();
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.verifyScroll);
    }

    async loadMapa(loading = true) {

        this.setState({ loading: loading });
        
        let mapaRequest = await Sig.request('GET', 'pe/mapa/getMapa', { id: this.state.idMapa });

        this.setState({ mapaRequest }, () => {
            this.loadTable(loading);
        });
    }

    loadTable = async (loading = true) => {
        this.setState({ loading: loading });

        if (this.state.mapaRequest?.mapa?.id) {
            const mapa = this.state.mapaRequest.mapa;
            const perspectivas = this.state.mapaRequest.perspectivas;

            this.setState({ mapa, perspectivas }, () => {
                this.loadResponsaveis(loading);
                this.getInfosProjetos();
            });            
        }
    }

    loadResponsaveis = async (loading = true) => {

        this.setState({ loading: loading });

        let perspectivas = [...this.state.perspectivas];

        for(let perspectiva of perspectivas){
            for(let objetivo of perspectiva.objetivos){
                objetivo.responsaveis = await Sig.request('GET', `pe/mapa/getResponsaveisObjetivos`, { id: objetivo.objetivo.id });
            
                for(let fator of objetivo.fatores){
                    let {quadrante} = await Sig.request('POST', 'pe/projeto/getQuadranteItens', { id: fator.fator.id});
                    fator.quadrante = quadrante;
                }
            }
        }

        this.setState({ perspectivas }, () => {
            this.loadCanvas(loading);
        });
    }

    loadCanvas = async (loading = true) => {

        this.setState({ loading: loading });
        let perspectivas = [...this.state.perspectivas];

        for(let perspectiva of perspectivas){
            for(let objetivo of perspectiva.objetivos){
                for(let iniciativa of objetivo.iniciativas){
                    for(let projeto of iniciativa.projetos){
                        let { canvas, podeCriaCanvas, podeVisualizarCanvas, podeFavoritarProjeto } = (await Sig.request('GET', 'pe/projeto/getProjeto', { id: projeto.id, relations: 1 }));
                        canvas = canvas?.length > 0 ? canvas[0] : false;
                        projeto.canvas = canvas;
                        projeto.podeCriaCanvas = podeCriaCanvas;
                        projeto.podeVisualizarCanvas = podeVisualizarCanvas;
                        projeto.podeFavoritarProjeto = podeFavoritarProjeto;
                    }
                }
            }
        }

        this.setState({ perspectivas: perspectivas, perspectivasOriginals: perspectivas, loading: false });
    }

    getInfosProjetos() {
        this.setState({ loadingInfos: true });
        
        let nFatores = 0, nResponsaveisProjetos = 0, nIniciativas = 0, nProjetos = 0, nObjetivos = 0;
        let fatores = [];
        let responsaveisProjetos = [];

        this.state.perspectivas.forEach(perspectiva => {
            perspectiva.objetivos.forEach(objetivo => {
                
                nObjetivos += 1;
                objetivo.iniciativas.forEach(iniciativa => {
                    nIniciativas += 1;
                    if (iniciativa.projetos && iniciativa.projetos.length > 0) {
                        nProjetos += iniciativa.projetos.length;
                        iniciativa.projetos.forEach(projeto => {
                            responsaveisProjetos.push(projeto.id_responsavel);
                        });
                    }
                });

                objetivo.fatores.forEach(fator => {
                    fatores.push(fator);
                });
            });
        });

        nFatores = new Set(fatores).size;
        nResponsaveisProjetos = new Set(responsaveisProjetos).size;

        this.setState({ nFatores, nResponsaveisProjetos, nIniciativas, nProjetos, nObjetivos, fatores, responsaveisProjetos, loadingInfos: false });
    }

    addCanvas = async () => {
        let { canvas } = await Sig.request('POST', 'pe/canvas/add',
            {
                nome: 'remover',
                id_pe_projeto: this.state.creatingCanvas,
                id_responsavel: this.state.creatingCanvasProjeto.id_responsavel
            }
        );

        if (canvas.id) {
            this.setState({ canvas, creatingCanvas: false, creatingCanvasProjeto: false }, () => {
                window.parent.postMessage({ type: '_blank', url: `/pe/canvas?id=${canvas.id}` }, '*');
                this.loadCanvas();
            });
        } else {
            toast.error('Erro ao criar canvas');
        }
    }

    verifyScroll = () =>{

        let element = document.querySelector('.divScroll');

        if (element && element.scrollHeight) {
            // Verifique se o scroll vertical está ativo
            this.setState({ scrollIsOn: element.scrollHeight > element.clientHeight });
        }
    }

    async filterObjetivos(searchText) {

        this.setState({ searchText });

        if (searchText && searchText.length && searchText.length > 0) {

            searchText = searchText.toLowerCase();

            let perspectivas = JSON.parse(JSON.stringify(this.state.perspectivasOriginals));

            perspectivas = perspectivas.map(perspectiva => {
                perspectiva.objetivos = perspectiva.objetivos.map(objetivo => {
                    objetivo.iniciativas = objetivo.iniciativas.map(iniciativa => {
                        iniciativa.projetos = iniciativa.projetos.filter(projeto => {
                            return projeto.nome.toLowerCase().includes(searchText);
                        });
                        return iniciativa.projetos.length > 0 ? iniciativa : null;
                    }).filter(iniciativa => iniciativa !== null);
                    return objetivo.iniciativas.length > 0 ? objetivo : null;
                }).filter(objetivo => objetivo !== null);
                return perspectiva.objetivos.length > 0 ? perspectiva : null;
            }).filter(perspectiva => perspectiva !== null);

            this.setState({ perspectivas }, () => {this.verifyScroll()});
        } else {
            let perspectivas = JSON.parse(JSON.stringify(this.state.perspectivasOriginals));
            this.setState({ perspectivas }, () => {this.verifyScroll()});
        }
    }

    renderSearch() {
        return (
            <InputGroup>
                <Form.Control
                    placeholder={"Buscar projetos..."}
                    type="text"
                    onChange={(event) => { this.filterObjetivos(event.target.value); }}
                    value={this.state.searchText}
                />
            </InputGroup>
        )
    }

    renderInfos() {

        return (
            <div style={ styles.infosTable }>
                
                <div style={{ ...EssentialStyle.rowFlexStart, width: '60%', minHeight: '100%' }}>
                { this.renderSearch() }
                </div>

                <div style={styles.showcase}>
                    <p style={styles.showcaseText}>
                        {this.state.nProjetos} {this.state.nProjetos > 1 ? 'Projetos Criados' : 'Projeto Criado'}
                        <span style={{margin: '0px 15px'}}>-</span>
                        {this.state.nIniciativas} {this.state.nIniciativas > 1 ? 'Iniciativas Criadas' : 'Iniciativa Criada'}
                        <span style={{margin: '0px 15px'}}>-</span>
                        {this.state.nFatores} {this.state.nFatores > 1 ? 'Fatores Distintos Relacionados' : 'Fator Distinto Relacionado'}
                        <span style={{margin: '0px 15px'}}>-</span>
                        {this.state.nObjetivos} {this.state.nObjetivos > 1 ? 'Objetivos Criados' : 'Objetivo Criado'}
                        <span style={{margin: '0px 15px'}}>-</span>
                        {this.state.nResponsaveisProjetos} {this.state.nResponsaveisProjetos > 1 ? 'Responsáveis por Projetos' : 'Responsável por Projetos'}
                    </p>
                </div>

                <DefaultButton 
                    tooltip={this.state.visibilidade === 'oculto' ? 'Ver tabela completa' : 'Ver tabela resumida'}
                    tooltipPlacement={'bottom'} 
                    leftIcon={this.state.visibilidade === 'oculto' ? <FontAwesomeIcon icon={faEyeSlash} /> : <FontAwesomeIcon icon={faEye} /> } 
                    color={Colors.secondaryButton} 
                    textColor={Colors.dark} 
                    loading={this.state.loading} 
                    onClick={() => { this.setState({ visibilidade: this.state.visibilidade == 'oculto' ? 'visivel' : 'oculto' }, () => {this.verifyScroll()}) }}
                />
            </div>
        )
    }

    renderHeader() {

        return (
            <tr>
                <td colSpan={12} style={{ padding: 0 }}>
                    <div style={{ ...EssentialStyle.rowFlexStart, width: '100%', height: 40, padding: 0 }}>
                        <div style={{ ...styles.celulaHeader, width: 'calc(15%)' }}>Perspectivas</div>
                        <div style={{ ...styles.celulaHeader, width: 'calc((85% / 4))' }}>Objetivos</div>
                        <div style={{ ...styles.celulaHeader, width: 'calc((85% / 4))' }}>Fatores</div>
                        <div style={{ ...styles.celulaHeader, width: 'calc((85% / 4))' }}>Iniciativas</div>
                        <div style={{ ...styles.celulaHeader, width: 'calc((85% / 4))', borderRight: 'none' }}>Projetos</div>
                    </div>
                </td>
            </tr>
        )
    }

    renderTable() {

        return this.state.perspectivas.map((perspectiva, key) => {
            return (this.renderPerspectivaLine(perspectiva))
        });
    }

    renderPerspectivaLine(perspectiva) {
        let height = this.state.height || 90;
        let lenObjetivos = perspectiva.objetivos.length;
        let lenFatores = 0;
        let totalIniciativasAndProjetos = 0;

        if (this.state.visibilidade === 'visivel') {

            lenFatores = perspectiva.objetivos.reduce((total, objetivo) => {
                return total + (objetivo.fatores.length == 0 ? 1 : objetivo.fatores.length);
            }, 0);


            totalIniciativasAndProjetos = perspectiva.objetivos.reduce((total, objetivo) => {

                let totalIniciativas = objetivo.iniciativas.reduce((totalIniciativas, iniciativa) => {
                    return totalIniciativas + (iniciativa.projetos ? (iniciativa.projetos.length > 0 ? iniciativa.projetos.length : 1) : 1);
                }, 0);

                let totalFatores = objetivo.fatores ? objetivo.fatores.length : 1;

                return total + (Math.max(totalIniciativas, totalFatores) || 1);
            }, 0);


        } else {
            lenFatores = perspectiva.objetivos.reduce((total, objetivo) => {
                
                let temProjetos = 0;

                if (objetivo.iniciativas && objetivo.iniciativas.length > 0) {
                    
                    temProjetos = objetivo.iniciativas.reduce((temProjetos, iniciativa) => {
                        return temProjetos + (iniciativa.projetos ? iniciativa.projetos.length : 0);
                    }, 0);

                } else {

                    temProjetos = 0;
                }

                if (temProjetos > 0) {
                    return total + (objetivo.fatores? objetivo.fatores.length : 0);
                } else {
                    return total + 0;
                }
                

            }, 0);

            totalIniciativasAndProjetos = perspectiva.objetivos.reduce((total, objetivo) => {

                let lenProjetos = 0;
                
                if (objetivo.iniciativas && objetivo.iniciativas.length > 0) {
                    lenProjetos = objetivo.iniciativas.reduce((temProjetos, iniciativa) => {
                        return temProjetos + (iniciativa.projetos ? iniciativa.projetos.length : 0);
                    }, 0);
                }

                if (lenProjetos > 0) {
                    return total + lenProjetos
                } else {
                    return total + 0;
                }
                
            }, 0);
        }

        let maxValor = 0;
        if (this.state.visibilidade === 'visivel') {
            maxValor = Math.max(lenObjetivos, lenFatores, totalIniciativasAndProjetos) == 0 ? 1 : Math.max(lenObjetivos, lenFatores, totalIniciativasAndProjetos);
        } else {
            maxValor = Math.max(lenFatores, totalIniciativasAndProjetos) == 0 ? 1 : Math.max(lenFatores, totalIniciativasAndProjetos);
        }

        if (this.state.visibilidade === 'oculto' && totalIniciativasAndProjetos == 0) return null;

        return (
            <tr key={`perspectiva-${perspectiva.perspectiva.id}`}>
                <td colSpan={12} style={{ padding: 0 }}>
                    <div style={{ ...EssentialStyle.rowFlexStart, width: '100%', height: (height * maxValor), padding: 0 }}>
                        {/* Perspectivas */}
                        <div style={{ ...styles.celulaRow, width: 'calc(15%)', fontSize: 14 }}>{DataHelper.removeHtmlTags(perspectiva.perspectiva.nome)}</div>
                        {/* Objetivos */}
                        <div style={{ ...styles.celulaColumn, width: 'calc(85% / 4)' }}>

                            { perspectiva.objetivos && perspectiva.objetivos.length == 0 ? this.renderEmptyCell("Objetivos") : null }
                            { perspectiva.objetivos.map((objetivo, key) => {

                                let totalProjetos = 0;
                                if (this.state.visibilidade === 'visivel') {
                                    totalProjetos = objetivo.iniciativas
                                        ? objetivo.iniciativas.reduce((total, iniciativa) =>
                                            total + (iniciativa.projetos ? (iniciativa.projetos.length > 0 ? iniciativa.projetos.length : 1) : 1), 0)
                                        : 1;

                                    totalProjetos = totalProjetos == 0 ? 1 : totalProjetos;
                                } else {
                                    totalProjetos = objetivo.iniciativas
                                        ? objetivo.iniciativas.reduce((total, iniciativa) =>
                                            total + (iniciativa.projetos ? iniciativa.projetos.length : 0), 0)
                                        : 0;
                                    
                                }

                                let linhas = Math.max(objetivo.fatores.length, totalProjetos);
                                if (this.state.visibilidade == 'visivel' || totalProjetos > 0 ) {
                                    return (
                                        <div 
                                            key={`objetivo-${objetivo.objetivo.id}`}
                                            style={{
                                                ...styles.subCelula,
                                                height: (height * linhas),
                                                maxHeight: (height * linhas),
                                                minHeight: (height * linhas),
                                                borderTop: key === 0 ? 'none' : styles.subCelula.borderTop,
                                                padding: 0,
                                            }
                                        }>
                                            {this.renderObjetivoCell(objetivo, height, linhas)}
                                        </div>
                                    )
                                } else {
                                    return null
                                }

                            })}
                        </div>
                        {/* Fatores */}
                        <div style={{ ...styles.celulaColumn, width: 'calc(85% / 4)' }}>
                            
                            {perspectiva.objetivos && perspectiva.objetivos.length == 0 ? this.renderEmptyCell("Fatores") : null}
                            {perspectiva.objetivos && perspectiva.objetivos.length == 0 ? null : perspectiva.objetivos.map((objetivo, key) => {

                                let totalProjetos = 0;
                                if (this.state.visibilidade === 'visivel') {
                                    totalProjetos = objetivo.iniciativas
                                        ? objetivo.iniciativas.reduce((total, iniciativa) =>
                                            total + (iniciativa.projetos ? (iniciativa.projetos.length > 0 ? iniciativa.projetos.length : 1) : 1), 0)
                                        : 1;
                                    totalProjetos = totalProjetos == 0 ? 1 : totalProjetos;
                                } else {
                                    totalProjetos = objetivo.iniciativas
                                        ? objetivo.iniciativas.reduce((total, iniciativa) =>
                                            total + (iniciativa.projetos ? iniciativa.projetos.length : 0), 0)
                                        : 0;
                                }

                                let linhas = Math.max(objetivo.fatores.length, totalProjetos);
                                if (this.state.visibilidade == 'visivel' || totalProjetos > 0 ) {
                                    return (
                                        <div style={{
                                            ...styles.subCelula,
                                            height: (height * linhas),
                                            maxHeight: (height * linhas),
                                            minHeight: (height * linhas),
                                            borderTop: key === 0 ? 'none' : styles.subCelula.borderTop,
                                            padding: 0
                                        }}> 
                                            {objetivo.fatores && objetivo.fatores.length == 0 ? this.renderEmptyCell("Fatores", height) : null}
                                            {objetivo.fatores.map((fator, key) => {

                                                let nFatores = objetivo.fatores.length;

                                                return (
                                                    <div style={{
                                                        ...styles.subCelulaFatores,
                                                        borderTop: key === 0 ? 'none' : styles.subCelulaFatores.borderTop,
                                                        padding: 5,
                                                        height: `calc(100%/${nFatores})`,
                                                        maxHeight: `calc(100%/${nFatores})`,
                                                        fontSize: 14
                                                    }}>
                                                        {this.renderFatorCell(fator, height, linhas, nFatores)}
                                                    </div>
                                                )
                                            })}
                                        </div>
                                    )
                                } else {
                                    return null
                                }

                            })}
                        </div>
                        {/* Iniciativas */}
                        <div style={{ ...styles.celulaColumn, width: 'calc(85% / 4)' }}>
                            {perspectiva.objetivos && perspectiva.objetivos.length == 0 ? this.renderEmptyCell("Iniciativas") : null}
                            {perspectiva.objetivos.map((objetivo, key) => {

                                let totalProjetos = 0;
                                if (this.state.visibilidade === 'visivel') {
                                    totalProjetos = objetivo.iniciativas
                                        ? objetivo.iniciativas.reduce((total, iniciativa) =>
                                            total + (iniciativa.projetos ? (iniciativa.projetos.length > 0 ? iniciativa.projetos.length : 1) : 1), 0)
                                        : 1;
                                        totalProjetos = totalProjetos == 0 ? 1 : totalProjetos;
                                } else {
                                    totalProjetos = objetivo.iniciativas
                                        ? objetivo.iniciativas.reduce((total, iniciativa) =>
                                            total + (iniciativa.projetos ? iniciativa.projetos.length : 0), 0)
                                        : 0;
                                }

                                let linhas = Math.max(objetivo.fatores.length, totalProjetos);
                                let contadorInciativas = 0;
                                if (this.state.visibilidade == 'visivel' || totalProjetos > 0 ) {
                                    return (
                                        <div style={{
                                            ...styles.subCelula,
                                            height: (height * linhas),
                                            maxHeight: (height * linhas),
                                            borderTop: key === 0 ? 'none' : styles.subCelula.borderTop,
                                            padding: 0
                                        }}>

                                            { objetivo.iniciativas && objetivo.iniciativas.length == 0 ? this.renderEmptyCell("Iniciativas", height) : null }
                                            { objetivo.iniciativas.map((iniciativa) => {

                                                let nLineIniciativas = 0;
                                                let nProjetos = 0;

                                                if(this.state.visibilidade === 'visivel'){
                                                    nLineIniciativas = iniciativa.projetos ? (iniciativa.projetos.length > 0 ? iniciativa.projetos.length : 1) : 1;
                                                    
                                                    nProjetos = objetivo.iniciativas.reduce((total, iniciativa) => {
                                                        return total + (iniciativa.projetos ? (iniciativa.projetos.length > 0 ? iniciativa.projetos.length : 1): 1);
                                                    }, 0);
                                                } else {
                                                    nLineIniciativas = iniciativa.projetos ? iniciativa.projetos.length : 0;
                                                    nProjetos = objetivo.iniciativas.reduce((total, iniciativa) => {
                                                        return total + (iniciativa.projetos ? iniciativa.projetos.length : 0);
                                                    }, 0);
                                                }

                                                if (nLineIniciativas > 0){
                                                    contadorInciativas += 1;
                                                    return (
                                                        <div style={{
                                                            ...styles.subCelula,
                                                            borderTop: contadorInciativas === 1 ? 'none' : styles.subCelula.borderTop,
                                                            padding: 5,
                                                            height: `calc((100%/${nProjetos})*${nLineIniciativas})`,
                                                            maxHeight: `calc((100%/${nProjetos})*${nLineIniciativas})`,
                                                            fontSize: 14
                                                        }}>
                                                            {this.renderInciativaCell(iniciativa, height, linhas, nProjetos, nLineIniciativas)}
                                                        </div>
                                                    )
                                                } else {
                                                    return null;
                                                }
                                            })}
                                        </div>
                                    )
                                } else {
                                    return null;
                                }
                            })}
                        </div>
                        {/* Projetos */}
                        <div style={{ ...styles.celulaColumn, width: 'calc(85% / 4)', borderRight: 'none' }}>
                            
                            {perspectiva.objetivos && perspectiva.objetivos.length == 0 ? this.renderEmptyCell("Projetos") : null}
                            {perspectiva.objetivos.map((objetivo, key) => {

                                let totalProjetos = 0;
                                if (this.state.visibilidade === 'visivel') {
                                    totalProjetos = objetivo.iniciativas
                                        ? objetivo.iniciativas.reduce((total, iniciativa) =>
                                            total + (iniciativa.projetos ? (iniciativa.projetos.length > 0 ? iniciativa.projetos.length : 1) : 1), 0)
                                        : 1;
                                    totalProjetos = totalProjetos == 0 ? 1 : totalProjetos;
                                } else {
                                    totalProjetos = objetivo.iniciativas
                                        ? objetivo.iniciativas.reduce((total, iniciativa) =>
                                            total + (iniciativa.projetos ? iniciativa.projetos.length : 0), 0)
                                        : 0;
                                }

                                let linhas = Math.max(objetivo.fatores.length, totalProjetos);
                                let contadorInciativas = 0;
                                if (this.state.visibilidade == 'visivel' || totalProjetos > 0 ) {
                                    return (
                                        <div style={{
                                            ...styles.subCelula,
                                            height: (height * linhas),
                                            maxHeight: (height * linhas),
                                            borderTop: key === 0 ? 'none' : styles.subCelula.borderTop,
                                            padding: 0
                                        }}>
                                            { objetivo.iniciativas && objetivo.iniciativas.length == 0 ? this.renderEmptyCell("Projetos") : null }
                                            { objetivo.iniciativas.map((iniciativa) => {

                                                let nLineIniciativas = 0;
                                                let nIniciativas = 0;

                                                if(this.state.visibilidade === 'visivel'){
                                                    nLineIniciativas = iniciativa.projetos ? (iniciativa.projetos.length > 0 ? iniciativa.projetos.length : 1) : 1;
                                                    nIniciativas = objetivo.iniciativas.length;
                                                } else {
                                                    nLineIniciativas = iniciativa.projetos ? iniciativa.projetos.length : 0;
                                                    nIniciativas = objetivo.iniciativas.filter(iniciativa => iniciativa.projetos && iniciativa.projetos.length > 0).length;
                                                }

                                                if (nLineIniciativas > 0){
                                                    contadorInciativas += 1;
                                                    return (
                                                        <div style={{
                                                            ...styles.subCelula,
                                                            borderTop: contadorInciativas === 1 ? 'none' : styles.subCelula.borderTop,
                                                            padding: 0,
                                                            height: `calc((100%/${nIniciativas})*${nLineIniciativas})`,
                                                            fontSize: 14
                                                        }}>

                                                            { iniciativa.projetos && iniciativa.projetos.length == 0 ? this.renderEmptyCell("Projetos") : null }
                                                            { iniciativa.projetos.map((projeto, key) => {

                                                                let nProjetos = iniciativa.projetos.length;

                                                                return (
                                                                    <div style={{
                                                                        ...styles.subCelula,
                                                                        borderTop: key === 0 ? 'none' : styles.subCelula.borderTop,
                                                                        padding: 5,
                                                                        height: `calc(100%/${nProjetos})`,
                                                                        maxHeight: `calc(100%/${nProjetos})`,
                                                                        fontSize: 14
                                                                    }}>
                                                                        { this.renderProjetoCell(projeto) }
                                                                    </div>
                                                                )
                                                            })}
                                                        </div>
                                                    )
                                                } else {
                                                    return null;
                                                }
                                            })}
                                        </div>
                                    )
                                } else {
                                    return null;
                                }
                            })}
                        </div>
                    </div>
                </td>
            </tr>
        )
    }

    renderObjetivoCell(objetivo, height, linhas) {

        let textoSemTagsHtml = DataHelper.removeHtmlTags(objetivo.objetivo.valor);

        return (
            <div style={{ ...EssentialStyle.columnStart, padding: 5, height: '100%', width: '100%', fontSize: 14 }}>
                <div style={{...EssentialStyle.rowFlexEnd, width: '100%', height: '2em'}}>
                    { this.renderStar(objetivo.objetivo) }
                </div>

                <div style={{...EssentialStyle.rowFlexCenter, height: 'calc(100% - 4.5em)', width: '100%'}}>
                     <EllipsisText text={textoSemTagsHtml}/>
                </div>

                <div style={{ ...EssentialStyle.rowFlexStart, padding: 3, height: '2.5em', width: '100%' }}>
                    { objetivo.responsaveis ? this.renderParticipants(objetivo.responsaveis) : null}
                </div>
            </div>
        )
    }

    renderFatorCell(fator, height, linhas, nFatores) {
        
        let textoSemTagsHtml = DataHelper.removeHtmlTags(fator.fatorNome);

        return (
            <div style={{ ...EssentialStyle.columnStart, padding: 5, height: '100%', width: '100%', fontSize: 14 }}>
                <div style={{...EssentialStyle.rowSpaceBetween, width: '100%', height: '1em'}}>
                    <div style={{ backgroundColor: Colors.swot[SwotHelper.getSwotKey(fator?.quadrante?.nome)], padding: '2px 5px', borderRadius: 5, fontSize: 11 }}>
                        { fator.quadrante.nome }
                    </div>
                </div>
                <div style={{ ...EssentialStyle.rowFlexCenter, height: 'calc(100% - 1em)', width: '100%'}}>
                    <EllipsisText text={textoSemTagsHtml}/>
                </div>
            </div>
        )
    }

    renderInciativaCell(iniciativa, height, linhas, nProjetos, nLineIniciativas) {

        let textoSemTagsHtml = DataHelper.removeHtmlTags(iniciativa.iniciativa.nome);        

        return (
            <div style={{ ...EssentialStyle.columnStart, padding: 5, height: '100%', width: '100%', fontSize: 14 }}>
                <div style={{...EssentialStyle.rowFlexCenter, height: '100%', width: '100%'}}>
                    <EllipsisText text={textoSemTagsHtml}/>
                </div>
            </div>
        )
    }

    renderProjetoCell(projeto) {

        let textoSemTagsHtml = DataHelper.removeHtmlTags(projeto.nome);

        return (
            <div
                style={{
                    ...EssentialStyle.columnSpaceBetween,
                    padding: 5, 
                    height: '100%', 
                    width: '100%'
                }}
            >
                <div/>
                
                <div 
                    style={{ 
                        ...EssentialStyle.rowFlexCenter, 
                        justifyContent: 'space-between',
                        height: 'calc(100% - 30px)',
                    }}
                >
                    <div style={{...EssentialStyle.rowFlexCenter, height: '100%', width: 'calc(100% - 55px)' }}>
                        <EllipsisText text={textoSemTagsHtml}/>
                    </div>
                
                    <div style={{...EssentialStyle.rowFlexCenter }} >
                        {
                            this.state.creatingCanvas == projeto.id ? 
                                <div style={{ ...EssentialStyle.rowFlexStart }}>
                                    <div style={{ ...EssentialStyle.rowFlexStart, marginLeft: 20, width: 85, justifyContent: 'space-between' }}>
                                        <DefaultButton
                                            tooltip={'Adicionar'}
                                            tooltipPlacement={'left'}
                                            color={Colors.success}
                                            leftIcon={<FontAwesomeIcon icon={faCheck} />}
                                            onClick={(event) => {
                                                event.stopPropagation();
                                                this.addCanvas();
                                            }}
                                        />
                                        <DefaultButton
                                            tooltip={'Cancelar'}
                                            tooltipPlacement={'left'}
                                            textColor={Colors.dark}
                                            color={Colors.secondaryButton}
                                            leftIcon={<FontAwesomeIcon icon={faTimes} />}
                                            onClick={() => { this.setState({ creatingCanvas: false }) }}
                                        />
                                    </div>
                                </div>
                            :

                            <DefaultButton
                                color={projeto.canvas ? Colors.dark : Colors.success}
                                tooltip={projeto.canvas ? (projeto.podeVisualizarCanvas ? 'Abrir Projeto' : "Sem Permissão para Visualizar Projeto") : (projeto.podeCriaCanvas ? 'Criar Canvas' : "Sem Permissão para Criar Canvas de Projeto")}
                                tooltipPlacement={'left'}
                                leftIcon={projeto.canvas ? <FontAwesomeIcon icon={faEye} /> : <FontAwesomeIcon icon={faPlusSquare} />}
                                disabled={projeto.canvas ? !projeto.podeVisualizarCanvas : !projeto.podeCriaCanvas}
                                onClick={() => {
                                    if (projeto.canvas) {
                                        window.parent.postMessage({ type: 'navigate', url: `/pe/projeto/gestao/${projeto.canvas.id}` }, '*');
                                    } else {
                                        this.setState({ creatingCanvas: projeto.id, creatingCanvasProjeto: projeto });
                                    }
                                }}
                        />
                        }
                    </div>
                </div>

                <div style={{width: '100%', height: 30}}>
                    <ProgressBarCustom tipo={'projeto'} id={projeto.id} style={{width: '100%'}} />
                </div>
            </div>
        )
    }

    renderStar(element) {
        let colorStar = element.prioritario == 1 ? Colors.projeto.starOn : Colors.projeto.starOff;
        return (
            <FontAwesomeIcon 
                icon={faStar} 
                style={{ 
                    color: colorStar,
                    fontSize: '1em',
                }} 
            />
        );
    }

    renderParticipants = (participants) => {
        
        if (participants.length > 0) {
            return (
                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', paddingLeft: 5, width: '100%' }}>
                    <TooManyAvatars participantsTotal={participants.length} participantsVisiveis={3} arrayParticipants={participants} />
                </div>
            )
        } else {
            return <div></div>
        }
    }

    renderEmptyCell(value, height = '100%') {
        return (
            <div style={{
                height: height,
                padding: 5,
                fontSize: 14,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                border: 'none',
            }}>
                <div style={{ width: 30, height: 30, borderRadius: 15, backgroundColor: '#ffc107', display: 'inline-block', marginRight: 5, padding: 3 }}>
                    <FontAwesomeIcon icon={faExclamation} color="white" />
                </div>
                Sem {value}
            </div>
        )
    }

    render() {

        return this.state.loading ? 
            <LoadingPage height={'calc(100vh - 75px)'}/> 
                : 
            (
                <div style={{ width: '100%', maxWidth: '100%', overflowX: 'auto', ...this.props.style}}>
                     <div style={{ width: '100%', minWidth: '60rem',  padding: 10, maxHeight: '100%', height: '100%' }}>
                        { this.renderInfos() }
                        <div style={{ ...EssentialStyle.rowFlexCenter, width: '100%', paddingRight: this.state.scrollIsOn ? 15: 0, backgroundColor: Colors.tableHeader }}>
                            <table>
                                <thead>
                                    {this.renderHeader()}
                                </thead>
                            </table>
                        </div>
                        <div className={'divScroll'} style={{ maxHeight: 'calc(100% - 105px)', height: 'calc(100% - 105px)', overflow: 'auto' }}>
                            <table>
                                <tbody>
                                    {this.state.loading || this.props.loading ? <tr><td colSpan={12}><DefaultLoader /></td></tr> : this.renderTable()}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            );
    }
}

const styles = {
    celulaRow: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        padding: 5,
        borderRight: '1px solid #ddd',
        height: '100%',
    },
    celulaColumn: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        borderRight: '1px solid #ddd',
        height: '100%',
        width: '100%',
    },
    subCelula: {
        borderTop: '1px solid #ddd',
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
    },
    subCelulaFatores: {
        borderTop: '1px solid #ddd',
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        boxSizing: 'border-box',
        paddingLeft: '5px',
        paddingRight: '5px',
    },
    celulaHeader: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        padding: 5,
        borderRight: '1px solid #ddd',
        height: '100%',
        backgroundColor: '#f3f3f3',
        fontWeight: 'bold',
    },
    showcase: {
        ...EssentialStyle.rowFlexStart, 
        position: 'relative', 
        overflow: 'hidden', 
        width: 'calc(40% - 50px)', 
        minHeight: '100%', 
        padding: 5, 
        margin: '0px 10px', 
        backgroundColor: 'white', 
        borderRadius: 5, 
        border: '1px solid #ccc'
    },
    showcaseText: {
        position: 'absolute',
        margin: 0,
        lineHeight: '45px',
        textAlign: 'center',
        /* Starting position */
        transform: 'translateX(70%)',
        /* Apply animation to this element */  
        animation: '20s linear infinite marquee',
        whiteSpace: 'nowrap',
    },
    infosTable: {
        ...EssentialStyle.rowFlexEnd, 
        minWidth: '100vh', 
        height: 60, 
        padding: 10, 
        backgroundColor: '#ededed', 
        border: '1px solid #ccc'
    }
}