import React from "react";
import './OrcamentoProjeto.css';

import EssentialStyle from "../../../../../style/EssentialStyle";
import UserAvatar from "../../../../tools/UserAvatar";
import Colors from "../../../../../constants/Colors";
import { Collapse } from "react-bootstrap";
import PlanoAcaoEtapaCard from "../../../planoAcao/PlanoAcaoEtapaCard/PlanoAcaoEtapaCard";

export default class PlanoAcaoOrcamentoCard extends React.Component {
    state = {
        displayEtapas: false,
        isSmallScreen: this.props.isSmallScreen,
    }

    componentDidUpdate(prevProps) {

        if(prevProps.isSmallScreen !== this.props.isSmallScreen) {
            this.setState({ isSmallScreen: this.props.isSmallScreen });
        }
    }

    renderTitle() {

        return (
            <div style={{ ...EssentialStyle.rowFlexStart, paddingLeft: 3, paddingBottom: 0, flex: 1, width: "100%"}}>
                <span style={{
                    marginLeft: 6,
                    fontSize: 18,
                    fontWeight: 500,
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    maxWidth: "auto",
                    flex: 1,
                }}>
                    {this.props.plano.nome}
                </span>
            </div>
        );
    }

    renderLeft() {
        return (
            <div style={{ ...EssentialStyle.rowFlexStart, width: "auto", minWidth: 100, overflow: "hidden", flex: 1 }}>
                <div style={{ ...EssentialStyle.columnStart, flex: 1, gap: 5, justifyContent: "center"}}>
                    {this.renderTitle()}
                </div>
            </div>
        );
    }

    renderOrcamento () {
        return (
            <div style={{ ...EssentialStyle.rowFlexEnd, gap: 15 }}>
                <div style={{ ...EssentialStyle.rowFlexStart, width: 150, maxWidth: '100%' }}>
                    <span style={{ fontSize: 14, fontWeight: 600, color: Colors.dark, marginRight: 8 }}>{this.props.plano.orcamento_previsto_value}</span>
                </div>
                <div style={{ ...EssentialStyle.rowFlexStart, width: 150, maxWidth: '100%' }}>
                    <span style={{ fontSize: 14, fontWeight: 600, color: Colors.dark, marginRight: 8 }}>{this.props.plano.orcamento_realizado_value}</span>
                </div>
                <div style={{ ...EssentialStyle.rowFlexStart, width: 150, maxWidth: '100%' }}>
                    <span style={{ fontSize: 14, fontWeight: 600, color: Colors.white, backgroundColor: this.props.plano.orcamento_saldo >= 0 ? Colors.success : Colors.error, marginRight: 8, padding: '3px 5px', borderRadius: 8 }}>{this.props.plano.orcamento_saldo_value}</span>
                </div>
            </div>
        )
    }

    renderRight() {
        let style = { height: 50, gap: 5 };

        if (this.props.isSmallScreen) {
            style = { ...EssentialStyle.rowSpaceBetween, alignItems: 'center', marginTop: 10, ...style, width: '100%' };
        } else {
            style = { ...EssentialStyle.rowSpaceBetween, alignItems: 'center', ...style };
        }

        return (
            <div style={style}>
                {this.renderOrcamento()}
                <div style={{ ...EssentialStyle.rowFlexCenter, width: 80}}>
                    <UserAvatar user={this.props.plano.responsavel} />
                </div>
            </div>
        );
    }

    getPeople = () => {
        let people = [];

        if(!this.props.plano) return people;

        if (this.props.plano?.responsavel) people.push(this.props.plano.responsavel);
        if (this.props.plano?.superior) people.push(this.props.plano.superior);
        if (this.props.plano?.participantes) people = people.concat(this.props.plano.participantes);

        people.sort((a, b) => {
            return a.nome.localeCompare(b.nome);
        });

        return people;
    }

    renderCard() {
        let cardStyle = {
            backgroundColor: Colors.white,
            padding: 10,
            cursor: 'pointer',
            width: "100%",
            minHeight: 50,
            ...EssentialStyle.columnCenter,
            border: `1px solid ${Colors.tag}`,
            borderBottom: this.props.borderBottom || "none",
        };

        if (this.props.isSmallScreen) {
            var planoStyle = {
                ...EssentialStyle.rowSpaceBetween,
                width: "100%",
            };
        } else {
            var planoStyle = {
                ...EssentialStyle.rowSpaceBetween,
                width: "100%",
                minHeight: 50,
            }
        }

        return (
            <div
                key={"id-plano-"+this.state.plano}
                style={cardStyle}
                className="plano-acao-card"
            >
                <div style={planoStyle} onClick={() => this.setState({ displayEtapas: !this.state.displayEtapas })}>
                    {this.renderLeft()}
                    {this.renderRight()}
                </div>
                <Collapse in={this.state.displayEtapas}>
                    <div style={{width: "100%"}}>
                        <div style={{ ...EssentialStyle.columnSpaceEvenly, width: "100%", marginTop: 10, borderBottom: this.props.plano?.etapas?.length > 0 ? "1px solid "+ Colors.homePage.extraLightGrey : "unset" }}>
                            {this.state.displayEtapas ? this.props.plano?.etapas?.length > 0 ? this.props.plano.etapas?.map((etapa, index) => {
                                return (
                                    <PlanoAcaoEtapaCard 
                                        key={"etapa-"+index} 
                                        etapa={etapa} 
                                        resumed={true}
                                        dialog={true}
                                        orcamento={true}
                                        participantes={this.getPeople()} 
                                        noEdit
                                        defaultCursor
                                    />
                                )
                            })
                            : 
                                <div style={{...EssentialStyle.columnCenter, width: "100%", padding: 10, fontSize: 18, fontWeight: 500}}>
                                    Nenhuma etapa cadastrada
                                </div>
                            : null} 
                        </div>
                    </div>
                </Collapse>
            </div>
        );
    }

    render() {
        return this.renderCard();
    }
}