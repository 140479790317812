import React from "react";

import Sig from "../../../../../api/Sig";

import DefaultLoader from "../../../../tools/DefaultLoader";

import { Form, Row, Col } from "react-bootstrap";

import './DescricaoProjeto.css';
import ProjetoObjetivoCard from "../ProjetoObjetivoCard/ProjetoObjetivoCard";
import ProjetoIniciativaCard from "../ProjetoIniciativaCard/ProjetoIniciativaCard";
import ProjetoFatoresSowtCard from "../ProjetoFatoresSwotCard/ProjetoFatoresSwotCard";


export default class DescricaoProjeto extends React.Component {

    state = {
        loading: true,
        id: this.props.id,
        isSmallScreen: this.props.isSmallScreen,
    }

    async componentDidMount() {
        this.loadProjeto();
    }

    async componentDidUpdate(prevProps) {
        if (prevProps.id !== this.props.id) {
            this.setState({ id: this.props.id });
            this.loadProjeto();
        }

        if (prevProps.isSmallScreen !== this.props.isSmallScreen) {
            this.setState({ isSmallScreen: this.props.isSmallScreen });
        }
    }

    loadProjeto = async () => {
        this.setState({ loading: true });

        let { perspectiva, objetivo, fatores, iniciativa, projeto } = (await Sig.request('GET', 'pe/projeto/getProjeto', { id: this.state.id, relations: 1 }));
        
        this.setState({ 
            projeto,
            objetivo,
            iniciativa,
            perspectiva,
            fatores,
            loading: false 
        });
    }

    renderLoading() {
        return (
            <div className="boxDescricao">
                <div className="loading">
                    <DefaultLoader />
                </div>
            </div>
        )
    }

    renderDescricao() {

        if (!this.state.perspectiva || !this.state.objetivo || !this.state.iniciativa) {
            return (
                <div className="boxDescricao">
                    <div className="emptyVinculo">
                        <span>Projeto Sem Vínculo com Mapa Estratégico</span>
                    </div>
                </div>
            )
        }

        return (
            <div className="boxDescricao">
                <Form>
                    <Row>
                        <Form.Group as={Col} md={3} sm={6}>
                            <Form.Label>Perspectiva</Form.Label>
                            <div className={"perspectiva"} dangerouslySetInnerHTML={{ __html: this.state.perspectiva.valor }}/>
                        </Form.Group>

                        <Form.Group as={Col} md={3} sm={6}>
                            <Form.Label>Objetivo</Form.Label>
                            <ProjetoObjetivoCard
                                key={this.state.objetivo.id}
                                objetivo={this.state.objetivo}
                                style={{ height: '10rem' }}
                            />
                        </Form.Group>

                        <Form.Group as={Col} md={3} sm={6}>
                            <Form.Label>Fatores SWOT</Form.Label>
                            <div className={"fatoresSwot"}>
                                {this.state.fatores.length == 0 && <div className={"aviso"}><span>Nenhum <strong>Fator SWOT</strong> vinculado a este <strong>Objetivo</strong>!</span></div>}
                                {this.state.fatores.length > 0 && this.state.fatores.map(fator => <ProjetoFatoresSowtCard key={fator.id} fator={fator} viewGut={false}/>)}
                            </div>
                        </Form.Group>

                        <Form.Group as={Col} md={3} sm={6}>
                            <Form.Label>Iniciativa</Form.Label>
                            <ProjetoIniciativaCard 
                                key={this.state.iniciativa.id}
                                id={this.state.iniciativa.id}
                                edit={false}
                                style={{ height: '10rem' }}
                            />
                        </Form.Group>
                    </Row>
                </Form>
            </div>
        )
    }

    render() {
        if (this.state.loading) return this.renderLoading();
        return this.renderDescricao();
    }
}
