import React from 'react';
import { forwardRef } from 'react';
import 'moment/locale/pt-br'
import moment from 'moment';
import Colors from "../../../../../constants/Colors";
import DatePicker from "react-datepicker";
import { Form, FormGroup } from 'react-bootstrap';
import EssentialStyle from "../../../../../style/EssentialStyle";
import DataHelper from "../../../../../helper/DataHelper";
import { InputGroup } from 'react-bootstrap';
import LayoutHelper from '../../../../../helper/LayoutHelper';
import { faTrash, faAdd, faClose} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import DefaultButton from '../../../../tools/DefaultButton';
import Sig from "../../../../../api/Sig";
import ReactDOM from 'react-dom';
import TimelineProjetosLinha from './TimelineProjetosLinha';
import DatePickerHelper from '../../../../../helper/DatePickerHelper';

export default class TimelineProjetos extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            projetos: [],
            timelineStart: 0,
            timelineEnd: 0,
            qtdMonthsStart: this.props.granularidade == "ano" ? 24 : this.props.qtdMonthsStart || 2,
            qtdMonthsEnd: this.props.granularidade == "ano" ? 24 : this.props.qtdMonthsEnd || 2,
            daySize: this.props.granularidade == 'mes' ? 4 : this.props.granularidade == "semana" ? 16 : 1,
            granularidade: this.props.granularidade || 'ano',
            edit: this.props.edit,
            editIdMarco: null,
            oldProjeto: null,
            selectedMarco: null,
            isSmallScreen: LayoutHelper.isSmallScreen(),
            showDeleteModal: false,
            id: this.props.id,
            isFocusedInside: false,
        };
        this.changeTimeout = null;
        this.scrollRef = React.createRef();
    }

    async componentDidMount() {
        window.addEventListener('resize', this.resize);

        await this.loadProjetos();

        this.setState({
            edit: this.props.edit,
            selectedMarco: null,
            showDeleteModal: false,
            qtdMonthsStart: this.props.granularidade == "ano" ? 24 : this.props.qtdMonthsStart || 2,
            qtdMonthsEnd: this.props.granularidade == "ano" ? 24 : this.props.qtdMonthsEnd || 2,
            daySize: this.props.granularidade == 'mes' ? 4 : this.props.granularidade == "semana" ? 16 : 1,
            granularidade: this.props.granularidade || 'ano',
        });

    }

    async loadProjetos() {
        let { projetos } = await Sig.request('GET', 'pe/projeto/getProjetosMapa', { id_pe_mapa: this.props.idMapa, ordenacao: 'alfabetic', relations: 2}) || {projetos: []};
        this.setState({projetos: [...projetos].filter(m => m.data_inicio && m.data_fim)}, () => this.scrollToCurrentDate());
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.resize);
    }

    resize = () => {
        this.setState({ isSmallScreen: LayoutHelper.isSmallScreen(), windowWidth: window.innerWidth});
    }

    componentDidUpdate(prevProps, prevState) {
        
        if(prevProps.granularidade != this.props.granularidade) {
            var qtdMonthsStart = this.state.qtdMonthsStart;
            var qtdMonthsEnd = this.state.qtdMonthsEnd;
            this.setState({ 
                granularidade: this.props.granularidade, 
                daySize: this.props.granularidade == 'mes' ? 4 : this.props.granularidade == "semana" ? 16 : 1,
                qtdMonthsStart: this.props.granularidade == "ano" ? 24 : this.props.qtdMonthsStart || 2, qtdMonthsEnd: this.props.granularidade == "ano" ? 24 : this.props.qtdMonthsEnd || 2
            }, () => {
                if(qtdMonthsStart != this.state.qtdMonthsStart || qtdMonthsEnd != this.state.qtdMonthsEnd){
                    var projetos = [...this.state.projetos];
                    var timelineStart = Math.min(...projetos.map(m => moment(m.data_inicio).toDate().getTime()));
                    timelineStart = moment(new Date(timelineStart)).subtract(this.state.qtdMonthsStart, 'months').startOf('month').startOf('day').toDate().getTime();
                    var timelineEnd = Math.max(...projetos.map(m => moment(m.data_fim).toDate().getTime()));
                    timelineEnd = moment(new Date(timelineEnd)).add(this.state.qtdMonthsEnd, 'months').endOf('month').endOf('day').toDate().getTime();
                    
                    this.setState({
                        timelineStart,
                        timelineEnd,
                    }, () => this.scrollToCurrentDate());
                } else{
                    this.scrollToCurrentDate();
                }
            });
        }

        if(prevState.editIdMarco !== this.state.editIdMarco && this.state.showDeleteModal === true) {
            this.setState({  showDeleteModal: false, });
        }
            
        if(prevProps.edit !== this.props.edit) {
            this.setState({ edit: this.props.edit });
        }

        if (prevState.projetos != this.state.projetos) {

            var projetos = [...this.state.projetos]

            var timelineStart = Math.min(...projetos.map(m => moment(m.data_inicio).toDate().getTime()));
            timelineStart = moment(new Date(timelineStart)).subtract(this.state.qtdMonthsStart, 'months').startOf('month').startOf('day').toDate().getTime();
            var timelineEnd = Math.max(...projetos.map(m => moment(m.data_fim).toDate().getTime()));
            timelineEnd = moment(new Date(timelineEnd)).add(this.state.qtdMonthsEnd, 'months').endOf('month').endOf('day').toDate().getTime();

            this.setState({
                timelineStart,
                timelineEnd,
            });
        }

        if(this.state.oldProjeto && this.state.editIdMarco) {
            const projeto = this.state.projetos.find(p => p.projeto.id == this.state.editIdProjeto);
            const id = projeto.marcos.findIndex(m => m.id == this.state.editIdMarco);
            if(JSON.stringify(projeto.marcos[id]) != JSON.stringify(this.state.oldProjeto.marcos[id])) {
                if(this.changeTimeout != null && prevState.oldProjeto != null && this.state.oldProjeto.projeto.id != prevState.oldProjeto.projeto.id) {
                    this.setTimeout(() => {
                        clearTimeout(this.changeTimeout);
                        this.changeTimeout = setTimeout(() => {
                            this.saveMarcos({ ...projeto });
                        }, 1000);
                    }, 1000);
                } else {
                    clearTimeout(this.changeTimeout);
                    this.changeTimeout = setTimeout(() => {
                        this.saveMarcos({ ...projeto });
                    }, 1000);
                }
            }
        }
    }

    scrollToCurrentDate = () => {
        if(!this.scrollRef.current) 
            setTimeout(this.scrollToCurrentDate, 100);
        else{
            var left = (moment(new Date()).diff(moment(new Date(this.state.timelineStart)), 'days') * this.state.daySize);
            this.scrollRef?.current?.scrollTo({ top: 0, left: left, behavior: 'smooth' })
        }
    }

    async changeProjetoCallback(updatedProjeto) {
        var projetos = [...this.state.projetos];
        const index = projetos.findIndex(p => p.projeto.id === updatedProjeto.projeto.id);

        var data_inicio = Math.min(...updatedProjeto.marcos.map(m => moment(m.data_inicio).toDate().getTime()));
        var data_fim = Math.max(...updatedProjeto.marcos.map(m => moment(m.data_fim).toDate().getTime()));
        if(moment(updatedProjeto.data_inicio).toDate().getTime() != data_inicio || moment(updatedProjeto.data_fim).toDate().getTime() != data_fim) {
            updatedProjeto.data_inicio = DataHelper.getDefaultDbDateFormat(data_inicio);
            updatedProjeto.data_fim = DataHelper.getDefaultDbDateFormat(data_fim);
        }
        if(projetos[index]){
            if(await this.saveMarcos(updatedProjeto)){
                projetos[index] = updatedProjeto;
                this.setState({projetos});
            }
        }
    }

    async saveMarcos(updatedProjeto) {
        var marcos = [...updatedProjeto.marcos];

        var request = await Sig.request('POST', 'pe/canvas/updateMarcos', {
            marcos: JSON.stringify(marcos),
            id: updatedProjeto.idCanvas
        });
        if (request && request.marcos && request.marcosSorted) {
            if(this.state.oldProjeto){
                var oldProjeto = {...updatedProjeto};
                oldProjeto.marcos = [...oldProjeto.marcos.map(m => ({...m}))];
                this.setState({oldProjeto});
            }
            return true;
        }
        const projetos = [...this.state.projetos];
        const index = projetos.findIndex(p => p.projeto.id === updatedProjeto.projeto.id);
        projetos[index] = this.state.oldProjeto;
        this.setState({ projetos });
        return false;
    }

    setEditIdMarco(id, idProjeto) {
        var projeto = {...this.state.projetos.find(p => p.projeto.id == idProjeto)};
        projeto.marcos = [...projeto.marcos.map(m => ({...m}))];
        this.setState({ editIdMarco: id, editIdProjeto: idProjeto, oldProjeto: projeto });
    }

    openElement(id, idProjeto) {
        var projeto = this.state.projetos.find(p => p.projeto.id == idProjeto);
        if(!projeto) return;
        if(id == null) {
            window.parent.postMessage({ type: '_blank', url: `/pe/canvas?id=${projeto.idCanvas}` }, "*");
            return;
        }
        const marco = projeto.marcos.find(m => m.id == id);
        if(marco && marco.vinculo) {
            window.parent.postMessage({ type: '_blank', url: `/planoAcao/detalhar/${marco.vinculo}` }, "*");
            return;
        } else {
            window.parent.postMessage({ type: '_blank', url: `/pe/canvas?id=${this.state.projetos.find(p => p.projeto.id == idProjeto).idCanvas}` }, "*");
        }
    }

    async removeProjetosItem(id) {
        var itemDeletado = await Sig.request('POST', 'pe/canvas/removeMarcosItem',
            {
                id: id,
            });
        
        if(itemDeletado) {
            var projetos = this.state.projetos.filter(m => m.id != id);
            this.setState({ projetos, showDeleteModal: false, editIdMarco: null, editIdProjeto: null, oldProjeto: null }, this.props.changeProjetosCallback(projetos));
        }
    }

    renderRemoveItemModal(id) {
        var marco = this.state.projetos.find(m => m.id == id);
        return (
            <div style={{ padding: 30, position: "absolute", backgroundColor: Colors.white, borderRadius: "10px", zIndex: 20 }} >
                <h1 style={{ marginTop: 0, fontWeight: 'normal', color: Colors.disabled, fontSize: 25 }}>Remover marco</h1>
                <p style={{ color: Colors.dark, fontSize: 15 }}>Tem certeza que deseja remover o marco "{marco.descricao}"?</p>
                <div style={EssentialStyle.rowReverseFlexStart}>
                    <DefaultButton title="Cancelar" color={Colors.notSelected} textColor={Colors.dark} onClick={() => this.setState({ showDeleteModal: false })} style={{ marginLeft: 5 }} />
                    <DefaultButton title="Remover" color={Colors.danger} textColor={Colors.dark} onClick={() => this.removeProjetosItem(marco.id) } style={{ marginLeft: 5 }} />
                </div>
            </div>
        );
    }

    calendarContainer = ({ children }) => {
        const el = document.getElementById("root");
        
        return ReactDOM.createPortal(
            children,
            el
        );
    };

    renderDatePicker(value, onChangeCallback, minDate = moment().subtract(100, 'years').toDate(), maxDate = moment().add(100, 'years').toDate()) {
        const CustomInput = forwardRef(({ value, onClick }, ref) => (
            <Form.Control onClick={onClick} ref={ref} value={value} s>
            </Form.Control>
        ));

        let date = moment().toDate();
        if (value) date = moment(value).toDate();

        return (
            <DatePicker
                wrapperClassName="w-100"
                selected={date}
                type="date"
                minDate={minDate}
                maxDate={maxDate}
                onChange={(event) => {
                    let changedDate = moment(event);
                    onChangeCallback(changedDate);
                }}
                dateFormat="dd/MM/YYYY"
                popperContainer={this.calendarContainer}
                customInput={<CustomInput/>}
                renderCustomHeader={(props) =>  
                    DatePickerHelper.renderCustomDatePickerHeader(props, minDate, maxDate)
                }
            />
        )
    };

    renderEditForm() {
        if(!this.state.editIdMarco || !this.state.editIdProjeto) return null;

        var idProjeto = this.state.projetos.findIndex(p => p.projeto.id == this.state.editIdProjeto);;
        var projeto = [...this.state.projetos][idProjeto];
        
        var pesoAtual = projeto.marcos.filter( m => m.id != this.state.editIdMarco ).reduce((acc, marco) => acc + Number(marco.peso || 0), 0);
        var id = projeto.marcos.findIndex(m => m.id == this.state.editIdMarco); 
        var marco = projeto.marcos[id];

        return (
            <FormGroup>
                <div style={{ ...EssentialStyle.columnCenter, paddingBottom: 10, paddingTop: 10, minHeight: "150px", maxWidth:"600px", width: "100%"}}>
                    { this.state.showDeleteModal  ? this.renderRemoveItemModal(marco.id) : null}
                    <div style={{ ...EssentialStyle.rowSpaceEvenly }}>
                        <div style={{ ...EssentialStyle.rowSpaceBetween, width: `${this.state.isSmallScreen ? "100%" : "calc(100%-80px)"}`, marginBottom: 16 }}>
                            <Form.Group className={this.state.isSmallScreen ? "w-40" : "w-50"} style={{ marginRight: 8, justifyContent: "center" }}>
                                <Form.Label>Descrição</Form.Label>
                                <Form.Control placeholder="Descrição" value={marco.descricao}
                                    onBlur={(event) => {
                                        var projetos = [...this.state.projetos];
                                        projetos[idProjeto].marcos[id].descricao = event.target.value;
                                        this.setState({ projetos });
                                    }}
                                    onChange={(event) => {
                                        var projetos = [...this.state.projetos];
                                        projetos[idProjeto].marcos[id].descricao = event.target.value;
                                        this.setState({ projetos });
                                    }}
                                />
                            </Form.Group>
                            <InputGroup className={this.state.isSmallScreen ? "w-30" : "w-25"} style={{ marginRight: 8, justifyContent: "center", zIndex: 15, position: "relative" }}>
                                <Form.Label>{this.state.isSmallScreen ? "Início" : "Data de Início"}</Form.Label>
                                {this.renderDatePicker(
                                    marco.data_inicio,
                                    (value) => { 
                                        var projetos = [...this.state.projetos];
                                        projetos[idProjeto].marcos[id].data_inicio = DataHelper.getDefaultDbDateFormat(value);
                                        this.setState({ projetos });
                                    },
                                    moment().subtract(100, 'years'),
                                    marco.data_fim ? moment(marco.data_fim).toDate() : moment().add(100, 'years').toDate(),
                                )}
                            </InputGroup>
                            <InputGroup className={this.state.isSmallScreen ? "w-30" : "w-25"} style={{ marginRight: 8, justifyContent: "center", zIndex: 15, position: "relative"}}>
                                <Form.Label>{this.state.isSmallScreen ? "Fim" : "Data de Fim"}</Form.Label>
                                {this.renderDatePicker(
                                    marco.data_fim,
                                    (value) => { 
                                        var projetos = [...this.state.projetos];
                                        projetos[idProjeto].marcos[id].data_fim = DataHelper.getDefaultDbDateFormat(value);
                                        this.setState({ projetos });
                                    },
                                    marco.data_inicio ? moment(marco.data_inicio).toDate() : moment().subtract(100, 'years').toDate(),
                                )}
                            </InputGroup>

                        </div>
                        <div style={{ ...EssentialStyle.columnSpaceBetween, marginLeft: 10, minHeight: "100%", gap: "10px" }}>
                            <DefaultButton
                                tooltip={'Fechar'}
                                tooltipPlacement={'top'}
                                leftIcon={<FontAwesomeIcon icon={faClose} />}
                                style={{ fontSize: "20px", width: "35px", height: "35px", padding: "0px", margin: "0px"}}
                                color={Colors.secondaryButton}
                                textColor={Colors.dark}
                                loading={this.state.loading}
                                onClick={ () => this.setState({ editIdMarco: null, editIdProjeto: null, showDeleteModal: false, oldProjeto: null }) }
                            />
                            <DefaultButton
                                tooltip={'Remover'}
                                tooltipPlacement={'top'}
                                leftIcon={<FontAwesomeIcon icon={faTrash} />}
                                style={{ fontSize: "14px", width: "35px", height: "35px", padding: "0px", margin: "0px"}}
                                color={Colors.secondaryButton}
                                textColor={Colors.dark}
                                loading={this.state.loading}
                                onClick={ () => this.setState({ showDeleteModal: true }) }
                            />
                        </div>
                    </div>

                    <div style={{ ...EssentialStyle.rowSpaceEvenly, width: '100%' }}>
                        <Form.Label style={{ marginTop: 5, width: 110 }}>% do projeto</Form.Label>
                        <InputGroup style={{ minWidth: 120, width: 'calc(100% - 170px)', marginRight: 10 }}>
                            <Form.Control
                                type="range"
                                min="0"
                                max={100 - pesoAtual} 
                                step="1"
                                value={Number(marco.peso || 0)}
                                onMouseUp={(event) => {
                                    var projetos = [...this.state.projetos];
                                    projetos[idProjeto].marcos[id].peso = Number(event.target.value|| 0);
                                    this.setState({ projetos });
                                }}
                                onTouchEnd={(event) => {
                                    var projetos = [...this.state.projetos];
                                    projetos[idProjeto].marcos[id].peso = Number(event.target.value|| 0);
                                    this.setState({ projetos });
                                }}
                                onChange={(event) => {
                                    var projetos = [...this.state.projetos];
                                    projetos[idProjeto].marcos[id].peso = Number(event.target.value|| 0);
                                    this.setState({ projetos });
                                }}
                            />
                        </InputGroup>
                        <Form.Label style={{ marginTop: 5, width: 50 }}>{Number(marco.peso || 0)}%</Form.Label>
                    </div>
                </div>
            </FormGroup>
        );
    }
    

    render() {

        var { timelineStart, timelineEnd } = this.state;
        
        var offsetLeft = 400;
        if(this.state.isSmallScreen) 
            offsetLeft = 200;
        
        var projetos = [...this.state.projetos];

        var totalDuration = timelineEnd - timelineStart;
        var quantMonths = Math.round(moment(new Date(timelineEnd)).diff(moment(new Date(timelineStart)), 'months', true));
        var quantDias = Math.round(moment(new Date(timelineEnd)).diff(moment(new Date(timelineStart)), 'days', true));
        var quantSemanas = Math.ceil(quantDias / 7);
        var quantAnos = Math.ceil(moment(new Date(timelineEnd)).diff(moment(new Date(timelineStart)), 'years', true));
        
        return (
            <div style={{ minHeight: this.props.style?.minHeight || '30vh', height: this.props.style?.height || '60vh', padding: 10, display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", ...this.props.style }} >
                {projetos && projetos.length > 0 ?
                    <div style={{ ...EssentialStyle.card, display: "flex", flexDirection: "column", width: '100%', height: "max-content", alignItems: 'center', justifyContent: 'flex-start', overflowY: "auto", borderRadius: this.props.softBorder ? 8 : 0 }}>
                        {this.state.edit ? 
                            this.state.editIdMarco !== null && this.state.editIdProjeto !== null ?
                                this.renderEditForm()
                            : 
                                <div style={{ ...EssentialStyle.columnCenter, width: '100%', maxWidth:"600px", fontSize: "1.5rem", minHeight: "150px", position: 'relative' }}>
                                    <span style={{height: "50px"}}> Nenhum marco selecionado </span>
                                </div>
                        : null}

                            <div ref={this.scrollRef} style={{ width: "max-content", maxWidth: "100%", height: "max-content", maxHeight: "100%", position: "relative", display: "flex", flexDirection: "column", justifyContent: "flex-start", overflow: "auto" }}>
                                <div style={{ width: `${(this.state.daySize * quantDias) + offsetLeft }px`, minHeight: "35px", display: "flex", flexDirection: "row", backgroundColor: Colors.white, position: "sticky", top: 0, zIndex: 5, borderBottom: `1px solid ${Colors.homePage.extraLightGrey}` }} >
                                    <div style={{
                                        width: offsetLeft, lineHeight: "35px", position: "sticky", left: 0,
                                        minHeight: "35px", display: "flex", zIndex: 10, backgroundColor: Colors.white,
                                        flexDirection: "column", justifyContent: "center", alignItems: "center",
                                        lineHeight: this.state.granularidade == "semana" ? "90px" : "60px",
                                        height: this.state.granularidade == "semana" ? "90px" : "60px",
                                        borderRight: `1px solid ${Colors.homePage.extraLightGrey}`
                                    }}>
                                        Projeto / Marco
                                    </div>
                                    <div style={{ width: `${this.state.daySize * quantDias}px`, display: "flex", flexDirection: "column", position: "relative", zIndex: 4 }}>
                                        { this.state.granularidade == "ano" &&
                                            <div style={{display: "flex", minWidth: "100%", height: "30px", flexDirection: "row", justifyContent: 'center', alignItems: "center"}}>
                                            {
                                                Array.from({ length: quantAnos }, (_, i) => i).map((_, index) => {
                                                    var currentDate = moment(new Date(timelineStart)).add(index, 'years').toDate().getTime();
                                                    if(index == 0) {
                                                        var daysInYear = Math.round(moment(new Date(currentDate)).endOf('year').diff(moment(new Date(currentDate)), 'days', true));
                                                    }
                                                    else if(index == quantAnos - 1) {
                                                        var daysInYear = Math.round(moment(new Date(timelineEnd)).diff(moment(new Date(currentDate)).startOf('year'), 'days', true));
                                                    }
                                                    else {
                                                        var daysInYear = Math.round(moment(new Date(currentDate)).endOf('year').diff(moment(new Date(currentDate)).startOf('year'), 'days', true));
                                                    }

                                                    return (
                                                        <div key={"year-"+index} style={{
                                                            width: `${daysInYear * this.state.daySize}px`, lineHeight: "30px", fontWeight: 500, 
                                                            minHeight: "30px", display: "flex", position: "relative", borderBottom: `1px solid ${Colors.tag}` ,
                                                            flexDirection: "column", justifyContent: "center", alignItems: "center",
                                                            backgroundColor: index % 2 == 1 ? Colors.tag : Colors.white
                                                        }}>
                                                            {moment(new Date(currentDate)).format('YYYY')}
                                                        </div>
                                                    );
                                                })
                                            }
                                            </div>
                                        }
                                        <div style={{display: "flex", minWidth: "100%", height: this.state.granularidade == "mes" ? "60px" : "30px", flexDirection: "row", justifyContent: 'center', alignItems: "center"}}>
                                            {
                                                Array.from({ length: quantMonths }, (_, i) => i).map((_, index) => {
                                                    var daysInMonth = moment(new Date(timelineStart)).add(index, 'months').daysInMonth();
                                                    return (
                                                        <div key={"month-"+index} style={{
                                                            width: `${daysInMonth * this.state.daySize}px`, lineHeight: this.state.granularidade == "mes" ? "28px" : "30px", fontWeight: 500, 
                                                            minHeight: this.state.granularidade == "mes" ? "60px" : "30px", display: "flex", position: "relative", borderBottom: `1px solid ${Colors.tag}` ,
                                                            flexDirection: "column", justifyContent: "center", alignItems: "center",
                                                            backgroundColor: index % 2 == 1 ? Colors.tag : Colors.white
                                                        }}>
                                                            {moment(new Date(timelineStart)).add(index, 'months').format(this.state.granularidade == "ano" ? 'MM' : 'MMM/YYYY')}
                                                        </div>
                                                    );
                                                })
                                            }
                                        </div>
                                        { this.state.granularidade == "semana" &&
                                            <div style={{display: "flex", minWidth: "100%", height: "30px", flexDirection: "row", alignItems: "center"}}>
                                            { 
                                                Array.from({ length: quantSemanas }, (_, i) => i).map((_, index) => {
                                                    var currentWeek = moment(new Date(timelineStart)).add(index, 'weeks');
                                                    var daysInWeek = 7;
                                                    if(index == 0){
                                                        var weekdayStart = currentWeek.day();
                                                        daysInWeek -= weekdayStart - 1;
                                                    }
                                                    if(index == quantSemanas - 1){
                                                        var weekdayEnd = currentWeek.day();
                                                        var daysToEnd = parseInt(moment(new Date(timelineEnd)).format('D')) - parseInt(currentWeek.format('D'));
                                                        if(daysToEnd){
                                                            weekdayEnd += daysToEnd;
                                                        }
                                                        daysInWeek = weekdayEnd;
                                                    }
                                                    return (
                                                        <div key={"week-"+index} style={{
                                                            width: `${daysInWeek * this.state.daySize}px`, lineHeight: "28px", fontWeight: 500, 
                                                            minHeight: "30px", display: "flex", position: "relative", borderBottom: `1px solid ${Colors.tag}` ,
                                                            flexDirection: "column", justifyContent: "center", alignItems: "center",
                                                            backgroundColor: Colors.white, borderTop: `1px solid ${Colors.tag}`, borderRight: `1px solid ${Colors.tag}`,
                                                        }}>
                                                            {daysInWeek > 6 ? "Semana " + currentWeek.isoWeek() : daysInWeek >= 2 ? currentWeek.isoWeek() : null}
                                                        </div>
                                                    );
                                                })
                                            }
                                            </div>    
                                        }
                                        { this.state.granularidade == "semana" &&
                                            <div style={{display: "flex", minWidth: "100%", height: "30px", flexDirection: "row", alignItems: "center", fontSize: 10}}>
                                                {
                                                    Array.from({ length: quantDias }, (_, i) => i).map((_, index) => {
                                                        var currentDay = moment(new Date(timelineStart)).add(index, 'days');
                                                        return (
                                                            <div key={"day-"+index} style={{
                                                                width: `${this.state.daySize}px`, lineHeight: "28px", fontWeight: 500, 
                                                                minHeight: "30px", display: "flex", position: "relative" ,
                                                                flexDirection: "column", justifyContent: "center", alignItems: "center",
                                                                backgroundColor: Colors.white, borderRight: `1px solid ${Colors.tag}`,
                                                            }}>
                                                                {currentDay.date()}
                                                            </div>
                                                        );
                                                    })
                                                }
                                            </div>  
                                        }
                                    </div>
                                </div>
                                <div style={{ width: "max-content", height: "100%", display: "flex", justifyContent: "flex-start", alignItems: "flex-start", position: "relative", flexDirection: "column" }}>

                                    {projetos.map((projeto, index) => {
                                        
                                        return (
                                            <TimelineProjetosLinha 
                                                key={index} 
                                                index={index} 
                                                isSmallScreen={this.state.isSmallScreen} 
                                                projeto={projeto} 
                                                timelineStart={timelineStart} 
                                                timelineEnd={timelineEnd} 
                                                edit={this.state.edit} 
                                                daySize={this.state.daySize}
                                                setEditId={(id) => this.openElement(id, projeto.projeto.id)} 
                                                changeProjetoCallback={(updatedProjeto) => this.changeProjetoCallback(updatedProjeto)}
                                            /> 
                                        );
                                    })}
                                </div>
                            </div>
                       
                    </div>
                    : 
                        this.state.edit ?
                            <DefaultButton
                            leftIcon={<FontAwesomeIcon icon={faAdd} />}
                            color={Colors.success}
                            textColor={Colors.white}
                            title={'Adicionar Marco'}
                            loading={this.state.loading}
                            onClick={async () => {
                                var data = await Sig.request('POST', 'pe/canvas/addMarcosItem',
                                    {
                                        id_pe_projeto_canvas: this.props.id_pe_projeto_canvas
                                    }
                                );

                                if(data){
                                    this.addMarco(data["item orcamento"]);
                                }
                            }}
                            style={{ marginRight: 8, marginTop: 16 }}
                            />
                        :
                            <div style={{...EssentialStyle.card, ...EssentialStyle.columnCenter, minHeight: 150, width: "100%"}}>
                                <p>Sem Projetos para exibir.</p>    
                            </div>
                    }
            </div>
        )
    }
    
}