import React from "react";
import ReactDOM from "react-dom";
import Sig from "../../../../api/Sig";
import Colors from "../../../../constants/Colors";
import LayoutHelper from "../../../../helper/LayoutHelper";
import EssentialStyle from "../../../../style/EssentialStyle";
import DefaultButton from "../../../tools/DefaultButton";
import ProgressBarCustom from "../../../tools/ProgressBar/ProgressBarCustom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBookOpen, faSearch, faCheck, faTimes, faPlusSquare, faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import Modal from "../../../tools/Modal";
import DefaultLoader from "../../../tools/DefaultLoader";
import ProjetoCard from "../projeto/ProjetoCard";
import DataHelper from "../../../../helper/DataHelper";
import ModalVincularProjetoMapa from "../projeto/ModalVincularProjetoMapa/ModalVincularProjetoMapa";

export default class ModalProjetosObjetivo extends React.Component {

    state = {
        isSmallScreen: LayoutHelper.isSmallScreen(),
        loading: true,
        objetivo: null,
        projetos: null,
        showModalVincularMapa: false,
    };

    async componentDidMount() {
        await this.loadObjetivo();
    }

    loadObjetivo = async () => {
        this.setState({ loading: true });

        let response = (await Sig.request('GET', `pe/mapa/getObjetivo`, { id: this.props.id })) || null;

        let objetivo = response.objetivo || null;
        let iniciativas = this.getProjetosFromIniciativas(response.iniciativas) || null;

        this.setState({ objetivo, projetos: iniciativas, loading: false });
    }

    getProjetosFromIniciativas(iniciativas) {
        let projetos = [];

        if (iniciativas) {
            iniciativas.forEach(iniciativa => {
                if (iniciativa.projetos) {
                    iniciativa.projetos.forEach(projeto => {
                        projetos.push(projeto);
                    });
                }
            });
        }

        return projetos;
    }

    vinculaProjeto(id) {
        this.setState({ showModalVincularMapa: this.state.showModalVincularMapa == id ? false : id });
    }

    renderContentModal() {
        return (
            <>
                {this.renderTitle()}
                {this.state.loading ? this.renderLoading() : this.renderBody()}
                {this.renderFooter()}
                {
                    this.state.showModalVincularMapa &&
                    <ModalVincularProjetoMapa
                        idProjeto={this.state.showModalVincularMapa}
                        closeModal={() => { this.vinculaProjeto(this.state.showModalVincularMapa); }}
                        reloadPage={() => { this.loadObjetivo(); }}
                    />
                }
            </>
        )
    }
    renderTitle() {
        return (
            <div style={{ fontSize: 22, fontWeight: 600, color: Colors.homePage.grey, marginBottom: 10 }}>
                <FontAwesomeIcon icon={faBookOpen} style={{ marginRight: 10 }} />
                Projetos criados apartir do objetivo <span style={{ fontSize: 22, fontWeight: 400 }}>{DataHelper.removeHtmlAndReplaceListItems(this.state.objetivo?.valor)}</span>
            </div>
        )
    }

    renderBody() {
        return (
            <div style={{ ...EssentialStyle.rowSpaceBetween, margin: 0, padding: 0, backgroundColor: 'transparent', boxShadow: 'none', height: '25rem', width: '100%' }}>
                <div style={{
                    width: '100%',
                    height: '100%',
                    display: 'flex',
                    overflowY: 'auto',
                    flexDirection: 'column',
                    marginTop: 20,
                    padding: 7,
                }}>
                    {this.state.projetos.map((projeto) => (<ProjetoCard key={`${projeto.id}-projeto-key`} id={projeto?.id} priorization={this.state.type} calbackDelete={this.calbackDelete} vinculaProjeto={(id) => this.vinculaProjeto(id)}/>))}
                </div>
            </div>
        )
    }

    renderFooter() {
        return (

            <div style={{ ...EssentialStyle.rowFlexEnd, width: '100%', height: 38, marginTop: 10 }}>
                <div style={{flex: 1, height: '100%'}}>
                    {!this.state.loading &&
                    <ProgressBarCustom tipo={'objetivo'} id={this.state.objetivo.id} style={{ width: '100%', padding: '0px 5px', height: '100%' }} />}
                </div>
                <DefaultButton
                    title={'Fechar'}
                    color={Colors.dark}
                    textColor={Colors.white}
                    onClick={() => this.props.closeModal(false)}
                />
            </div>
        )
    }

    renderEmpty() {
        return (
            <div style={{ ...EssentialStyle.columnCenter, width: '100%', height: '12rem' }}>
                <div style={{ fontSize: 18, color: Colors.homePage.grey }}>
                    Nenhum plano de ação disponível para vincular.
                </div>
            </div>
        )
    }

    renderLoading() {
        return (
            <div style={{ ...EssentialStyle.columnCenter, width: '100%', height: '6rem' }}>
                <DefaultLoader />
            </div>
        )
    }

    render() {
        return (
            <Modal style={{ width: this.state.isSmallScreen ? '95vw' : '90vw' }}>
                {this.renderContentModal()}
            </Modal>
        )
    }
}