import React from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCopy, faEye, faPlusSquare, faTrash, faCheckCircle, faTimesCircle } from '@fortawesome/free-solid-svg-icons'
import DefaultButton from "../../../tools/DefaultButton";
import LoadingPage from "../../../../pages/LoadingPage";
import DefaultTable from "../../../tools/DefaultTable";
import moment from "moment";
import DataHelper from "../../../../helper/DataHelper";
import DefaultTableSearch from "../../../tools/DefaultTableSearch";
import Colors from "../../../../constants/Colors";
import { confirmAlert } from "react-confirm-alert";
import { toast } from "react-toastify";
import UserAvatar from "../../../tools/UserAvatar";
import CustomConfirm from "../../../tools/CustomConfirm";
import CanvasView from "../../../modules/pe/canvas/CanvasView";
import Sig from "../../../../api/Sig";
import CustomTooltip from "../../../tools/CustomTooltip";
import EssentialStyle from "../../../../style/EssentialStyle";
import SessionHelper from "../../../../helper/SessionHelper";

const searchableFields = ['nome_projeto', 'responsavel', 'data_cadastro' ];

export default class CanvasList extends React.Component {

    state = {
        canvas: [],
        loading: true,
        showWizard: false,
        showCanvas: 0,
        podeCriarCanvas: false,
    }

    async componentDidMount() {
       await this.loadData();
    }

    async loadData(loading = true) {
        if (loading) this.setState({ loading: true });

        let {canvas, podeCriarCanvas} = (await Sig.request('GET', 'pe/canvas/list', { relations: 1 })) || [];

        if (canvas.length === 0) return this.props.callbackEmptyCanvas();

        this.setState({ canvas, podeCriarCanvas }, () => { this.setState({ loading: false }) });
    }

    remove = async (id) => {
        this.setState({ loading: true });

        try {
            let request = await Sig.request('POST', `pe/canvas/delete`, { id });

            if(!request.success) throw new Error();
            toast.success(request.message);
        } catch (e) {
            toast.error('Erro ao remover');
        }

        this.loadData();
    }

    renderHeader() {

        return (

            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', marginTop: 10, marginBottom: 20 }}>
                <DefaultButton color={Colors.success} title={'Adicionar Canvas de Projeto'} tootltip={!this.state.podeCriarCanvas ? "Sem Permissão Para Adiconar Cnavas de Projeto" : null} leftIcon={<FontAwesomeIcon icon={faPlusSquare} />} loading={this.state.loading} disabled={!this.state.podeCriarCanvas} onClick={() => { this.setState({ showWizard: true }) }} />
                <div></div>
                <DefaultTableSearch fields={searchableFields} searchObjects={true} data={this.state.canvas} onDataUpdate={(data) => { this.setState({ canvas: data }) }} onEmpty={() => { this.loadData(false) }}/>
            </div>
        )
    }

    renderActions(row) {

        return (

            <div style={EssentialStyle.rowFlexStart}>
                <CustomTooltip placement={'bottom'} tooltip={'Remover'}>
                    <FontAwesomeIcon style={{ marginLeft: 20, fontSize: 17, color: Colors.error }} icon={faTrash} onClick={() => {
                        confirmAlert({
                            customUI: ({ onClose }) => (
                                <CustomConfirm
                                    title={'Excluir este Canvas?'}
                                    message={<p style={{ marginTop: 10, marginBottom: 10 }}>Essa ação não pode ser desfeita</p>}
                                    buttons={[
                                        {
                                            label: 'Remover',
                                            color: SessionHelper.getColor(),
                                            textColor: Colors.light,
                                            onClick: () => { this.remove(row.id); onClose(); }
                                        },
                                        {
                                            label: 'Cancelar',
                                            onClick: () => { onClose(); }
                                        },
                                    ]}
                                />
                            )
                        });
                    }}/>
                </CustomTooltip>
            </div>
        )
    }

    renderCanvasList() {
        return (
            <div>
                <DefaultTable
                    title={this.renderHeader()}
                    columns={[
                        { name: 'Projeto', selector: row => row.nome_projeto, sortable: true },
                        { name: 'Responsável', cell: row => <div  style={{marginLeft: 20}}><UserAvatar id={row.id_responsavel}/></div>, sortable: true, sortFunction: (a, b) => a.responsavel.nome > b.responsavel.nome, width: '120px' },
                        { name: 'Criação', selector: row => moment(row.data_cadastro).format('DD/MM/YYYY HH:mm'), sortable: true, sortFunction: (a, b) => DataHelper.multiArraySort(a, b, 'data_cadastro'), width: '200px', },
                        { name: 'Vínculo', selector: row => row.iniciativa ? <FontAwesomeIcon icon={faCheckCircle} style={{ color: Colors.success, marginLeft: 15 }}  /> : <FontAwesomeIcon icon={faTimesCircle} style={{ color: Colors.error, marginLeft: 15 }} />, sortable: true, sortFunction: (a, b) => {
                            const aHasContent = !!a.iniciativa;
                            const bHasContent = !!b.iniciativa;
                            return aHasContent === bHasContent ? 0 : aHasContent ? -1 : 1;
                        }, width: '100px' },
                        { name: 'Ações', selector: row => this.renderActions(row), width: '100px' },
                    ]}
                    data={this.state.canvas}
                    onRowClicked={row => this.props.canvasCallback(row.id)}
                    pagination
                    pointerOnHover
                    highlightOnHover
                />
            </div>
        );
    }

    render() {
        if (this.state.loading) return <LoadingPage />
        if (this.state.showWizard) return this.props.callback();
        if (this.state.showCanvas) return this.props.canvasCallback(this.state.showCanvas);
        return this.renderCanvasList();
    }
}