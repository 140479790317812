import Sig from "../api/Sig";
import DataHelper from "./DataHelper";

export default class IdiomaHelper {
    constructor() {
        this._idioma = false;
        this._idiomas = [];
    }

    static setIdioma(idioma) {
        this._idioma = idioma;
        localStorage.setItem('idioma', idioma);
    }

    static getIdioma() {
        if(!this._idioma) {
            this._idioma = localStorage.getItem('idioma') || 'pt';
        };

        return this._idioma;
    }

    static async setIdiomas() {
        if(sessionStorage.getItem('idiomas') && sessionStorage.getItem('idiomas').length) {
            this._idiomas = JSON.parse(sessionStorage.getItem('idiomas'));
            return;
        }

        let request = await Sig.request('GET', 'config/idioma/getIdiomas');

        if(request.status !== 200) return;

        this._idiomas = request.idiomas;

        let jsonIdiomas = JSON.stringify(request.idiomas);
        sessionStorage.setItem('idiomas', jsonIdiomas);
    }

    static getIdiomasSelect() {
        let idiomas = DataHelper.formatSelectData(this._idiomas, 'sigla', 'nome');
        return idiomas;
    }

    static getIdiomas() {
        return this._idiomas;
    }

    static getBySigla(sigla) {
        return this._idiomas.find(idioma => idioma.sigla === sigla);
    }

    static getSiglaId(sigla) {
        return this._idiomas.find(idioma => idioma.sigla === sigla).id;
    }

    static getSiglaNome(sigla) {
        return this._idiomas.find(idioma => idioma.sigla === sigla).nome;
    }

    static getStruct(text) {
        let struct = {};

        let emptyType = '';

        if(text && Object.keys(text).length > 0) {
            let firstText = text[Object.keys(text)[0]];

            if(typeof firstText === 'array') emptyType = [];
            if(typeof firstText === 'object') emptyType = {};
            if(typeof firstText === 'string') emptyType = '';
        }

        if(!this._idiomas?.length) return text;

        this._idiomas.forEach(idioma => {
            struct[idioma.sigla] = text[idioma.sigla] || emptyType;
        });

        return struct;
    }
}