import React from "react";

import Sig from "../../../../../api/Sig";

import DefaultLoader from "../../../../tools/DefaultLoader";

import Highcharts from "highcharts/highcharts.js";
import HighchartsReact from "highcharts-react-official";
import highchartsMore from 'highcharts/highcharts-more';
import variablePie from "highcharts/modules/variable-pie.js";
import accessibility from "highcharts/modules/accessibility.js";

import { Form, Row, Col } from "react-bootstrap";

import './GraficosProjeto.css';
import moment from "moment";
import Colors from "../../../../../constants/Colors";
import CustomTooltip from "../../../../tools/CustomTooltip";
import PlanoAcaoCardRelatorio from "../../../planoAcao/PlanoAcaoRelatorio/PlanoAcaoCardRelatorio";
import GraficosPlanoAcao from "../../../planoAcao/GraficosPlanoAcao/GraficosPlanoAcao";

highchartsMore(Highcharts);
variablePie(Highcharts);
accessibility(Highcharts);

export default class GraficosProjeto extends React.Component {

    state = {
        loading: true,
        id: this.props.id,
        isSmallScreen: this.props.isSmallScreen,
    }

    async componentDidMount() {
        this.loadProjeto();
    }

    async componentDidUpdate(prevProps) {
        if (prevProps.id !== this.props.id) {
            this.setState({ id: this.props.id });
            this.loadProjeto();
        }

        if (prevProps.isSmallScreen !== this.props.isSmallScreen) {
            this.setState({ isSmallScreen: this.props.isSmallScreen });
        }
    }

    loadProjeto = async () => {
        this.setState({ loading: true });

        let { projeto, marcosCanvas, canvas } = (await Sig.request('GET', 'pe/projeto/getProjeto', { id: this.state.id, relations: 1 }));

        this.setState({ 
            projeto,
            canvas,
            marcosCanvas,
            loading: false 
        });
    }

    renderLoading() {
        return (
            <div className="boxGraficos" style={this.props.style}>
                <div className="loading">
                    <DefaultLoader />
                </div>
            </div>
        )
    }

    renderContainer() {
        let hasMarcos = this.state.marcosCanvas && this.state.marcosCanvas.length > 0;

        if (!hasMarcos) {
            return (
                <div className="boxGraficos" style={{width: this.props.style.width}}>
                    <div className="emptyMarcos">
                        <span>Projeto Sem Marcos definidos</span>
                    </div>
                </div>
            )
        }

        return (
            <div className="boxGraficos" style={{...this.props.style}}>
                <div className="graficosList">
                    {this.renderGraficoPesos()}
                    {this.renderGraficosMarcos()}
                </div>
            </div>
        )
    }

    renderGraficoPesos() {
        if (!this.state.marcosCanvas || this.state.marcosCanvas.length === 0) return null;
        return (
            <div className="pesoPlanos border">
                <HighchartsReact
                    highcharts={Highcharts}
                    constructorType={'chart'}
                    containerProps={{ style: { height: "100%", width: "100%" } }}
                    options={{
                        chart: {
                          type: "packedbubble",
                          backgroundColor: "#dfdfdf",
                          spacing: [10, 0, 0, 0],
                        },
                        title: {
                          text: "Pesos dos Marcos"
                        },
                        tooltip: {
                          useHTML: true,
                          pointFormat: "<b>{point.name}:</b> Peso {point.value}<br/>Data Início: {point.data_inicio}<br/>Data Fim: {point.data_fim}"
                        },
                        plotOptions: {
                          packedbubble: {
                            minSize: "30%",
                            maxSize: "95%",
                            layoutAlgorithm: {
                              splitSeries: false,
                              gravitationalConstant: 0.01,
                            },
                            dataLabels: {
                                enabled: true,
                                format: "{point.value}%",
                                style: {
                                    color: "black",
                                    textOutline: "none",
                                    fontWeight: "normal",
                                    fontSize: "10px",
                                    fontWeight: "bold",
                                    textAlign: "center"
                                },
                                align: 'center',
                                verticalAlign: 'middle'
                            }
                          }
                        },
                        legend: {
                            layout: 'vertical',
                            align: 'right',
                            verticalAlign: 'middle',
                            labelFormatter: function() {
                                const maxLength = 20; // Defina o comprimento máximo desejado
                                return this.name.length > maxLength ? 
                                    this.name.substring(0, maxLength) + '...'
                                    : 
                                    this.name;
                            },
                            itemEvents: {
                                mouseOver: function() {
                                    const point = this.chart.series[this.index].points[0];
                                    point.onMouseOver(); // Foca no ponto
                                    this.chart.tooltip.hide(); // Atualiza o tooltip
                                },
                                mouseOut: function() {
                                    this.chart.tooltip.hide();
                                }
                            }
                        },
                        series: this.state.marcosCanvas.map((marco, index) => {
                            return {
                                name: marco.descricao,
                                data: [
                                    {
                                        name: marco.descricao,
                                        value: Number(marco.peso),
                                        data_inicio: moment(marco.data_inicio).format('DD/MM/YYYY'),
                                        data_fim: moment(marco.data_fim).format('DD/MM/YYYY'),
                                        // color: Colors.graphics[index]

                                    }
                                ],
                                // color: Colors.graphics[index]

                            }
                        }),
                        credits: {
                            enabled: false
                        },
                      }}
                />
            </div>
        )
    }

    renderGraficosMarcos() {

        return (
            this.state.canvas && this.state.marcosCanvas && this.state.marcosCanvas.length > 0 ? this.state.marcosCanvas.map((marco, index) => {
                if (!marco.planoAcao) return null
                return (
                    <GraficosPlanoAcao 
                        key={`marco-${marco.planoAcao.id}`}
                        className="border"
                        codigo={marco.planoAcao.codigo}
                        style={{ width: '300px', minWidth: '300px', height: this.props.isSmallScreen ? '15rem':'100%', backgroundColor: '#dfdfdf' }}
                    />
                )
            })
            :
            null
        )
    }

    render() {
        return this.state.loading ? this.renderLoading() : this.renderContainer();
    }
}