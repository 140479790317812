import React from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBars } from '@fortawesome/free-solid-svg-icons'
import Colors from "../../../constants/Colors";
import { Form, Row, Col } from "react-bootstrap";
import DragAndDropHelper from "../../../helper/DragAndDropHelper";
import DataHelper from "../../../helper/DataHelper";
import ConfigHelper from "../../../helper/inicio/ConfigHelper";
import UserDashboard from "./UserDashboard";
import DefaultLoader from "../../tools/DefaultLoader";
import EssentialStyle from "../../../style/EssentialStyle";

export default class InicioConfigOrdem extends React.Component {
    state = {
        loading: true,
        pages: [],
        draggingItem: null,
        draggingOverItem: null,
        mouseOverItem: null,
        top: false
    }

    async componentDidMount() {
        this.loadData();
    }

    loadData = async () => {
        let orderedPages = await ConfigHelper.getMyPagesAvailable(this.props.userId, this.props.viewUserConfig);
        this.setState({ pages: orderedPages, loading: false }, () => {
            this.props.callback(this.state.pages);
        });
    }

    createDragImage = (e) => {
        const dragImage = e.target.cloneNode(true);
        dragImage.style.position = 'absolute';
        dragImage.style.top = '-9999px';
        dragImage.style.width = e.target.offsetWidth + 'px';
        document.body.appendChild(dragImage);
        e.dataTransfer.setDragImage(dragImage, 0, 0);
        setTimeout(() => document.body.removeChild(dragImage), 0);
    }

    onDragStartItem = async (e, item, key) => {
        this.setState({ draggingItem: item, draggingOverItem: null });
        setTimeout(() => { e.target.style.display = "none" }, 5);
        this.createDragImage(e);
    }

    onDragEndItem = async (e, item, key) => {
        e.target.style.display = "block";

        let pages = [...this.state.pages];
        let draggingOverItem = this.state.draggingOverItem;

        if (draggingOverItem !== null) {
            pages.splice(pages.indexOf(this.state.draggingItem), 1);

            let indexToInsert = pages.indexOf(draggingOverItem);

            if (this.state.top) {
                pages.splice(indexToInsert, 0, item);
            } else {
                pages.splice(indexToInsert + 1, 0, item);
            }
        }

        this.setState({ pages }, () => {
            this.props.callback(pages);
            this.setState({ draggingItem: null, draggingOverItem: null, top: false });
        });
    }

    onDragEnterItem = async (e, item, key) => {
        e.preventDefault();
        const top = DragAndDropHelper.checkTop(e, 1.5);
        this.setState({ top, draggingOverItem: item });
    }

    renderPage(page, key) {
        return (
            <div
                style={{
                    marginTop: 10,
                    display: 'flex',
                    justifyContent: 'center',
                    flexDirection: 'column',
                    backgroundColor: 'white',
                    border: this.state.dragging ? `2px dashed ${Colors.notSelected}` : '0.3px solid lightgrey',
                    padding: 10,
                    borderRadius: 5,
                    marginTop: this.state.draggingOverItem && this.state.draggingOverItem.key === page.key && this.state.top ? 50 : 10,
                    marginBottom: this.state.draggingOverItem && this.state.draggingOverItem.key === page.key && !this.state.top ? 50 : 0,
                    borderBottom: this.state.draggingOverItem && this.state.draggingOverItem.key === page.key && !this.state.top ? `2px dashed ${Colors.info}` : '0.3px solid lightgrey',
                    borderTop: this.state.draggingOverItem && this.state.draggingOverItem.key === page.key && this.state.top ? `2px dashed ${Colors.info}` : '0.3px solid lightgrey',
                }}
                key={`${key}-page`}
                onDragStart={(e) => { this.onDragStartItem(e, page, key) }}
                onDragEnter={(e) => { this.onDragEnterItem(e, page, key) }}
                onDragEnd={(e) => { this.onDragEndItem(e, page, key) }}
                draggable={true}
                onMouseOver={() => { if (this.state.mouseOverItem !== key) this.setState({ mouseOverItem: key }); }}
                onMouseLeave={() => { this.setState({ mouseOverItem: null }); }}
            >
                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-start' }}>
                        {this.state.mouseOverItem === key && key > -1 &&
                            <FontAwesomeIcon icon={faBars}
                                style={{ cursor: 'move', boxShadow: '0 20px 75px rgba(0, 0, 0, 0.13)', marginRight: 12 }}
                                color={Colors.dark}
                                fontSize={20}
                            />
                        }
                        <Form.Label style={{ marginLeft: 5, marginBottom: 0 }}>{page.title}</Form.Label>
                    </div>
                </div>
            </div>
        )
    }

    renderPages() {
        return (
            <div style={{ display: 'flex', flexDirection: 'column', overflowX: 'auto', paddingTop: 10 }}>
                {this.state.pages.map((page, key) => (this.renderPage(page, key)))}
            </div>
        );
    }

    renderLoading() {
        return (
            <div style={{ ...EssentialStyle.columnCenter, width: '100%', minHeight: 350 }}>
                <DefaultLoader />
            </div>
        )
    }

    render() {
        return (
            <div style={{ minWidth: '100%', marginBottom: 40, marginTop: 20 }}>
                <Form.Label>Ordem de exibição das Dashboards:</Form.Label>
                {this.state.loading ? this.renderLoading() : this.renderPages()}
            </div>
        );
    }
}