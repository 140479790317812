import React from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAdd, faFileAlt, faTimes, faTimesCircle, faArrowCircleLeft, faArrowCircleRight } from '@fortawesome/free-solid-svg-icons'
import DefaultButton from "../../../tools/DefaultButton";
import Colors from "../../../../constants/Colors";
import { Form, Row, Col } from "react-bootstrap";
import { confirmAlert } from "react-confirm-alert";
import CustomConfirm from "../../../tools/CustomConfirm";
import TextEditor from "../../../tools/TextEditor/TextEditor";
import DefaultLoader from "../../../tools/DefaultLoader";
import SessionHelper from "../../../../helper/SessionHelper";


export default class AddNorteadores extends React.Component {
    state = {
        loading: true,
        norteadores: [{ name: 'Missão', content: '' }, { name: 'Visão', content: '' }, { name: 'Valores', content: '' }]
    }

    componentDidMount() {

        if (this.props.value) {

            this.setState({ norteadores: this.props.value });
            this.props.callback(this.props.value);

        } else {

            this.props.callback(this.state.norteadores);
        }

        this.setState({ loading: false });
    }

    alteraIndex = (norteador, right) => {
        this.setState({ loading: true });

        let norteadores = this.state.norteadores;
        let index = norteadores.indexOf(norteador);

        if (right) {
            let temp = norteadores[index];
            norteadores[index] = norteadores[index + 1];
            norteadores[index + 1] = temp;
        } else {
            let temp = norteadores[index];
            norteadores[index] = norteadores[index - 1];
            norteadores[index - 1] = temp;
        }

        this.setState({ norteadores }, () => {
            if (this.props.callback) this.props.callback(norteadores);
        });

        setTimeout(() => {
            this.setState({ loading: false });
        }, 100);
    }

    removeNorteador = async (norteador) => {
        let norteadores = this.state.norteadores;
        let index = norteadores.indexOf(norteador);

        if (index > -1) {
            norteadores.splice(index, 1);
            this.setState({ norteadores });
        }

        this.props.callback(norteadores);
        this.setState({ loading:true }, ()=>{this.setState({loading:false})});
    }

    renderNorteador(norteador) {
                return (
            <div style={{ margin: '10px 20px 20px 0px', display: 'flex', flexDirection: 'column' }}>
                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
                        <Form.Label style={{ marginLeft: 5, padding: '3px 10px 3px 10px', borderRadius: 5, boxShadow: '0px 0px 5px 1px rgba(0, 0, 0, 0.1)' }}>{norteador.name}</Form.Label>
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'row-reverse', justifyContent: 'center' }}>
                        <FontAwesomeIcon
                            onClick={() => {
                                confirmAlert({
                                    customUI: ({ onClose }) => (
                                        <CustomConfirm
                                            title={'Remover este norteador?'}
                                            message={<p style={{ marginTop: 10, marginBottom: 10 }}>Tem certeza que deseja descartar este norteador?</p>}
                                            buttons={[
                                                {
                                                    label: 'Remover',
                                                    color: SessionHelper.getColor(),
                                                    textColor: Colors.light,
                                                    onClick: () => { this.removeNorteador(norteador); onClose(); }
                                                },
                                                {
                                                    label: 'Cancelar',
                                                    onClick: () => { onClose(); }
                                                },
                                            ]}
                                        />
                                    )
                                });
                            }}
                            icon={faTimesCircle}
                            style={{ cursor: 'pointer', boxShadow: '0 20px 75px rgba(0, 0, 0, 0.13)', marginLeft: 5 }}
                            color={Colors.error}
                            fontSize={28}
                        />
                        {this.state.norteadores.indexOf(norteador) === this.state.norteadores.length - 1 || this.state.norteadores.length == 1 ? null :
                            <FontAwesomeIcon
                                onClick={() => {
                                    this.alteraIndex(norteador, true);
                                }}
                                icon={faArrowCircleRight}
                                style={{ cursor: 'pointer', boxShadow: '0 20px 75px rgba(0, 0, 0, 0.13)', marginLeft: 5 }}
                                color={Colors.info}
                                fontSize={28}
                            />
                        }
                        {this.state.norteadores.indexOf(norteador) === 0 ? null || this.state.norteadores.length == 1 : 
                            <FontAwesomeIcon
                                onClick={() => {
                                    this.alteraIndex(norteador, false);
                                }}
                                icon={faArrowCircleLeft}
                                style={{ cursor: 'pointer', boxShadow: '0 20px 75px rgba(0, 0, 0, 0.13)', marginLeft: 5 }}
                                color={Colors.info}
                                fontSize={28}
                            />
                        }
                    </div>
                </div>
                <div style={{ borderRadius: 5, display: 'flex', height: 200, width: 400, marginBottom: 10, justifyContent: 'center' }}>
                    {this.state.loading ? 
                        <DefaultLoader color={SessionHelper.getColor()} />
                    : 
                    <TextEditor
                        defaultValue={norteador.content}
                        height={150}
                        backgroundColor={'transparent'}
                        placeholder={`Digite o texto de ${norteador.name}`}
                        onChange={(value) => {
                            let norteadores = this.state.norteadores;
                            let index = norteadores.indexOf(norteador);

                            if (index > -1) {
                                norteadores[index].content = value;
                                this.setState({ norteadores }, () => {
                                    if (this.props.callback) this.props.callback(norteadores);
                                });
                            }
                        }}
                    />
                    }
                </div>
            </div>
        )
    }

    addNorteador = () => {
        let norteadores = [{ content: '', name: this.state.name }, ...this.state.norteadores];
        this.setState({ norteadores }, () => { this.setState({ addingNew: false, name: ''}) });
        this.props.callback(norteadores);
        this.setState({ loading:true }, ()=>{this.setState({loading:false})});
    }

    renderAddNew() {
        return this.state.addingNew ?
            (
                <div style={{ margin: 10, display: 'flex', flexDirection: 'column', height: 200, minWidth: 400, alignContent: 'center', justifyContent: 'center' }}>
                    <Row className="mb-3">
                        <Form.Group as={Col}>
                            <Form.Label>Nome do Norteador</Form.Label>
                            <Form.Control type="text" placeholder="Norteador Y..." onChange={(event) => { this.setState({ name: event.target.value }) }} />
                        </Form.Group>
                    </Row>

                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                        <DefaultButton loading={this.state.loadingUpload} color={Colors.error} leftIcon={<FontAwesomeIcon icon={faTimes} />} style={{ width: '49%' }} title={'Cancelar'} onClick={() => { this.setState({ name: '', addingNew: false }) }} />
                        <DefaultButton loading={this.state.loadingUpload} disabled={!this.state.name} color={Colors.success} leftIcon={<FontAwesomeIcon icon={faFileAlt} />} style={{ width: '49%' }} title={'Adicionar'} onClick={()=>{this.addNorteador(); }} />
                    </div>
                </div>
            ) :
            (

                <div style={{ marginTop: 10, display: 'flex', flexDirection: 'column', width: 50, alignContent: 'center', alignItems: 'center', justifyContent: 'center' }}>
                    <div onClick={() => { this.setState({ addingNew: true }) }} style={{ borderRadius: 5, cursor: 'pointer', display: 'flex', height: 50, width: 50, marginBottom: 10, backgroundColor: Colors.info, alignContent: 'center', alignItems: 'center', justifyContent: 'center' }}>
                        <FontAwesomeIcon icon={faAdd} color={Colors.background} fontSize={25} />
                    </div>
                    <Form.Label style={{ marginLeft: 0 }}>Novo</Form.Label>
                </div>
            );
    }

    renderNorteadores() {
                return (
            <div>
                <div style={{ display: 'flex', flexDirection: 'row', overflowX: 'auto' }}>
                    {this.state.norteadores.map(norteador => (this.renderNorteador(norteador)))}
                </div>
                {this.renderAddNew()}
            </div>
        );
    }

    render() {
        return (
            <div style={{ minWidth: '80%', marginBottom: 40 }}>
                <Form.Label>Norteadores Estratégicos:</Form.Label>
                {this.renderNorteadores()}
            </div>
        );
    }
}