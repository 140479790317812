import React from "react";

import Sig from "../../../../api/Sig";
import { toast } from "react-toastify";

import Highcharts from "highcharts/highcharts.js";
import highchartsMore from 'highcharts/highcharts-more';
import variablePie from "highcharts/modules/variable-pie.js";
import accessibility from "highcharts/modules/accessibility.js";

import './GraficosPlanoAcao.css';
import DefaultLoader from "../../../tools/DefaultLoader";
import Colors from "../../../../constants/Colors";
import ChartBarMeta from "../../../tools/ChartBarMeta/ChartBarMeta";
import GaugeSemiCircle from "../../../tools/GaugeSemiCircle/GaugeSemiCircle";

highchartsMore(Highcharts);
variablePie(Highcharts);
accessibility(Highcharts);

export default class GraficosPlanoAcao extends React.Component {

    state = {
        loading: true,
        codigo: this.props.codigo,
    }

    async componentDidMount() {
        await this.loadData();
    }

    async componentDidUpdate(prevProps) {
        if (prevProps.codigo !== this.props.codigo) {
            this.setState({ codigo: this.props.codigo });
            this.loadPlanoAcao();
        }
    }

    loadData = async () => {
        this.setState({ loading: true });

        await this.loadPlano();
        await this.loadPlanoAcaoProgress();

        this.setState({ loading: false });
    }

    async loadPlano() {
        const planoAcao = await Sig.request('POST', 'planoAcao/getPlanoAcao', { codigo: this.state.codigo });

        if (!planoAcao?.data) {
            toast.error('Não foi possível encontrar o plano de ação');
            return window.parent.postMessage({ type: 'navigate', url: `/pe/projeto/detalhar` }, '*');
        } else {
            this.setState({ plano: planoAcao.data });
        }
    }

    async loadPlanoAcaoProgress() {

        let response = await Sig.request('GET', 'planoAcao/getProgress', { codigo: this.state.codigo });
        if (response.status === 200) {
            this.setState({
                progress: response.progress,
            });

        } else {
            this.setState({ loading: false });
        }

    }

    renderLoading() {
        return (
            <div className={`graficosPlano ${this.props.className}`} style={this.props.style}>
                <div className="loading">
                    <DefaultLoader />
                </div>
            </div>
        )
    }

    renderContainer() {

        let hasOrcamento = !(Number(this.state.plano.orcamento_previsto) == 0 && Number(this.state.plano.orcamento_realizado) == 0);
        return (
            <div className={`graficosPlano ${this.props.className}`} style={this.props.style}>
                {this.renderGaugeProgresso(hasOrcamento)}
                {this.renderBarOrcamento(hasOrcamento)}
            </div>
        )
    }

    renderGaugeProgresso(hasOrcamento) {

        return (
            <GaugeSemiCircle
                min={0}
                max={100}
                title={
                    this.state.plano.nome.length > 40 
                    ? this.state.plano.nome.substring(0, 40) + '...' 
                    : this.state.plano.nome
                }
                color={Colors.planoAcao.status.backgroundColor[this.state.plano.status]}
                progress={this.state.progress}
                style={{height: `calc(100% ${hasOrcamento ? '- 50px' : ''})`, width: "100%"}}
            />
        )
    }

    renderBarOrcamento(hasOrcamento) {

        if (!hasOrcamento) return null;
        return (
            <div className="graficoOrcamento">
                <ChartBarMeta
                    key={`key-grafico-resultado-0`}
                    color={this.state.plano.orcamento_previsto <= this.state.plano.orcamento_realizado ? Colors.success : Colors.error }
                    meta={this.state.plano.orcamento_previsto}
                    metaValue={this.state.plano.orcamento_previsto_value}
                    realizado={this.state.plano.orcamento_realizado}
                    realizadoValue={this.state.plano.orcamento_realizado_value}
                    performance={null}
                    minHeight={55}
                    height={55}
                />
            </div>
        )
    }

    render() {
        return this.state.loading ? this.renderLoading() : this.renderContainer();
    }
}