import React from 'react';
import EssentialStyle from '../../../../../style/EssentialStyle';
import DefaultLoader from '../../../../tools/DefaultLoader';
import Select from 'react-select'
import Sig from '../../../../../api/Sig';
import DataHelper from '../../../../../helper/DataHelper';
import Colors from '../../../../../constants/Colors';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import CustomTooltip from '../../../../tools/CustomTooltip';
import './MapaIndicadorSelect.css';

export default class MapaIndicadorSelect extends React.Component {
    state = {
        loading: false,
        indicadores: this.props.indicadores || [],
        paineis: [],
        invalid: false,
        indicador: this.props.indicador || {}
    }

    async componentDidMount() {
        if (this.state.indicador?.id_indicador) {
            await this.getPaineis(this.state.indicador.id_indicador, async () => {
                if (this.state.indicador?.id_gmr_painel) {
                    await this.getIndicadoresPainel(this.state.indicador?.id_gmr_painel);
                    await this.checkPeriodoPainel(this.state.indicador?.id_gmr_painel);
                }
            });
        }
    }

    checkPeriodoPainel = async (id_gmr_painel) => {
        if (id_gmr_painel) {
            let check = await Sig.request('POST', 'helper/checkPeriodoPainelMapa', { id_gmr_painel, id_pe_mapa: this.props.id_pe_mapa });

            if (check.error == "error") {
                this.setState({ invalid: check.message });
            } else {
                this.setState({ invalid: false });
            }
        }
    }

    getIndicadoresPainel = async (id_painel, callback) => {
        this.setState({ loading: true }, async () => {
            let indicadores = [];

            if (id_painel) {
                indicadores = await Sig.request('POST', 'helper/getIndicadoresPaineisGmr', { raw_object: true, id_painel }) || [];
            }

            this.setState({ indicadores });
        });

        if (callback) callback();

        this.setState({ loading: false });
    }

    getPaineis = async (id_indicador, callback) => {
        this.setState({ loading: true, paineis: [] }, async () => {
            let paineis = await Sig.request('POST', 'helper/getPaineisIndicador', { id_indicador, raw_object: true }) || [];
            this.setState({ paineis }, () => { setTimeout(() => { this.setState({ loadingEditViewProp: false }) }, 100); });
        });

        if (callback) callback();

        this.setState({ loading: false });
    }

    getTipoIndicador = (id) => {
        if(!id) return null;
        
        let indicador = this.state.indicadores.find((indicador) => indicador.id === id);
        return indicador?.tipo;
    }

    renderSelects() {
        let selectedIndicador = this.state.indicadores.find((indicador) => indicador.id === this.state.indicador?.id_indicador);
        let selectedPainel = this.state.paineis.find((painel) => painel.id === this.state.indicador?.id_gmr_painel);

        return (
            <div style={{ ...EssentialStyle.columnStart, borderTop: this.props.borderTop || 'none', width: '100%', marginTop: 20, paddingTop: 20 }}>

                <div style={{ ...EssentialStyle.columnStart, width: '100%' }}>
                    <div style={{ ...EssentialStyle.rowSpaceBetween, width: '100%' }}>
                        <h6 style={{ fontWeight: 'bold', marginBottom: 20 }}>Indicador</h6>
                        <CustomTooltip tooltip={'Remover Indicador'}>
                            <FontAwesomeIcon icon={faTimes} style={{ cursor: 'pointer' }} onClick={() => this.props.removeCallback(this.state.indicador.id_indicador, this.state.indicador.id_gmr_painel)} />
                        </CustomTooltip>
                    </div>
                    <Select
                        isSearchable
                        isClearable={false}
                        placeholder={'Selecione um Indicador'}
                        value={DataHelper.formatSelectedData(selectedIndicador, 'id', 'nome')}
                        options={DataHelper.formatSelectData(this.state.indicadores, 'id', 'nome')}
                        noOptionsMessage={() => <a href={`${document.referrer}indicador/cadastrar`} target={'_blank'}>Adicionar Indicador +</a>}
                        formatOptionLabel={({ label, value }) => (
                            <div style={{
                                ...EssentialStyle.rowFlexStart
                            }}>
                                {label}
                                {this.getTipoIndicador(value) && <div dangerouslySetInnerHTML={{ __html: this.getTipoIndicador(value) }} />}
                            </div>
                        )}
                        onChange={async (option) => {
                            let indicador = this.state.indicador;
                            indicador.id_indicador = option.value;
                            if (!this.state.indicador?.id_gmr_painel) await this.getPaineis(option.value);

                            this.setState({ indicador }, () => {
                                this.props.updateCallback(this.state.indicador.id_indicador, this.state.indicador.id_gmr_painel);
                            });
                        }}
                        styles={{
                            container: (base, state) => ({
                                ...base,
                                width: '100%',
                            }),
                            control: (base, state) => ({
                                ...base,
                                width: '100%'
                            })
                        }}
                    />
                    {this.state.paineis.length > 0 && (
                        <div style={{ ...EssentialStyle.columnStart, width: '100%' }}>
                            <div style={{ ...EssentialStyle.rowFlexStart, width: '100%', marginBottom: 20, marginTop: 20, alignItems: 'center' }}>
                                {this.state.invalid && <FontAwesomeIcon icon={faExclamationTriangle} style={{ color: Colors.danger, marginRight: 10 }} />}
                                <h6 style={{ fontWeight: 'bold', marginBottom: 0 }}>Painel</h6>
                            </div>

                            <Select
                                isSearchable
                                isClearable={false}
                                placeholder={'Selecione um Painel'}
                                value={DataHelper.formatSelectedData(selectedPainel, 'id', 'nome')}
                                options={DataHelper.formatSelectData(this.state.paineis, 'id', 'nome')}
                                onChange={(option) => {
                                    let indicador = this.state.indicador;
                                    indicador.id_gmr_painel = option.value;
                                    this.setState({ indicador }, () => {
                                        this.getIndicadoresPainel(option.value);
                                        this.checkPeriodoPainel(option.value);
                                        this.props.updateCallback(this.state.indicador.id_indicador, this.state.indicador.id_gmr_painel);
                                    });
                                }}
                                styles={{
                                    container: (base, state) => ({
                                        ...base,
                                        width: '100%',
                                    }),
                                    control: (base, state) => ({
                                        ...base,
                                        width: '100%'
                                    })
                                }}
                            />

                            {this.state.invalid && (
                                <div style={{ ...EssentialStyle.rowStart, width: '100%' }}>
                                    <h6
                                        style={{
                                            color: Colors.white,
                                            fontWeight: 'bold',
                                            backgroundColor: Colors.danger,
                                            marginTop: 10,
                                            paddingLeft: 6,
                                            paddingRight: 6,
                                            borderRadius: 5
                                        }}>
                                        {this.state.invalid}
                                    </h6>
                                </div>
                            )}
                        </div>
                    )}
                </div>
            </div>
        );
    }

    render() {
        if (this.state.loading) return <div style={{ ...EssentialStyle.columnCenter, width: '100%', borderTop: this.props.borderTop || 'none', width: '100%', marginTop: 20, paddingTop: 20 }}><DefaultLoader /></div>;
        return this.renderSelects();
    }
}