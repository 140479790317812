import React from "react";

import Highcharts from "highcharts/highcharts.js";
import highchartsMore from "highcharts/highcharts-more.js"
import accessibility from 'highcharts/modules/accessibility';
import HighchartsReact from "highcharts-react-official";
import solidgauge from "highcharts/modules/solid-gauge";
import Colors from "../../../constants/Colors";

import './GaugeSemiCircle.css';

highchartsMore(Highcharts);
accessibility(Highcharts);
solidgauge(Highcharts);

export default class GaugeSemiCircle extends React.Component {
    
    render() {
        return <HighchartsReact
            highcharts={Highcharts}
            containerProps={{ style: { ...this.props.style }}}
            options={{
                chart: {
                    type: 'solidgauge',
                    backgroundColor: 'transparent',
                    margin: [null, 0, -30, 0],
                    spacing: [5, 0, 0, 0],
                    events: {
                        render: function () {
                            if (this.centerText) {
                                this.centerText.destroy();
                            }

                            const fontSize = Math.min(this.plotWidth, this.plotHeight) * 0.15;

                            this.centerText = this.renderer.text(
                                `${this.series[0].points[0].y.toFixed(0)}%`,
                                this.plotLeft + (this.plotWidth / 2),
                                this.plotTop + (this.plotHeight / 2) - 10 + fontSize / 3
                            )
                                .attr({
                                    align: 'center',
                                    'text-anchor': 'middle'
                                })
                                .css({
                                    fontSize: `${fontSize}px`,
                                    color: Colors.dark,
                                    fontWeight: 'bold'
                                })
                                .add();
                        }
                    },
                },
                title: {
                    text: this.props.title || '',
                },
                tooltip: {
                    enabled: false
                },
                credits: {
                    enabled: false
                },
                pane: {
                    startAngle: -90,
                        endAngle: 90,
                        background: [{
                            outerRadius: '120%',
                            innerRadius: '80%',
                            backgroundColor: Colors.notSelected,
                            borderWidth: 0,
                            shape: 'arc',
                            startAngle: -90,
                            endAngle: 90 
                        }]
                },
                yAxis: {
                    min: this.props.min || 0,
                    max: this.props.max || 100,
                    lineWidth: 0,
                    tickPositions: []
                },
                plotOptions: {
                    solidgauge: {
                        dataLabels: {
                            enabled: false
                        },
                        linecap: 'round',
                        stickyTracking: false,
                        rounded: true
                    }
                },
                series: [{
                    name: 'Conversion',
                    data: [{
                        color: this.props.color,
                        radius: '120%',
                        innerRadius: '80%',
                        y: this.props.progress
                    }],
                    animation: {
                        duration: 1500 // Duração da animação em milissegundos
                    }
                }]
            }}
        />
    }
}