export default {
    primary: '#333333',
    secondaryButton: '#d5d5d5',
    contrast: '#333333',
    disabled: '#585858',
    selected: '#FFFFFF',
    notSelected: '#b4bcc8',
    dark: '#22262E',
    light: '#F3F3F3',
    secondary: '#FFFFFF',
    white: '#FFFFFF',
    background: 'rgb(240,243,244)',
    border: 'rgb(217, 218, 219)',
    boxShadow: 'rgba(50, 50, 50, 0.2) 1px 1px 10px 0px',
    error: '#d74d4e',
    success: '#59a63d',
    danger: '#FFB200',
    tableHeader: "#ededed",
    info: '#17a2b8',
    tag: 'hsl(0, 0%, 90%)',
    purple: '#6a1b9a',
    swot: {
        forcas: "rgb(115, 180, 103)",
        fraquezas: "rgb(198, 93, 83)",
        oportunidades: "rgb(84, 138, 194)",
        ameacas: "rgb(230, 176, 97)",
        addButton: 'rgb(66, 82, 110)'
    },
    favorite: '#FFB200',
    metas: [
        '#3a87ad',
        '#49afcd',
        '#49afcd',
        '#46A546',
        '#237a2e',
        '#ffc40d',
        '#f9d402',
        '#ffa500',
        '#ff8c00',
        '#9d261d',
        '#68010d',
    ],
    projeto: {
        addButton: 'rgb(0, 0, 0)',
        iniciativa: {
            prioritization: 'rgb(0, 0, 0)',
        },
        starOn: 'rgb(255, 178, 0)',
        starOff: '#a9a9a9',
    },
    homePage: {
        extraLightGrey: '#D5D5D5',
        lightGrey: '#BCBCBC',
        grey: '#808080',
        darkGrey: '#333333',
        title: '#585858',
        line: '#b4bcc8',
        atividades: {
            urgente: "#BF182A",
            alta: "#BF182A",
            media: "#FD9E4B",
            baixa: "#446EA2",
        }
    },
    graphics: [
        '#1f77b4', // Azul
        '#000', // preto
        '#2ca02c', // Verde
        '#d62728', // Vermelho
        '#9467bd', // Roxo
        '#8ce34b', // Verde Claro
        '#e377c2', // Rosa
        '#777', // Cinza
        '#bcbd22', // Verde Oliva
        '#17becf', // Azul Turquesa
        '#1c9099', // Azul Petróleo
        '#d6616b', // Vermelho Vinho
        '#31a354', // Verde Escuro
        '#756bb1', // Roxo Lavanda
        '#dd1c77', // Rosa Choque
        '#fdae6b', // Laranja Claro
        '#6baed6', // Azul Claro
        '#fd8d3c', // Laranja Escuro
        '#9e9ac8', // Roxo Claro
        '#74c476', // Verde Claro
        '#807dba', // Roxo Escuro
        '#e6550d', // Laranja Queimado
        '#6b6ecf', // Azul Lavanda
        '#d94801', // Laranja Profundo
        '#637939', // Verde Oliva Escuro
        '#8ca252', // Verde Oliva Claro
        '#b5cf6b', // Verde Limão
        '#cedb9c', // Verde Pastel
        '#bd9e39', // Ouro
        '#e7ba52', // Amarelo Ouro
        '#e7cb94', // Bege
        '#843c39', // Marrom Escuro
        '#ad494a', // Vermelho Terra
        '#d6616b', // Rosa Escuro
        '#e7969c', // Rosa Claro
    ],
    planoAcao: {
        vinculo: {
            color: "#ffffff",
            backgroundColor: {
                1: "#0883CA", // Azul
                2: "#D9D9D9", // Cinza Claro
                3: "#000000", // Preto
                4: "#FEA500", // Amarelo
                5: "#B0B0B0", // Cinza
                6: "#237545", // Verde Escuro
                7: "#6a1b9a", // Roxo
                8: "#4ed28e", // Verde Hospitalar
                9: "#4ed28e", // Verde Hospitalar
            },
        },
        status: {
            color: {
                'Não Iniciado': '#FFFFFF',
                'Não Iniciada': '#FFFFFF',
                'Em Desenvolvimento': '#333333',
                'Concluído': '#FFFFFF',
                'Concluída': '#FFFFFF',
                'Atrasado': '#FFFFFF',
                'Atrasada': '#FFFFFF',
                'Cancelado': '#FFFFFF',
                'Cancelada': '#FFFFFF',
            },
            backgroundColor: {
                'Não Iniciado': '#6c757d',
                'Não Iniciada': '#6c757d',
                'Em Desenvolvimento': 'rgb(149,206,255)',
                'Concluído': '#28a745',
                'Concluída': '#28a745',
                'Atrasado': '#dc3545',
                'Atrasada': '#dc3545',
                'Cancelado': '#fd8d3c',
                'Cancelada': '#fd8d3c',
            }
        },
        statusAprovacao: {
            color: {
                'Aprovação não Solicitada': '#333333',
                'Solicitação de Aprovação Não Respondida': '#FFFFFF',
                'Aprovado': '#FFFFFF',
                'Rejeitado': '#FFFFFF',
                'Parcialmente Aprovado': '#FFFFFF',
            },
            backgroundColor: {
                'Aprovação não Solicitada': '#aaaaaa',
                'Solicitação de Aprovação Não Respondida': '#17a2b8',
                'Aprovado': '#59a63d',
                'Rejeitado': '#d74d4e',
                'Parcialmente Aprovado': '#ffb200',
                
            },
        }
    }
}

// Paleta SIG
// #D90000 #B30000 #000000 #333333 #585858 #22262E #b4bcc8 #141518 #FFFFFF