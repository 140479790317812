import React from 'react';
import LayoutHelper from '../../../helper/LayoutHelper';
import Modal from '../../tools/Modal';
import Colors from '../../../constants/Colors';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCog, faTimes, faCheck } from '@fortawesome/free-solid-svg-icons';
import EssentialStyle from '../../../style/EssentialStyle';
import DefaultLoader from '../../tools/DefaultLoader';
import DefaultButton from '../../tools/DefaultButton';
import Sig from '../../../api/Sig';
import InicioConfigOrdem from './InicioConfigOrdem';
import ConfigHelper from '../../../helper/inicio/ConfigHelper';
import SessionHelper from '../../../helper/SessionHelper';
import UserAvatar from '../../tools/UserAvatar';

export default class InicioConfigModal extends React.Component {
    state = {
        isSmallScreen: LayoutHelper.isSmallScreen(),
        loading: true,
        ordem: [],
    }

    async componentDidMount() {
        window.addEventListener('resize', this.verifyScreen);
        let ordem = await ConfigHelper.getMyPagesAvailable(this.props.userId, this.props.viewUserConfig);
        this.setState({ ordem, loading: false });
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.verifyScreen);
    }

    verifyScreen = () => {
        this.setState({ isSmallScreen: LayoutHelper.isSmallScreen() });
    }

    renderLoading() {
        return (
            <div style={{ ...EssentialStyle.columnCenter, width: '100%', height: '100%' }}>
                <DefaultLoader />
            </div>
        )
    }

    saveOrderConfig = async () => {
        this.setState({ loading: true });

        let response = await Sig.request('POST', 'inicio/saveOrdemConfig', { 
            colaboradorAlvo: this.props.userId, 
            viewUserConfig: this.props.viewUserConfig, 
            ordem: JSON.stringify(this.state.ordem)
        });

        if (response) {
            this.props.onClose();
            this.props.reloadCallback();
        }

        this.setState({ loading: false });
    }

    renderFooter() {
        return (
            <div style={{ ...EssentialStyle.rowCenter, width: '100%', marginTop: 20 }}>
                <DefaultButton
                    title={'Salvar'}
                    onClick={this.saveOrderConfig}
                    color={Colors.success}
                    style={{ width: '100%' }}
                    leftIcon={<FontAwesomeIcon icon={faCheck} />}
                    disabled={this.state.loading}
                    loading={this.state.loading}
                />
            </div>
        );
    }

    renderBody() {
        return (
            <div style={{ ...EssentialStyle.columnStart, width: '100%', height: '100%' }}>
                {this.renderOrderConfig()}
            </div>
        )
    }

    setOrdem = (ordem) => {
        this.setState({ ordem });
    }

    renderOrderConfig() {   
        return <InicioConfigOrdem userId={this.props.userId} viewUserConfig={this.props.viewUserConfig} callback={this.setOrdem} />
    }

    renderModalContent() {
        return (
            <div style={{ ...EssentialStyle.columnStart, height: '85vh', width: '100%' }}>
                {this.renderTitle()}
                {this.state.loading ? this.renderLoading() : this.renderBody()}
                {this.renderFooter()}
            </div>
        )
    }

    renderTitle() {
        return (
            <div style={{ ...EssentialStyle.rowSpaceBetween, width: '100%', fontSize: 22, fontWeight: 600, color: Colors.homePage.grey, marginBottom: 10 }}>
                <div style={{ ...EssentialStyle.rowFlexStart }}>
                    <FontAwesomeIcon icon={faCog} style={{ marginRight: 10 }} />
                    Configurações
                    {this.props.userId != SessionHelper.getData().id_usuario ? <div style={{ ...EssentialStyle.rowFlexStart, marginLeft: 8 }}> <span style={{ marginRight: 8 }}>para</span> <UserAvatar showName showNameAdaptative id={this.props.userId}/></div> : null}
                </div>

                <FontAwesomeIcon icon={faTimes} style={{ color: Colors.homePage.grey, cursor: 'pointer' }} onClick={this.props.onClose} />
            </div>
        )
    }

    render() {
        return (
            <Modal style={{ width: this.state.isSmallScreen ? '95vw' : '90vw' }}>
                {this.renderModalContent()}
            </Modal>
        )
    }
}