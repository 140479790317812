import React, { Suspense } from "react";
import '../PlanoAcaoRelatorio.css';

import EssentialStyle from "../../../../../style/EssentialStyle";
import Colors from "../../../../../constants/Colors";
import DefaultLoader from "../../../../tools/DefaultLoader";
export default class PlanoAcaoRelatorioLista extends React.Component {

    render() {
        
        const PlanoAcaoEtapaCard = React.lazy(() => import('../../PlanoAcaoEtapaCard/PlanoAcaoEtapaCard'));
        const PlanoAcaoCardRelatorio = React.lazy(() => import('../PlanoAcaoCardRelatorio'));

        if(this.props.loading){
            return (
                <div style={{...EssentialStyle.columnCenter, width: '100%', height: '20vh'}}>
                    <DefaultLoader/>
                </div>
            )
        }

        if(!this.props.elements || this.props.elements.length === 0) {
            return (
                <div style={{...EssentialStyle.columnCenter, width: '100%', height: '20vh'}}>
                    <h3 style={{color: Colors.text, textAlign: 'center', color: Colors.homePage.lightGrey, fontWeight: 500}}>Nenhum resultado encontrado</h3>
                </div>
            )
        }
        
        return(
            <div style={{...EssentialStyle.columnStart, width: '100%', gap: 5, maxHeight: "100%", flex: 1}}>
                <Suspense key={"suspense-lista"} 
                    fallback={
                        <div style={{ ...EssentialStyle.columnCenter, height: "35vh", width: "100%"}}>
                            <DefaultLoader/>
                        </div>}
                >
                    {this.props.view === 'planos' ?
                            this.props.elements.map((plano, index) => {
                                return (
                                    <PlanoAcaoCardRelatorio key={plano.id} plano={plano} onClick={(codigo) => window.parent.postMessage({ type: '_blank', url: `/planoAcao/detalhar/${codigo}` }, '*')} isSmallScreen={this.props.isSmallScreen} noMargin/>
                                    )
                                })
                        : 
                    this.props.view === 'etapas' ?
                            this.props.elements.map((etapa, index) => {
                                return (
                                        <PlanoAcaoEtapaCard key={"etapa-"+index} borderBottom={`1px solid ${Colors.border}`} softBorders etapa={etapa} resumed={this.props.isSmallScreen} participantes={[etapa.colaborador]} onClick={() => console.log(etapa.id)} noEdit/>
                                    )
                            })
                        : null
                    }
                </Suspense>
            </div>
        );
    }
}