import React from "react";

import Sig from "../../../api/Sig";
import EssentialStyle from "../../../style/EssentialStyle";
import DefaultLoader from "../../tools/DefaultLoader";
import Constants from "../../../constants/Api";
import PlanoAcaoEtapaCard from "./PlanoAcaoEtapaCard/PlanoAcaoEtapaCard";
import Colors from "../../../constants/Colors";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAdd, faChevronUp, faChevronDown } from "@fortawesome/free-solid-svg-icons";
import SessionHelper from "../../../helper/SessionHelper";

const gateway = Constants.getSigEndPoint();

export default class PlanoAcaoConfigComplexibilidade extends React.Component {
    state = {
        loading: false,
        etapas: [],
        orderBy: null,
        isSmallScreen: this.props.isSmallScreen,
        max: 100,
    }

    componentDidMount = async () => {
        await this.loadData();
    }

    componentDidUpdate = async (prevProps) => {

        if(this.props.isSmallScreen !== prevProps.isSmallScreen) {
            this.setState({ isSmallScreen: this.props.isSmallScreen });
        }

        if(this.props.shouldUpdateEtapas && prevProps.shouldUpdateEtapas !== this.props.shouldUpdateEtapas) {
            this.setState({ loadingEtapas: true });

            await this.loadData(true);

            this.setState({ loadingEtapas: false });
        }
    }

    loadData = async (quiet = false) => {
        if (!quiet) this.setState({ loading: true });

        let etapas = await Sig.request('POST', 'planoAcao/listEtapasPlano', {
            codigo: this.props.codigo,
            orderBy: this.state.orderBy,
            status: [0, 1, 3] 
        });

        this.setState({ etapas: etapas || [] }, () => { 
            this.setState({ loading: false });
            this.props.updateEtapas(); 
        });
    }

    getHeaderHeight = () => {
        let headerComponents = document.getElementsByClassName('pa-header-component');

        let headerHeight = 0;


        for (let headerComponent of headerComponents) {
            headerHeight += headerComponent.clientHeight;
        }

        return headerHeight;
    }

    controlOrderBy = async (field) => {
        this.setState({ loadingEtapas: true });

        let order = `ASC`;

        if (this.state.orderBy && this.state.orderBy.includes(field)) {
            if (this.state.orderBy.includes('DESC')) {
                this.setState({ orderBy: null }, async () => {
                    await this.loadData(true);
                    this.setState({ loadingEtapas: false });
                });
                return;
            }
            order = this.state.orderBy.includes('ASC') ? 'DESC' : 'ASC';
        }

        this.setState({ orderBy: `${field} ${order}` }, async () => {
            await this.loadData(true);
            this.setState({ loadingEtapas: false });
        });
    }

    handleSavePesoChange = async (idEtapa, peso) => {
        let response = await Sig.request('POST', 'planoAcao/updatePesoEtapa', {
            idEtapa: idEtapa,
            peso: peso
        });

        this.props.updateEtapas();
    }

    handlePesoChange = async (idEtapa, peso) => {
        let etapas = [...this.state.etapas];

        let etapa = etapas.find(etapa => etapa.id === idEtapa);

        etapa.peso = peso;

        this.setState({ etapas: etapas });
    }

    // Render

    renderEmptyEtapas() {
        if((this.props.filter.people && this.props.filter.people.length > 0) || 
            (this.props.filter.search && this.props.filter.search != "") ||
            (this.props.filter.status && this.props.filter.status.length > 0 ) ||   
            (this.props.filter.prioridades && this.props.filter.prioridades.length > 0) ||
            (this.props.filter.data_inicio && this.props.filter.data_inicio != "") ||
            (this.props.filter.data_fim && this.props.filter.data_fim != ""))
            {
                return (
                    <div style={{
                        ...EssentialStyle.columnCenter,
                        backgroundColor: Colors.white,
                        color: Colors.disabled,
                        border: `1px solid ${Colors.border}`,
                        borderBottom: 'none',
                        paddingTop: 20,
                        paddingBottom: 20,
                        width: '100%'
                    }}>
                        <span>Nenhuma etapa encontrada!</span>
                    </div>
                );
            }


        return (
            <div style={{
                ...EssentialStyle.columnCenter,
                backgroundColor: Colors.white,
                color: Colors.disabled,
                border: `1px solid ${Colors.border}`,
                borderBottom: 'none',
                paddingTop: 20,
                paddingBottom: 20,
                width: '100%'
            }}>
                <span>Ainda não há etapas, para criá-las clique em "<FontAwesomeIcon icon={faAdd} /> Adicionar"</span>
            </div>
        );
    }

    renderLoadingEtapas() {
        return (
            <div style={{
                ...EssentialStyle.columnCenter,
                backgroundColor: Colors.white,
                color: Colors.disabled,
                border: `1px solid ${Colors.border}`,
                borderBottom: 'none',
                height: this.state.etapas.length * 54,
                maxHeight: window.innerHeight - 200,
                width: '100%'
            }}>
                <DefaultLoader />
            </div>
        );
    }

    renderOrderByIcon(field) {
        if (this.state.orderBy && this.state.orderBy.includes(field)) {
            return this.state.orderBy.includes('ASC') ?
                <FontAwesomeIcon style={{ marginRight: 8 }} icon={faChevronUp} />
                : <FontAwesomeIcon style={{ marginRight: 8 }} icon={faChevronDown} />;
        }

        return null;
    }

    renderListHeader() {
        let spanStyle = {
            fontWeight: 500,
            cursor: 'pointer',
            userSelect: 'none'
        }

        return (
            <div style={{...EssentialStyle.rowFlexStart, width: '100%', position: 'sticky', top: this.state.headerStickyTop, paddingTop: 15, backgroundColor: SessionHelper.getBackgroundColor(), zIndex: 1}}>
                <div style={{
                    ...EssentialStyle.rowSpaceBetween,
                    backgroundColor: Colors.white,
                    fontSize: 14,
                    width: '100%',
                    borderTopLeftRadius: 5,
                    borderTopRightRadius: 5,
                    border: `1px solid ${Colors.border}`,
                    userSelect: 'none',
                    padding: 10,
                }}>
                    <span
                        onClick={() => { this.controlOrderBy('atividade') }}
                        style={{ ...spanStyle, width: 'calc(100% - 430px)' }}
                    >
                        {this.renderOrderByIcon('atividade')}
                        Atividades
                    </span>
                    <span
                        onClick={() => { this.controlOrderBy('peso') }}
                        style={{ ...spanStyle, width: '400px', textAlign: 'center' }}
                    >
                        {this.renderOrderByIcon('peso')}
                        Complexidade
                    </span>
                </div>
            </div>
        );
    }

    renderEtapas() {
        if (this.state.loadingEtapas) return this.renderLoadingEtapas();
        if (this.state.etapas?.length === 0) return this.renderEmptyEtapas();

        let totalPeso = this.state.etapas.reduce((total, etapa) => total + Number(etapa.peso), 0);


        return this.state.etapas.map((etapa, index) => {
            return (
                <PlanoAcaoEtapaCard
                    key={`plano-acao-etapa-${index}`}
                    editPeso={true}
                    totalPeso={totalPeso}
                    handleSavePesoChange={(idEtapa, peso) => {this.handleSavePesoChange(idEtapa, peso)}}
                    handlePesoChange={(idEtapa, peso) => {this.handlePesoChange(idEtapa, peso)}}
                    detailed={false} 
                    etapa={{...etapa}}
                    hasAvaliador={this.props.hasAvaliador}
                    participantes={this.props.participantes} 
                    noTopBorder={index == 0 ? true : false} 
                    detailsModalCallback={this.props.detailsModalCallback}
                    updateEtapas={() => {this.props.updateEtapas()}}/>
            );
        });
    }

    renderListMobile() {
        return (
            <div style={{ maxWidth: '100%', overflowX: 'scroll' }}>
                <div style={{ width: '1200px' }}>
                    {this.renderListHeader()}
                    {this.renderEtapas()}
                    {this.renderFooter()}
                </div>
            </div>
        );
    }

    renderListDesktop() {
        return (
            <>
                {this.renderListHeader()}
                {this.renderEtapas()}
                {this.renderFooter()}
            </>
        );
    }

    renderList() {
        return (
            <div style={{ ...EssentialStyle.columnCenter, width: '100%', paddingBottom: 20, position: "relative"}}>
                {this.state.isSmallScreen ? this.renderListMobile() : this.renderListDesktop()}
            </div>
        );
    }

    renderFooter() {

        let totalPeso = this.state.etapas.reduce((total, etapa) => total + Number(etapa.peso), 0);

        let cardStyle = {
            ...EssentialStyle.rowFlexEnd,
            backgroundColor: Colors.white,
            border: `1px solid ${Colors.border}`,
            borderBottomRightRadius: 5,
            borderBottomLeftRadius: 5,
            fontWeight: 500,
            fontSize: 16,
            width: '100%',
            cursor: 'default',
            padding: 10,
            gap: 8,
        };

        let sumPeso = {
            width: 400, 
            textAlign: 'center', 
            borderRadius: 5,
            backgroundColor: totalPeso < 100 ? Colors.danger : Colors.success,
            color: Colors.white,
            fontWeight: 'bold',
        }

        return (
            <div style={cardStyle}>
                <div style={sumPeso}>
                    Total: {totalPeso}%
                </div>
            </div>
        )
            
    }

    renderLoading() {
        return (
            <div style={{ ...EssentialStyle.columnCenter, width: '100%', height: `calc(100vh - ${this.getHeaderHeight()}px)`}}>
                <DefaultLoader />
            </div>
        );
    }

    render() {
        return (
            <>
                {this.state.loading ? this.renderLoading() : this.renderList()}
            </>
        );
    }
}