import React from "react";
import EssentialStyle from "../../../../../style/EssentialStyle";
import LayoutHelper from "../../../../../helper/LayoutHelper";
import DefaultLoader from "../../../../tools/DefaultLoader";
import ChartHelper from "../../../../../helper/inicio/ChartHelper";
import ParceirosSummary from "./ParceirosSummary";
import ParceirosPlanoAcao from "./ParceirosPlanosAcao";

export default class Parceiros extends React.Component {
    state = {
        loadingTitle: true,
        loadingContent: true,
        selectedParceiros: [],
        isSmallScreen: LayoutHelper.isSmallScreen(),
        transform: 'translateY(+100%)',
        opacity: 0,
        reloadParceiros: 0,
        expandedBox: false,
    }

    async componentDidMount() {
        window.addEventListener('resize', this.verifyScreen);
        this.loadData();
    }

    async componentDidUpdate(prevProps) {
        if ((this.props.reloadParceiros !== prevProps.reloadParceiros)) {
            this.reloadParceiros();
        }
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.verifyScreen);
    }

    verifyScreen = () => {
        this.setState({ isSmallScreen: LayoutHelper.isSmallScreen() });
    }

    loadData = async () => {
        this.setState({ loadingTitle: true, loadingContent: true });

        this.setState({ loadingTitle: false, loadingContent: false });
    }

    renderloadingContent() {
        let styles = {
            content: {
                ...(this.state.isSmallScreen ? EssentialStyle.columnStart : EssentialStyle.rowFlexStart),
                width: '100%',
                height: 'calc(100% - 40px)',
                ...EssentialStyle.columnCenter,
            }
        }

        return (
            <div style={styles.content}>
                <DefaultLoader />
            </div>
        );
    }

    selectParceiros = async (parceiros) => {
        this.setState({ selectedParceiros: parceiros });
    }

    renderContent() {
        let styles = {
            content: {
                ...(this.state.isSmallScreen ? EssentialStyle.columnStart : EssentialStyle.rowSpaceBetween),
                width: '100%',
                height: '100%',
            }
        }

        return (
            <div style={styles.content}>
                {/* <div style={{
                    ...EssentialStyle.columnSpaceBetween,
                    ...EssentialStyle.card,
                    width: this.state.isSmallScreen ? '100%' : '30%',
                    height: this.state.isSmallScreen ? ChartHelper.getHeight(40) : '100%',
                    marginBottom: this.state.isSmallScreen ? 8 : 0,
                    transition: 'width 0.5s ease',
                    overflow: 'hidden'
                }}>
                    <ParceirosSummary 
                        userId={this.props.userId}
                        selectParceiros={ (parceiros) => {this.selectParceiros(parceiros)} }
                    />
                </div> */}

                <div style={{
                    ...EssentialStyle.columnSpaceBetween,
                    ...EssentialStyle.card,
                    flexDirection: this.state.isSmallScreen ? 'column-reverse' : 'column',
                    // width: this.state.isSmallScreen ? '100%' : 'calc(70% - 8px)',
                    width: '100%',
                    height: this.state.isSmallScreen ? ChartHelper.getHeight(60) : '100%',
                    transition: 'width 0.5s ease',
                }}>
                    <ParceirosPlanoAcao
                        userId={this.props.userId}
                        expandCallbackPageTwo={() => { }}
                        expandedPageTwo={false}
                        reloadParceiros={this.state.reloadParceiros}
                    />
                </div>
            </div>
        );
    }

    reloadParceiros() {
        this.setState({ reloadParceiros: this.state.reloadParceiros + 1 });
    }

    render() {
        let styles = {
            page: {
                ...EssentialStyle.columnStart,
                width: '100%',
                height: '100%',
                minHeight: `calc(100vh - ${ChartHelper.getHeaderHeight()}px - 60px)`,
                padding: 10,
            },
        }

        return (
            <div style={styles.page}>
                {this.state.loadingContent ? this.renderloadingContent() : this.renderContent()}
            </div>
        );
    }
}