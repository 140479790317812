import React from "react";

import EssentialStyle from '../../../../../style/EssentialStyle';
import DefaultLoader from '../../../../tools/DefaultLoader';
import { Collapse } from "react-bootstrap";
import Colors from "../../../../../constants/Colors";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';
import SessionHelper from '../../../../../helper/SessionHelper';
import moment from 'moment';
import DefaultButton from '../../../../tools/DefaultButton';
import UserAvatar from '../../../../tools/UserAvatar';
import ChartHelper from '../../../../../helper/inicio/ChartHelper';
import Tabs from "../../../../tools/Tabs";

export default class RelatorioProjetosColaboradores extends React.Component {

    state={
        contadores: {},
        expanded: false,
        status: [
            "Não Iniciado",
            "Em Desenvolvimento",
            "Concluído",
            "Atrasado",
            "Cancelado",
        ],
        statusEtapa: [
            "Não Iniciada",
            "Em Desenvolvimento",
            "Concluída",
            "Atrasada",
            "Cancelada",
        ],
        activeTab: 'projetos',
        loading: true,
    }

    componentDidMount(){
        this.calculaContadores();
    }

    componentDidUpdate(prevProps){
        if(prevProps.projetos !== this.props.projetos){
            this.calculaContadores();
        }
    }

    calculaContadores = () => {

        this.setState({ loading: true });

        var defaultPlanoCount = {
            total: 0,
            "Não Iniciado": 0,
            "Em Desenvolvimento": 0,
            "Concluído": 0,
            "Atrasado": 0,
            "Cancelado": 0,
        }
        var defaultEtapaCount = {
            total: 0,
            "Não Iniciada": 0,
            "Em Desenvolvimento": 0,
            "Concluída": 0,
            "Atrasada": 0,
            "Cancelada": 0,
        }

        var contadores = {
            colaboradores: [],
        }

        if(!this.props.projetos || this.props.projetos.length === 0) {
            this.setState({ loading: false, contadores });
            return;
        } 

        var projetos = this.props.projetos;

        projetos.forEach(projeto => {
            var colaboradores = [];

            if(projeto.responsavel) {
                var indexColaborador = contadores.colaboradores.findIndex(colaborador => colaborador.id == projeto.responsavel.id);
                if(indexColaborador == -1) {
                    contadores.colaboradores.push(projeto.responsavel);
                }
                if(!colaboradores.includes(projeto.responsavel.id)){
                    colaboradores.push(projeto.responsavel.id);
                    let index = indexColaborador == -1 ? (contadores.colaboradores.length - 1) : indexColaborador;
                    if(!contadores.colaboradores[index].projetos){
                        contadores.colaboradores[index].projetos = {...defaultPlanoCount};
                    }
                    contadores.colaboradores[index].projetos.total++;
                    contadores.colaboradores[index].projetos[projeto.status]++;
                }
            }  
            
            if(projeto.marcos && projeto.marcos.length > 0){

                projeto.marcos.forEach(marco => {
                    var colaboradoresMarco = [];
                    if(marco.planoAcao || projeto.responsavel) {
                        var colaboradorAtual = marco.planoAcao ? marco.planoAcao.responsavel : projeto.responsavel;
                        let indexColaborador = contadores.colaboradores.findIndex(colaborador => colaborador.id == colaboradorAtual.id);
                        if(indexColaborador == -1) {
                            contadores.colaboradores.push(colaboradorAtual);
                        }
                        if(!colaboradoresMarco.includes(colaboradorAtual.id)){
                            colaboradoresMarco.push(colaboradorAtual.id);
                            let index = indexColaborador == -1 ? (contadores.colaboradores.length - 1) : indexColaborador;
                            if(!contadores.colaboradores[index].marcos){
                                contadores.colaboradores[index].marcos = {...defaultPlanoCount};
                            }
                            contadores.colaboradores[index].marcos.total++;
                            contadores.colaboradores[index].marcos[marco.status]++;

                            if(!contadores.colaboradores[index].atrasoTotal){
                                contadores.colaboradores[index].atrasoTotal = 0;
                            }
                            
                        }
                        
                    }

                    
                });
                
            }
        });
        this.setState({ contadores, loading: false });
    }

    getDiasAtrasoEtapa = (marco) => {
        var dataFim = moment(marco.data_fim);
        var dataFimReal = moment();
    
        if(marco.status == 2 || marco.status == 3 && marco.data_conclusao){
          dataFimReal = moment(marco.data_conclusao);
        }
    
        if(dataFimReal.isAfter(dataFim)){
          return dataFimReal.diff(dataFim, 'days');
        }
        return 0;
    }

    fieldIsFiltered = (filter) => {
        if(!filter) return false;
        var view = filter.view;
        var valuesFilter = filter.values;

        var isFiltered = false;
        if(this.props.filter?.view == view && this.props.filter?.values && this.props.filter?.values.length > 0){
            isFiltered = JSON.stringify(valuesFilter) == JSON.stringify(this.props.filter.values);
        }
        return isFiltered;
    }

    renderBarra(responsavel, tipo) {
        if(tipo != "marcos" && tipo != "projetos" || !responsavel) return null;
        const status = this.state.status;

        const statusCounts = responsavel[tipo];

        const total = responsavel[tipo].total;

        const renderDiv = (status, index) => {
            var filter = {view: tipo, values: [{ field: 'status', value: status }, {field: 'responsavel', value: responsavel.id}]};
            return (
                <div 
                    key={status}
                    onClick={() => {
                        if(this.fieldIsFiltered(filter))
                            this.props.filterCallback({view: "projetos", values: []});
                        else
                            this.props.filterCallback(filter);
                    }}
                    style={{ 
                        ...EssentialStyle.rowFlexStart,
                        height: this.fieldIsFiltered(filter) ? 35 : 32,
                        width: `${(statusCounts[status] / total) * 100}%`,
                        minWidth: statusCounts[status] > 0 ? 15 : 0,
                        backgroundColor: ChartHelper.getColor(status),
                        transition: 'height 0.5s ease, boxShadow 0.5s ease',
                        overflow: 'visible',
                        cursor: "pointer",
                        filter: this.fieldIsFiltered(filter) ? "brightness(0.9)" : "unset",
                        boxShadow: this.fieldIsFiltered(filter) ? `0px 0px 10px 0px ${ChartHelper.getColor(status)}` : "none",
                    }}
                >
                    <span style={{ 
                        color: 'white', 
                        fontWeight: '500', 
                        marginLeft: 5, 
                        opacity: 1, 
                        transition: 'opacity 0.3s ease-in-out' 
                    }}>
                        {responsavel[tipo][status]}
                    </span>
                </div>
            );
        }

        return (
            <div style={{...EssentialStyle.rowSpaceBetween, width: '100%', height: 35, borderRadius: 5, marginLeft: 5, overflow: 'hidden'}}>
                {status.map(renderDiv)}
            </div>
        );
    }

    handleTabChange = (tab) => {
        if(this.state.activeTab === tab.name) return;
        this.setState({ activeTab: tab.name });
    }

    renderColaboradores = () => {
        if(this.state.loading) {
            return (
            <div style={{...EssentialStyle.columnCenter, width: "100%", height: "auto", justifyContent: 'center', alignItems: 'center'}} >
                <DefaultLoader />
            </div>
           )
        }

        const tabs = [
            { name: 'projetos', title: 'Projetos' },
            { name: 'marcos', title: 'Marcos' },
        ];

        return (
            <div className="pa-colaboradores-component" style={{ ...EssentialStyle.columnCenter, width: '100%', backgroundColor: SessionHelper.getBackgroundColor(), paddingTop: "3px", position: 'sticky', left: 0}}>
                <div style={{...EssentialStyle.columnCenter, minHeight: 35, boxShadow: `0px 1px 10px 0px  rgba(50, 50, 50, 0.1)`, borderRadius: 8, backgroundColor: Colors.white, width: '100%', overflow: "hidden"}}>

                    <div style={{ ...EssentialStyle.rowFlexCenter, cursor: 'pointer', minHeight: 35 }} onClick={() => this.setState({expanded: !this.state.expanded})} >
                        {this.state.expanded ?
                            <FontAwesomeIcon icon={faChevronUp} style={{ marginRight: 8 }} /> :
                            <FontAwesomeIcon icon={faChevronDown} style={{ marginRight: 8 }} />
                        }
                        {
                            this.state.expanded ? 'Ocultar Colaboradores' : 'Colaboradores'
                        }
                    </div>

                    <Collapse in={this.state.expanded} style={{ width: '100%', transition: "all 0.2s ease" }}>
                        <div style={{...(this.props.isSmallScreen ? EssentialStyle.columnCenter : EssentialStyle.rowFlexCenter), width: '100%'}}>
                            <div style={{...EssentialStyle.columnStart, width: '100%', height: 300, paddingTop: 0, gap: 15}}>
                                {this.state.loading ? 
                                    <div style={{ ...EssentialStyle.columnCenter, padding: 10, width: '100%', height: "100%"}}>
                                        <DefaultLoader />
                                    </div>
                                : 
                                    <div style={{...EssentialStyle.columnStart, paddingBottom: 5, width: "100%"}}>
                                        {this.props.isSmallScreen && 
                                            <Tabs 
                                                isSmallScreen={this.props.isSmallScreen}
                                                tabs={tabs}
                                                activeTab={this.state.activeTab}
                                                onTabChange={this.handleTabChange}
                                                tabStyle={{width: this.props.isSmallScreen ? '40vw' : 90, maxWidth: 150}}
                                                style={{placeSelf: "center"}}
                                            />
                                        }
                                        <div style={{...EssentialStyle.rowFlexCenter, height: 40, borderBottom: `1px solid ${Colors.homePage.extraLightGrey}`, width: "100%", textAlign: 'center'}}>
                                            <div style={{fontSize: 16, fontWeight: 500, color: Colors.homePage.grey, width: this.props.isSmallScreen ? "45%" : "50%", height: "100%", alignContent: "center", textOverflow: "ellipsis", overflow: "hidden", whiteSpace: "nowrap"}}>Responsável</div>
                                            {!this.props.isSmallScreen || this.state.activeTab === "projetos" ?
                                                <div style={{fontSize: 16, fontWeight: 500, color: Colors.homePage.grey, width: this.props.isSmallScreen ? "55%" : "25%", height: "100%", alignContent: "center", textOverflow: "ellipsis", overflow: "hidden", whiteSpace: "nowrap"}}>Projetos</div>
                                            : null
                                            }
                                            {!this.props.isSmallScreen || this.state.activeTab === "marcos" ?
                                            <>
                                                <div style={{fontSize: 16, fontWeight: 500, color: Colors.homePage.grey, width: this.props.isSmallScreen ? "55%" : "25%", height: "100%", alignContent: "center", textOverflow: "ellipsis", overflow: "hidden", whiteSpace: "nowrap"}}>Marcos</div>
                                            </> : null
                                            }
                                        </div>
                                        <div style={{...EssentialStyle.columnStart, width: "100%", overflowY: "auto", maxHeight: 250, height: "auto"}}>
                                            {this.state.contadores.colaboradores.map((colaborador, index) => {
                                                return (
                                                    <div key={"colaborador-"+index} style={{...EssentialStyle.rowFlexCenter, borderBottom: `1px solid ${Colors.homePage.extraLightGrey}`, width: "100%", height: 40, minHeight: 40}}>
                                                        <div style={{fontSize: 16, fontWeight: 500, color: Colors.homePage.darkGrey, width: this.props.isSmallScreen ? "45%" : "50%", height: "100%", alignContent: "center", paddingLeft: 10}}>
                                                            <UserAvatar user={colaborador} size={35} showNameAdaptative showName />
                                                        </div>
                                                        {!this.props.isSmallScreen || this.state.activeTab === "projetos" ?
                                                            <div style={{...EssentialStyle.rowFlexStart, fontSize: 16, fontWeight: 500, color: Colors.homePage.darkGrey, width: this.props.isSmallScreen ? "55%" : "25%", height: "100%", alignContent: "center"}}>
                                                                {colaborador.projetos ? 
                                                                    <>
                                                                        <div style={{...EssentialStyle.rowFlexStart, height: "100%", width: "calc(100% - 45px)"}}>
                                                                            {this.renderBarra(colaborador, "projetos")}
                                                                        </div>
                                                                        <div
                                                                            onClick={() => {
                                                                                if(this.fieldIsFiltered({view: "projetos", values: [{ field: 'responsavel', value: colaborador.id }]}))
                                                                                    this.props.filterCallback({view: "projetos", values: []});
                                                                                else
                                                                                    this.props.filterCallback({view: "projetos", values: [{ field: 'responsavel', value: colaborador.id }]})
                                                                            }} 
                                                                            style={{
                                                                                ...EssentialStyle.rowFlexCenter, cursor: "pointer",
                                                                                height: 30, width: 40, marginLeft: 5,
                                                                                borderLeft: `2px solid ${Colors.homePage.extraLightGrey}`, 
                                                                                borderRadius: this.fieldIsFiltered({view: "projetos", values: [{ field: 'responsavel', value: colaborador.id }]}) ? 5 : 0,
                                                                                backgroundColor: this.fieldIsFiltered({view: "projetos", values: [{ field: 'responsavel', value: colaborador.id }]}) ? Colors.homePage.extraLightGrey : "transparent"}}
                                                                        >
                                                                            {colaborador.projetos.total}
                                                                        </div>
                                                                    </>

                                                                : 
                                                                    <div style={{...EssentialStyle.rowFlexCenter, height: "100%", width: "calc(100% - 45px)"}}>
                                                                        -
                                                                    </div>
                                                                }
                                                            </div>
                                                        : null
                                                        }
                                                        {!this.props.isSmallScreen || this.state.activeTab === "marcos" ?
                                                        <>
                                                            <div style={{...EssentialStyle.rowFlexStart, fontSize: 16, fontWeight: 500, color: Colors.homePage.darkGrey, width: this.props.isSmallScreen ? "55%" : "25%", height: "100%", alignContent: "center"}}>
                                                                {colaborador.marcos ? 
                                                                    <>    
                                                                        <div style={{...EssentialStyle.rowFlexStart, height: "100%", width: "calc(100% - 45px)"}}>
                                                                            {this.renderBarra(colaborador, "marcos")}
                                                                        </div>
                                                                        <div
                                                                            onClick={() => {
                                                                                if(this.fieldIsFiltered({view: "marcos", values: [{ field: 'responsavel', value: colaborador.id }]}))
                                                                                    this.props.filterCallback({view: "projetos", values: []});
                                                                                else
                                                                                    this.props.filterCallback({view: "marcos", values: [{ field: 'responsavel', value: colaborador.id }]})
                                                                            }} 
                                                                            style={{
                                                                                ...EssentialStyle.rowFlexCenter, cursor: "pointer",
                                                                                height: 30, width: 40, marginLeft: 5, 
                                                                                borderLeft: `2px solid ${Colors.homePage.extraLightGrey}`,
                                                                                borderRadius: this.fieldIsFiltered({view: "marcos", values: [{ field: 'responsavel', value: colaborador.id }]}) ? 5 : 0,
                                                                                backgroundColor: this.fieldIsFiltered({view: "marcos", values: [{ field: 'responsavel', value: colaborador.id }]}) ? Colors.homePage.extraLightGrey : "transparent"
                                                                            }}
                                                                        >
                                                                            {colaborador.marcos.total}
                                                                        </div>
                                                                    </>
                                                                :
                                                                    <div style={{...EssentialStyle.rowFlexCenter, height: "100%", width: "calc(100% - 45px)"}}>
                                                                        -
                                                                    </div>
                                                                }
                                                            </div>
                                                        </> : null
                                                        }
                                                    </div>
                                                )
                                            })}
                                        </div>
                                    </div>
                                }
                            </div>
                        </div>
                    </Collapse>
                </div>
            </div>
        );
    }


    render() {
        return (
            this.renderColaboradores()
        )
    }
}