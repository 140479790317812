import React from "react";

import Sig from "../../../../../../api/Sig";

import EssentialStyle from "../../../../../../style/EssentialStyle";
import DefaultLoader from "../../../../../tools/DefaultLoader";
import Constants from "../../../../../../constants/Api";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";

import ChartHelper from "../../../../../../helper/inicio/ChartHelper";
import ProjetoChartResumo from "../../ProjetoChartResumo/ProjetoChartResumo";

import './ProjetosObjetivo.css';
import DetalhamentoMarcosCollapsable from "../../DetalhamentoMarcosCollapsable/DetalhamentoMarcosCollapsable";

const gateway = Constants.getSigEndPoint();

export default class ProjetosObjetivo extends React.Component {

    state = {
        loading: true,
        idObjetivo: this.props.idObjetivo,
        isSmallScreen: this.props.isSmallScreen,
        projetos: null,
        idProjeto: null,
    }

    async componentDidMount() {
        this.loadData();
    }

    async componentDidUpdate(prevProps) {
        if (prevProps.idObjetivo !== this.props.idObjetivo) {
            this.setState({ idObjetivo: this.props.idObjetivo });
            this.loadData();
        }

        if (prevProps.isSmallScreen !== this.props.isSmallScreen) {
            this.setState({ isSmallScreen: this.props.isSmallScreen });
        }
    }

    loadData = async () => {
        this.setState({ idObjetivo: this.props.idObjetivo, loading: true });
        await this.loadProjetos();
    }

    loadProjetos = async () => {
        let { projetos } = await Sig.request('GET', `pe/projeto/getProjetosObjetivo`, { id_objetivo: this.state.idObjetivo });
        this.setState({ projetos, loading: false });
    }

    selectIndicadorExpanded = (idProjeto) => {
        this.setState({ idProjeto: this.state.idProjeto == idProjeto ? null : idProjeto });
    }

    renderLoading() {
        return (
            <div className="boxDescricao">
                <div className="loading">
                    <DefaultLoader />
                </div>
            </div>
        )
    }

    renderProjetosObjetivo() {

        return (
            <div className="boxProjetosObjetivo">
                {this.state.projetos && this.state.projetos.length > 0 ? this.renderProjetosGrid() : this.renderEmptyProjetos()}
            </div>
        )
    }

    renderProjetosGrid() {
        let projetos = this.state.projetos || [];
        let widthBox = ChartHelper.getWidth(100) - 20;
        let minWidthGauge = this.state.isSmallScreen ? 100 : 200;
        let nMaxGaugeLine = Math.ceil(widthBox / minWidthGauge);
        let nLine = Math.ceil(projetos.length/nMaxGaugeLine);
        let nGaugeLine = Math.ceil(projetos.length/nLine);

        let projetosGroups = [];
        for (let i = 0; i < projetos.length; i += nGaugeLine) {
            projetosGroups.push(projetos.slice(i, i + nGaugeLine));
        }

        let gaugesGrid = projetosGroups.map((group, index) => {
            return (
                <div key={`group-${index}`} style={{
                    ...EssentialStyle.rowSpaceEvenly,
                    width: '100%',
                    height: `${100 / projetosGroups.length}%`,
                    minHeight: 80,
                    maxHeight: 300,
                }}>
                    {
                        group.map((projeto, ig_index) => {
                            return (
                                <div key={`ig_index_${ig_index}`} style={{
                                    ...EssentialStyle.rowFlexCenter,
                                    display: 'flex',
                                    width: `${100 / (group.length)}%`,
                                    height: '15rem',
                                    padding: 5,
                                }}>
                                    <ProjetoChartResumo
                                        nome
                                        gauge
                                        projeto={projeto.projeto}
                                        iniciativaNome={projeto.iniciativaNome}
                                        link={true}
                                        onClick={() => { this.selectIndicadorExpanded(projeto.projeto.id); }}
                                    />
                                </div>
                            );
                        })
                    }
                </div>
            );
        });

        return (
            <div className={"gridContainer"} style={{justifyContent: nLine == 1 ?'center' : 'default'}}>
                {gaugesGrid}
            </div>
        );
    }

    renderEmptyProjetos() {
        return (
            <div className="emptyProjetos">
                <img
                    src={`${gateway}/img/undraw/undraw_pie_graph.svg`}
                    alt="Pessoa de tocando em um gráfico, como se adicionasse um elemento a ele"
                    style={{
                        maxHeight: '95%',
                    }}
                />
                <div style={{
                    ...EssentialStyle.columnCenter,
                    alignItems: 'flex-start',
                    alignContent: 'flex-start',
                }}>
                    <span style={{
                        ...EssentialStyle.titleBoxHomePage,
                        marginBottom: 10
                    }}>Objetivo sem Projetos vinculados.</span>
                </div>
            </div>
        );
    }

    renderProjetoExpanded() {
        let idProjeto = (this.state.idProjeto || this.state.projetos[0].projeto.id);
        return (
            <div className="boxProjetosObjetivo">
                <div className="projetoExpanded">
                    <div className={"historyChart"}>
                        <DetalhamentoMarcosCollapsable
                            idProjeto={idProjeto}
                            callbakLoadProjeto={() => {}}
                            hasMarginBottom={false}
                            isSmallScreen={this.props.isSmallScreen}
                        />
                    </div>
                    <div className={"gaugeChart"}>
                        <div className={"head"}>
                            <FontAwesomeIcon className={"hover"} icon={faArrowLeft} onClick={() => { this.selectIndicadorExpanded(idProjeto); }} />
                        </div>
                        <div className={"body"}>
                            <ProjetoChartResumo
                                nome
                                gauge
                                projeto={this.state.projetos.find(p => p.projeto.id == idProjeto).projeto}
                                iniciativaNome={this.state.projetos.find(p => p.projeto.id == idProjeto).iniciativaNome}
                                link={true}
                                onClick={() => { this.selectIndicadorExpanded(idProjeto); }}
                            />
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    render() {
        if (this.state.loading) return this.renderLoading();
        return this.state.idProjeto || this.state.projetos.length == 1 ? this.renderProjetoExpanded() : this.renderProjetosObjetivo();
    }
}
