import React from "react";

import './GraficoMapaCalor.css';
import DiagnosticoHelper from "../../../../../../helper/diagnostico/DiagnosticoHelper";
import DataHelper from "../../../../../../helper/DataHelper";
import EssentialStyle from "../../../../../../style/EssentialStyle";
import DefaultLoader from "../../../../../tools/DefaultLoader";
import BarraDemonstracao from "../../../../../tools/BarraDemonstracao";
import EllipsisText from "../../../../../tools/EllipsisText";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';
import Select from 'react-select';
import Colors from "../../../../../../constants/Colors";
import { Collapse, Form, Row, Col } from "react-bootstrap";
import SessionHelper from "../../../../../../helper/SessionHelper";
import CustomTooltip from "../../../../../tools/CustomTooltip";
import IdiomaHelper from "../../../../../../helper/IdiomaHelper";

export default class GraficoMapaCalor extends React.Component {

    state = {
        idPeriodoAvaliacao: this.props.idPeriodoAvaliacao,
        loading: false,
        loadingColunas: true,
        loadingChart: true,
        loadingColors: true,
        colunas: [],
        colunaSelected: null,
        chart: null,
        chartCopy: null,
        chartFooter: null,
        colors: null,

        orderY: [
            { 
                value: 'alfabetica', 
                label: IdiomaHelper.getStruct({ 
                    'pt': 'Alfabética', 
                    'en': 'Alphabetical', 
                    'es': 'Alfabético'
                }) 
            },
            { 
                value: 'maiorMedia', 
                label:  IdiomaHelper.getStruct({
                    'pt': 'Maior Média', 
                    'en': 'Highest Average', 
                    'es': 'Promedio más alto'
                })
            },
            { 
                value: 'menorMedia', 
                label: IdiomaHelper.getStruct({
                    'pt': 'Menor Média', 
                    'en': 'Lowest Average', 
                    'es': 'Promedio más bajo'
                })
            },
        ],
        orderYNode: null,
        orderSelectedY: 'alfabetica',

        filters: false,
        filterNomeEmpresa: '',

        hoverLine: null,
        hoverColumn: null,
    }

    async componentDidUpdate(prevProps) {
        if (this.props.idPeriodoAvaliacao !== prevProps.idPeriodoAvaliacao || this.props.lang !== prevProps.lang) {
            this.setState({ idPeriodoAvaliacao: this.props.idPeriodoAvaliacao }, async () => {
                await this.loadData();
            });
        }
    }

    async componentDidMount() {
        await this.loadData();
    }

    async loadData() {
        await this.loadingColunas();
    }

    async loadingColunas() {
        this.setState({ loadingColunas: true });
        let colunas = await DiagnosticoHelper.getColunasQuestionario(this.state.idPeriodoAvaliacao, this.props.lang);
        let colunaSelected = colunas.length > 0 ? colunas[0].id : null;
        this.setState({ colunas, colunaSelected, loadingColunas: false }, async () => {
            this.loadInfosChart();
            this.loadColors();
        });
    }

    async loadInfosChart() {
        this.setState({ loadingChart: true });
        let { chart, chartFooter, colors } = await DiagnosticoHelper.getHeatmapData(this.state.idPeriodoAvaliacao, this.state.colunaSelected, this.props.lang);

        colors = colors.map((interval) => {

            let colorAux = {
                from: interval.min,
                to: interval.max,
                color: interval.cor,
                infinito: 0,
            }

            return colorAux;
        })

        chart = this.orderEmpresas(chart);

        this.setState({ chart, chartCopy: chart, chartFooter, colors, loadingChart: false });
    }

    async loadColors() {
        this.setState({ loadingColors: true });
        let colors = await DiagnosticoHelper.getColorsPeriodoAvaliacao(this.state.idPeriodoAvaliacao);

        colors = colors.map((interval) => {

            let colorAux = {
                from: interval.min,
                to: interval.max,
                color: interval.cor,
                infinito: 0,
            }

            return colorAux;
        })

        this.setState({ colors, loadingColors: false });
    }

    setNodeColuna(colunaSelected) {
        this.setState({ colunaSelected }, () => {
            this.loadInfosChart();
        });
    }

    setOrderY(orderSelectedY) {
        this.setState({ orderSelectedY, orderYNode: null }, () => {
            this.setState({ chart: this.orderEmpresas(this.state.chart) });
        })
    }

    orderEmpresas(chart) {
        let orderSelectedY = this.state.orderSelectedY;

        if (this.state.orderYNode != null && this.state.orderYNode.node) {
            chart = chart.sort((a, b) => {
                let resultA = a.resultados.find((result) => result.node == this.state.orderYNode.node).resultados.valor;
                let resultB = b.resultados.find((result) => result.node == this.state.orderYNode.node).resultados.valor;

                if (this.state.orderYNode.order == 0) {
                    return resultA > resultB ? -1 : resultA < resultB ? 1 : 0;
                } else {
                    return resultA < resultB ? -1 : resultA > resultB ? 1 : 0;
                }
            });
        } else if (orderSelectedY == 'alfabetica') {
            chart = chart.sort((a, b) => {
                if (a.empresaAvaliada.nome_fantasia < b.empresaAvaliada.nome_fantasia) return -1;
                if (a.empresaAvaliada.nome_fantasia > b.empresaAvaliada.nome_fantasia) return 1;
                return 0;
            });
        } else if (orderSelectedY == 'maiorMedia' || orderSelectedY == 'menorMedia') {
            chart = chart.sort((a, b) => {
                let mediaA = parseFloat(
                    a.resultados.map((coluna) => {
                        return coluna.resultados.valor;
                    }).reduce((a, b) => a + b, 0) / a.resultados.length
                ).toFixed(1);

                let mediaB = parseFloat(
                    b.resultados.map((coluna) => {
                        return coluna.resultados.valor;
                    }).reduce((a, b) => a + b, 0) / b.resultados.length
                ).toFixed(1);

                if (orderSelectedY == 'maiorMedia') {
                    return mediaA > mediaB ? -1 : mediaA < mediaB ? 1 : 0;
                } else {
                    return mediaA < mediaB ? -1 : mediaA > mediaB ? 1 : 0;
                }
            });
        }

        return chart;
    }

    filter() {
        let chart = this.state.chartCopy;
        let filterNomeEmpresa = this.state.filterNomeEmpresa.toLowerCase();

        if (filterNomeEmpresa && filterNomeEmpresa.length > 0) {
            chart = chart.filter((linha) => {
                return linha.empresaAvaliada.nome_fantasia.toLowerCase().includes(filterNomeEmpresa);
            });
        }

        chart = this.orderEmpresas(chart);

        this.setState({ chart });
    }

    orderByNode(node) {
        let orderYNode = null;
        if (this.state.orderYNode == null || this.state.orderYNode.node != node) {
            orderYNode = {node, order: 0};
        } else if (this.state.orderYNode.node == node){
            orderYNode = this.state.orderYNode.order == 1 ? null : {node, order: 1};
        }

        this.setState({ orderYNode }, () => {
            this.setState({chart: this.orderEmpresas(this.state.chart)})
        });
    }

    getMessage(text) {
        let message = IdiomaHelper.getStruct({
            'pt': {
                colunaSelected: 'Selecione a coluna',
                chartHeader: 'Média das Avaliações',
                chartHeaderTotal: 'Geral por Empresa',
                chartHeaderTotalI: 'Média Aritmética de todas as respostas',
                chartFooter: `Total por ${this.state.colunas.find((coluna) => coluna.id == this.state.colunaSelected).descricao}`,
                selectOrder: 'Selecione a ordem',
                formEmpresa: 'Nome da Empresa',
                formEmpresaPlaceHolder: 'Busque por empresa',
                empresaEmpty: 'Nenhuma empresa encontrada.',
                labelColuna: 'Coluna',
                labelOrderY: 'Ordem das Empresas',
                labelOrderX: 'Ordem das Colunas',
                labelBar: 'Escala',
            },
            'en': {
                colunaSelected: 'Select the column',
                chartHeader: 'Average of Evaluations',
                chartHeaderTotal: 'General by Company',
                chartHeaderTotalI: 'Arithmetic mean of all answers',
                chartFooter: `Total by ${this.state.colunas.find((coluna) => coluna.id == this.state.colunaSelected).descricao}`,
                selectOrder: 'Select the order',
                formEmpresa: 'Company Name',
                formEmpresaPlaceHolder: 'Search for company',
                empresaEmpty: 'No company found.',
                labelColuna: 'Column',
                labelOrderY: 'Order of Companies',
                labelOrderX: 'Order of Columns',
                labelBar: 'Scale',
            },
            'es': {
                colunaSelected: 'Seleccione la columna',
                chartHeader: 'Promedio de Evaluaciones',
                chartHeaderTotal: 'General por empresa',
                chartHeaderTotalI: 'Media aritmética de todas las respuestas.',
                chartFooter: `Total por ${this.state.colunas.find((coluna) => coluna.id == this.state.colunaSelected).descricao}`,
                selectOrder: 'Seleccione el orden',
                formEmpresa: 'Busar por empresa',
                formEmpresaPlaceHolder: 'Nombre de la Empresa',
                empresaEmpty: 'No se encontró ninguna empresa.',
                labelColuna: 'Columna',
                labelOrderY: 'Orden de Empresas',
                labelOrderX: 'Orden de Columnas',
                labelBar: 'Escala',
            }
        });

        return message[this.props.lang][text];
    }

    selectGetMessage(options){
        return options.map((option) => {
            return {
                value: option.value,
                label: option.label[this.props.lang],
            }
        });
    }

    renderBody() {
        return (
            <div
                className="containerBody"
                style={{ ...EssentialStyle.columnCenter }}
            >
                {this.renderConfigChart()}
                {this.renderChart()}
            </div>
        )
    }

    renderChart() {

        const loadingChart = () => {
            return (
                <div className="containerChart" style={EssentialStyle.columnCenter}>
                    <DefaultLoader size={36} />
                </div>
            )
        }

        if (this.state.loadingChart) return loadingChart();
        if (this.state.chart.length == 0) return DiagnosticoHelper.renderEmpty(this.getMessage('empresaEmpty'), '5rem');
        return (
            <div className="containerChart" >
                {/* Cabeçalho da tabela de Mapa de calor */}
                <div className="line-chart line-header">

                    <div
                        className="cel-chart header-cel"
                        style={{ borderRadius: '8px 0px 0px 8px' }}
                    >
                        {this.getMessage('chartHeader')}
                    </div>

                    {this.state.chart[0].resultados.map((coluna, indexColuna) => {
                        return (
                            <div
                                key={`cel-chart-${coluna.node}`}
                                className="cel-chart header-cel"
                                style={{
                                    fontWeight: this.state.hoverColumn == coluna.node ? '900' : '',
                                    boxShadow: this.state.hoverColumn == coluna.node ? Colors.boxShadow : '',
                                    zIndex: this.state.hoverColumn == coluna.node ? 1 : '',
                                    cursor: 'pointer',
                                }}
                                onClick={() => { this.orderByNode(coluna.node) }}
                            >
                                {this.state.orderYNode && this.state.orderYNode.node == coluna.node && 
                                    <FontAwesomeIcon icon={this.state.orderYNode.order == 1 ? faChevronDown : faChevronUp} style={{marginRight: 5}} />
                                }
                                <EllipsisText text={coluna.nome} style={{minWidth: '', maxWidth: 'calc(100% - 20px)'}}/>
                            </div>
                        )
                    })}

                    <div
                        className="cel-chart header-cel"
                        style={{
                            borderRadius: '0px 8px 8px 0px',
                            fontWeight: this.state.hoverColumn == 'totalEmpresa' ? '900' : '',
                            boxShadow: this.state.hoverColumn == 'totalEmpresa' ? Colors.boxShadow : '',
                            zIndex: this.state.hoverColumn == 'totalEmpresa' ? 1 : '',
                        }}
                    >
                        {this.getMessage('chartHeaderTotal')} 
                        <CustomTooltip 
                            tooltip={this.getMessage('chartHeaderTotalI')}
                        >
                            <div className="info">i</div>
                        </CustomTooltip>
                    </div>

                </div>

                {/* Corpo da tabela de Mapa de calor */}
                {this.state.chart.map((linha, indexLinha) => {

                    let media = parseFloat(
                        linha.resultados.map((coluna) => {
                            return coluna.resultados.valor;
                        }).reduce((a, b) => a + b, 0) / linha.resultados.length
                    ).toFixed(1)

                    return (
                        <div key={`linha-chart-${linha.empresaAvaliada.id}`} className="line-chart line-body">

                            <div
                                className="cel-chart header-cel"
                                style={{
                                    backgroundColor: indexLinha % 2 == 0 ? 'rgb(170, 170, 170)' : 'rgb(190, 190, 190)',
                                    borderTopLeftRadius: indexLinha == 0 ? '8px' : '0px',
                                    fontWeight: this.state.hoverLine == indexLinha ? '900' : '',
                                    boxShadow: this.state.hoverLine == indexLinha ? Colors.boxShadow : '',
                                    zIndex: this.state.hoverLine == indexLinha ? 1 : '',
                                }}
                            >
                                {linha.empresaAvaliada.nome_fantasia}
                            </div>

                            {linha.resultados.map((coluna, indexColuna) => {
                                return (
                                    <div
                                        key={`cel-chart-${coluna.node}`}
                                        className="cel-chart value-chart"
                                        style={{
                                            backgroundColor: coluna.resultados.cor,
                                        }}
                                        onMouseEnter={() => this.setState({ hoverLine: indexLinha, hoverColumn: coluna.node })}
                                        onMouseLeave={() => this.setState({ hoverLine: null, hoverColumn: null })}
                                    >
                                        {parseFloat(coluna.resultados.valor).toFixed(1)}%
                                    </div>
                                )
                            })}

                        <div 
                            className="cel-chart value-chart"
                            style={{
                                backgroundColor: linha.resultadoMedia.cor,
                                borderTopRightRadius: indexLinha == 0 ? '8px' : '0px',
                                }}
                            onMouseEnter={() => this.setState({ hoverLine: indexLinha, hoverColumn: 'totalEmpresa' })}
                            onMouseLeave={() => this.setState({ hoverLine: null, hoverColumn: null })}
                        >
                            {parseFloat(linha.resultadoMedia.valor).toFixed(1)}%
                        </div>
                            
                            {/* 
                            
                            Cálculo da média da coluna (todas colunas com mesmo peso - descontínuada momentaneamente)
                            <div
                                className="cel-chart value-chart"
                                style={{
                                    backgroundColor: DiagnosticoHelper.getColor(this.state.colors, media),
                                    borderTopRightRadius: indexLinha == 0 ? '8px' : '0px',
                                }}
                                onMouseEnter={() => this.setState({ hoverLine: indexLinha, hoverColumn: 'totalEmpresa' })}
                                onMouseLeave={() => this.setState({ hoverLine: null, hoverColumn: null })}
                            >
                                {media}%
                            </div> 
                            */}

                        </div>
                    )
                })}

                {/* Rodapé da tabela de Mapa de calor */}
                <div className="line-chart line-footer">

                    <div
                        className="cel-chart header-cel"
                        style={{
                            backgroundColor: 'rgb(220, 220, 220)',
                            borderBottomLeftRadius: '8px',
                            fontWeight: this.state.hoverLine == 'totalColuna' ? '900' : '',
                            boxShadow: this.state.hoverLine == 'totalColuna' ? Colors.boxShadow : '',
                            zIndex: this.state.hoverLine == 'totalColuna' ? 1 : '',
                        }}
                    >
                        {this.getMessage('chartFooter')}
                    </div>

                    {/* {this.state.chartFooter.map((coluna, indexColunaFooter) => {
                    return (
                        <div 
                            key={`cel-chart-footer-${coluna.node}`} 
                            className="cel-chart value-chart"
                            style={{ 
                                backgroundColor: coluna.cor,
                                borderBottomRightRadius: indexColunaFooter == (this.state.chartFooter.length -1) ? '8px' : '0px',
                            }}
                            onMouseEnter={() => this.setState({ hoverLine: 'totalColuna', hoverColumn: coluna.node != null ? coluna.node : 'totalEmpresa' })}
                            onMouseLeave={() => this.setState({ hoverLine: null, hoverColumn: null })}
                        >
                            {parseFloat(coluna.valor).toFixed(1)}%
                        </div>
                    )
                })} */}

                    {this.state.chart[0].resultados.map((node) => {
                        let idNode = node.node;
                        let listSum = this.state.chart.map((linha) => {
                            return linha.resultados.find((result) => result.node == idNode).resultados.valor;
                        });
                        let media = listSum.reduce((a, b) => a + b, 0) / listSum.length;

                        return (
                            <div
                                key={`cel-chart-footer-${idNode}`}
                                className="cel-chart value-chart"
                                style={{
                                    backgroundColor: DiagnosticoHelper.getColor(this.state.colors, media),
                                    borderTop: '1px solid rgb(220, 220, 220)',
                                }}
                                onMouseEnter={() => this.setState({ hoverLine: 'totalColuna', hoverColumn: idNode })}
                                onMouseLeave={() => this.setState({ hoverLine: null, hoverColumn: null })}
                            >
                                {parseFloat(media).toFixed(1)}%
                            </div>
                        )
                    })}

                    <div
                        key={`cel-chart-footer-total-empresa`}
                        className="cel-chart value-chart"
                        style={{
                            backgroundColor: DiagnosticoHelper.getColor(this.state.colors, parseFloat(
                                this.state.chart.map((linha) => {
                                    return linha.resultadoMedia.valor;
                                }).reduce((a, b) => a + b, 0) / this.state.chart.length
                            ).toFixed(1)),
                            borderBottomRightRadius: '8px',
                            borderTop: '1px solid rgb(220, 220, 220)',
                        }}
                        onMouseEnter={() => this.setState({ hoverLine: 'totalColuna', hoverColumn: 'totalEmpresa' })}
                        onMouseLeave={() => this.setState({ hoverLine: null, hoverColumn: null })}
                    >
                        {
                            parseFloat(
                                this.state.chart.map((linha) => {
                                    return linha.resultadoMedia.valor;
                                }).reduce((a, b) => a + b, 0) / this.state.chart.length
                            ).toFixed(1)
                        }%
                    </div>

                </div>
            </div>
        )
    }

    renderConfigChart() {

        if (this.state.loadingColunas) return (
            <div className="containerConfigChart" style={EssentialStyle.columnCenter}>
                <DefaultLoader size={36} />
            </div>
        )

        return (
            <div className="containerConfigChart" >

                <div className="line">
                    <div className="left">
                        <div>
                            <span className="label" style={{marginLeft: 0}}>{this.getMessage('labelColuna')}</span>
                            <Select
                                options={DataHelper.formatSelectData(this.state.colunas, 'id', 'descricao')}
                                value={DataHelper.formatSelectData(this.state.colunas, 'id', 'descricao').find((coluna) => coluna.value == this.state.colunaSelected)}
                                styles={{ control: (base) => ({ ...base, minWidth: '10rem', height: '43px', marginTop: '5px' }) }}
                                onChange={(e) => { this.setNodeColuna(e.value) }}
                                menuPortalTarget={document.body}
                                placeholder={this.getMessage('colunaSelected')}
                                menuPlacement="auto"
                                menuPosition="fixed"
                            />
                        </div>

                        <div>
                            <span className="label" style={{marginLeft: 0}}>{this.getMessage('labelOrderY')}</span>
                            <Select
                                options={this.selectGetMessage(this.state.orderY)}
                                value={this.state.orderYNode == null ? this.selectGetMessage(this.state.orderY).find((item) => item.value == this.state.orderSelectedY) : null}
                                styles={{ control: (base) => ({ ...base, minWidth: '10rem', height: '43px', marginTop: '5px' }) }}
                                onChange={(e) => { this.setOrderY(e.value) }}
                                menuPortalTarget={document.body}
                                placeholder={this.getMessage('selectOrder')}
                                menuPlacement="auto"
                                menuPosition="fixed"
                            />
                        </div>

                        <div
                            className="buttonFilter"
                            style={{ backgroundColor: SessionHelper.getColor() }}
                            onClick={() => this.setState({ filters: !this.state.filters })}
                        >
                            {this.state.filterNomeEmpresa.length > 0 && (
                                <div className="indicadorFiltro">1</div>
                            )}
                            <FontAwesomeIcon icon={this.state.filters ? faChevronUp : faChevronDown} />
                        </div>


                    </div>
                    <div>
                        <span className="label">{this.getMessage('labelBar')}</span>
                        <BarraDemonstracao intervals={this.state.colors} loading={this.state.loadingColors} width={'20rem'} marginTop={'0px'} />
                    </div>
                </div>

                <Collapse in={this.state.filters}>
                    <Form style={{width: '100%'}}>

                        <Row className="mb-1" style={{ width: 'calc(100%)' }}>
                            <Form.Group as={Col}>
                                <Form.Label className={"label"} style={{marginLeft: 0}}>{this.getMessage('formEmpresa')}</Form.Label>
                                <Form.Control 
                                    style={{ margin: '0 3px' }}
                                    placeholder={this.getMessage('formEmpresaPlaceHolder')}
                                    value={this.state.filterNomeEmpresa} 
                                    onChange={(e) => this.setState({ filterNomeEmpresa: e.target.value }, () => this.filter())} />
                            </Form.Group>
                        </Row>

                    </Form>
                </Collapse>

            </div>

        )
    }

    render() {
        if (this.state.loading) return <div style={{ ...EssentialStyle.rowFlexCenter, width: '100%', height: '100%' }}><DefaultLoader size={36} /></div>
        return this.renderBody();
    }
}