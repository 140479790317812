import React from "react";
import '../PlanoAcaoRelatorio.css';

import EssentialStyle from '../../../../../style/EssentialStyle';
import DefaultLoader from '../../../../tools/DefaultLoader';
import { Collapse } from "react-bootstrap";
import Colors from "../../../../../constants/Colors";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';
import SessionHelper from '../../../../../helper/SessionHelper';
import moment from 'moment';
import DefaultButton from '../../../../tools/DefaultButton';
import UserAvatar from '../../../../tools/UserAvatar';
import ChartHelper from '../../../../../helper/inicio/ChartHelper';
import Tabs from "../../../../tools/Tabs";

export default class PlanoAcaoRelatorioParceiros extends React.Component {

    state = {
        contadores: {},
        expanded: false,
        status: [
            "Não Iniciado",
            "Em Desenvolvimento",
            "Concluído",
            "Atrasado",
            "Cancelado",
        ],
        statusEtapa: [
            "Não Iniciada",
            "Em Desenvolvimento",
            "Concluída",
            "Atrasada",
            "Cancelada",
        ],
        activeTab: 'planos',
        loading: true,
    }

    componentDidMount() {
        this.calculaContadores();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.planos !== this.props.planos) {
            this.calculaContadores();
        }
    }

    calculaContadores = () => {

        this.setState({ loading: true });

        var defaultPlanoCount = {
            total: 0,
            "Não Iniciado": 0,
            "Em Desenvolvimento": 0,
            "Concluído": 0,
            "Atrasado": 0,
            "Cancelado": 0,
        }
        var defaultEtapaCount = {
            total: 0,
            "Não Iniciada": 0,
            "Em Desenvolvimento": 0,
            "Concluída": 0,
            "Atrasada": 0,
            "Cancelada": 0,
        }

        let contadores = {
            parceiros: [],
        }

        if (!this.props.planos || this.props.planos.length === 0) {
            this.setState({ loading: false, contadores });
            return;
        }

        let planos = this.props.planos;

        planos.forEach(plano => {
            let parceiros = [];

            if (plano.id_empresa) {
                let indexParceiro = contadores.parceiros.findIndex(parceiro => parceiro.id == plano.id_empresa);
                if (indexParceiro == -1) {
                    contadores.parceiros.push({ id: plano.id_empresa, nome: plano.nome_empresa });
                }

                if (!parceiros.includes(plano.id_empresa)) {
                    parceiros.push(plano.id_empresa);
                    let index = indexParceiro == -1 ? (contadores.parceiros.length - 1) : indexParceiro;

                    if (!contadores.parceiros[index].planos) {
                        contadores.parceiros[index].planos = { ...defaultPlanoCount };
                    }

                    contadores.parceiros[index].planos.total++;
                    contadores.parceiros[index].planos[plano.status]++;
                }

            }

            if (plano.etapas && plano.etapas.length > 0) {
                plano.etapas.forEach(etapa => {
                    let parceirosEtapa = [];

                    let indexParceiro = contadores.parceiros.findIndex(parceiro => parceiro.id == plano.id_empresa);
                    if (indexParceiro == -1) {
                        contadores.parceiros.push({ id: etapa.id_empresa, nome: etapa.nome_empresa });
                    }
                    if (!parceirosEtapa.includes(plano.id_empresa)) {
                        parceirosEtapa.push(plano.id_empresa);
                        let index = indexParceiro == -1 ? (contadores.parceiros.length - 1) : indexParceiro;
                        if(!contadores.parceiros[index].etapas){
                            contadores.parceiros[index].etapas = {...defaultEtapaCount};
                        }
                        contadores.parceiros[index].etapas.total++;
                        if(etapa.atrasada && etapa.status != 2 && etapa.status != 3){
                            contadores.parceiros[index].etapas['Atrasada']++;
                        } else {
                            contadores.parceiros[index].etapas[etapa.status_text]++;
                        }

                        if(!contadores.parceiros[index].atrasoTotal){
                            contadores.parceiros[index].atrasoTotal = 0;
                        }
                        contadores.parceiros[index].atrasoTotal += this.getDiasAtrasoEtapa(etapa);
                    }
                });
            }
        });

        this.setState({ contadores, loading: false });
    }

    getDiasAtrasoEtapa = (etapa) => {
        var dataFim = moment(etapa.data_fim);
        var dataFimReal = moment();

        if (etapa.status == 2 || etapa.status == 3 && etapa.data_conclusao) {
            dataFimReal = moment(etapa.data_conclusao);
        }

        if (dataFimReal.isAfter(dataFim)) {
            return dataFimReal.diff(dataFim, 'days');
        }
        return 0;
    }

    fieldIsFiltered = (filter) => {
        if(!filter) return false;
        var view = filter.view;
        var valuesFilter = filter.values;

        var isFiltered = false;
        if(this.props.filter?.view == view && this.props.filter?.values && this.props.filter?.values.length > 0){
            isFiltered = JSON.stringify(valuesFilter) == JSON.stringify(this.props.filter.values);
        }
        return isFiltered;
    }

    renderBarra(parceiro, tipo) {
        if (tipo != "etapas" && tipo != "planos" || !parceiro) return null;
        const status = tipo == "etapas" ? this.state.statusEtapa : this.state.status;

        const statusCounts = parceiro[tipo];

        const total = parceiro[tipo].total;

        const renderDiv = (status) => {
            var filter = { view: tipo, values: [{ field: tipo == "planos" ? 'status' : 'status_text', value: status }, { field: 'parceiro', value: parceiro.id }] };
            return (
                <div
                    key={status}
                    onClick={() => {
                        if(this.fieldIsFiltered(filter))
                            this.props.filterCallback( { view: "planos", values: []});
                        else
                            this.props.filterCallback(filter);
                    }}
                    style={{
                        ...EssentialStyle.rowFlexStart,
                        height: 35,
                        width: `${(statusCounts[status] / total) * 100}%`,
                        minWidth: statusCounts[status] > 0 ? 15 : 0,
                        backgroundColor: ChartHelper.getColor(status),
                        transition: 'height 0.5s ease, boxShadow 0.5s ease',
                        overflow: 'visible',
                        cursor: "pointer",
                        filter: this.fieldIsFiltered(filter) ? "brightness(0.8)" : "unset",
                    }}
                >
                    <span style={{
                        color: 'white',
                        fontWeight: '500',
                        marginLeft: 5,
                        opacity: 1,
                        transition: 'opacity 0.3s ease-in-out'
                    }}>
                        {parceiro[tipo][status]}
                    </span>
                </div>
            );
        }

        return (
            <div style={{ ...EssentialStyle.rowSpaceBetween, width: '100%', height: 35, borderRadius: 5, marginLeft: 5, overflow: 'hidden' }}>
                {status.map(renderDiv)}
            </div>
        );
    }

    handleTabChange = (tab) => {
        if (this.state.activeTab === tab.name) return;
        this.setState({ activeTab: tab.name });
    }

    renderParceiros = () => {
        if (this.state.loading) {
            return (
                <div style={{ ...EssentialStyle.columnCenter, width: "100%", height: "auto", justifyContent: 'center', alignItems: 'center' }} >
                    <DefaultLoader />
                </div>
            )
        }

        const tabs = [
            { name: 'planos', title: 'Planos de Ação' },
            { name: 'etapas', title: 'Etapas' },
        ];

        return (
            <div className="pa-colaboradores-component" style={{ ...EssentialStyle.columnCenter, width: '100%', backgroundColor: SessionHelper.getBackgroundColor(), paddingTop: "3px", position: 'sticky', left: 0 }}>
                <div style={{ ...EssentialStyle.columnCenter, minHeight: 35, boxShadow: `0px 1px 10px 0px  rgba(50, 50, 50, 0.1)`, borderRadius: 8, backgroundColor: Colors.white, width: '100%', overflow: "hidden" }}>

                    <div style={{ ...EssentialStyle.rowFlexCenter, cursor: 'pointer', minHeight: 35 }} onClick={() => this.setState({ expanded: !this.state.expanded })} >
                        {this.state.expanded ?
                            <FontAwesomeIcon icon={faChevronUp} style={{ marginRight: 8 }} /> :
                            <FontAwesomeIcon icon={faChevronDown} style={{ marginRight: 8 }} />
                        }
                        {
                            this.state.expanded ? 'Ocultar Parceiros' : 'Parceiros'
                        }
                    </div>

                    <Collapse in={this.state.expanded} style={{ width: '100%', transition: "all 0.2s ease" }}>
                        <div style={{ ...(this.props.isSmallScreen ? EssentialStyle.columnCenter : EssentialStyle.rowFlexCenter), width: '100%' }}>
                            <div style={{ ...EssentialStyle.columnStart, width: '100%', height: 300, paddingTop: 0, gap: 15 }}>
                                {this.state.loading ?
                                    <div style={{ ...EssentialStyle.columnCenter, padding: 10, width: '100%', height: "100%" }}>
                                        <DefaultLoader />
                                    </div>
                                    :
                                    <div style={{ ...EssentialStyle.columnStart, paddingBottom: 5, width: "100%" }}>
                                        {this.props.isSmallScreen &&
                                            <Tabs
                                                isSmallScreen={this.props.isSmallScreen}
                                                tabs={tabs}
                                                activeTab={this.state.activeTab}
                                                onTabChange={this.handleTabChange}
                                                tabStyle={{ width: this.props.isSmallScreen ? '40vw' : 90, maxWidth: 150 }}
                                                style={{ placeSelf: "center" }}
                                            />
                                        }
                                        <div style={{ ...EssentialStyle.rowFlexCenter, height: 40, borderBottom: `1px solid ${Colors.homePage.extraLightGrey}`, width: "100%", textAlign: 'center' }}>
                                            <div style={{ fontSize: 16, fontWeight: 500, color: Colors.homePage.grey, width: this.props.isSmallScreen ? "45%" : "35%", height: "100%", alignContent: "center", textOverflow: "ellipsis", overflow: "hidden", whiteSpace: "nowrap" }}>Empresa</div>
                                            {!this.props.isSmallScreen || this.state.activeTab === "planos" ?
                                                <div style={{ fontSize: 16, fontWeight: 500, color: Colors.homePage.grey, width: this.props.isSmallScreen ? "55%" : "25%", height: "100%", alignContent: "center", textOverflow: "ellipsis", overflow: "hidden", whiteSpace: "nowrap" }}>Planos de Ação</div>
                                                : null
                                            }
                                            {!this.props.isSmallScreen || this.state.activeTab === "etapas" ?
                                                <>
                                                    <div style={{ fontSize: 16, fontWeight: 500, color: Colors.homePage.grey, width: this.props.isSmallScreen ? "35%" : "25%", height: "100%", alignContent: "center", textOverflow: "ellipsis", overflow: "hidden", whiteSpace: "nowrap" }}>Etapas</div>
                                                    <div style={{ fontSize: 16, fontWeight: 500, color: Colors.homePage.grey, width: this.props.isSmallScreen ? "20%" : "15%", height: "100%", alignContent: "center", textOverflow: "ellipsis", overflow: "hidden", whiteSpace: "nowrap" }}>Atraso (média)</div>
                                                </> : null
                                            }
                                        </div>
                                        <div style={{ ...EssentialStyle.columnStart, width: "100%", overflowY: "auto", maxHeight: 250, height: "auto" }}>
                                            {this.state.contadores.parceiros.map((parceiro, index) => {
                                                return (
                                                    <div key={"colaborador-" + index} style={{ ...EssentialStyle.rowFlexCenter, borderBottom: `1px solid ${Colors.homePage.extraLightGrey}`, width: "100%", height: 40, minHeight: 40 }}>
                                                        <div style={{ fontSize: 16, fontWeight: 500, color: Colors.homePage.darkGrey, width: this.props.isSmallScreen ? "45%" : "35%", height: "100%", alignContent: "center", paddingLeft: 10 }}>
                                                            {parceiro.nome}
                                                        </div>
                                                        {!this.props.isSmallScreen || this.state.activeTab === "planos" ?
                                                            <div style={{ ...EssentialStyle.rowFlexStart, fontSize: 16, fontWeight: 500, color: Colors.homePage.darkGrey, width: this.props.isSmallScreen ? "55%" : "25%", height: "100%", alignContent: "center" }}>
                                                                {parceiro.planos ?
                                                                    <>
                                                                        <div style={{ ...EssentialStyle.rowFlexStart, height: "100%", width: "calc(100% - 45px)" }}>
                                                                            {this.renderBarra(parceiro, "planos")}
                                                                        </div>
                                                                        <div
                                                                            onClick={() => {
                                                                                if(this.fieldIsFiltered({ view: "planos", values: [{ field: 'parceiro', value: parceiro.id }] }))
                                                                                    this.props.filterCallback( { view: "planos", values: []});
                                                                                else
                                                                                    this.props.filterCallback({ view: "planos", values: [{ field: 'parceiro', value: parceiro.id }] })
                                                                            }}
                                                                            style={{ 
                                                                                ...EssentialStyle.rowFlexCenter, cursor: "pointer",
                                                                                height: 30, width: 40, marginLeft: 5, 
                                                                                borderLeft: `2px solid ${Colors.homePage.extraLightGrey}`,  
                                                                                borderRadius: this.fieldIsFiltered({ view: "planos", values: [{ field: 'parceiro', value: parceiro.id }] }) ? 5 : 0,
                                                                                backgroundColor: this.fieldIsFiltered({ view: "planos", values: [{ field: 'parceiro', value: parceiro.id }] }) ? Colors.homePage.extraLightGrey : "transparent"
                                                                            }}
                                                                        >
                                                                            {parceiro.planos.total}
                                                                        </div>
                                                                    </>

                                                                    :
                                                                    <div style={{ ...EssentialStyle.rowFlexCenter, height: "100%", width: "calc(100% - 45px)" }}>
                                                                        -
                                                                    </div>
                                                                }
                                                            </div>
                                                            : null
                                                        }
                                                        {!this.props.isSmallScreen || this.state.activeTab === "etapas" ?
                                                            <>
                                                                <div style={{ ...EssentialStyle.rowFlexStart, fontSize: 16, fontWeight: 500, color: Colors.homePage.darkGrey, width: this.props.isSmallScreen ? "35%" : "25%", height: "100%", alignContent: "center" }}>
                                                                    {parceiro.etapas ?
                                                                        <>
                                                                            <div style={{ ...EssentialStyle.rowFlexStart, height: "100%", width: "calc(100% - 45px)" }}>
                                                                                {this.renderBarra(parceiro, "etapas")}
                                                                            </div>
                                                                            <div
                                                                                onClick={() => {
                                                                                    if(this.fieldIsFiltered({ view: "etapas", values: [{ field: 'parceiro', value: parceiro.id }] }))
                                                                                        this.props.filterCallback( { view: "planos", values: []});
                                                                                    else
                                                                                        this.props.filterCallback({ view: "etapas", values: [{ field: 'parceiro', value: parceiro.id }] })
                                                                                }}
                                                                                style={{ 
                                                                                    ...EssentialStyle.rowFlexCenter, cursor: "pointer",
                                                                                    height: 30, width: 40, marginLeft: 5, 
                                                                                    borderLeft: `2px solid ${Colors.homePage.extraLightGrey}`,  
                                                                                    borderRadius: this.fieldIsFiltered({ view: "etapas", values: [{ field: 'parceiro', value: parceiro.id }] }) ? 5 : 0,
                                                                                    backgroundColor: this.fieldIsFiltered({ view: "etapas", values: [{ field: 'parceiro', value: parceiro.id }] }) ? Colors.homePage.extraLightGrey : "transparent"
                                                                                }}
                                                                            >
                                                                                {parceiro.etapas.total}
                                                                            </div>
                                                                        </>
                                                                        :
                                                                        <div style={{ ...EssentialStyle.rowFlexCenter, height: "100%", width: "calc(100% - 45px)" }}>
                                                                            -
                                                                        </div>
                                                                    }
                                                                </div>
                                                                <div style={{ fontSize: 16, fontWeight: 500, color: Colors.homePage.darkGrey, width: this.props.isSmallScreen ? "20%" : "15%", height: "100%", alignContent: "center", textAlign: "center", textOverflow: "ellipsis", overflow: "hidden", whiteSpace: "nowrap" }}>
                                                                    {parceiro.atrasoTotal && parceiro.atrasoTotal > 0 ? Math.round((parceiro.atrasoTotal / parceiro.etapas.total) * 10) / 10 < 1.5 ? "1 dia" : Math.round((parceiro.atrasoTotal / parceiro.etapas.total) * 10) / 10 + " dias" : "Em dia"}
                                                                </div>
                                                            </> : null
                                                        }
                                                    </div>
                                                )
                                            })}
                                        </div>
                                    </div>
                                }
                            </div>
                        </div>
                    </Collapse>
                </div>
            </div>
        );
    }


    render() {
        return (
            this.renderParceiros()
        )
    }
}