import React from "react";
import ReactDOM from "react-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight, faChevronLeft, faMaximize, faMinimize, faSync, faWarning, faChartColumn, faChartLine } from "@fortawesome/free-solid-svg-icons";
import SessionHelper from "../../../../helper/SessionHelper";
import Colors from "../../../../constants/Colors";
import EssentialStyle from "../../../../style/EssentialStyle";
import DefaultLoader from "../../../tools/DefaultLoader";
import DefaultButton from "../../../tools/DefaultButton";
import Sig from "../../../../api/Sig";
import ChartHelper from "../../../../helper/inicio/ChartHelper";
import Highcharts from "highcharts/highcharts.js";
import HighchartsReact from "highcharts-react-official";
import variablePie from "highcharts/modules/variable-pie.js";
import DatePicker from "react-datepicker";
import { forwardRef } from 'react';
import { Form, Collapse } from "react-bootstrap";
import moment from "moment";
import { toast } from 'react-toastify';
import Constants from "../../../../constants/Api";
import UserAvatar from "../../../tools/UserAvatar";
import Select from 'react-select';
import DataHelper from "../../../../helper/DataHelper";
import { Dropdown } from "react-bootstrap";
import { faSort } from "@fortawesome/free-solid-svg-icons";
import LayoutHelper from "../../../../helper/LayoutHelper";

variablePie(Highcharts);
const gateway = Constants.getSigEndPoint();

export default class GraficosMinhaEquipe extends React.Component {
    state = {
        loading: true,
        loadingSelect: false,
        loadingGrafico: false,
        loadingSelect: false,

        subordinados: [],
        transform: 'translateY(+100%)',
        opacity: 0,
        hoverSubordinado: null,

        data_inicio: moment().subtract(1, 'years').toDate(),
        data_fim: moment().subtract(1, 'month').toDate(),
        periodos: null,
        grafico: null,
        tendencia: null,

        expanded: false,

        indicadores: [],
        selectedIndicador: {},
        selectedPainel: {},

        showGraphic: false,
        unidadesDistintas: false,

        colaboradorColors: [],

        visualizationTypes: {
            performance: 'Performance',
            meta: 'Meta',
            realizado: 'Realizado',
            metaRealizado: 'Meta x Realizado',
            performanceGeralPainel: 'Performance Geral do Painel',

        },
        selectedVisualization: localStorage.getItem('equipe_visualization_option') || 'performance',
        isSmallScreen: LayoutHelper.isSmallScreen(),

        config: [],
        configGrafico: [],
    }

    async componentDidMount() {
        window.addEventListener('resize', this.verifyScreen);
        await this.loadData("didMount");
    }

    async componentDidUpdate(prevProps) {
        if ((this.props.userId && this.props.userId !== prevProps.userId) || (this.props.reloadEquipe !== prevProps.reloadEquipe)) {
            this.loadData("userId");

        } else if (!this.arraysAreEqual(this.props.selectedUsersEquipe, prevProps.selectedUsersEquipe)) {
            if (this.props.selectedUsersEquipe && this.props.selectedUsersEquipe.length > 0 && this.props.notAtualizeGraphic == prevProps.notAtualizeGraphic) {
                await this.loadData("equipe");

            } else if (this.props.selectedUsersEquipe && this.props.selectedUsersEquipe.length == 0){
                this.setState({ indicadores: [], showGraphic: false, selectedIndicador: {}, selectedPainel: {}});
            }
        }

        if (this.props.atualizarTamanho !== prevProps.atualizarTamanho) {
            setTimeout(() => {
                this.updateWidthGrafico();
            }, 1000);
        }
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.verifyScreen);
    }

    verifyScreen = () => {
        this.updateWidthGrafico();
        this.setState({ isSmallScreen: LayoutHelper.isSmallScreen() });
    }

    arraysAreEqual(array1, array2) {
        if (array1.length !== array2.length) return false;
        return array1.every((element, index) => element === array2[index]);
    }

    loadData = async (tipo) => {
        try {
            if (stateUpdates[tipo]) {
                this.setState(stateUpdates[tipo]);
            }

            if (tipo !== "equipe") {
                let { config } = await Sig.request('GET', 'inicio/getInicioConfigColaboradoresEquipe', { colaboradorAlvo: this.props.userId, viewUserConfig: this.props.viewUserConfig });
                let { configGrafico } = await Sig.request('GET', 'inicio/getInicioConfigGraficoEquipe', { colaboradorAlvo: this.props.userId, viewUserConfig: this.props.viewUserConfig });
                this.setState({ config, configGrafico });
            }

            let data_inicio = this.state.configGrafico?.data_inicio && tipo == "didMount" ? moment(this.state.configGrafico?.data_inicio).format('YYYY-MM-DD HH:mm:ss') : moment(this.state.data_inicio).format('YYYY-MM-DD HH:mm:ss');
            let data_fim =    this.state.configGrafico?.data_fim && tipo == "didMount"    ? moment(this.state.configGrafico?.data_fim).format('YYYY-MM-DD HH:mm:ss')    :    moment(this.state.data_fim).format('YYYY-MM-DD HH:mm:ss');
            
            let { indicadores } = this.props.selectedUsersEquipe.length > 0 ? await Sig.request('GET', 'config/colaborador/getEquipeIndicadores', {
                data_inicio, 
                data_fim, 
                colaboradores: this.props.selectedUsersEquipe
            }) : { indicadores: [] };

            for (let key in indicadores) {
                if (indicadores[key].indicadores && typeof indicadores[key].indicadores === 'object') {
                    indicadores[key].indicadores = Object.values(indicadores[key].indicadores);
                }
            }

            let colaboradorColors = [];
            if(this.props.selectedUsersEquipe.length > 0){
                for(let i = 0; i < this.props.selectedUsersEquipe.length; i++) {
                    let colaborador = this.props.selectedUsersEquipe[i];
                    colaboradorColors.push({id: colaborador, color: Colors.graphics[i] || 'black'});
                }
            }

            let newState = { indicadores, colaboradorColors };

            if (tipo === "didMount") {
                newState = { ...newState, transform: 'translateY(0)', opacity: 1 };
            } else if (tipo === "equipe") {
                newState = { ...newState, showGraphic: false, loadingSelect: false, loading: false};
            }

            this.setState(newState, () => {
                if (tipo === "didMount") {
                  this.useConfig();
                }
            });

        } catch (error) {
            toast.error('Erro ao buscar métricas dos indicadores.');
        }
    }

    useConfig = async () => {
        try {
            let configGrafico = this.state.configGrafico;
            let config = this.state.config;
            if (this.state.configGrafico && this.state.config.length > 0) {
                this.setState({loading: true, loadingSelect: true})
                let selectedVisualization = configGrafico.tipo_grafico;
                let data_inicio = moment(configGrafico.data_inicio).toDate();
                let data_fim = moment(configGrafico.data_fim).toDate();

                this.setState({ selectedVisualization, data_inicio, data_fim}, () => {
                    
                    this.handleVisualizationChange(selectedVisualization);
                    
                    config.forEach((configColaborador) => {
                        let listaIndicadores = this.state.indicadores[configColaborador.id_colaborador] ? Object.values(this.state.indicadores[configColaborador.id_colaborador].indicadores) : [];
                        let listaPaineis = this.state.indicadores[configColaborador.id_colaborador] ? Object.values(this.state.indicadores[configColaborador.id_colaborador].painel) : [];
                        let options = DataHelper.formatSelectData(this.state.selectedVisualization == "performanceGeralPainel" ? listaPaineis : listaIndicadores, 'id', 'nome')
                        
                        let ref = this.state.selectedVisualization == "performanceGeralPainel" ? configColaborador.id_painel : configColaborador.id_indicador;
                        let value = options.find(option => option.value === ref);

                        this.adicionaIndicadorPainel(value, configColaborador.id_colaborador);
                    });

                    this.setState({loading: false, loadingSelect: false}, () => {this.calculaGrafico()});
                });
            }

            this.setState({ loading: false });

        } catch (error) {
            toast.error('Erro ao buscar configurações do usuário.');
        }
    }

    atualizaIndicadorPainel() {

        let selected = this.state.selectedVisualization == "performanceGeralPainel" ? this.state.selectedPainel : this.state.selectedIndicador;

        this.props.selectedUsersEquipe.forEach((colaborador) => {

            if (!selected[colaborador]) {
                selected[colaborador] = null;
            }
        });

        for (let key in selected) {
            if (!this.props.selectedUsersEquipe.includes(key)) {
                delete selected[key];
            }
        }
        
        this.setState({ 
            selectedPainel: this.state.selectedVisualization == "performanceGeralPainel" ? selected : this.state.selectedPainel,
            selectedIndicador: this.state.selectedVisualization == "performanceGeralPainel" ? this.state.selectedIndicador : selected,
        });
    }

    adicionaIndicadorPainel(value, colaborador) {
        if (this.state.selectedVisualization == "performanceGeralPainel") {
            let selectedPainel = this.state.selectedPainel;
            selectedPainel[colaborador] = value;
            this.setState({ selectedPainel });
        } else {
            let selectedIndicador = this.state.selectedIndicador;
            selectedIndicador[colaborador] = value;

            if (value !== null){
                this.props.selectedUsersEquipe.forEach((otherColaborador) => {
                    if (selectedIndicador[otherColaborador] === null) {
                        let options = DataHelper.formatSelectData(this.state.indicadores[otherColaborador] ? Object.values(this.state.indicadores[otherColaborador].indicadores) : [], 'id', 'nome');
                        let otherValue = options.find(option => option.value === value.value);
                        selectedIndicador[otherColaborador] = otherValue;
                    }
                });
            }

            this.setState({ selectedIndicador });
        }
    }

    handleVisualizationChange(key) {

        if (key !== this.state.selectedVisualization) {
            this.setState({ loadingSelect: true });
            this.setState({ selectedVisualization: key }, () => { this.setState({ loadingSelect: false }) });
            window.localStorage.setItem('equipe_visualization_option', key);
        }
    }

    calculaGrafico = async () =>{

        this.setState({ loadingGrafico: true });

        let data_inicio = moment(this.state.data_inicio).format('YYYY-MM-DD HH:mm:ss');
        let data_fim = moment(this.state.data_fim).format('YYYY-MM-DD HH:mm:ss');

        if (this.state.selectedVisualization === "performanceGeralPainel") {
            if (this.checkSelection(this.state.selectedPainel, 'painéis')) {
                // toast.success("Gerando gráfico...");
            } else {
                return;
            }
        } else {
            if (this.checkSelection(this.state.selectedIndicador, 'indicadores')) {
                // toast.success("Gerando gráfico...");
            } else {
                return;
            }
        }

        let selectedIndicador = this.state.selectedIndicador;
        if(this.state.selectedVisualization != "performanceGeralPainel") {
            for (let key in selectedIndicador) {
                selectedIndicador[key].painel = this.state.indicadores[key].indicadores.find(indicador => indicador.id === selectedIndicador[key].value).painel;
                selectedIndicador[key].processos = this.state.indicadores[key].indicadores.find(indicador => indicador.id === selectedIndicador[key].value).processos;
            }
        }

        try {
            let { grafico, periodos } = await Sig.request('POST', 'config/colaborador/getEquipeGrafico', {
                data_inicio,
                data_fim,
                selectedIndicador: JSON.stringify(selectedIndicador),
                selectedPainel: JSON.stringify(this.state.selectedPainel),
                tipo: this.state.selectedVisualization,
            });

            this.setState({ grafico, periodos }, () => {
                if (this.state.selectedVisualization == "performanceGeralPainel") {
                    this.calculaGraficoPerformanceGeralPainel(true);
                    this.setState({unidadesDistintas: false})
                }
                if (this.state.selectedVisualization == "meta") {
                    this.calculaGraficoMetaIndicador(true);
                    this.verificaUnidadeIndicadores();
                }
                if (this.state.selectedVisualization == "realizado") {
                    this.calculaGraficoRealizadoIndicador(true);
                    this.verificaUnidadeIndicadores();
                }
                if (this.state.selectedVisualization == "metaRealizado") {
                    this.calculaGraficoMetaRealizadoIndicador(true);
                    this.verificaUnidadeIndicadores();
                }
                if (this.state.selectedVisualization == "performance") {
                    this.calculaGraficoPerformanceIndicador(true);
                    this.setState({unidadesDistintas: false})
                }
            });
            
            
            this.saveConfigUser();
            

        } catch (error) {
            this.setState({ loadingGrafico: false, showGraphic: false});
            toast.error('Erro ao buscar métricas dos indicadores.');
        }
    }

    saveConfigUser = async () => {
        let data_inicio = moment(this.state.data_inicio).format('YYYY-MM-DD HH:mm:ss');
        let data_fim = moment(this.state.data_fim).format('YYYY-MM-DD HH:mm:ss');

        let selectedIndicador = this.state.selectedIndicador;
        let selectedPainel = this.state.selectedPainel;

        let config = {
            viewUserConfig: this.props.viewUserConfig,
            colaboradorAlvo: this.props.userId,
            selectedVisualization: this.state.selectedVisualization,
            selectedIndicador: JSON.stringify(selectedIndicador),
            selectedPainel: JSON.stringify(selectedPainel),
            data_inicio,
            data_fim,
        }

        try {
            await Sig.request('POST', 'inicio/setInicioConfigMinhaEquipe', config);
        } catch (error) {
            toast.error('Erro ao salvar configurações do usuário.');
        }
    }

    updateWidthGrafico() {
        let tendenciaContainer = document.getElementById('tendenciaChartContainer');
        if (tendenciaContainer) {
            let tendencia = this.state.tendencia;
            tendencia.chart.width = tendenciaContainer.offsetWidth;

            this.setState({ loadingGrafico: true });
            this.setState({ tendencia }, () => { this.setState({ loadingGrafico: false }) });
        }
    }

    verificaUnidadeIndicadores() {
        let unidades = [];

        let selectedIndicador = this.state.selectedIndicador;
        if(this.state.selectedVisualization != "performanceGeralPainel") {
            for (let key in selectedIndicador) {
                let value = this.state.indicadores[key].indicadores.find(indicador => indicador.id === selectedIndicador[key].value).unidade;

                if (!unidades.includes(value % 2 === 0 ? parseInt(value) + 1 : parseInt(value))) {
                    unidades.push(value % 2 === 0 ? parseInt(value) + 1 : parseInt(value));
                }            
            }
        }

        if (unidades.length > 1) {
            this.setState({unidadesDistintas: true})
        } else {
            this.setState({unidadesDistintas: false})
        }
    }

    // === start - funções de cálculo de gráficos ===

    calculaGraficoPerformanceGeralPainel(AlterShowGraphic = true) {

        let grafico = this.state.grafico;
        let periodos = this.state.periodos;

        let tendencia = {
            chart: {
                type: periodos.length <= 1 ? 'column' : 'spline',
                width: this.state.expanded ? (ChartHelper.getWidth(68) - 100) : (ChartHelper.getWidth(43) - 10),
            },
            title: {
                text: ''
            },
            xAxis: {
                categories: periodos.map((key) => (key.mes + "/" + String(key.ano).slice(-2))),
                accessibility: {
                    description: 'Períodos do Painel no GMR'
                }
            },
            yAxis: {
                title: {
                    text: 'Performance Geral'
                },
                labels: {
                    format: '{value}%'
                }
            },
            legend: {
                enabled: false
            },
            tooltip: {
                crosshairs: [true, true],
                shared: false,
                useHTML: true,
                headerFormat: '<span style="font-size: 15px">{point.key}</span><hr/><table>',
                pointFormat: '<tr><span style="color:{point.color}">\u25CF</span> {series.name}: <b>{point.yFormated}</b></tr><br/><br/>',
                footerFormat: '</table>',
            },
            plotOptions: {
                spline: {
                    marker: {
                        radius: 4,
                        fillColor: SessionHelper.getColor()
                    },
                    lineWidth: 4,
                    dashStyle: 'none'
                }
            },
            series: Object.keys(grafico).map((keyColaborador) => {
                return {
                    name: `${grafico[keyColaborador].nomeColaborador}<br><strong>Painel</strong>: ${grafico[keyColaborador].nomePainel}`,
                    marker: {
                        symbol: 'diamond'
                    },
                    color: this.state.colaboradorColors.find(colaborador => colaborador.id === keyColaborador).color,
                    data: grafico[keyColaborador].performancePeriodos.map((key) => {

                        return {
                            y: parseFloat(key.performance),
                            yFormated: key.performanceValue,
                        };
                    })
                }
            }),
            credits: {
                enabled: false
            },
        }
        
        this.setState({ 
            loadingGrafico: AlterShowGraphic ? false : this.state.loadingGrafico, 
            showGraphic: AlterShowGraphic ? true : this.state.showGraphic, 
            tendencia
        }, () => {this.updateWidthGrafico();});
    }

    calculaGraficoMetaIndicador(AlterShowGraphic = true) {

        let grafico = this.state.grafico;
        let periodos = this.state.periodos;
        let unidade = grafico[this.props.selectedUsersEquipe[0]].unidade;
        let moeda = grafico[this.props.selectedUsersEquipe[0]].moedaSimbolo;

        let tendencia = {
            chart: {
                type: periodos.length <= 1 ? 'column' : 'spline',
                width: this.state.expanded ? (ChartHelper.getWidth(68) - 100) : (ChartHelper.getWidth(43) - 10),
            },
            title: {
                text: ''
            },
            xAxis: {
                categories: periodos.map((key) => (key.mes + "/" + String(key.ano).slice(-2))),
                accessibility: {
                    description: 'Períodos do Painel no GMR'
                }
            },
            yAxis: {
                title: {
                    text: 'Meta'
                },
                labels: {
                    formatter: function () {
                        if (unidade == 0 || unidade == 1) {
                            return moeda + " " + this.value.toLocaleString('pt-BR', { minimumFractionDigits: 2 });
                        } else if (unidade == 2 || unidade == 3) {
                            return this.value + '%';
                        } else if (unidade == 6 || unidade == 7) {
                            return this.value + ' Dias';
                        } else if (unidade == 8 || unidade == 9) {
                            return this.value + ' Horas';
                        } else {
                            return this.value;
                        }
                    }
                }
            },
            legend: {
                enabled: false
            },
            tooltip: {
                crosshairs: [true, true],
                shared: false,
                useHTML: true,
                headerFormat: '<span style="font-size: 15px">{point.key}</span><hr/><table>',
                pointFormat: '<tr><span style="color:{point.color}">\u25CF</span> {series.name}: <b>{point.yFormated}</b></tr><br/><br/>',
                footerFormat: '</table>',
            },
            plotOptions: {
                spline: {
                    marker: {
                        radius: 4,
                        fillColor: SessionHelper.getColor()
                    },
                    lineWidth: 4,
                    dashStyle: 'none'
                }
            },
            series: Object.keys(grafico).map((keyColaborador) => {

                let hasMetaAjustada = grafico[keyColaborador].hasMetaAjustada;
                let metaPeriodos = hasMetaAjustada ? grafico[keyColaborador].performanceMetaAjustadaPeriodos : grafico[keyColaborador].performanceMetaPeriodos;
                
                return {
                    name: `${hasMetaAjustada ? 'META AJUSTADA' : 'META'} - ${grafico[keyColaborador].nomeColaborador}<br><strong>Painel</strong>: ${grafico[keyColaborador].painelNome}<br><strong>Indicador</strong>: ${grafico[keyColaborador].indicadorNome}`,                   
                    marker: {
                        symbol: 'diamond'
                    },
                    color: this.state.colaboradorColors.find(colaborador => colaborador.id === keyColaborador).color,
                    data: metaPeriodos.map((key) => {

                        let yFormated = hasMetaAjustada ? key.metaAjustadaValue : key.metaValue;

                        return {
                            y: parseFloat(hasMetaAjustada ? key.metaAjustada : key.meta),
                            yFormated: yFormated,
                        };
                    })
                }
            }),
            credits: {
                enabled: false
            },
        }
        
        this.setState({ 
            loadingGrafico: AlterShowGraphic ? false : this.state.loadingGrafico, 
            showGraphic: AlterShowGraphic ? true : this.state.showGraphic, 
            tendencia
        }, () => {this.updateWidthGrafico();});
    }

    calculaGraficoRealizadoIndicador(AlterShowGraphic = true) {

        let grafico = this.state.grafico;
        let periodos = this.state.periodos;
        let unidade = grafico[this.props.selectedUsersEquipe[0]].unidade;
        let moeda = grafico[this.props.selectedUsersEquipe[0]].moedaSimbolo;

        let tendencia = {
            chart: {
                type: periodos.length <= 1 ? 'column' : 'spline',
                width: this.state.expanded ? (ChartHelper.getWidth(68) - 100) : (ChartHelper.getWidth(43) - 10),
            },
            title: {
                text: ''
            },
            xAxis: {
                categories: periodos.map((key) => (key.mes + "/" + String(key.ano).slice(-2))),
                accessibility: {
                    description: 'Períodos do Painel no GMR'
                }
            },
            yAxis: {
                title: {
                    text: 'Realizado'
                },
                labels: {
                    formatter: function () {
                        if (unidade == 0 || unidade == 1) {
                            return moeda + " " + this.value.toLocaleString('pt-BR', { minimumFractionDigits: 2 });
                        } else if (unidade == 2 || unidade == 3) {
                            return this.value + '%';
                        } else if (unidade == 6 || unidade == 7) {
                            return this.value + ' Dias';
                        } else if (unidade == 8 || unidade == 9) {
                            return this.value + ' Horas';
                        } else {
                            return this.value;
                        }
                    }
                }
            },
            legend: {
                enabled: false
            },
            tooltip: {
                crosshairs: [true, true],
                shared: false,
                useHTML: true,
                headerFormat: '<span style="font-size: 15px">{point.key}</span><hr/><table>',
                pointFormat: '<tr><span style="color:{point.color}">\u25CF</span> {series.name}: <b>{point.yFormated}</b></tr><br/><br/>',
                footerFormat: '</table>',
            },
            plotOptions: {
                spline: {
                    marker: {
                        radius: 4,
                        fillColor: SessionHelper.getColor()
                    },
                    lineWidth: 4,
                    dashStyle: 'none'
                }
            },
            series: Object.keys(grafico).map((keyColaborador) => {
                return {
                    name: `${grafico[keyColaborador].nomeColaborador}<br><strong>Painel</strong>: ${grafico[keyColaborador].painelNome}<br><strong>Indicador</strong>: ${grafico[keyColaborador].indicadorNome}`,
                    marker: {
                        symbol: 'diamond'
                    },
                    color: this.state.colaboradorColors.find(colaborador => colaborador.id === keyColaborador).color,
                    data: grafico[keyColaborador].performanceRealizadoPeriodos.map((key) => {

                        let yFormated = key.realizadoValue;

                        return {
                            y: parseFloat(key.realizado),
                            yFormated: yFormated,

                        };
                    })
                }
            }),
            credits: {
                enabled: false
            },
        }
        
        this.setState({ 
            loadingGrafico: AlterShowGraphic ? false : this.state.loadingGrafico, 
            showGraphic: AlterShowGraphic ? true : this.state.showGraphic, 
            tendencia
        }, () => {this.updateWidthGrafico();});
    }

    calculaGraficoMetaRealizadoIndicador(AlterShowGraphic = true) {

        let grafico = this.state.grafico;
        let periodos = this.state.periodos;
        let unidade = grafico[this.props.selectedUsersEquipe[0]].unidade;
        let moeda = grafico[this.props.selectedUsersEquipe[0]].moedaSimbolo;

        let series = [];
        
        Object.keys(grafico).map((keyColaborador) => {

            let hasMetaAjustada = grafico[keyColaborador].hasMetaAjustada;
            let metaPeriodos = hasMetaAjustada ? grafico[keyColaborador].performanceMetaAjustadaPeriodos : grafico[keyColaborador].performanceMetaPeriodos;

            series.push({
                name: `${hasMetaAjustada ? 'META AJUSTADA' : 'META'} | ${grafico[keyColaborador].nomeColaborador}<br><strong>Painel</strong>: ${grafico[keyColaborador].painelNome}<br><strong>Indicador</strong>: ${grafico[keyColaborador].indicadorNome} `,                   
                type: 'column',
                marker: {
                    symbol: 'diamond'
                },
                color: this.state.colaboradorColors.find(colaborador => colaborador.id === keyColaborador).color,
                data: metaPeriodos.map((key) => {

                    let yFormated = hasMetaAjustada ? key.metaAjustadaValue : key.metaValue;

                    return {
                        y: parseFloat(hasMetaAjustada ? key.metaAjustada : key.meta),
                        yFormated: yFormated,
                    };
                })
            })
        });

        Object.keys(grafico).map((keyColaborador) => {
            series.push({
                name: `REALIZADO | ${grafico[keyColaborador].nomeColaborador}<br><strong>Painel</strong>: ${grafico[keyColaborador].painelNome}<br><strong>Indicador</strong>: ${grafico[keyColaborador].indicadorNome} `,                   
                type: 'spline',
                marker: {
                    symbol: 'diamond'
                },
                color: LayoutHelper.darkenColor(this.state.colaboradorColors.find(colaborador => colaborador.id === keyColaborador).color, 15),
                data: grafico[keyColaborador].performanceRealizadoPeriodos.map((key) => {

                    let yFormated = key.realizadoValue;

                    return {
                        y: parseFloat(key.realizado),
                        yFormated: yFormated,

                    };
                })
            })
        });

        let tendencia = {
            chart: {
                type: 'xy',
                width: this.state.expanded ? (ChartHelper.getWidth(68) - 100) : (ChartHelper.getWidth(43) - 10),
            },
            title: {
                text: ''
            },
            xAxis: {
                categories: periodos.map((key) => (key.mes + "/" + String(key.ano).slice(-2))),
                accessibility: {
                    description: 'Períodos do Painel no GMR'
                }
            },
            yAxis: {
                title: {
                    text: 'Meta x Realizado'
                },
                labels: {
                    formatter: function () {
                        if (unidade == 0 || unidade == 1) {
                            return moeda + " " + this.value.toLocaleString('pt-BR', { minimumFractionDigits: 2 });
                        } else if (unidade == 2 || unidade == 3) {
                            return this.value + '%';
                        } else if (unidade == 6 || unidade == 7) {
                            return this.value + ' Dias';
                        } else if (unidade == 8 || unidade == 9) {
                            return this.value + ' Horas';
                        } else {
                            return this.value;
                        }
                    }
                }
            },
            legend: {
                enabled: false
            },
            tooltip: {
                crosshairs: [true, true],
                shared: false,
                useHTML: true,
                headerFormat: '<span style="font-size: 15px">{point.key}</span><hr/><table>',
                pointFormat: '<tr><span style="color:{point.color}">\u25CF</span> {series.name}: <b>{point.yFormated}</b></tr><br/><br/>',
                footerFormat: '</table>',
            },
            plotOptions: {
                spline: {
                    marker: {
                        radius: 4,
                        fillColor: SessionHelper.getColor()
                    },
                    lineWidth: 4,
                    dashStyle: 'none'
                }
            },
            series: series,
            credits: {
                enabled: false
            },
        }
        
        this.setState({ 
            loadingGrafico: AlterShowGraphic ? false : this.state.loadingGrafico, 
            showGraphic: AlterShowGraphic ? true : this.state.showGraphic, 
            tendencia
        }, () => {this.updateWidthGrafico();});
    }

    calculaGraficoPerformanceIndicador(AlterShowGraphic = true) {
        let grafico = this.state.grafico;
        let periodos = this.state.periodos;

        let tendencia = {
            chart: {
                type: periodos.length <= 1 ? 'column' : 'spline',
                width: this.state.expanded ? (ChartHelper.getWidth(68) - 100) : (ChartHelper.getWidth(43) - 10),
            },
            title: {
                text: ''
            },
            xAxis: {
                categories: periodos.map((key) => (key.mes + "/" + String(key.ano).slice(-2))),
                accessibility: {
                    description: 'Períodos do Painel no GMR'
                }
            },
            yAxis: {
                title: {
                    text: 'Performance'
                },
                labels: {
                    format: '{value}%'
                }
            },
            legend: {
                enabled: false
            },
            tooltip: {
                crosshairs: [true, true],
                shared: false,
                useHTML: true,
                headerFormat: '<span style="font-size: 15px">{point.key}</span><hr/><table>',
                pointFormat: '<tr><span style="color:{point.color}">\u25CF</span> {series.name}: <b>{point.yFormated}</b></tr><br/><br/>',
                footerFormat: '</table>',
            },
            plotOptions: {
                spline: {
                    marker: {
                        radius: 4,
                        fillColor: SessionHelper.getColor()
                    },
                    lineWidth: 4,
                    dashStyle: 'none'
                }
            },
            series: Object.keys(grafico).map((keyColaborador) => {
                return {

                    name: `${grafico[keyColaborador].nomeColaborador}<br><strong>Painel</strong>: ${grafico[keyColaborador].painelNome}<br><strong>Indicador</strong>: ${grafico[keyColaborador].indicadorNome}`,
                    color: this.state.colaboradorColors.find(colaborador => colaborador.id === keyColaborador).color,
                    data: grafico[keyColaborador].performancePeriodos.map((key) => {
                        
                        let yFormated = key.performanceValue;
                        return {
                            y: parseFloat(key.performance),
                            yFormated: yFormated,
                            marker: {
                                fillColor: key.color,
                                radius: 5,
                            },
                        };
                        
                    })
                }
            }),
            credits: {
                enabled: false
            }
        }
        
        this.setState({ 
            loadingGrafico: AlterShowGraphic ? false : this.state.loadingGrafico, 
            showGraphic: AlterShowGraphic ? true : this.state.showGraphic, 
            tendencia
        }, () => {this.updateWidthGrafico();});
    }

    // === end - funções de cálculo de gráficos ===

    checkSelection = (selection, type) => {
        if (!selection || Object.keys(selection).length === 0) {
            this.setState({ loadingGrafico: false });
            toast.info(`Selecione Colaboraadores para gerar o gráfico.`);
            return false;
        }
    
        if (Object.values(selection).some(value => value === null)) {
            this.setState({ loadingGrafico: false });
            toast.info(`Por favor, selecione os ${type} dos colaboradores primeiro para gerar o gráfico.`);
            return false;
        }
    
        return true;
    }

    renderDatePickerInicio() {
        const ExampleCustomInput1 = forwardRef(({ value, onClick }, ref) => (
            <Form.Control
                onChange={() => {}}
                onClick={onClick} 
                    ref={ref}
                    style={{ margin: '0px', fontSize: 14, color: Colors.homePage.darkGrey, cursor: 'pointer', padding: 1, height: '100%', maxWidth: 80,  textAlign: 'center'}}
                    value={value} >
            </Form.Control>
        ));
        
        return (
            <DatePicker
                selected={this.state.data_inicio}
                type="date"
                onChange={(event) => {
                    let novoInicio = moment(event);
                    if (novoInicio.toDate() !== this.state.data_inicio){
                        this.setState({ data_inicio: novoInicio.toDate()}, () => {
                            this.loadData("equipe");
                        });
                    }
                }}
                style={{maxWidth: 80}}
                dateFormat="MM/yyyy"
                showMonthYearPicker
                customInput={<ExampleCustomInput1 />}
                popperPlacement="top-start"
            />
        )
    };

    renderDatePickerFim() {
        const ExampleCustomInput1 = forwardRef(({ value, onClick }, ref) => (
            <Form.Control
                onChange={() => {}}
                onClick={onClick} 
                    ref={ref}
                    style={{ margin: '0px', fontSize: 14, color: Colors.homePage.darkGrey, cursor: 'pointer', padding: 1, height: '100%', width: 80,  textAlign: 'center'}}
                    value={value} >
            </Form.Control>
        ));
        
        return (
            <DatePicker
                selected={this.state.data_fim}
                type="date"
                onChange={(event) => {
                    let novoFim = moment(event);
                    if (novoFim.toDate() !== this.state.data_fim){
                        this.setState({ data_fim: novoFim.toDate()}, () => {
                            this.loadData("equipe");
                        });
                    }
                }}
                dateFormat="MM/yyyy"
                showMonthYearPicker
                customInput={<ExampleCustomInput1 />}
                popperPlacement="top-start"
            />
        )
    };

    renderLoading() {
        return (
            <div style={{...EssentialStyle.columnCenter, width: '100%', height: '100%'}}>
                <DefaultLoader size={50} />
            </div>
        );
    }

    renderGrafico() {
        return (

            this.state.showGraphic ?
            <div id="tendenciaChartContainer" style={{ display: 'flex', flexDirection: 'column', width: "100%", height: '100%', backgroundColor: 'white' }}>
                <HighchartsReact
                    highcharts={Highcharts}
                    options={this.state.tendencia}
                />
                { 
                    this.state.selectedVisualization == "metaRealizado" && 
                    <div style={EssentialStyle.rowSpaceAround}>
                        <span>
                            <FontAwesomeIcon
                                icon={faChartColumn}
                                style={{ color: SessionHelper.getColor(), marginRight: 5 }}
                            />
                            Meta
                        </span>
                        
                        <span style={{marginLeft: 10}}>
                            <FontAwesomeIcon
                                icon={faChartLine}
                                style={{ color: SessionHelper.getColor(), marginRight: 5 }}
                            />
                            Realizado
                        </span>
                    </div>
                }
            </div>
            : 
            <div style={{ ...EssentialStyle.columnCenter, width: '100%', height: '100%', backgroundColor: 'white', paddingTop: 5 }}>
                <img
                    src={`${gateway}/img/undraw/undraw_setup_analytics.svg`}
                    alt="Pessoa de costas analizando um gráfico"
                    style={{
                        height: 'calc(100% - 40px)',
                        maxHeight: 180,
                        width: 350,
                    }}
                />

                <span style={{ ...EssentialStyle.titleBoxHomePage }}>Carregue o seu gráfico no botão ao lado!</span>
            </div>
        )
    }

    renderPeriodo() {
        return (
            <div style={{...EssentialStyle.rowSpaceEvenly, margin: '5px 0px', width: '100%'}}>
                {this.renderDatePickerInicio()}
                <span style={{ margin: '5px 10px'}}>até</span>
                {this.renderDatePickerFim()}
            </div>
        )
    }

    renderColaboradorSelect(colaborador) {
    
        let listaIndicadores = this.state.indicadores[colaborador] ? Object.values(this.state.indicadores[colaborador].indicadores) : [];
        let listaPaineis = this.state.indicadores[colaborador] ? Object.values(this.state.indicadores[colaborador].painel) : [];
        if (this.state.loadingSelect) return <DefaultLoader size={20} />
        return (
            <div style={{ paddingLeft: 10, fontSize: 12, width: 'calc(100% - 50px)' }}>
                <Select 
                    options={DataHelper.formatSelectData(this.state.selectedVisualization == "performanceGeralPainel" ? listaPaineis : listaIndicadores, 'id', 'nome')} 
                    placeholder={'Selecione um parâmetro'} 
                    noOptionsMessage={DataHelper.getSelectEmptyMessage}
                    isClearable
                    isSearchable
                    value={this.state.selectedVisualization == "performanceGeralPainel" ? this.state.selectedPainel[colaborador] : this.state.selectedIndicador[colaborador]}
                    onChange={(value) => {
                        this.adicionaIndicadorPainel(value, colaborador);
                    }}
                    menuPortalTarget={document.body}
                    menuPosition={'fixed'}
                />
            </div>
        )
    }

    renderColaborador(colaborador) {
        
        let cor = this.state.colaboradorColors?.find(colaboradorColor => colaboradorColor.id === colaborador)?.color || Colors.homePage.grey;
        return (
            <div key={'key-'+colaborador} style={{...EssentialStyle.rowFlexStart, width: '100%', padding: '5px 0px'}}>
                <div
                    style={{ borderRadius: '50%', width: 44, height: 44, backgroundColor: cor, display: 'flex', justifyContent: 'center', alignItems: 'center'}}
                >
                    <UserAvatar size={38} id={colaborador} placement={'left'}/>
                </div>
                { !this.state.expanded ? this.renderColaboradorSelect(colaborador) : null }
            </div>
        )
    }

    renderColaboradores() {
    
        return (
            <div style={{...EssentialStyle.columnStart, width: '100%', alignItems: 'center'}}>
                { !this.state.expanded ? this.renderPeriodo() : null }
                <div style={{...EssentialStyle.columnStart, alignItems: 'start', height: 'calc(100% - 40px)', width: '100%', marginTop: 5, overflow: 'auto'}}>
                    {this.props.selectedUsersEquipe.map((colaborador) => {
                        return this.renderColaborador(colaborador);
                    })}
                </div>
               <div style={{ display: 'flex', justifyContent: this.state.expanded ? 'start': 'center', width: '100%'}}> 
                    <DefaultButton
                        leftIcon={<FontAwesomeIcon icon={faSync}/>}
                        title={!this.state.expanded ? "Gerar Gráfico" : undefined}
                        color={SessionHelper.getColor()}
                        textColor={'white'}
                        onClick={() => { this.calculaGrafico() }}
                    />
                </div>
            </div>
        )
    }

    renderTitle() {
        const portalTarget = document.getElementById('root');
        return (
            <div 
                style={{
                    ...EssentialStyle.cardTitle, 
                    height: 40, 
                    borderBottom: `1px solid ${this.props.expandedPageTwo != "GraficosMinhaEquipe" && this.props.expandedPageTwo != false ? 'transparent' : Colors.homePage.line }` 
                }}
            >
                <strong style={{ ...EssentialStyle.titleBoxHomePage }}>Gráficos</strong>
                { this.state.unidadesDistintas ? 
                    <div style={{ backgroundColor: "#e2504c", padding: "5px 10px", borderRadius: 5 }}>
                        <FontAwesomeIcon style={{ color: "white", fontSize: 14 }} icon={faWarning} />
                        <span style={{ color: 'white', marginLeft: 5, fontSize: 11 }}>Unidades de Medida Distintas</span>
                    </div> 
                    : null }
                <div style={{ ...EssentialStyle.rowFlexStart }}>
                    <Dropdown>
                        <Dropdown.Toggle
                            onChange={() => {}}
                            value={this.state.selectedVisualization} 
                            style={{
                                backgroundColor: Colors.secondaryButton, 
                                color: Colors.dark,
                                padding: '3px 6px',
                                borderColor: 'black',
                            }}
                            disabled={this.state.loading}
                        > 
                            <span style={{ marginRight: 5, marginLeft: 10, fontSize: 12 }}>{this.state.visualizationTypes[this.state.selectedVisualization]}</span>
                        </Dropdown.Toggle>
                        {ReactDOM.createPortal(<Dropdown.Menu> 
                            {Object.keys(this.state.visualizationTypes).map(key => (
                                <Dropdown.Item
                                    key={`visualization-opt-${key}`} 
                                    onClick={() => { this.handleVisualizationChange(key) }} 
                                    style={key === this.state.selectedVisualization ? { backgroundColor: Colors.dark } : {}} 
                                    active={key === this.state.selectedVisualization}
                                >
                                    <span style={{ fontSize: 12 }}>{this.state.visualizationTypes[key]}</span>
                                </Dropdown.Item>
                            ))}
                        </Dropdown.Menu>
                        , portalTarget)}
                    </Dropdown>

                    <DefaultButton
                        leftIcon={this.props.expandedPageTwo == "GraficosMinhaEquipe" ? <FontAwesomeIcon icon={faMinimize} /> : <FontAwesomeIcon icon={faMaximize} />}
                        color={'transparent'}
                        textColor={SessionHelper.getColor()}
                        style={{ marginLeft: 10 }}
                        onClick={() => {  this.props.expandCallbackPageTwo(this.props.expandedPageTwo != 'GraficosMinhaEquipe' ? 'GraficosMinhaEquipe' : false) }}
                    />
                </div>
            </div>
        )
    }

    renderBody() {
        const { expanded } = this.state;
        const icon = expanded ? faChevronLeft : faChevronRight;

        if (this.state.loading) return this.renderLoading();
        return (
            this.props.numberSubordinados > 0 ? 
                (
                    this.props.selectedUsersEquipe.length > 0 ? 
                        <div style={ this.state.isSmallScreen ? styles.bodyColumn : styles.bodyRow }>
                            
                            <div 
                                style={{ 
                                    display: 'flex', 
                                    width: this.state.isSmallScreen ? '100%' : (this.state.expanded ? 'calc(100% - 70px)' : 'calc(65% - 10px)'),
                                    transition: 'width 0.5s ease',
                                    height: '100%'
                                }}
                            >
                                { this.state.loading || this.state.loadingGrafico ? this.renderLoading() : this.renderGrafico()}
                            </div>
                            
                            {
                                !this.state.isSmallScreen &&
                                <div style={{ ...EssentialStyle.columnCenter, width: '20px', height: '100%' }}>
                                    <FontAwesomeIcon
                                        className={"hoverScale"}
                                        icon={icon} 
                                        style={{
                                            color: SessionHelper.getColor(),
                                            cursor: 'pointer',
                                        }}
                                        onClick={() => { this.setState({ expanded: !expanded }, () => { if(this.state.showGraphic) setTimeout(() => {this.updateWidthGrafico()}, 500) }) }}
                                    />
                                </div>
                            }
                            
                            <div 
                                style={{ 
                                    display: 'flex', 
                                    width: this.state.isSmallScreen ? '100%' : (this.state.expanded ? '58px' : 'calc(35% - 10px)'), 
                                    transition: 'width 0.5s ease',
                                    height: '100%', 
                                    marginLeft: 10
                                    }}
                                >
                                { this.state.loading ? this.renderLoading() : this.renderColaboradores() }
                            </div>

                        </div>
                        : 
                        <div style={{...EssentialStyle.columnCenter, width: '100%', height: 'calc(100% - 40px)', paddingTop: 5}}>
                            <img
                                src={`${gateway}/img/undraw/undraw_following.svg`}
                                alt="Pessoa de costas selecionando itens em uma lista pessoas"
                                style={{
                                    height: 'calc(100% - 50px)',
                                    maxHeight: 180,
                                    width: 350,
                                }}
                            />
                            <span style={{ ...EssentialStyle.titleBoxHomePage }}><strong>Selecione</strong> os colaboradores da <strong>sua Equipe</strong> ao lado!</span>
                        </div>
                )
                : 
                <div style={{ ...EssentialStyle.columnCenter, width: 'calc(100%  -55px)', margin: '0px -10px', height: 'calc(100% - 45px)', overflow: 'auto' }}>
                    <img
                        src={`${gateway}/img/undraw/undraw_team_collaboration.svg`}
                        alt="Time de Pessoas trabalhando na montagem de gráficos"
                        style={{
                            height: '85%',
                            maxHeight: 180,
                            width: 350,
                        }}
                    />
                    <span style={{ ...EssentialStyle.titleBoxHomePage }}>Nenhum subordinado encontrado</span>
                </div> 
        )
    }

    renderContent() {
        return (
            <div style={ styles.cardContent }>
                {this.renderTitle()}
                {
                    this.props.expandedPageTwo == "GraficosMinhaEquipe" || this.props.expandedPageTwo == false
                        ? this.renderBody()
                        : null
                }
            </div>
        );
    }

    render() {
        return (
            <div style={{ ...styles.card, transform: this.state.transform, opacity: this.state.opacity }}>
                {this.renderContent()}
            </div>
        )
    }
}

const styles = {

    card: {
        ...EssentialStyle.rowSpaceBetween,
        ...EssentialStyle.card,
        transition: 'opacity .3s ease-in-out, transform .6s ease-in-out',
        width: '100%',
        height: '100%',
        padding: 10
    },
    cardContent:{
        ...EssentialStyle.columnCenter,
        width: '100%', 
        height: '100%'
    },
    bodyRow: {
        ...EssentialStyle.rowFlexStart,
        width: '100%', 
        height: 'calc(100% - 40px)'
    },
    bodyColumn: {
        ...EssentialStyle.columnCenter,
        width: '100%', 
        height: 'calc(100% - 40px)'
    },
}

const stateUpdates = {
    didMount: { 
        transform: 'translateY(0)', 
        opacity: 0, 
        loading: true 
    },
    userId: { 
        selectedIndicador: {}, 
        selectedPainel: {}, 
        indicadores: [], 
        showGraphic: false,
        loading: true 
    },
    equipe: { 
        // selectedIndicador: {}, 
        // selectedPainel: {}, 
        indicadores: [],
        loading: true,
        showGraphic: false,
        loadingSelect: true 
    }
};
