import React from "react";

import DiagnosticoHelper from "../../../../../helper/diagnostico/DiagnosticoHelper";
import DefaultLoader from "../../../../tools/DefaultLoader";
import Colors from "../../../../../constants/Colors";
import { toast } from 'react-toastify';
import UserAvatar from "../../../../tools/UserAvatar";
import DefaultButton from "../../../../tools/DefaultButton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import IdiomaHelper from "../../../../../helper/IdiomaHelper";
import { confirmAlert } from 'react-confirm-alert';
import CustomConfirm from "../../../../tools/CustomConfirm";

import './RelatorioCard.css';

export default class RelatorioCard extends React.Component {
    state = {
        loading: true,
        relatorio: this.props.relatorio || null,
        idRelatorio: this.props.idRelatorio || null,
    }

    async componentDidMount() {
        await this.loadRelatorio();
    }

    async loadRelatorio() {
        this.setState({ loading: true });

        if (this.props.idRelatorio) {
            // buscar o relatorio no bando de dados
        }

        this.setState({ loading: false })
    }

    async deleteRelatorio() {
        
        let message = IdiomaHelper.getStruct({
            'pt': {
                title: 'Excluir Relatório',
                message: 'Deseja realmente Excluir este Relatório? Essa ação não pode ser desfeita',
                confirm: 'Excluir',
                cancel: 'Cancelar',
            },
            'en': {
                title: 'Delete Report',
                message: 'Do you really want to delete the report? This action cannot be undone',
                confirm: 'Delete',
                cancel: 'Cancel',
            },
            'es': {
                title: 'Eliminar Informe',
                message: '¿Realmente quieres eliminar el informe? Esta acción no se puede deshacer',
                confirm: 'Eliminar',
                cancel: 'Cancelar',
            }
        });
        
        confirmAlert({
            customUI: ({ onClose }) => (
                <CustomConfirm
                    title={message[this.props.lang].title}
                    message={<p style={{ marginTop: 10, marginBottom: 10}}>{message[this.props.lang].message}</p>}
                    buttons={[
                        {
                            label: message[this.props.lang].confirm,
                            color: Colors.error,
                            textColor: Colors.light,
                            onClick: async () => {
                                
                                if (!await DiagnosticoHelper.deleteRelatorio(this.state.relatorio.id)) {
                                    toast.error("Erro ao excluir relatório - Error deleting report - Error al eliminar el informe");
                                } else {
                                    this.props.reloadPage && this.props.reloadPage();
                                }

                                onClose();
                            }
                        },
                        {
                            label: message[this.props.lang].cancel,
                            onClick: () => { onClose(); }
                        },
                    ]}
                />
            )
        });
    }

    renderLoading() {
        return (
            <div className="RelatorioCard relatorio">
                <DefaultLoader />
            </div>
        )
    }

    renderCard() {

        let relatorio = this.state.relatorio;
        let disabled = this.props.disabled;

        let message = IdiomaHelper.getStruct({
            'pt': {delete: 'Excluir'},
            'en': {delete: 'Delete'},
            'es': {delete: 'Eliminar'},
        });

        return (
            <div 
                key={relatorio.id} 
                className={`RelatorioCard relatorio ${disabled ? ` relatorio-disabled` : ``}`}
                style={{boxShadow: Colors.boxShadow}}
                onClick={() => {
                    if(disabled) return;
                    this.props.openRelatorio(relatorio.id);
                }}
            >
                <div className="line">
                    <UserAvatar id={relatorio.id_colaborador} size={40} showName={this.state.expandedMinhaEquipe} placement={'right'}/>
                </div>
                
                <div className="line" style={{marginBottom: 0}}>
                    <span style={{paddingTop: 10}}>{relatorio.nome}</span>
                    { relatorio.canDelete &&
                        <DefaultButton
                            title={message[this.props.lang].delete}
                            leftIcon={<FontAwesomeIcon icon={faTimes} />}
                            color={Colors.error}
                            onClick={async (e) =>  {
                                e.preventDefault();
                                e.stopPropagation();
                                this.deleteRelatorio();
                            }}
                        />
                    }
                </div>
            </div>
        )
    }

    render() {
        if (this.state.loading) return this.renderLoading();
        return this.renderCard();
    }
}