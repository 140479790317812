import React from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlusSquare } from '@fortawesome/free-solid-svg-icons'
import Colors from "../../constants/Colors";
import DefaultButton from "../../components/tools/DefaultButton";
import LoadingPage from "../LoadingPage";
import AddMapa from "../../components/forms/pe/add/AddMapa";
import MapaList from "../../components/forms/pe/list/MapaList";
import { toast } from "react-toastify";
import MapaView from '../../components/modules/pe/mapa/MapaView';
import Sig from "../../api/Sig";

export default class MapaEstrategicoPage extends React.Component {
    state = {
        id: null,
        mapa: null,
        loading: true,
        showMapasList: false,
        showWizard: false,
        modeView: 'read-only'
    }

    async componentDidMount() {
        this.handleUrlParams();
    }

    handleUrlParams = () => {
        window.parent.postMessage({ type: 'get_url' }, '*');

        const listenerFunction = async (event) => {
            if (event.data.type === 'return_url') {
                const urlObj = new URL(event.data.url);
                const queryParams = new URLSearchParams(urlObj.search);
                const id = queryParams.get('id');

                if (!id) {
                    await this.loadData();
                } else {
                    this.setState({id}, async () => await this.loadData());
                }

                window.removeEventListener('message', listenerFunction);
            }
        };

        window.addEventListener('message', listenerFunction);
    }

    async loadData() {
        this.setState({ loading: true });

        const favoriteOrLastSwot = await Sig.request('GET', 'pe/mapa/getFavorite', {id: this.state.id});

        let mapa = favoriteOrLastSwot?.mapa ?? null;
        let id = mapa?.id ?? null;

        this.setState({ mapa, id, loading: false }, () => {
            if (!mapa && !id) {
                this.showMapasListCallback();
                toast.error('Mapa não encontrado');
            } else {
                window.parent.postMessage({ type: 'add_url_param', param: 'id', value: id }, '*');
            }
        });
    }

    remove = async (id) => {
        this.setState({ loading: true });

        try {
            await Sig.request('POST', 'pe/mapa/delete', { id });
            window.parent.postMessage({ type: 'delete_url_param', param: 'id' }, '*');
        } catch (e) {
            toast.error('Erro ao remover');
        }

        this.loadData();
    }

    addMapaCallback = async () => {
        this.setState({ showMapasList: false, showWizard: true, modeView: 'edit' });
    }

    openMapaCallback = async (id) => {
        this.setState({id, showMapasList: false}, async () => await this.loadData());
    }

    saveMapaCallback = async (id) => {
        window.parent.postMessage({ type: 'add_url_param', param: 'id', value: id }, '*');
        this.setState({ id, showMapasList: false, showWizard: false, modeView: 'edit' });
    }

    showMapasListCallback = async () => {
        this.setState({ showMapasList: true });
        window.parent.postMessage({ type: 'delete_url_param', param: 'id' }, '*');
    }

    renderMapa() {
        return <MapaView 
                    remove={this.remove} 
                    showMapasListCallback={this.showMapasListCallback} 
                    id={this.state.id} 
                    modeView={this.state.modeView}
                />
    }

    renderEmpty() {
        return (
            <div style={{ padding: 50, backgroundColor: 'white', marginTop: 20, display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', boxShadow: Colors.boxShadow, border: '0.3px solid lightgrey', borderRadius: 5, textAlign: 'center' }}>
                <DefaultButton style={{ position: 'absolute', top: 30, right: 10 }} link={"https://advis.freshdesk.com/support/solutions/articles/63000218509-mapa-estrat%C3%A9gico"}/>
                <h5>Até o momento, sua empresa ainda não criou nenhum <strong>Mapa Estratégico</strong>.</h5>
                <div style={{ marginTop: 20 }} />
                <DefaultButton color={Colors.success} title={'Adicionar Mapa Estratégico'} leftIcon={<FontAwesomeIcon icon={faPlusSquare} />} loading={this.state.loading} onClick={() => { this.setState({ showWizard: true }) }} />
            </div>
        )
    }

    render() {
        if(this.state.loading) return <LoadingPage />
        if (this.state.showWizard) return <AddMapa saveMapaCallback={(id) => this.saveMapaCallback(id)} />
        if(this.state.showMapasList) return <MapaList addMapaCallback={this.addMapaCallback} openMapaCallback={(id) => {this.openMapaCallback(id)}}/>

        return this.state.mapa ? this.renderMapa() : this.renderEmpty();
    }
}