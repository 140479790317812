import React from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronDown, faChevronUp, faHistory, faSort, faLayerGroup, faStar, faQuestionCircle, faCog, faTableCells, faCamera, faTrash, faTableColumns, faTableList, faSitemap, faPaintBrush } from '@fortawesome/free-solid-svg-icons'
import DefaultButton from "../../../tools/DefaultButton";
import Colors from "../../../../constants/Colors";
import { toast } from "react-toastify";
import EssentialStyle from "../../../../style/EssentialStyle";
import LoadingPage from "../../../../pages/LoadingPage";
import UserAvatar from "../../../tools/UserAvatar";
import { Form } from "react-bootstrap";
import DrawDiagram from "../../../tools/DrawDiagram";
import { confirmAlert } from "react-confirm-alert";
import CustomConfirm from "../../../tools/CustomConfirm";
import MapaHistory from "./MapaHistory";
import MapaTableView from "./MapaTableView";
import EditMapa from "../../../forms/pe/edit/EditMapa";
import Sig from "../../../../api/Sig";
import SessionHelper from "../../../../helper/SessionHelper";
import LayoutHelper from "../../../../helper/LayoutHelper";
import TooManyAvatars from "../../../tools/TooManyAvatars";

export default class MapaView extends React.Component {

    state = {
        step: 0,
        loading: true,
        fromProjectId: null,
        mapa: [],
        participants: [],
        name: '',
        responsable: null,
        participants: [],
        showSettings: false,
        isFavorite: false,
        showHistory: false,
        showDiagram: this.props.modeView === 'edit' ? true : false,
        collapsedNorteadores: false,
        view: 'normal',
        norteadores: [],
        perspectivas: [],

        podeFavoritarMapa: false,
        podeEditarMapa: false,
        podeRemoverMapa: false,
        podeConfigurarMapa: false,
        podeFavoritarObjetivo: false,

        isSmallScreen: LayoutHelper.isSmallScreen(),
    }

    async componentDidMount() {
        window.addEventListener('resize', this.verifySmallScreen);
        await this.loadData();
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.verifySmallScreen);
    }

    verifySmallScreen = () => {
        this.setState({ isSmallScreen: LayoutHelper.isSmallScreen() });
    }

    async loadData() {
        this.setState({ loading: true });

        let mapaRequest = (await Sig.request('GET', `pe/mapa/getMapa`, { id: this.props.id })) || null;
        let { elementos } = await Sig.request('POST', `pe/diagrama/getElementos`, { id: this.props.id, model: "PeMapa" });

        if (elementos == 0) this.setState({ showDiagram: true });

        if(mapaRequest?.mapa?.id) {
            const mapa = mapaRequest.mapa;
            const isFavorite = (mapa.favorito == 1);
            const participants = mapaRequest.participantes;
            const norteadores = mapaRequest.norteadores;
            const podeFavoritarMapa = mapaRequest.podeFavoritarMapa;
            const podeEditarMapa = mapaRequest.podeEditarMapa;
            const podeRemoverMapa = mapaRequest.podeRemoverMapa;
            const podeConfigurarMapa = mapaRequest.podeConfigurarMapa;
            const podeFavoritarObjetivo = mapaRequest.podeFavoritarObjetivo;

            const perspectivasRaw = mapaRequest.perspectivas;

            let perspectivas = [];

            for (let index = 0; index < perspectivasRaw.length; index++) {
                perspectivas[index] = perspectivasRaw[index].perspectiva;
                perspectivas[index].objetivos = perspectivasRaw[index].objetivos.map(objetivoStruct => ( { ...objetivoStruct.objetivo } ));
            }

            this.setState({ 
                participants, 
                norteadores, 
                perspectivas, 
                mapa, 
                name: mapa.nome, 
                isFavorite, 

                podeFavoritarMapa,
                podeEditarMapa,
                podeRemoverMapa,
                podeConfigurarMapa,
                podeFavoritarObjetivo,

            }, () => { this.setState({ loading: false }) });
        }
    }

    handleFavorite = async (id, isFavorite) => {
        this.setState({ loading: true });

        await Sig.request('POST', `pe/mapa/setFavorito`, { id: id, favorito: isFavorite ? 0 : 1 });
        await this.loadData();

        this.setState({ loading: false });
        toast.success('Alterado com sucesso');
    }

    historyCallback = () => {
        this.setState({ showHistory: false });
    }

    settingsCallback = async () => {
        this.setState({ showSettings: false });
        await this.loadData();
    }

    remove = () => {
        confirmAlert({
            customUI: ({ onClose }) => (
                <CustomConfirm
                    title={'Excluir este Mapa?'}
                    message={<p style={{ marginTop: 10, marginBottom: 10 }}>Essa ação não pode ser desfeita</p>}
                    buttons={[
                        {
                            label: 'Remover',
                            color: SessionHelper.getColor(),
                            textColor: Colors.light,
                            onClick: async () => { await this.props.remove(this.props.id); this.props.showMapasListCallback(); onClose(); }
                        },
                        {
                            label: 'Cancelar',
                            onClick: () => { onClose(); }
                        },
                    ]}
                />
            )
        });
    }

    renderHeader() {

        let podeFavoritar =  this.state.podeFavoritarMapa;
        let podeEditar =     this.state.podeEditarMapa;
        let podeRemover =    this.state.podeRemoverMapa;
        let podeConfigurar = this.state.podeConfigurarMapa;

        let style = {
            header: {
                display: 'flex',
                flexDirection: this.state.isSmallScreen ? 'column' : 'row',
                justifyContent: this.state.isSmallScreen ? 'start' : 'space-between',
                alignItems: this.state.isSmallScreen ? 'space-between' : 'center',
                padding: 10,
                backgroundColor: 'white',
                boxShadow: Colors.boxShadow,
                border: '0.3px solid lightgrey',
                borderRadius: 8,
                width: '100%',
            },
            itens: {
                display: 'flex', 
                flexDirection: 'row',
                justifyContent: 'center',
                alignItems: 'center',
                width: this.state.isSmallScreen ? '100%' : 'auto', 
            },
        }

        return (
            <div style={style.header}>
                
                <div style={{...style.itens, display: this.state.isSmallScreen ? 'none' : ''}}>
                    { this.state.view === 'normal' ? <DefaultButton tooltip={!this.state.collapsedNorteadores ? 'Esconder Norteadores' : 'Mostrar Norteadores'} tooltipPlacement={'right'} leftIcon={<FontAwesomeIcon icon={this.state.collapsedNorteadores ? faChevronDown : faChevronUp} color={Colors.dark} />} color={Colors.secondaryButton} textColor={Colors.dark} loading={this.state.loading} onClick={() => { this.setState({ collapsedNorteadores: !this.state.collapsedNorteadores }) }} style={{ marginRight: 8 }} /> : null }
                </div>
                
                <div style={{...style.itens, minWidth: this.state.isSmallScreen ? 'unset' : 300}}>
                    <div style={{ fontWeight: 'bold', fontSize: 22 }}>{this.state.name}</div>
                    <div style={{ display: 'flex', flexDirection: 'row', marginLeft: 20 }}>

                        <div style={{ marginLeft: -10 }}>
                            <UserAvatar noLoading={true} id={this.state.mapa.id_responsavel} />
                        </div>
                        <TooManyAvatars style={{ marginLeft: -8 }} noLoading={true} participantsTotal={this.state.participants.length} participantsVisiveis={2} arrayParticipants={this.state.participants} />

                    </div>
                </div>

                <div style={{ ...style.itens, justifyContent: this.state.isSmallScreen ? 'center' : 'center', gap: 8, flexWrap: 'wrap', flexShrink: 1, width: "100%", maxWidth: "max-content", paddingLeft: 8}}>
                    <DefaultButton tooltip={!podeFavoritar ? 'Sem Permissão Para Favoritar Mapas' : (this.state.isFavorite ? 'Remover Favorito' : 'Marcar como Favorito')} tooltipPlacement={'left'} leftIcon={<FontAwesomeIcon icon={faStar} color={this.state.isFavorite ? Colors.favorite : Colors.dark} />} color={Colors.secondaryButton} textColor={Colors.dark} loading={this.state.loading} disabled={!podeFavoritar} onClick={() => { this.handleFavorite(this.props.id, this.state.isFavorite) }} />
                    <DefaultButton tooltip={!podeEditar ? 'Sem Permissão Para Editar esse Mapa' : 'Edição'} tooltipPlacement={'left'} leftIcon={<FontAwesomeIcon icon={faPaintBrush} />} color={Colors.secondaryButton} textColor={Colors.dark} loading={this.state.loading} disabled={!podeEditar} onClick={() => { this.setState({ showDiagram: true }) }} />
                    {!this.state.isSmallScreen && <DefaultButton tooltip={this.state.view === 'normal' ? 'Visualizar em Tabela' : 'Visualizar em Mapa'} tooltipPlacement={'left'} leftIcon={this.renderSwitch()} color={Colors.secondaryButton} textColor={Colors.dark} loading={this.state.loading} onClick={() => { this.handleView() }} />}
                    <DefaultButton tooltip={!podeRemover ? 'Sem Permissão Para Remover esse Mapa': 'Remover'} tooltipPlacement={'left'} leftIcon={<FontAwesomeIcon icon={faTrash} />} color={Colors.secondaryButton} textColor={Colors.dark} loading={this.state.loading} disabled={!podeRemover} onClick={async () => { this.remove(); }} />
                    <DefaultButton tooltip={'Histórico'} tooltipPlacement={'left'} leftIcon={<FontAwesomeIcon icon={faHistory} />} color={Colors.secondaryButton} textColor={Colors.dark} loading={this.state.loading} onClick={() => { this.setState({ showHistory: true }) }} />
                    <DefaultButton tooltip={!podeConfigurar ? 'Sem Permissão Para Configurar esse Mapa' : 'Configurações'} tooltipPlacement={'left'} leftIcon={<FontAwesomeIcon icon={faCog} />} color={Colors.secondaryButton} textColor={Colors.dark} loading={this.state.loading} disabled={!podeConfigurar} onClick={() => { this.setState({ showSettings: true }) }} />
                    <DefaultButton tooltip={'Listar Mapas'} tooltipPlacement={'left'} leftIcon={<FontAwesomeIcon icon={faTableCells} />} color={Colors.secondaryButton} textColor={Colors.dark} loading={this.state.loading} onClick={() => { this.props.showMapasListCallback(); }} />
                    {this.state.isSmallScreen && this.state.view === 'normal' ? <DefaultButton tooltip={!this.state.collapsedNorteadores ? 'Esconder Norteadores' : 'Mostrar Norteadores'} tooltipPlacement={'top'} leftIcon={<FontAwesomeIcon icon={this.state.collapsedNorteadores ? faChevronDown : faChevronUp} color={Colors.dark} />} color={Colors.secondaryButton} textColor={Colors.dark} loading={this.state.loading} onClick={() => { this.setState({ collapsedNorteadores: !this.state.collapsedNorteadores }) }} style={{ marginRight: 0 }} /> : null}
                    <DefaultButton 
                        style={{ width: 38, height: 38}}
                        fontSize={this.state.isSmallScreen ? 14 : "unset"}
                        color={Colors.secondaryButton}
                        textColor={Colors.dark} 
                        link={'https://advis.freshdesk.com/support/solutions/articles/63000218509-mapa-estrat%C3%A9gico'}/>
                </div>
            </div>
        );
    }

    handleView() {

        let view = this.state.view;
        view = view === 'normal' ? 'table' : 'normal';

        this.setState({ view });
    }

    async callback() {

        this.setState({ showDiagram: false });
        await this.loadData();
    }

    renderSwitch() {

        return (

            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>
                <FontAwesomeIcon icon={faSitemap} />
                <Form.Switch
                    type="switch"
                    disabled
                    checked={this.state.view === 'normal' ? false : true}
                    style={{ marginLeft: 3, marginRight: 3 }}
                />
                <FontAwesomeIcon icon={faTableCells} />
            </div>
        )
    }

    renderNorteadores() {
        return this.state.view === 'normal' && !this.state.collapsedNorteadores ? (
            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', overflowX: 'scroll', width: '100%', padding: 10 }}>
                {
                    this.state.norteadores.map((norteador, key) => {
                        let minWidth = (window.innerWidth * 0.3) - 10;

                        return (
                            <div key={`nort_${key}`} style={{ marginRight: 10, width:'100%' , minWidth: minWidth < 350 ? 350 : minWidth, minHeight: 20, maxHeight: 300, boxShadow: Colors.boxShadow, borderRadius: 5, border: '0.3px solid lightgrey', backgroundColor: 'white', overflow: 'auto' }}>
                                <h5 style={{ textAlign: 'center', backgroundColor: SessionHelper.getColor(), fontWeight: 'bold', color: 'white', padding: 10, borderTopLeftRadius: 5, borderTopRightRadius: 5 }}>{norteador.nome}</h5>
                                <div className="revert-font-size" style={{ backgroundColor: 'white', padding: 10, overflow: 'auto' }} dangerouslySetInnerHTML={{ __html: norteador.content }} />
                            </div>
                        )
                    })
                }
            </div>
        ) : null;
    }

    renderMapa() {
        return this.state.view === 'normal' ? (

            <div style={{ backgroundColor: 'white', borderRadius: 8, boxShadow: Colors.boxShadow, overflow: 'scroll', marginTop: 20, border: '0.3px solid lightgrey', }}>
                <DrawDiagram 
                    handleFavorite={this.handleFavorite} 
                    id={this.props.id} 
                    delete={() => { this.props.remove(this.props.id) }} 
                    table={'PeMapa'} 
                    historyTable={'PeMapaHistorico'} 
                    elementsTable={'PeMapaElemento'} 
                    relationField={'id_pe_mapa'}
                    mode={'read-only'}
                    callback={() => { this.props.showMapasListCallback() }} 
                    modeView={'read-only'}
                />
            </div>

        ) : <MapaTableView id={this.props.id} podeFavoritarObjetivo={this.state.podeFavoritarObjetivo} />
    }

    render() {
        if (this.state.loading) return <LoadingPage />
        if (this.state.showDiagram) return <DrawDiagram handleFavorite={this.handleFavorite} modeView={'edit'} backButton={true} id={this.props.id} delete={() => { this.props.remove(this.props.id) }} table={'PeMapa'} historyTable={'PeMapaHistorico'} elementsTable={'PeMapaElemento'} relationField={'id_pe_mapa'} callback={() => { this.setState({ showDiagram: false }) }} />
        if (this.state.showHistory) return <MapaHistory id={this.props.id} historyCallback={this.historyCallback} />
        if (this.state.showSettings) return <EditMapa id={this.props.id} settingsCallback={this.settingsCallback} deleteCallback={() => { this.props.showMapasListCallback() }} />

        return (
            <div style={{ minWidth: '100%', minHeight: '100vh', overflow: 'hidden' }}>
                {this.renderHeader()}
                {this.renderNorteadores()}
                {this.renderMapa()}
            </div>
        );
    }
}