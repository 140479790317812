import React from "react";

import { faCheck, faChevronLeft, faPencil, faTimes, faUser, faUserGroup, faCog } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { Form } from "react-bootstrap";
import Sig from "../../../api/Sig";
import Colors from "../../../constants/Colors";
import ChartHelper from "../../../helper/inicio/ChartHelper";
import EssentialStyle from "../../../style/EssentialStyle";
import CustomTooltip from "../../tools/CustomTooltip";
import DefaultButton from "../../tools/DefaultButton";
import DefaultLoader from "../../tools/DefaultLoader";
import UserAvatar from "../../tools/UserAvatar";
import SessionHelper from "../../../helper/SessionHelper";
export default class Header extends React.Component {
    state = {
        loading: true,
        subordinados: [],
        subordinadosGmr: [],
        subordinadosGd: [],
        me: {},
        viewer: {},
        selected: null,
        history: [],
        transform: 'translateY(+100%)',
        viewUserConfig: true,
        opacity: 0,
        viewEditHeader: false,
        editingHeader: false
    }

    async componentDidMount() {
        await this.selectColaborador();
    }

    selectColaborador = async (id = null) => {
        this.setState({ loading: true, transform: 'translateY(0)', opacity: 1, viewEditHeader: false });

        let { subordinados, subordinadosGmr, subordinadosGd, me, viewer } = await Sig.request('GET', 'config/colaborador/getEquipe', { id_colaborador: id, viewUserConfig: this.state.viewUserConfig });

        let selected = id || me.id;
        let history = this.state.history;

        if (selected != this.state.me.id) history.push(selected);

        this.setState({ subordinados, subordinadosGmr, subordinadosGd, me, viewer, selected, history }, () => {
            this.setState({ loading: false });
            this.props.onSelectColaborador(selected);
            this.props.numberSubordinados(subordinados.length);
        });
    }

    editSubordinadosVisualization(subordinado) {

        let subordinados = this.state.subordinados;
        let index = subordinados.findIndex(sub => sub.id === subordinado.id);
        subordinados[index].oculto = !(subordinados[index].oculto == true || subordinados[index].oculto == "true");

        this.setState({ subordinados });
    }

    confirmChangeVisualization = async (confirm) => {

        if (confirm) {
            await Sig.request('POST', 'config/colaborador/editEquipe', { me: this.state.me.id, viewer: this.state.viewer.id, subordinados: JSON.stringify(this.state.subordinados) })
                .then(() => {
                    this.setState({ editingHeader: false }, () => {
                        this.selectColaborador(this.state.me.id);
                        this.props.reloadEquipe();
                    });
                });
        } else {
            this.setState({ editingHeader: false }, () => {
                this.selectColaborador(this.state.me.id);
            });
        }
    }

    renderSwitch() {
        return (
            <div style={{ ...EssentialStyle.rowFlexEnd }}>
                {this.state.selected != this.state.viewer.id &&
                    <DefaultButton
                        id={'viewUserConfigSwitch'}
                        leftIcon={
                            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>
                                <CustomTooltip
                                    tooltip={'Visualizar a minha configuração para este membro da equipe'}
                                    placement={'left'}
                                >
                                    <FontAwesomeIcon icon={faUserGroup} />
                                </CustomTooltip>
                                <Form.Switch
                                    type="switch"
                                    checked={this.state.viewUserConfig}
                                    style={{ marginLeft: 3, marginRight: 3 }}
                                />
                                <CustomTooltip
                                    tooltip={'Visualizar configuração do membro da equipe'}
                                    placement={'left'}
                                >
                                    <FontAwesomeIcon icon={faUser} />
                                </CustomTooltip>
                            </div>
                        }
                        color={'transparent'}
                        textColor={Colors.dark}
                        loading={this.state.loading}
                        onClick={() => {
                            this.setState({ viewUserConfig: !this.state.viewUserConfig }, () => {
                                this.props.onChangeUserConfig(this.state.viewUserConfig);
                                this.selectColaborador(this.state.me.id);
                                this.props.reloadEquipe();
                            })
                        }}
                        style={{ marginRight: 8 }}
                    />
                }
                {(SessionHelper.getData().id_usuario === this.state.selected || !this.state.viewUserConfig) &&
                    <DefaultButton
                        style={{ marginRight: 10 }}
                        fontSize={this.state.isSmallScreen ? 14 : "unset"}
                        color={Colors.secondaryButton}
                        textColor={Colors.dark}
                        leftIcon={<FontAwesomeIcon icon={faCog} />}
                        onClick={() => {
                            if (this.props.toggleConfigModal) this.props.toggleConfigModal();
                        }}
                    />
                }
                <DefaultButton
                    style={{ width: 42, height: 38, marginRight: 10 }}
                    fontSize={this.state.isSmallScreen ? 14 : "unset"}
                    color={Colors.secondaryButton}
                    textColor={Colors.dark}
                    link={'https://advis.freshdesk.com/support/solutions/articles/63000257804-configura%C3%A7%C3%B5es-da-tela-inicial'} />

            </div>
        );
    }

    renderBackButton() {
        return this.state.history.length > 1 && (
            <div style={{
                marginRight: 10
            }}>
                <DefaultButton
                    onClick={() => {
                        let history = this.state.history;
                        history.pop();
                        this.selectColaborador(history.pop());
                    }}
                    color={'transparent'}
                    textColor={Colors.dark}
                    leftIcon={<FontAwesomeIcon icon={faChevronLeft} />}
                />
            </div>
        );
    }

    renderEditButons() {
        return (
            this.state.editingHeader ?
                <>
                    <div
                        key={`sub-avt-edit-confirm`}
                        style={{
                            marginLeft: -10
                        }}
                    >
                        <div
                            style={{
                                ...EssentialStyle.columnCenter,
                                backgroundColor: 'white',
                                borderRadius: '50%',
                                width: 48,
                                height: 48,
                                cursor: 'pointer'
                            }}
                            onClick={() => { this.confirmChangeVisualization(true) }}
                        >
                            <CustomTooltip
                                tooltip={'Salvar alterações'}
                                placement={'bottom'}
                            >
                                <div style={{ ...EssentialStyle.columnCenter, backgroundColor: Colors.success, opacity: 0.9, borderRadius: '50%', width: 44, height: 44 }}>
                                    <FontAwesomeIcon icon={faCheck} style={{ color: 'white', fontSize: 22 }} />
                                </div>
                            </CustomTooltip>
                        </div>
                    </div>

                    <div
                        key={`sub-avt-edit-ignore`}
                        style={{
                            marginLeft: -10
                        }}
                    >
                        <div
                            style={{
                                ...EssentialStyle.columnCenter,
                                backgroundColor: 'white',
                                borderRadius: '50%',
                                width: 48,
                                height: 48,
                                cursor: 'pointer'
                            }}
                            onClick={() => { this.confirmChangeVisualization(false) }}
                        >
                            <CustomTooltip
                                tooltip={'Cancelar alterações'}
                                placement={'top'}
                            >
                                <div style={{ ...EssentialStyle.columnCenter, backgroundColor: Colors.error, opacity: 0.9, borderRadius: '50%', width: 44, height: 44 }}>
                                    <FontAwesomeIcon icon={faTimes} style={{ color: 'white', fontSize: 22 }} />
                                </div>
                            </CustomTooltip>
                        </div>
                    </div>
                </>
                :
                (
                    this.state.viewEditHeader ?
                        <div
                            key={`sub-avt-edit`}
                            style={{
                                marginLeft: -10
                            }}
                        >
                            <div
                                style={{
                                    ...EssentialStyle.columnCenter,
                                    backgroundColor: 'white',
                                    borderRadius: '50%',
                                    width: 48,
                                    height: 48,
                                    cursor: 'pointer'
                                }}
                                onClick={() => { this.setState({ editingHeader: !this.state.editingHeader }) }}
                            >
                                <CustomTooltip
                                    tooltip={'Editar visualização'}
                                    placement={'top'}
                                >
                                    <div style={{ ...EssentialStyle.columnCenter, backgroundColor: Colors.homePage.extraLightGrey, borderRadius: '50%', width: 44, height: 44 }}>
                                        <FontAwesomeIcon icon={faPencil} style={{ color: 'white', fontSize: 22 }} />
                                    </div>
                                </CustomTooltip>
                            </div>
                        </div>
                        :
                        null
                )
        )
    }

    renderContent() {
        return (
            <div style={{
                ...EssentialStyle.rowSpaceBetween,
                width: '100%'
            }}
            >
                <div style={{
                    ...EssentialStyle.rowFlexStart,
                    width: `calc(100% - ${this.state.selected != this.state.viewer.id ? 218 : 108}px)`
                }}>
                    {this.renderBackButton()}

                    <div style={{
                        marginRight: 20
                    }}>
                        <UserAvatar
                            selected={this.state.selected === this.state.me.id}
                            size={48}
                            id={this.state.me.id}
                        />
                    </div>
                    <div style={{
                        ...EssentialStyle.rowFlexStart,
                        overflowX: 'auto',
                        minWidth: 60,
                        minHeight: 40,
                        paddingLeft: 20
                    }}
                        onMouseEnter={() => { ((this.state.me.id == this.state.viewer.id) || (!this.state.viewUserConfig)) && this.setState({ viewEditHeader: true }) }}
                        onMouseLeave={() => { this.setState({ viewEditHeader: false }) }}
                    >
                        {this.state.subordinados.map((subordinado, index) => (

                            this.state.editingHeader || !(subordinado.oculto === 'false' ? false : Boolean(subordinado.oculto)) ?
                                <div
                                    key={`sub-avt-${index}`}
                                    style={{
                                        marginLeft: -10
                                    }}
                                >
                                    <UserAvatar
                                        selected={this.state.selected === subordinado.id}
                                        size={48}
                                        id={subordinado.id}
                                        editing={this.state.editingHeader}
                                        oculto={(subordinado.oculto === 'false' ? false : Boolean(subordinado.oculto))}
                                        onClick={() => {
                                            this.state.editingHeader ? this.editSubordinadosVisualization(subordinado) : this.selectColaborador(subordinado.id);
                                        }}
                                    />
                                </div>
                                :
                                null
                        ))}
                        {this.renderEditButons()}
                    </div>
                </div>
                {this.renderSwitch()}
            </div>

        );
    }

    renderLoadingPlaceholder() {
        let size = 48;
        let fontSize = 15;

        return (
            <div style={{ ...styles.loadingPlaceholder, height: size, width: size, fontSize: fontSize }} >
                <DefaultLoader size={fontSize} color={'white'} />
            </div>
        );
    }

    renderLoading() {
        return (
            <div style={{ display: 'flex', flexDirection: 'row', marginLeft: 5 }}>
                {this.renderLoadingPlaceholder()}

                <div style={{ width: 20 }} />

                {Array.from({ length: 6 }).map((elem, index) => (
                    <div key={`plhdr-${index}`} style={{ marginLeft: -10 }}>
                        {this.renderLoadingPlaceholder()}
                    </div>
                ))}
            </div>
        );
    }

    render() {
        return (
            <div style={{
                ...styles.container,
                height: ChartHelper.getHeaderHeight(),
                transform: this.state.transform,
                opacity: this.state.opacity
            }}
            >
                {this.state.loading ? this.renderLoading() : this.renderContent()}
            </div>
        )
    }
}

const styles = {
    container: {
        ...EssentialStyle.rowSpaceBetween,
        ...EssentialStyle.card,
        transition: 'opacity .3s ease-in-out, transform .6s ease-in-out',
        width: '100%',
        padding: 15,
        paddingRight: 0
    },
    loadingPlaceholder: {
        border: '2px solid white',
        borderRadius: '100%',
        backgroundColor: 'grey',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        color: 'white',
        fontWeight: 'bold',
    }
}