import React from "react";
import { Form } from "react-bootstrap";
import { forwardRef } from "react";
import DatePicker from "react-datepicker";
import moment from "moment";
import DefaultLoader from "../../../../../tools/DefaultLoader";
import Highcharts from "highcharts/highcharts.js";
import accessibility from 'highcharts/modules/accessibility';
import HighchartsReact from "highcharts-react-official";

import './PainelPerformanceLineChart.css';
import SessionHelper from "../../../../../../helper/SessionHelper";
import EssentialStyle from "../../../../../../style/EssentialStyle";
import Colors from "../../../../../../constants/Colors";

accessibility(Highcharts);

export default class PainelPerformanceLineChart extends React.Component {
    state = {
        loading: true,
        tendencia: {},
    }

    componentDidMount() {
        if (!this.props.loading && this.props.painel) {
            this.loadChart();
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.painel !== this.props.painel || prevProps.dataInicio !== this.props.dataInicio || prevProps.dataFim !== this.props.dataFim) {
            this.loadChart();
        }

        if (prevProps.loading !== this.props.loading && !this.props.loading && this.props.painel) {
            this.loadChart();
        }
    }

    async loadChart() {
        this.setState({ loading: true });

        let painel = this.props.painel;
        let periodosValidos = Object.keys(painel.performancePeriodos).map((key) => (painel.performancePeriodos[key].performance)).filter((periodo) => periodo != null && periodo != "");
        
        let tendencia = {
            chart: {
                type: periodosValidos.length > 1 ? 'spline' : 'column',
                backgroundColor: 'transparent',
                spacing: [7, 10, 0, 5],
            },
            title: {
                text: ''
            },
            xAxis: {
                categories: Object.keys(painel.performancePeriodos).map((key) => (painel.performancePeriodos[key]?.periodoFormatado)),
                accessibility: {
                    description: 'Períodos do Painel no GMR'
                }
            },
            yAxis: {
                title: {
                    text: 'Performance'
                },
                labels: {
                    format: '{value}%'
                }
            },
            legend: {
                enabled: false
            },
            tooltip: {
                crosshairs: true,
                shared: true,
                pointFormatter: function () {
                    return `${this.category}: ${this.y.toFixed(2).replace('.', ',')}%`;
                }
            },
            plotOptions: {
                spline: {
                    marker: {
                        radius: 4,
                        fillColor: SessionHelper.getSecondaryColor(),
                    },
                    lineColor: Colors.dark,
                    lineWidth: 3,
                    dashStyle: 'none'
                }
            },
            series: [{
                name: 'Performance Geral',
                data: Object.keys(painel.performancePeriodos).map((key) => {
                    return {
                        y: painel.performancePeriodos[key]?.performance != "" ? painel.performancePeriodos[key]?.performance : null,
                        marker: {
                            fillColor: painel.performancePeriodos[key]?.performanceColor, // Cor do marcador para cada período
                            radius: 5, // Define o tamanho do marcador
                        },
                    };
                })
            }],
            lineWidth: 3,
            credits: {
                enabled: false
            }
        }

        this.setState({ tendencia, loading: false });
    }

    renderDatePicker(stateProp, callback = () => { }) {
        const DatePickerComponent = forwardRef(({ value, onClick }, ref) => (
            <Form.Control
                onChange={() => { }}
                onClick={onClick}
                ref={ref}
                style={{ marginLeft: 5, fontSize: 14, color: Colors.dark, fontWeight: 'bold', cursor: 'pointer', boxShadow: 'none', backgroundColor: Colors.homePage.lightGrey, padding: 1, height: '100%', width: 90, textAlign: 'center', zIndex: 1 }}
                value={value} >
            </Form.Control>
        ));

        return (
            <div style={{
                ...EssentialStyle.rowFlexStart
            }}>
                <DatePicker
                    selected={this.props[stateProp]}
                    type="date"
                    onChange={(event) => {
                        let novoInicio = moment(event);
                        if (stateProp === 'dataInicio' && novoInicio.isAfter(this.props.dataFim)) {
                            return;
                        } else if (stateProp === 'dataFim' && novoInicio.isBefore(this.props.dataInicio)) {
                            return;
                        }

                        if (novoInicio.toDate() !== this.state[stateProp]) {
                            callback(novoInicio.toDate());
                        }
                    }}
                    dateFormat="MM/yyyy"
                    showMonthYearPicker
                    customInput={<DatePickerComponent />}
                    popperPlacement="top-start"
                />
            </div>
        )
    }

    renderLoading() {
        return (
            <div className="PainelPerformanceLineChart">
                <DefaultLoader />
            </div>
        )
    }

    renderControlsPerformance() {

        return (
            <div className="controlsPerformance">
                <span className="controlsPerformance__title">Performance Geral:</span>
                <div className="controlsPerformance__buttons">
                    {this.renderDatePicker('dataInicio', (novaData) => {this.props.onChangeData && this.props.onChangeData('performanceDataInicio', novaData)})}
                    {this.renderDatePicker('dataFim', (novaData) => {this.props.onChangeData && this.props.onChangeData('performanceDataFim', novaData)})}
                </div>
            </div>
        );
    }

    render() { 

        if (this.state.loading || this.props.loading) return this.renderLoading();

        return (
            <div className="PainelPerformanceLineChart">
                {this.renderControlsPerformance()}
                <HighchartsReact
                    highcharts={Highcharts}
                    constructorType={'chart'}
                    containerProps={{ style: { height: `calc(100% - 29px)`, width: '100%' } }}
                    options={this.state.tendencia}
                />
            </div>
        )
    }
}