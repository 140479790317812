import { faExclamation } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import DefaultLoader from "./DefaultLoader";
import Sig from "../../api/Sig";
import CustomTooltip from "./CustomTooltip";
import EssentialStyle from "../../style/EssentialStyle";

export default class DefaultIndicador extends React.Component {

    state = {
        loading: true,
        indicador: null,
        painel: null
    }

    async componentDidMount() {
        await this.loadIndicador();
    }

    async loadIndicador() {
        this.setState({ loading: true });
        
        if(this.props.id_indicador) {
            const indicador = await Sig.request('GET', 'indicador/getIndicador', { id: this.props.id_indicador, mapaEstrategico: true });
            const painel = await Sig.request('GET', 'helper/getPaineis', { ids: [ this.props.id_gmr_painel ] });
            
            if(indicador && indicador.status === 200) {
                let p = null;

                if(painel && painel.status === 200) p = painel[0];

                this.setState({ indicador, painel: p }, () => { this.setState({ loading: false }) });
            }
        }
    }

    renderDadosfaltantes() {
        return (

        <div style={{ ...EssentialStyle.rowFlexCenter, minHeight: '5em', width: '100%' }}>
            <div style={{ width: 30, height: 30, borderRadius: 15, backgroundColor: '#ffc107', display: 'inline-block', marginRight: 5, padding: 3 }}>
                    <FontAwesomeIcon icon={faExclamation} color="white"/>
                </div>
                Indicador Faltante
            </div>
        )
    }

    render() {
        return !this.state.loading ? (
            <CustomTooltip placement={'bottom'} tooltip={this.state.indicador ? "Descrição: "+this.state.indicador.descricao : 'Sem Descrição'}>
                <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>
                    {this.state.indicador ? this.state.indicador.nome : this.renderDadosfaltantes()}
                    {this.state.painel ? <div style={{ marginLeft: 5, fontSize: 10, color: '#999' }}>{this.state.painel.nome}</div> : null}
                </div>
            </CustomTooltip>
        ) : !this.props.noLoading ? <DefaultLoader size={20} title={'Carregando dados..'} /> : null;
    }
}