import React from "react";
import Sig from "../../../../api/Sig";
import DefaultLoader from "../../../tools/DefaultLoader";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlusSquare, faEye, faTrash, faCheck, faTimes } from '@fortawesome/free-solid-svg-icons'
import Colors from "../../../../constants/Colors";
import { Form } from 'react-bootstrap'
import EssentialStyle from "../../../../style/EssentialStyle";
import DefaultButton from "../../../tools/DefaultButton";
import { toast } from "react-toastify";
import CustomTooltip from "../../../tools/CustomTooltip";
import CustomConfirm from "../../../tools/CustomConfirm";
import SessionHelper from "../../../../helper/SessionHelper";
import { confirmAlert } from "react-confirm-alert";

export default class ProjetoCardSmall extends React.Component {
    state = {
        loading: true,
        projeto: this.props.projeto || {},
        iniciativaNome: this.props.projetoRelations?.iniciativaNome || '',
        responsavel: this.props.projetoRelations?.responsavel || {},
        hoverOn: false,
        shake: false,
    }

    async componentDidMount() {
        await this.load();
    }

    async load() {
        this.setState({ loading: true });
        let { canvas } = (await Sig.request('GET', 'pe/projeto/getProjeto', { id: this.state.projeto.id, relations: 1 }));

        canvas = canvas?.length > 0 ? canvas[0] : false;

        this.setState({ canvas, loading: false });
    }

    async deleteProjeto() {
        
        let response = await Sig.request('POST', 'pe/projeto/delete', { id: this.state.projeto.id });

        if (response.success) {
            this.props.calbackDelete(this.state.projeto.id);
            return toast.success('Projeto deletado com sucesso');
        } else {
            return toast.error('Erro ao deletar projeto');
        }

    }

    addCanvas = async () => {
        let { canvas } = await Sig.request('POST', 'pe/canvas/add',
            {
                nome: 'remover',
                id_pe_projeto: this.state.projeto.id,
                id_responsavel: this.state.projeto.id_responsavel
            }
        );

        if (canvas.id) {
            this.setState({ canvas, creatingCanvas: false, canvasName: '' }, () => {
                window.parent.postMessage({ type: '_blank', url: `/pe/canvas?id=${canvas.id}` }, '*');
            });
        } else {
            toast.error('Erro ao criar canvas');
        }
    }

    shake(){
        this.setState({ shake: true });
        setTimeout(() => this.setState({ shake: false }), 500);
    }

    renderDeleteProjetoModal = () => { 
        var message = "Caso existam ações de marco criadas para o projeto, elas também serão excluídas! Essa ação não pode ser desfeita";

        return (
            confirmAlert({
                customUI: ({ onClose }) => (
                    <CustomConfirm
                        title={'Excluir este Projeto?'}
                        message={<p style={{ marginTop: 10, marginBottom: 10 }}>{message}</p>}
                        buttons={[
                            {
                                label: 'Remover',
                                color: SessionHelper.getColor(),
                                textColor: Colors.light,
                                onClick: () => { this.deleteProjeto(); onClose(); }
                            },
                            {
                                label: 'Cancelar',
                                onClick: () => { onClose(); }
                            },
                        ]}
                    />
                )
            })
        )
    }

    renderInfos() {
        return (
            <div style={{...style.leftInfo}}>
                <div style={style.title}>
                    {this.state.projeto?.nome ? this.state.projeto?.nome : 'Projeto sem nome'}
                </div>
                <div style={style.subTitle}>
                    {this.state.iniciativaNome ? this.state.iniciativaNome : ''}
                </div>
            </div>
        );
    }

    renderTools() {
        return (
            <div style={style.rightInfo}>
                {this.state.creatingCanvas ?
                    <div style={{ ...EssentialStyle.rowFlexStart }}>
                        <Form.Label>Criar ?</Form.Label>
                        <div style={{ ...EssentialStyle.rowFlexStart, marginLeft: 20, width: 85, justifyContent: 'space-between' }}>
                            <DefaultButton
                                tooltip={'Adicionar'}
                                color={Colors.success}
                                leftIcon={<FontAwesomeIcon icon={faCheck} />}
                                onClick={(event) => {
                                    event.stopPropagation();
                                    this.addCanvas();
                                }}
                            />
                            <DefaultButton
                                tooltip={'Cancelar'}
                                textColor={Colors.dark}
                                color={Colors.secondaryButton}
                                leftIcon={<FontAwesomeIcon icon={faTimes} />}
                                onClick={() => { this.setState({ creatingCanvas: false }) }}
                            />
                        </div>
                    </div>
                :
                    <div style={style.rightInfo}>
                        <DefaultButton
                            color={Colors.error}
                            style={{ marginRight: 5 }}
                            tooltip={"Excluir Projeto"}
                            tooltipPlacement={"left"}
                            leftIcon={<FontAwesomeIcon icon={faTrash}/>}
                            onClick={(event) => {
                                event.stopPropagation();
                                this.renderDeleteProjetoModal();
                            }}/>

                        <DefaultButton
                            color={this.state.canvas ? Colors.dark : Colors.success}
                            tooltip={(this.state.canvas ? "Ver Canvas" : "Criar Canvas")}
                            tooltipPlacement={"left"}
                            leftIcon={this.state.canvas ? <FontAwesomeIcon icon={faEye} /> : <FontAwesomeIcon icon={faPlusSquare} />}
                            style={{ width: 38, height: 38, justifyContent: 'center', alignItems: 'center', padding: 0}}
                            onClick={(event) => {
                                event.stopPropagation();
                                if (this.state.canvas) {
                                    window.parent.postMessage({ type: 'navigate', url: `/pe/canvas?id=${this.state.canvas.id}` }, '*');
                                } else {
                                    this.setState({ creatingCanvas: true });
                                }
                            }}/>
                    </div>
                }
            </div>
        );
    }

    renderLoading() {
        return (
            <div style={{ ...style.projetoCard, ...EssentialStyle.columnCenter }}>
                <DefaultLoader size={32} />
            </div>
        );
    }

    render() {
        if (this.state.loading) return this.renderLoading();
        return (
            <div className={this.state.shake ? 'shake' : ''} 
                 style={{...style.projetoCard, cursor: 'not-allowed', boxShadow: this.state.hoverOn ? '0 0 10px rgba(0, 0, 0, 0.2)' : 'none', ...this.props.style }}  
                 onClick={() => { this.shake() }}
                 onMouseEnter={() => { this.setState({ hoverOn: true }) }}
                 onMouseLeave={() => { this.setState({ hoverOn: false }) }}
            >
                { this.renderInfos() }
                {this.renderTools()}
            </div>
        );
    }
}

let style = {
    projetoCard: {
        ...EssentialStyle.rowSpaceBetween,
        ...EssentialStyle.card,
        width: '100%',
        padding: '5px',
        marginBottom: 5,
        backgroundColor: '#BCBCBC',
        minHeight: 48,
    },
    title: {
        fontSize: 15,
        color: Colors.dark
    },
    subTitle: {
        fontSize: 10,
        color: Colors.disabled
    },
    leftInfo: {
        ...EssentialStyle.columnStart,
        justifyContent: 'space-evenly',
        height: '100%',
        alignItems: 'flex-start'
    },
    rightInfo: {
        ...EssentialStyle.rowFlexEnd,
        justifyContent: 'space-evenly',
        height: '100%',
        alignItems: 'flex-end'
    }
}