import React from "react";
import DiagnosticoHelper from "../../helper/diagnostico/DiagnosticoHelper";
import UnderConstruction from "../../components/tools/UnderConstruction";

export default class DiagnosticoProcessosPage extends React.Component {

    state = {
        construction: true,
        loading: true,
    }

    async componentDidMount() {
    }


    render() {
        if (this.state.construction) return <UnderConstruction height={'100vh'}/>
    }
}