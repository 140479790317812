import React from "react";
import Sig from "../../../../../api/Sig";
import EssentialStyle from "../../../../../style/EssentialStyle";
import Colors from "../../../../../constants/Colors";
import ChartBarMeta from "../../../../tools/ChartBarMeta/ChartBarMeta";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';

import './CardIndicadorPainel.css';
import CustomTooltip from "../../../../tools/CustomTooltip";

export default class CardIndicadorPainel extends React.Component {
    state = {
        loading: true,
        id_elemento: this.props.id,
        indicador: null,
    }

    async componentDidMount() {
        await this.loadData();
    }

    async loadData() {
        this.setState({ loading: true });

        
        let reqBody = {
            id: this.state.id_elemento,
            model: 'PeMapaElemento',
            id_indicador: this.props.indicador,
            id_gmr_painel: this.props.painel
        };

        let { indicadores } = await Sig.request('POST', `pe/diagrama/getAcompanhamentoObjetivoElemento`, reqBody);

        this.setState({ indicador: indicadores[0] }, () => { this.setState({ loading: false }) });
    }
    
    renderHeaderCard() {

        const renderTextTooltip = () => {
            return (
                <div style={{ padding: '10px', maxWidth: '400px' }}>
                    <p style={{ marginBottom: '10px', fontWeight: 'bold' }}>
                        Performance do Indicador
                    </p>
                    <p style={{ marginBottom: '10px' }}>
                        Meta e Resultado até o último período realizado.
                    </p>
                    <hr style={{ margin: '10px 0' }} />
                    <p style={{ marginBottom: '5px', fontWeight: 'bold' }}>
                        Observação:
                    </p>
                    <p style={{fontSize: '11px', textAlign: 'justify'}}>
                        A cor da performance do indicador é definida pelas faixas de cores do Mapa Estratégico, 
                        que pode ser diferente da faixas de cores configuradas para o processo de Gestão de Metas e Resultados 
                        em que está o seu Painel de Controle.
                    </p>
                </div>
            )
        }

        return (
            <div className="headerCard" style={{ backgroundColor: this.state.indicador?.cor ?? Colors.homePage.grey}}>
                {this.state.indicador?.performanceRealizado ?? '--%'}
                <CustomTooltip tooltip={renderTextTooltip()}>
                    <FontAwesomeIcon icon={faInfoCircle} style={{...EssentialStyle.icon, color: Colors.white, cursor: 'help'}} />
                </CustomTooltip>
            </div>
        )
    }

    renderContentCard() {
        return (
            <div className="contentCard">
                <div style={{...EssentialStyle.columnCenter, height: 'calc(100% - 50px)'}}>
                    <div className="contentCardTitle">{this.state.indicador?.indicador?.nome ?? ''}</div>
                </div>

                <ChartBarMeta
                    metaValue={this.state.indicador?.metaRealizado ?? '--%'}
                    realizadoValue={this.state.indicador?.realizadoRealizado ?? '--%'}
                    color={this.state.indicador?.cor ?? Colors.homePage.grey}
                    performance={this.state.indicador?.performanceRealizado.slice(0, -1) ?? 0}
                    minHeight={30}
                    height={50}
                />
            </div>
        )
    }

    render() {
        return (
            <div className="CardIndicadorPainel">
                {this.renderHeaderCard()}
                {this.renderContentCard()}
            </div>
        )
    }
}