import React from "react";
import EssentialStyle from "../../style/EssentialStyle";
import Colors from "../../constants/Colors";
import Constants from "../../constants/Api";
import IdiomaHelper from "../../helper/IdiomaHelper";

const gateway = Constants.getSigEndPoint();

export default class ErrorBoundary extends React.Component {
    constructor(props) {
        super(props);
        this.state = { 
            hasError: false,
            message: IdiomaHelper.getStruct({
                'pt': {
                    title: <div style={{ fontSize: 25, marginTop: 20 }}>Oops! Ocorreu um erro desconhecido!</div>,
                    subtitle: <div style={{ fontSize: 16, marginTop: 10 }}>Aguarde alguns momentos e tente novamente, ou vá para a <span onClick={() => { window.parent.postMessage({ type: '_blank', url: `/` }, '*'); }} style={{ textDecoration: 'underline', cursor: 'pointer', fontWeight: 'bold' }}>Página Inicial</span></div>,
                    contact: <div style={{ fontSize: 14, marginTop: 5 }}>Caso o erro persista entre em contato conosco por e-mail em <a href="mailto:sig@grupoadvis.com.br" style={{ textDecoration: 'underline', cursor: 'pointer', fontWeight: 'bold', color: Colors.dark }}>sig@grupoadvis.com.br</a> </div>
                },
                'en': {
                    title: <div style={{ fontSize: 25, marginTop: 20 }}>Oops! An unknown error occurred!</div>,
                    subtitle: <div style={{ fontSize: 16, marginTop: 10 }}>Wait a few moments and try again, or go to the <span onClick={() => { window.parent.postMessage({ type: '_blank', url: `/` }, '*'); }} style={{ textDecoration: 'underline', cursor: 'pointer', fontWeight: 'bold' }}>Home Page</span></div>,
                    contact: <div style={{ fontSize: 14, marginTop: 5 }}>If the error persists, contact us by email at <a href="mailto:sig@grupoadvis.com.br" style={{ textDecoration: 'underline', cursor: 'pointer', fontWeight: 'bold', color: Colors.dark }}>sig@grupoadvis.com.br</a> </div>
                },
                'es': {
                    title: <div style={{ fontSize: 25, marginTop: 20 }}>¡Ups! ¡Ocurrió un error desconocido!</div>,
                    subtitle: <div style={{ fontSize: 16, marginTop: 10 }}>Espere unos momentos y vuelva a intentarlo, o vaya a la <span onClick={() => { window.parent.postMessage({ type: '_blank', url: `/` }, '*'); }} style={{ textDecoration: 'underline', cursor: 'pointer', fontWeight: 'bold' }}>Página de inicio</span></div>,
                    contact: <div style={{ fontSize: 14, marginTop: 5 }}>Si el error persiste, contáctenos por correo electrónico en <a href="mailto:sig@grupoadvis.com.br" style={{ textDecoration: 'underline', cursor: 'pointer', fontWeight: 'bold', color: Colors.dark }}>sig@grupoadvis.com.br</a> </div>
                }
            }),
            lang: IdiomaHelper.getIdioma() || 'pt'
        };
    }

    componentDidCatch(error, info) {
        this.setState({ hasError: true });
    }

    render() {
        if (this.state.hasError) {
            return (
                <div style={{ height: this.props.height ? this.props.height : '100vh', backgroundColor: 'transparent', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', color: Colors.dark, fontWeight: 'bold' }}>
                    <img src={`${gateway}/img/data-not-found4.png`} alt="404" style={{ height: 200 }} />
                    {this.state.message[this.state.lang].title}
                    {this.state.message[this.state.lang].subtitle}
                    {this.state.message[this.state.lang].contact}
                </div>
            )
        }

        return this.props.children;
    }
}