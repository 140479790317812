import React from "react";

import EssentialStyle from '../../../../../style/EssentialStyle';
import DefaultLoader from '../../../../tools/DefaultLoader';
import { Collapse } from "react-bootstrap";
import Colors from "../../../../../constants/Colors";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';
import SessionHelper from '../../../../../helper/SessionHelper';
import moment from 'moment';
import ChartHelper from '../../../../../helper/inicio/ChartHelper';
import EllipsisText from "../../../../tools/EllipsisText";
import DataHelper from "../../../../../helper/DataHelper";

export default class RelatorioProjetosVinculos extends React.Component {

    state={
        contadores: {},
        expanded: false,
        status: [
            "Não Iniciado",
            "Em Desenvolvimento",
            "Concluído",
            "Atrasado",
            "Cancelado",
        ],
        loading: true,
    }

    componentDidMount(){
        this.calculaContadores();
    }

    componentDidUpdate(prevProps){
        if(prevProps.projetos !== this.props.projetos){
            this.calculaContadores();
        }
    }

    calculaContadores = () => {

        this.setState({ loading: true });

        var defaultPlanoCount = {
            total: 0,
            "Não Iniciado": 0,
            "Em Desenvolvimento": 0,
            "Concluído": 0,
            "Atrasado": 0,
            "Cancelado": 0,
        }

        var contadores = {
            perspectiva: [],
        }

        if(!this.props.projetos || this.props.projetos.length === 0) {
            this.setState({ loading: false, contadores });
            return;
        } 

        var projetos = this.props.projetos;

        projetos.forEach(projeto => {
            var perspectivas = [];
            var objetivos = [];

            if(projeto.perspectiva && projeto.objetivo){
                var indexObjetivo = null;
                var indexContador = contadores.perspectiva.findIndex(perspectiva => perspectiva.id == projeto.perspectiva.id);
                if(indexContador == -1) {
                    contadores.perspectiva.push(projeto.perspectiva);
                    contadores.perspectiva[contadores.perspectiva.length - 1].objetivos = [];
                    contadores.perspectiva[contadores.perspectiva.length - 1].objetivos.push(projeto.objetivo);
                } else {
                    indexObjetivo = contadores.perspectiva[indexContador].objetivos.findIndex(objetivo => objetivo.id == projeto.objetivo.id);
                    if(indexObjetivo == -1) {
                        contadores.perspectiva[indexContador].objetivos.push(projeto.objetivo);
                    }
                }

                if(!perspectivas.includes(projeto.perspectiva.id)){
                    perspectivas.push(projeto.perspectiva.id);
                    var index = indexContador == -1 ? (contadores.perspectiva.length - 1) : indexContador;
                    if(!contadores.perspectiva[index].projetos){
                        contadores.perspectiva[index].projetos = {...defaultPlanoCount};
                    }
                    contadores.perspectiva[index].projetos.total++;
                    contadores.perspectiva[index].projetos[projeto.status]++;

                    if(!objetivos.includes(projeto.objetivo.id)){
                        objetivos.push(projeto.objetivo.id);
                        let indexObj = indexObjetivo == -1 || indexObjetivo == null ? (contadores.perspectiva[index].objetivos.length - 1) : indexObjetivo;
                        if(!contadores.perspectiva[index].objetivos[indexObj].projetos){
                            contadores.perspectiva[index].objetivos[indexObj].projetos = {...defaultPlanoCount};
                        }
                        contadores.perspectiva[index].objetivos[indexObj].projetos.total++;
                        contadores.perspectiva[index].objetivos[indexObj].projetos[projeto.status]++;
                    }
                }
            }  
        });
        this.setState({ contadores, loading: false });
    }

    getDiasAtrasoEtapa = (etapa) => {
        var dataFim = moment(etapa.data_fim);
        var dataFimReal = moment();
    
        if(etapa.status == 2 || etapa.status == 3 && etapa.data_conclusao){
          dataFimReal = moment(etapa.data_conclusao);
        }
    
        if(dataFimReal.isAfter(dataFim)){
          return dataFimReal.diff(dataFim, 'days');
        }
        return 0;
    }

    fieldIsFiltered = (filter) => {
        if(!filter) return false;
        var view = filter.view;
        var valuesFilter = filter.values;

        var isFiltered = false;
        if(this.props.filter?.view == view && this.props.filter?.values && this.props.filter?.values.length > 0){
            isFiltered = JSON.stringify(valuesFilter) == JSON.stringify(this.props.filter.values);
        }
        return isFiltered;
    }

    renderBarra(elemento, type) {
        if(!elemento) return null;
        const status = this.state.status;

        const statusCounts = elemento.projetos;

        const total = elemento.projetos.total;

        const renderDiv = (status, index) => {
            var filter = {view: "projetos", values: [{ field: 'status', value: status }, {field: type, value: elemento.id}]};
            return (
                <div 
                    key={status+index}
                    onClick={() => {
                        if(this.fieldIsFiltered(filter))
                            this.props.filterCallback({view: "projetos", values: []});
                        else
                            this.props.filterCallback(filter);
                    }}
                    style={{ 
                        ...EssentialStyle.rowFlexStart,
                        height: this.fieldIsFiltered(filter) ? 35 : 32,
                        width: `${(statusCounts[status] / total) * 100}%`,
                        minWidth: statusCounts[status] > 0 ? 15 : 0,
                        backgroundColor: ChartHelper.getColor(status),
                        transition: 'height 0.5s ease, boxShadow 0.5s ease',
                        overflow: 'visible',
                        cursor: "pointer",
                        filter: this.fieldIsFiltered(filter) ? "brightness(0.9)" : "unset",
                        boxShadow: this.fieldIsFiltered(filter) ? `0px 0px 10px 0px ${ChartHelper.getColor(status)}` : "none",
                    }}
                >
                    <span style={{ 
                        color: 'white', 
                        fontWeight: '500', 
                        marginLeft: 5, 
                        opacity: 1, 
                        transition: 'opacity 0.3s ease-in-out' 
                    }}>
                        {elemento.projetos[status]}
                    </span>
                </div>
            );
        }

        return (
            <div style={{...EssentialStyle.rowSpaceBetween, width: '100%', height: 35, borderRadius: 5, marginLeft: 5, overflow: 'hidden'}}>
                {status.map(renderDiv)}
            </div>
        );
    }

    renderVinculos = () => {
        if(this.state.loading) {
            return (
            <div style={{...EssentialStyle.columnCenter, width: "100%", height: "auto", justifyContent: 'center', alignItems: 'center'}} >
                <DefaultLoader />
            </div>
           )
        }

        return (
            <div className="pa-perspectiva-component" style={{ ...EssentialStyle.columnCenter, width: '100%', backgroundColor: SessionHelper.getBackgroundColor(), paddingTop: "3px", position: 'sticky', left: 0}}>
                <div style={{...EssentialStyle.columnCenter, minHeight: 35, boxShadow: `0px 1px 10px 0px  rgba(50, 50, 50, 0.1)`, borderRadius: 8, backgroundColor: Colors.white, width: '100%', overflow: "hidden"}}>

                    <div style={{ ...EssentialStyle.rowFlexCenter, cursor: 'pointer', minHeight: 35 }} onClick={() => this.setState({expanded: !this.state.expanded})} >
                        {this.state.expanded ?
                            <FontAwesomeIcon icon={faChevronUp} style={{ marginRight: 8 }} /> :
                            <FontAwesomeIcon icon={faChevronDown} style={{ marginRight: 8 }} />
                        }
                        {
                            this.state.expanded ? 'Ocultar Vínculos' : 'Vínculos'
                        }
                    </div>

                    <Collapse in={this.state.expanded} style={{ width: '100%', transition: "all 0.2s ease" }}>
                        <div style={{...(this.props.isSmallScreen ? EssentialStyle.columnCenter : EssentialStyle.rowFlexCenter), width: '100%'}}>
                            <div style={{...EssentialStyle.columnStart, width: '100%', height: 300, paddingTop: 0, gap: 15}}>
                                {this.state.loading ? 
                                    <div style={{ ...EssentialStyle.columnCenter, padding: 10, width: '100%', height: "100%"}}>
                                        <DefaultLoader />
                                    </div>
                                : 
                                    <div style={{...EssentialStyle.columnStart, paddingBottom: 5, width: "100%"}}>
                                        <div style={{...EssentialStyle.rowFlexCenter, height: 40, borderBottom: `1px solid ${Colors.homePage.extraLightGrey}`, width: "100%", textAlign: 'center'}}>
                                            <div style={{fontSize: 16, fontWeight: 500, color: Colors.homePage.grey, flex: 1, height: "100%", alignContent: "center", textOverflow: "ellipsis", overflow: "hidden", whiteSpace: "nowrap"}}>Perspectiva / Objetivo</div>
                                            <div style={{fontSize: 16, fontWeight: 500, color: Colors.homePage.grey, width: "30%", minWidth: 250, height: "100%", alignContent: "center", textOverflow: "ellipsis", overflow: "hidden", whiteSpace: "nowrap"}}>Projetos</div>
                                        </div>
                                        <div style={{...EssentialStyle.columnStart, width: "100%", overflowY: "auto", maxHeight: 250, height: "auto"}}>
                                            {this.state.contadores.perspectiva.map((perspectiva, index) => {
                                                return (
                                                <>
                                                    <div key={"perspectiva-"+index} style={{...EssentialStyle.rowFlexCenter, borderBottom: `1px solid ${Colors.homePage.extraLightGrey}`, width: "100%", height: 40, minHeight: 40}}>
                                                        <div style={{fontSize: 16, fontWeight: 500, color: Colors.homePage.darkGrey, flex: 1, height: "100%", alignContent: "center", paddingLeft: 10}}>
                                                            <EllipsisText text={DataHelper.removeHtmlAndReplaceListItems(perspectiva.nome)} />
                                                        </div>
                                                        <div style={{...EssentialStyle.rowFlexStart, fontSize: 16, fontWeight: 500, color: Colors.homePage.darkGrey, width: "30%", minWidth: 250, height: "100%", alignContent: "center"}}>
                                                            {perspectiva.projetos ? 
                                                                <>
                                                                    <div style={{...EssentialStyle.rowFlexStart, height: "100%", width: "calc(100% - 45px)"}}>
                                                                        {this.renderBarra(perspectiva, 'perspectiva')}
                                                                    </div>
                                                                    <div
                                                                        onClick={() => {
                                                                            if(this.fieldIsFiltered({view: "projetos", values: [{ field: 'perspectiva', value: perspectiva.id }]}))
                                                                                this.props.filterCallback({view: "projetos", values: []});
                                                                            else
                                                                            this.props.filterCallback({view: "projetos", values: [{ field: 'perspectiva', value: perspectiva.id }]})
                                                                    }} 
                                                                    style={{
                                                                        ...EssentialStyle.rowFlexCenter, cursor: "pointer",
                                                                        height: 30, width: 40, marginLeft: 5,
                                                                        borderLeft: `2px solid ${Colors.homePage.extraLightGrey}`, 
                                                                        borderRadius: this.fieldIsFiltered({view: "projetos", values: [{ field: 'perspectiva', value: perspectiva.id }]}) ? 5 : 0,
                                                                        backgroundColor: this.fieldIsFiltered({view: "projetos", values: [{ field: 'perspectiva', value: perspectiva.id }]}) ? Colors.homePage.extraLightGrey : "transparent"}}
                                                                        >
                                                                        {perspectiva.projetos.total}
                                                                    </div>
                                                                </>
                                                                : 
                                                                <div style={{...EssentialStyle.rowFlexCenter, height: "100%", width: "calc(100% - 45px)"}}>
                                                                    -
                                                                </div>
                                                            }
                                                        </div>
                                                    </div> 
                                                    {
                                                        perspectiva.objetivos && perspectiva.objetivos.map((objetivo, indexObj) => {
                                                            return (
                                                                <div key={"objetivo-"+indexObj} style={{...EssentialStyle.rowFlexCenter, borderBottom: `1px solid ${Colors.homePage.extraLightGrey}`, width: "100%", height: 40, minHeight: 40, backgroundColor: Colors.tag}}>
                                                                    <div style={{fontSize: 16, fontWeight: 500, color: Colors.homePage.darkGrey, flex: 1, height: "100%", alignContent: "center", paddingLeft: 20}}>
                                                                        <EllipsisText text={DataHelper.removeHtmlAndReplaceListItems(objetivo.valor)} />
                                                                    </div>
                                                                    <div style={{...EssentialStyle.rowFlexStart, fontSize: 16, fontWeight: 500, color: Colors.homePage.darkGrey, width: "30%", minWidth: 250, height: "100%", alignContent: "center"}}>
                                                                        {objetivo.projetos ? 
                                                                            <>
                                                                                <div style={{...EssentialStyle.rowFlexStart, height: "100%", width: "calc(100% - 45px)"}}>
                                                                                    {this.renderBarra(objetivo, 'objetivo')}
                                                                                </div>
                                                                                <div
                                                                                    onClick={() => {
                                                                                        if(this.fieldIsFiltered({view: "projetos", values: [{ field: 'objetivo', value: objetivo.id }]}))
                                                                                            this.props.filterCallback({view: "projetos", values: []});
                                                                                        else
                                                                                        this.props.filterCallback({view: "projetos", values: [{ field: 'objetivo', value: objetivo.id }]})
                                                                                    }} 
                                                                                    style={{
                                                                                        ...EssentialStyle.rowFlexCenter, cursor: "pointer",
                                                                                        height: 30, width: 40, marginLeft: 5,
                                                                                        borderLeft: `2px solid ${Colors.homePage.extraLightGrey}`, 
                                                                                        borderRadius: this.fieldIsFiltered({view: "projetos", values: [{ field: 'objetivo', value: objetivo.id }]}) ? 5 : 0,
                                                                                        backgroundColor: this.fieldIsFiltered({view: "projetos", values: [{ field: 'objetivo', value: objetivo.id }]}) ? Colors.homePage.extraLightGrey : "transparent"}}
                                                                                    >
                                                                                    {objetivo.projetos.total}
                                                                                </div>
                                                                            </>
                                                                            : 
                                                                            <div style={{...EssentialStyle.rowFlexCenter, height: "100%", width: "calc(100% - 45px)"}}> 
                                                                                -
                                                                            </div>
                                                                        }
                                                                    </div>
                                                                </div> 
                                                            )
                                                        })           
                                                    }
                                                </>
                                                )
                                            })}
                                        </div>
                                    </div>
                                }
                                </div>
                        </div>
                    </Collapse>
                </div>
            </div>
        );
    }


    render() {
        return (
            this.renderVinculos()
        )
    }
}