import React from 'react';
import ResizableTimelineElement from '../../../../tools/ResizableTimelineElement/ResizableTimelineElement';
import 'moment/locale/pt-br'
import moment from 'moment';
import Colors from "../../../../../constants/Colors";
import EllipsisText from "../../../../tools/EllipsisText";
import { Collapse } from "react-bootstrap";
import UserAvatar from '../../../../tools/UserAvatar';
import DataHelper from '../../../../../helper/DataHelper';

export default class TimelineProjetosLinha extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            expanded: false,
        };
    }


    async changeMarcoCallback(updatedMarco) {
        var projeto = {...this.props.projeto};
        const index = projeto.marcos.findIndex(m => m.id === updatedMarco.id);
        projeto.marcos[index] = updatedMarco;
        this.props.changeProjetoCallback(projeto);
    }

    setEditId(id) {
        this.props.setEditId(id);
    }

    render() {

        var { timelineStart, timelineEnd, projeto } = this.props;
        
        var offsetLeft = 400;
        if(this.props.isSmallScreen) 
            offsetLeft = 200;

        var totalDuration = timelineEnd - timelineStart;
        var quantMonths = Math.round(moment(new Date(timelineEnd)).diff(moment(new Date(timelineStart)), 'months', true));
        var quantDias = Math.round(moment(new Date(timelineEnd)).diff(moment(new Date(timelineStart)), 'days', true));

            
        const projetostart = moment(projeto.data_inicio).startOf('day').toDate().getTime() - timelineStart;
        const projetoDuration = moment(projeto.data_fim).endOf('day').toDate().getTime() - moment(projeto.data_inicio).startOf('day').toDate().getTime();
        const projetoPosition = Math.round((projetostart / totalDuration) * quantDias) * this.props.daySize;
        const projetoLength = Math.round((projetoDuration / totalDuration) * quantDias) * this.props.daySize;
        
        return (
            <>
                <div key={this.props.index} style={{ display: "flex", flexDirection: "row" }}>
                    <div
                        onClick={() => this.setState({ expanded: !this.state.expanded })} 
                        style={{ height: "35px", left: 0, zIndex: 4, width: offsetLeft, display: "flex", justifyContent: "center", padding: "0px 5px", alignItems: "center", backgroundColor: `${this.props.index % 2 == 1 ? Colors.white : "rgb(247, 248, 249)"}`, borderRight: `1px solid ${Colors.homePage.extraLightGrey}`, position: "sticky", cursor: projeto.marcos && projeto.marcos.length > 0 ? "pointer" : "default", fontWeight: 500 }}>
                        <div style={{display: 'flex', height: "100%", width: "40px", alignItems: 'center', justifyContent: 'center'}}>
                            <UserAvatar id={projeto.projeto.id_responsavel} size={35}/>
                        </div>
                        <div style={{height: "100%", width: "calc(100% - 40px)", fontWeight: 500}}>
                            <EllipsisText text={projeto.projeto.nome} />
                        </div>
                    </div>
                    <div style={{ left: offsetLeft, width: `${this.props.daySize * quantDias}px`, paddingTop: '5px', paddingBottom: '5px', display: 'flex', flexDirection: 'column', backgroundColor: `${this.props.index % 2 == 1 ? Colors.white : "rgb(247, 248, 249)"}` }}>
                        <ResizableTimelineElement
                            element={{...projeto}}
                            elementPosition={projetoPosition}
                            elementLength={projetoLength}
                            width={this.props.daySize * quantDias}
                            timelineStart={timelineStart}
                            timelineEnd={timelineEnd}
                            isSmallScreen={this.props.isSmallScreen}
                            daySize={this.props.daySize}
                            selectOnClick={true}
                            type={'projeto'}
                            setEditId={() => this.setEditId(null)}
                        />
                    </div>
                </div>
                {projeto.marcos && projeto.marcos.length > 0 && projeto.marcos.map((marco, marcoIndex) => {
                    const marcoStart = moment(marco.data_inicio).startOf('day').toDate().getTime() - timelineStart;
                    const marcoDuration = moment(marco.data_fim).endOf('day').toDate().getTime() - moment(marco.data_inicio).startOf('day').toDate().getTime();
                    const marcoPosition = Math.round((marcoStart / totalDuration) * quantDias) * this.props.daySize;
                    const marcoLength = Math.round((marcoDuration / totalDuration) * quantDias) * this.props.daySize;
                    return (
                        <Collapse in={this.state.expanded} key={"marco-"+marcoIndex}>
                            <div>
                                <div style={{ display: "flex", flexDirection: "row" }}>
                                    <div style={{height: "35px", left: 0, zIndex: 4, width: offsetLeft, display: "flex", justifyContent: "center", padding: "0px 5px", alignItems: "center", backgroundColor: `${(this.props.index + marcoIndex + 1) % 2 == 1 ? Colors.white : "rgb(247, 248, 249)"}`, borderRight: `1px solid ${Colors.homePage.extraLightGrey}`, position: "sticky", fontWeight: 500, paddingLeft: 15, filter: "brightness(0.95)" }}>
                                        {marco.planoAcao ? 
                                            <>
                                                <div style={{display: 'flex', height: "100%", width: "40px", alignItems: 'center', justifyContent: 'center'}}>
                                                    <UserAvatar user={marco.planoAcao.responsavel} size={35} />
                                                </div>
                                                <div style={{height: "100%", width: "calc(100% - 40px)", fontWeight: 500}}>
                                                    <EllipsisText text={marco.planoAcao.nome} />
                                                </div>
                                            </>
                                        :
                                            
                                            <div style={{height: "100%", width: "calc(100% - 40px)", fontWeight: 500, marginLeft: 40}}>
                                                <EllipsisText text={marco.descricao} />
                                            </div>
                                        }
                                    </div>
                                    <div style={{ left: offsetLeft, width: `${this.props.daySize * quantDias}px`, paddingTop: '5px', paddingBottom: '5px', display: 'flex', flexDirection: 'column', backgroundColor: `${(this.props.index + marcoIndex + 1) % 2 == 1 ? Colors.white : "rgb(247, 248, 249)"}`, filter: "brightness(0.92)" }}>
                                        <ResizableTimelineElement
                                            element={{...marco}}
                                            elementPosition={marcoPosition}
                                            elementLength={marcoLength}
                                            width={this.props.daySize * quantDias}
                                            changeElementCallback={(marco) => {this.changeMarcoCallback(marco)}}
                                            timelineStart={timelineStart}
                                            timelineEnd={timelineEnd}
                                            edit={this.props.edit}
                                            setEditId={(id) => this.setEditId(id)}
                                            isSmallScreen={this.props.isSmallScreen}
                                            selectOnClick={true}
                                            daySize={this.props.daySize}
                                            type={'projeto'}
                                        />
                                    </div>
                                </div>
                            </div>
                        </Collapse>
                    );
                })}
            </>
        );
    }
    
}