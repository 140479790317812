import React from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLayerGroup, faAdd, faTimes, faCheck, faSortAlphaUp, faPlusSquare, faSortAmountUp, faStar } from '@fortawesome/free-solid-svg-icons'
import { Form } from "react-bootstrap";
import Colors from "../../constants/Colors";
import DefaultButton from "../../components/tools/DefaultButton";
import LoadingPage from "../LoadingPage";
import Sig from "../../api/Sig";
import EssentialStyle from "../../style/EssentialStyle";
import KeyboardHelper from "../../helper/KeyboardHelper";
import DataHelper from "../../helper/DataHelper";
import Select from 'react-select'
import DefaultLoader from "../../components/tools/DefaultLoader";
import ProjetoCardSmall from "../../components/modules/pe/projeto/ProjetoCardSmall";
import ProjetoObjetivoCard from "../../components/modules/pe/projeto/ProjetoObjetivoCard/ProjetoObjetivoCard";
import ProjetoFatoresSowtCard from "../../components/modules/pe/projeto/ProjetoFatoresSwotCard/ProjetoFatoresSwotCard";
import { toast } from "react-toastify";
import ProjetoIniciativaCard from "../../components/modules/pe/projeto/ProjetoIniciativaCard/ProjetoIniciativaCard";
import GutMatrix from "../../components/tools/GutMatrix";
import CustomTooltip from "../../components/tools/CustomTooltip";
import LayoutHelper from "../../helper/LayoutHelper";
import AddProjeto from "../../components/forms/pe/add/AddProjeto";

export default class ProjetoPageCriar extends React.Component {

    state = {
        loading: true,
        loadingBody: true,
        orderBy: 'prioritization', //prioritization or alfabetic
        hoverOrderBy: false,

        mapasUser: [],
        preSelectedMapa: null,
        type: null, //'star' or 'prioritization',
        showGut: false,

        objetivos: [],
        perspectivas: [],
        temFatores: false,
        fatores: [],

        objetivoSelectedId: null,
        loadingFatores: false,
        addingIniciativa: false,

        nameIniciativa: '',
        iniciativas: [],
        loadingIniciativas: false,
        iniciativaSelectId: null,

        loadingProjetos: false,
        projetos: [],
        nomeProjeto: '',
        addNovoProjetoPage: false,

        isSmallScreen: LayoutHelper.isSmallScreen(),
    }

    async componentDidMount() {
        window.addEventListener('resize', this.verifySmallScreen);

        await this.loadData(true);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.verifySmallScreen);
    }

    verifySmallScreen = () => {
        this.setState({ isSmallScreen: LayoutHelper.isSmallScreen() });
    }

    async loadData(firstLoad = false, preSelectedMapaValue = false) {
        this.setState({ loading: true });
        
        let { mapasUser, preSelectedMapa } = await Sig.request('GET', 'pe/mapa/getMapaUser', { mapaId: null, select: true });
        let type = preSelectedMapa?.tipo_priorizacao == "0" ? 'star' : 'prioritization';

        mapasUser.unshift({"label": "Projetos Sem Vinculos", "value": null});

        if (preSelectedMapaValue) {
            preSelectedMapa = mapasUser.find(mapa => mapa.value == preSelectedMapaValue);
        }

        this.setState({ type, loading: false, preSelectedMapa, mapasUser: mapasUser }, () => { 
            if(preSelectedMapa) {
                if(firstLoad) this.getDadosMapa(this.state.preSelectedMapa);
                this.loadProjetosMapa();
                this.loadIniciativas();
            }
        });
    }

    async loadIniciativas(){
        
        this.setState({ loadingIniciativas: true });
        let { iniciativas } = await Sig.request('POST', 'pe/projeto/getIniciativasMapa', { id_pe_mapa: this.state.preSelectedMapa.value, ordenacao: this.state.orderBy, prioridade: this.state.type});
        this.setState({ iniciativas, loadingIniciativas: false });
    }

    async loadProjetos(){
        this.setState({ loadingProjetos: true });
        let { projetos } = await Sig.request('GET', 'pe/projeto/getProjetosIniciativa', { id_pe_projeto_iniciativa: this.state.iniciativaSelectId});
        this.setState({ projetos, loadingProjetos: false });
    }

    async loadProjetosObjetivo(){
        // Carrega todos projetos do objetivo selecionado
        this.setState({ loadingProjetos: true });
        let { projetos } = await Sig.request('GET', 'pe/projeto/getProjetosObjetivo', { id_objetivo: this.state.objetivoSelectedId});
        this.setState({ projetos, loadingProjetos: false });
    }

    async loadProjetosMapa(){
        // Carrega todos projetos do mapa selecionado
        this.setState({ loadingProjetos: true });
        let { projetos } = await Sig.request('GET', 'pe/projeto/getProjetosMapa', { id_pe_mapa: this.state.preSelectedMapa.value, ordenacao: 'alfabetic', relations: 1});
        this.setState({ projetos, loadingProjetos: false });
    }

    async getDadosMapa(id) {

        if (id.value == null) {
            this.setState({addNovoProjetoPage: true});
        } else {
            this.setState({ preSelectedMapa: id, loadingBody: true });
            let { objetivos, temFatores, fatores, perspectivas } = await Sig.request('GET', 'pe/mapa/getMapaObjetivosUser', { id: id.value, objetivoHasIniciativa: false });
            this.setState({ objetivos, perspectivas, temFatores, fatores, loadingBody: false }, () => { this.loadIniciativas(); this.loadProjetosMapa(); });
        }
    }

    async deleteIniciativa(id) {
        this.setState({ loadingIniciativas: true });
        let { response } = await Sig.request('POST', 'pe/projeto/deleteIniciativa', { id: id });
        if(!response) toast.error('Erro ao deletar iniciativa!');

        this.loadIniciativas();
    }

    async alterTypeMapa(event) {

        this.setState({ type: event.target.checked ? 'prioritization' : 'star' }, () =>{
            this.alterTypeMapaBanco()
        });
        
    }

    async alterTypeMapaBanco(){
        let { response } = await Sig.request('GET', 'pe/mapa/alterTypeMapa', { id_pe_mapa: this.state.preSelectedMapa.value, type: this.state.type });
        if(!response) {
            toast.error('Erro ao alterar tipo de mapa!');
        }
    }

    controlObjetivoSelect = (select, id) => {
        this.setState({
            objetivoSelectedId: select ? null : id,
            iniciativaSelectId: null,
            loadingFatores: false,
            loadingIniciativas: false
        }, () => {
            this.state.objetivoSelectedId ? this.loadProjetosObjetivo() : this.loadProjetosMapa();
        });
    }

    controlIniciativaSelect = (select, id) => {
        this.setState({ 
            iniciativaSelectId: select ? null : id 
        }, () => {
            !select ? this.loadProjetos() : (this.state.objetivoSelectedId ? this.loadProjetosObjetivo() : this.loadProjetosMapa());
        });
    }

    addIniciativa = async () => {
        if (!this.state.nameIniciativa) return toast.info('Informe o nome da iniciativa!');

        this.setState({ loadingIniciativas: true });
        let response = await Sig.request('POST', 'pe/projeto/addIniciativa', { id_pe_mapa_objetivo: this.state.objetivoSelectedId, nome: this.state.nameIniciativa, id_pe_mapa: this.state.preSelectedMapa.value });
        if(!response) toast.error('Erro ao adicionar iniciativa!'); 

        this.setState({ nameIniciativa: '', addingIniciativa: false }, () => { this.loadIniciativas() });
    }

    addProjeto = async () => {
        
        if(this.state.nomeProjeto === '') return toast.info('Informe o nome do projeto!');

        this.setState({ loadingProjetos: true });
        let response = await Sig.request('POST', 'pe/projeto/addProjeto', { id_pe_projeto_iniciativa: this.state.iniciativaSelectId, nome: this.state.nomeProjeto });

        if(response) {
            this.loadProjetos();
            this.setState({ nomeProjeto: '', loadingProjetos: false });
        } else {
            this.setState({ loadingProjetos: false });
            toast.error('Erro ao adicionar projeto!');
        }
    }

    gutCallback = async () => {
        this.setState({ showGut: false });
        await this.loadData(false, this.state.preSelectedMapa.value);
    }

    renderOrderBy(tipo) {

        return (
            <CustomTooltip placement={'top'} tooltip={ this.state.orderBy === 'prioritization' ? 'Ordenar Alfabeticamente' : (this.state.type == 'star' ? 'Ordenar pela Estrela' : ' Ordenar pela Matriz de Priorização') }>
                <FontAwesomeIcon 
                    icon={this.state.orderBy === 'alfabetic' ? faSortAlphaUp : (this.state.type === 'prioritization' ? faSortAmountUp : faStar)} 
                    style={{ cursor: 'pointer', transition: 'transform 0.3s', transform: this.state.hoverOrderBy ? 'scale(1.2)' : 'scale(1)' }} 
                    onClick={() => { 
                        this.setState({ orderBy: this.state.orderBy === 'alfabetic' ? 'prioritization' : 'alfabetic' }, () => {this.loadIniciativas()});
                    }}
                    onMouseEnter={() => {
                        this.setState({ hoverOrderBy: true });
                    }}
                    onMouseLeave={() => {
                        this.setState({ hoverOrderBy: false });
                    }}
                />
            </CustomTooltip>
        )
    }

    renderLoadingBody(){
        return (
            <div style={{ width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <DefaultLoader size={32} title={"Carregando ..."} />
            </div>
        )
    }

    renderObjetivosBody(){
        let objetivosPorPerspectiva = Object.values(this.state.objetivos).reduce((acc, objetivo) => {
            if (!acc[objetivo.id_pe_mapa_perspectiva]) {
              acc[objetivo.id_pe_mapa_perspectiva] = [];
            }
            acc[objetivo.id_pe_mapa_perspectiva].push(objetivo);
            return acc;
        }, {});

        let perspectivasNomes = Object.values(this.state.perspectivas);

        return (
            <>
                {perspectivasNomes.map(perspectivaNome => (
                    <>
                        {Object.values(perspectivaNome).map((perspectivaNomeStr, index) => (
                            <div style={{ display: 'flex', alignItems: 'center', color: '#BCBCBC', marginBottom: 10 }}>
                                <span>{ DataHelper.removeHtmlTags(perspectivaNomeStr) }</span>
                                <hr style={{ flexGrow: 1, margin: '5px 0px 5px 10px', border: 'none', borderTop: '1px solid' }} />
                            </div>
                        ))}

                        {Object.keys(perspectivaNome).map(key => {
                            return objetivosPorPerspectiva[key].map(objetivo => {
                                return (
                                    <ProjetoObjetivoCard
                                        key={objetivo.id}
                                        objetivo={objetivo}
                                        selected={ this.state.objetivoSelectedId }
                                        onSelect={ (select, id) => { this.controlObjetivoSelect(select, id) } } 
                                    />
                                )
                            })
                        })}
                    </>
                ))}
            </>
        )
    }

    renderFatoresSwotBody() {
        let { objetivoSelectedId, fatores, loadingFatores } = this.state;

        if(loadingFatores) return <DefaultLoader />

        if (objetivoSelectedId === null) {
            return (
                <div style={avisoStyle}>
                    <span>Selecione um <strong>Objetivo</strong> para visualizar os <strong>Fatores SWOT</strong> vinculados a ele!</span>
                </div>
            );
        }

        if (fatores[objetivoSelectedId] === undefined || fatores[objetivoSelectedId].length === 0) {
            return (
                <div style={avisoStyle}>
                    <span>Nenhum <strong>Fator SWOT</strong> vinculado a este <strong>Objetivo</strong>!</span>
                </div>
            );
        }
            
        return fatores[objetivoSelectedId].map(fator => <ProjetoFatoresSowtCard key={fator.id} fator={fator} viewGut={true}/>);
    }

    renderIniciativasBody(){

        let { iniciativas, loadingIniciativas, objetivoSelectedId } = this.state;

        if(loadingIniciativas) return <DefaultLoader />

        if (objetivoSelectedId === null || iniciativas[objetivoSelectedId] === undefined || iniciativas[objetivoSelectedId].length == 0) {
            return (
                <div style={avisoStyle}>
                    {objetivoSelectedId === null ? 
                    <span>Selecione um <strong>Objetivo</strong> para visualizar as <strong>Iniciativas</strong> vinculadas a ele!</span> 
                        : 
                    <span>Nenhuma <strong>Iniciativa</strong> vinculada a este <strong>Objetivo</strong>!</span>
                    }
                </div>
            );
        }

        return (
            <>
                {iniciativas[objetivoSelectedId].map(iniciativa => 
                    <ProjetoIniciativaCard 
                        key={iniciativa}
                        type={this.state.type}
                        edit={true}
                        delete={(id) => {this.deleteIniciativa(id)}} 
                        selected={ this.state.iniciativaSelectId }
                        selecting={(select, id) => { this.controlIniciativaSelect(select, id) }}
                        id={iniciativa}/>
                )}
            </>
        )
    }

    renderIniciativaFooter() {
        return this.state.addingIniciativa ?
            <div style={{ ...EssentialStyle.rowFlexStart, padding: 5, letterSpacing: 1.1, color: Colors.projeto.addButton, fontSize: 12, fontWeight: 'bold', width: 'calc(100% - 20px)', borderTop: `.5px solid ${Colors.projeto.addButton}`, cursor: 'pointer', marginLeft: '10px', marginRight: '10px' }}>
                <Form.Control
                    className="w-100 me-1" 
                    type="text"
                    autoFocus
                    maxLength={100}
                    placeholder="Nome da Iniciativa..." 
                    onChange={(event) => { this.setState({ nameIniciativa: event.target.value }) }} 
                    onKeyDown={(evt) => { 
                        KeyboardHelper.handleShortcut(evt, ["Enter", "Escape"], 
                        [ this.addIniciativa, 
                        (e) => { e.preventDefault(); this.setState({ nameIniciativa: '', addingIniciativa: false }); }]) 
                    }} />

                <DefaultButton 
                    width={48} 
                    className="me-1" 
                    leftIcon={<FontAwesomeIcon icon={faTimes} />} 
                    color={Colors.projeto.addButton} 
                    loading={this.state.loading}
                    onClick={() => { this.setState({ nameIniciativa: '', addingIniciativa: false }) }} />

                <DefaultButton 
                    width={48} 
                    leftIcon={<FontAwesomeIcon icon={faCheck} />} 
                    color={Colors.projeto.addButton} 
                    loading={this.state.loading}
                    onClick={ this.addIniciativa } />
            </div>
            :
            <div onClick={() => { this.setState({ addingIniciativa: true }); }} 
                 style={{ ...EssentialStyle.rowFlexStart, padding: 8, letterSpacing: 1.1, color: Colors.swot.addButton, fontSize: 12, fontWeight: 'bold', width: 'calc(100% - 20px)', borderTop: `.5px solid ${Colors.swot.addButton}`, cursor: 'pointer', marginLeft: '10px', marginRight: '10px' }}>
                
                <FontAwesomeIcon icon={faAdd} />
                <span style={{ marginLeft: 8 }}>ADICIONAR INICIATIVA</span>
            </div>
            ;
    }

    renderAddProjeto(){

        if (this.state.iniciativaSelectId) return (
            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', width: '100%', height: '100%', backgroundColor: 'trasparent' }}>
                <div style={{ height: 'calc(100% - 58px)', backgroundColor: 'white', borderRadius: 8, marginBottom: 10, padding: 5 }} >
                    <Form.Control 
                        as="textarea" 
                        placeholder="Nome do Projeto..." 
                        style={{ resize: "none", marginBottom: 5 }}
                        maxLength={100}
                        value={ this.state.nomeProjeto } 
                        onChange={(event) => { this.setState({ nomeProjeto: event.target.value }) }}
                        onKeyDown={(evt) => { 
                            KeyboardHelper.handleShortcut(evt, ["Enter", "Escape"], 
                            [ this.addProjeto, 
                            (e) => { e.preventDefault(); this.setState({ nomeProjeto: ''}); }]) 
                        }}
                    />
                    <span style={{ color: 'rgb(188, 188, 188)', fontSize: 12 }}>Defina um nome para o <strong>Projeto</strong>.</span>
                </div>
                <DefaultButton
                    title={'Adicionar Projeto'}
                    leftIcon={<FontAwesomeIcon icon={faPlusSquare} />}
                    color={Colors.success}
                    loading={this.state.loading}
                    style={{ marginBottom: 10 }}
                    onClick={() => { this.addProjeto() }}
                />
            </div>
        );

        return (
            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', width: '100%', height: '100%', backgroundColor: 'trasparent' }}>  
                <div style={{...avisoStyle }}>
                    <span>Selecione uma <strong>Iniciativa</strong> para criar um <strong>Projeto</strong> a partir dela.</span>
                </div>
            </div>
        )
    }

    renderProjetosBody(){
        let { projetos, loadingProjetos, iniciativaSelectId } = this.state;

        if(loadingProjetos) return <DefaultLoader />
        if(projetos.length > 0) return projetos.map(projeto => <ProjetoCardSmall key={projeto.id} projeto={projeto.projeto} projetoRelations={projeto} calbackDelete={()=>{ this.loadProjetos() }}/>);
    }

    renderColumn(tipo){

        let title = tipo === 'objetivos' ? 'OBJETIVOS' : (tipo === 'fatores' ? 'FATORES SWOT' : (tipo === 'projetos' ? 'PROJETOS' : 'INICIATIVAS'));

        if (title === 'PROJETOS') {
            if (this.state.iniciativaSelectId) {
                title = `PROJETOS DA INICIATIVA SELECIONADA`;
            } else if (this.state.objetivoSelectedId) {
                title = `PROJETOS DO OBJETIVO SELECIONADO`;
            } else {
                title = `PROJETOS DO MAPA SELECIONADO`;
            }
        }

        let styleColumn = {
            width: this.state.isSmallScreen ? (tipo == 'projetos' ? '100%' : 'calc(100% - 10px)') : (tipo == 'projetos' ? '100%' : (this.state.temFatores ? '25%' : 'calc(100% / 3)')),
            height: tipo == 'projetos' ? '100%' : '',
            maxHeight: this.state.isSmallScreen ? '40vh' : '',
            flex: 1,
            margin: tipo == 'projetos' ? 0 : 5,
            marginTop: tipo == 'projetos' ? 0 : 10,
            marginLeft: tipo == "objetivos" ? 10 : (tipo == 'projetos' ? 0 : 5),
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            backgroundColor: 'white',
            borderRadius: 8,
        }

        return (
            <div style={{...styleColumn }}>
                <div className="headerColumn" style={{ color: '#BCBCBC', display: 'flex', justifyContent: 'space-between', alignItems: 'center',  width: '100%', padding: 10, fontSize: 12 }}>
                    <strong>{ title }</strong>
                    { tipo == 'iniciativas' ? this.renderOrderBy(tipo) : null }
                </div>

                <div className="bodyColumn" style={{ color: '#BCBCBC', display: 'flex', flexDirection: 'column',justifyContent: 'start', width: '100%', height: '100%', overflowY: 'auto', padding: '1px 10px 10px 10px', fontSize: 12 }}>
                    {
                        tipo == 'objetivos' ?   (this.renderObjetivosBody()) :
                        tipo == 'fatores' ?     (this.renderFatoresSwotBody()) :
                        tipo == 'iniciativas' ? (this.renderIniciativasBody()) :
                        tipo == 'projetos' ?    (this.renderProjetosBody()) : null
                    }
                </div>

                    { tipo == 'iniciativas' && this.state.objetivoSelectedId != null ? 
                        this.renderIniciativaFooter()
                    :
                        <div className="footerColumn" style={{ width: '100%', height: 10 }}></div>
                    }
                
            </div>
        )
    }

    renderCreationBody(){
        let style = {
            body: {
                ...EssentialStyle.rowSpaceBetween, 
                margin: 0, 
                padding: 0, 
                backgroundColor: 'transparent', 
                boxShadow: 'none', 
                height: this.state.isSmallScreen ? '' : 'calc(100vh - 70px)'
            },
            boxColumns: {
                width: '100%', 
                height: this.state.isSmallScreen ? '' : '100%', 
                display: 'flex',
                flexDirection: this.state.isSmallScreen ? 'column' : 'row',
            },
            lastColumn: {
                display: 'flex', 
                flexDirection: 'column', 
                justifyContent: 'end', 
                width: this.state.isSmallScreen ? '100%' : (this.state.temFatores ? '25%' : 'calc(100% / 3)'), 
                padding: this.state.isSmallScreen ? '10px 5px 10px 5px': '10px 10px 5px 5px', 
                height: '100%',
            }
        }

        return (
            <div style={style.body}>
                { 
                    this.state.loadingBody ? this.renderLoadingBody() 
                :
                    <div style={style.boxColumns}>
                        { this.renderColumn('objetivos') }
                        { this.state.temFatores ? this.renderColumn('fatores') : null }
                        { this.renderColumn('iniciativas') }
                        <div style={style.lastColumn}>
                            <div style={{ height: this.state.isSmallScreen ? '' : '40%'}}>
                                { this.renderAddProjeto() }
                            </div>
                            <div style={{ height: this.state.isSmallScreen ? '' : '60%'}}>
                                { this.renderColumn('projetos') }
                            </div>
                        </div>
                    </div>
                }
            </div>
        )
    }

    renderCreationHeader(){

        let style= {
            header: {
                ...(this.state.isSmallScreen ? EssentialStyle.columnCenter : EssentialStyle.rowSpaceBetween), 
                margin: "10px 10px 0px 10px",
                padding: 10,
                backgroundColor: 'white', 
                boxShadow: Colors.boxShadow, 
                border: '0.3px solid lightgrey',
                borderRadius: 8
            }
        }
        return (
            <div style={style.header}>
                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', width: 'auto' }}>
                    <Form.Group>
                        <Select
                            styles={{ control: (base) => ({...base, border: 'none', minWidth: '40vh',}) }}
                            options={ this.state.mapasUser }
                            value={ this.state.preSelectedMapa }
                            onChange={ (valueSelected) => { 
                                this.getDadosMapa(valueSelected); 
                                this.setState({ objetivoSelectedId: null, iniciativaSelectId: null });
                            } }
                            placeholder={'Selecione um Mapa Estratégico'} 
                            noOptionsMessage={DataHelper.getSelectEmptyMessage}
                            isSearchable 
                            menuPortalTarget={document.body}
                        />
                    </Form.Group>
                </div>

                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', width: 'auto' }}>
                    <span style={{ marginRight: 7 }}>Marcar com Estrela</span>
                    <Form.Check 
                        type="switch"
                        onChange={ (event) => { this.alterTypeMapa(event) } }
                        checked={ this.state.type === 'prioritization' }
                    />
                    <span className="teste">Matriz de Priorização</span>

                    <DefaultButton 
                        tooltip={this.state.type === 'star' ? 'Alterne para o modo Matriz de priorização para poder editar' : 'Configurações da Matriz de Priorização'} 
                        tooltipPlacement={'left'} 
                        leftIcon={<FontAwesomeIcon icon={faLayerGroup} />} 
                        color={Colors.secondaryButton} 
                        textColor={Colors.dark} 
                        loading={this.state.loading} 
                        onClick={() => { this.setState({ showGut: true }) }} 
                        disabled={this.state.type === 'star'} 
                        style={{ 
                            marginRight: 8, 
                            marginLeft: 8, 
                            cursor: 'pointer',
                        }} 
                    />
                    <DefaultButton 
                        style={{ width: 38, height: 38}}
                        fontSize={this.state.isSmallScreen ? 14 : "unset"}
                        color={Colors.secondaryButton}
                        textColor={Colors.dark} 
                        link={'https://advis.freshdesk.com/support/solutions/articles/63000283266-cria%C3%A7%C3%A3o-de-projetos'}/>                
                </div>
            </div>
        )
    }

    renderCreation(){
        return (
            <div>
                { this.renderCreationHeader() }
                { this.renderCreationBody() }
            </div>
        )
    }

    renderEmpty() {
        return (
            <div style={{ padding: 50, backgroundColor: 'white', marginTop: 20, display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', boxShadow: Colors.boxShadow, border: '0.3px solid lightgrey', borderRadius: 5 }}>
                <h5>Para adicionar projetos você precisa ter pelo menos um Mapa Estratégico adicionado.</h5>
                <div style={{ marginTop: 20 }} />
                <DefaultButton color={Colors.success} title={'Adicionar Mapa Estratégico'} leftIcon={<FontAwesomeIcon icon={faPlusSquare} />} loading={this.state.loading} onClick={() => { window.parent.postMessage({ type: 'navigate', url: '/pe/mapa' }, '*'); }} />
            </div>
        )
    }

    render() {
        if(this.state.loading) return <LoadingPage />
        if(this.state.showGut) return <GutMatrix idPeMapa={this.state.preSelectedMapa.value} gutCallback={this.gutCallback} />
        if(this.state.addNovoProjetoPage || this.state.mapasUser?.length == 1) return <AddProjeto title={"Criar Projeto sem Vínculo com Mapa Estratégico"} addCallback={() => {}} hasBackButton={this.state.mapasUser?.length > 1} backButton={() => {this.setState({addNovoProjetoPage: false})}} viewList={true}/>
        if(this.state.mapasUser?.length > 0) return this.renderCreation();
        return this.renderEmpty();
    }
}

const avisoStyle = {
    display: 'flex', 
    alignItems: 'center', 
    color: '#BCBCBC', 
    textAlign: 'center', 
    marginTop: 10, 
    boxShadow: '0 0 10px rgba(0, 0, 0, 0.08)', 
    borderRadius: 8, 
    padding: 10,
    fontSize: 15,
    height: '15vh'
};