import React, { Suspense } from "react";

import EssentialStyle from "../../../../../style/EssentialStyle";
import Colors from "../../../../../constants/Colors";
import DefaultLoader from "../../../../tools/DefaultLoader";

export default class RelatorioProjetosLista extends React.Component {

    render() {
        
        const ProjetoCardRelatorio = React.lazy(() => import('./ProjetoCardRelatorio'));
        const ProjetoCardMarco = React.lazy(() => import('./ProjetoCardMarco'));

        if(this.props.loading){
            return (
                <div style={{...EssentialStyle.columnCenter, width: '100%', height: '20vh'}}>
                    <DefaultLoader/>
                </div>
            )
        }

        if(!this.props.elements || this.props.elements.length === 0) {
            return (
                <div style={{...EssentialStyle.columnCenter, width: '100%', height: '20vh'}}>
                    <h3 style={{color: Colors.text, textAlign: 'center', color: Colors.homePage.lightGrey, fontWeight: 500}}>Nenhum resultado encontrado</h3>
                </div>
            )
        }
        
        return(
            <div style={{...EssentialStyle.columnStart, width: '100%', gap: 5, maxHeight: "100%", flex: 1}}>
                <Suspense key={"suspense-lista"} 
                    fallback={
                        <div style={{ ...EssentialStyle.columnCenter, height: "35vh", width: "100%"}}>
                            <DefaultLoader/>
                        </div>}
                >
                    {this.props.view === 'projetos' ?
                            this.props.elements.map((projeto, index) => {
                                return (
                                    <ProjetoCardRelatorio key={`${projeto.id}-projeto-key`} projeto={projeto} isSmallScreen={this.props.isSmallScreen} addCanvasCallback={this.props.addCanvasCallback}/>
                                    )
                                })
                        : 
                    this.props.view === 'marcos' ?
                            this.props.elements.map((marco, index) => {
                                return (
                                        <ProjetoCardMarco key={`${marco.id}-marco-key`} marco={marco} isSmallScreen={this.props.isSmallScreen}/>
                                    );
                            })
                        : null
                    }
                </Suspense>
            </div>
        );
    }
}