import React from 'react';
import Sig from '../../../../../api/Sig';
import RelatorioProjetosStatus from './RelatorioProjetosStatus';
import EssentialStyle from '../../../../../style/EssentialStyle';
import DefaultLoader from '../../../../tools/DefaultLoader';
import RelatorioProjetosVinculos from './RelatorioProjetosVinculos';
import RelatorioProjetosColaboradores from './RelatorioProjetosColaboradores';
import Colors from '../../../../../constants/Colors';
import { Collapse } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';
import SessionHelper from '../../../../../helper/SessionHelper';
import DefaultButton from '../../../../tools/DefaultButton';
import Tabs from '../../../../tools/Tabs';
import RelatorioProjetosLista from './RelatorioProjetosLista';
import DataHelper from '../../../../../helper/DataHelper';


export default class RelatorioProjetos extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            projetos: [],
            filteredElements: [],
            filter: {view: 'projetos', values: []},
            loading: false,
            view: 'projetos',
            displayContent: true,
            activeTipoVisaoTab: 'lista',
            activeTabTimeline: 'semana',
            orcamento: {
                orcamento_previsto: 0,
                orcamento_realizado: 0,
                orcamento_saldo: 0,
            },
        };
    }

    async componentDidMount() {
        await this.loadProjetos();
    }

    async componentDidUpdate(prevProps) {
        if (prevProps.idMapa != this.props.idMapa) {
            await this.loadProjetos();
        }

        if (prevProps.filter != this.props.filter) {
            await this.loadProjetos();
        }

    }

    async loadProjetos() {

        let view = 'projetos';

        this.setState({ loading: true, view});

        let {
            projetos,
            orcamento
        } = await Sig.request('GET', 'pe/projeto/relatorioProjetosMapa', {
            id_pe_mapa: this.props.idMapa,
            searchText: this.props.filter.nome ? this.props.filter.nome : '',
            filter: JSON.stringify(this.props.filter)
        });

        this.setState({
            projetos: projetos || [],
            filteredElements: [...projetos] || [], 
            orcamento,
            loading: false,
        });
    }

    filterElements = async () => {
        if(this.state.filter.view === 'projetos') {
            var projetos = await this.filterProjetos();
            this.setState({  filteredElements: [...projetos], view: this.state.filter.view, loading: false });
        }
        if(this.state.filter.view === 'marcos') {
            var marcos = await this.filterMarcos(this.state.projetos.reduce((acc, projeto) => {
                if(projeto.marcos && projeto.marcos.length > 0) {
                    return acc = [...acc, ...projeto.marcos];
                }
                return acc;
            }, []));
            this.setState({ loading: false, view: this.state.filter.view, filteredElements: marcos });
        }
    }
    
    filterMarcos = async (marcos) => {
        return new Promise((resolve, reject) => {
            if(this.state.filter.values && this.state.filter.values.length > 0) {
                marcos = [...marcos].filter(marco => {
                    return !this.state.filter.values.some((filter) => {
                        var field;

                        switch(filter.field) {
                            case 'responsavel':
                                field = marco.planoAcao ? marco.planoAcao.responsavel.id : this.state.projetos.find(projeto => projeto.canvas && projeto.canvas.id == marco.id_pe_projeto_canvas).responsavel.id;
                                break;
                            default:
                                field = marco[filter.field];
                                break;
                        }

                        if(field === undefined || field === null) return true;

                        if(filter.operation == '>') {
                            return !(field > filter.value)
                        } else if(filter.operation == '<') {
                            return !(field < filter.value)
                        } else 
                            return !(field == filter.value)
                    }   
                )}
            )}
            resolve([...marcos]);
        })
    }

    filterProjetos = async () => {
        return new Promise((resolve, reject) => {
            var projetos = [...this.state.projetos];
            if(this.state.filter.values && this.state.filter.values.length > 0) {
                projetos = projetos.filter(projeto => {
                    return !this.state.filter.values.some((filter) => {
                        var field;

                        switch(filter.field) {
                            case 'responsavel':
                                field = projeto.responsavel ? projeto.responsavel.id : null;
                                break;
                            case 'perspectiva':
                                field = projeto.perspectiva ? projeto.perspectiva.id : null;
                                break;
                            case 'objetivo':
                                field = projeto.objetivo ? projeto.objetivo.id : null;
                                break;
                            default:
                                field = projeto[filter.field];
                                break;
                        }

                        if(field === undefined || field === null) return true;

                        if(filter.operation == '>') {
                            return !(field > filter.value)
                        } else if(filter.operation == '<') {
                            return !(field < filter.value)
                        } else 
                            return !(field == filter.value)
                    })
                })
            }
            resolve([...projetos]);
        })
    }

    filter = (filter) => {
        this.setState({ filter, loading: true }, () => this.filterElements());   
    }   

    addCanvasCallback = (canvas, idProjeto) => {
        if(canvas && idProjeto) {
            var projetos = [...this.state.projetos];
            var projeto = projetos.find(projeto => projeto.id == idProjeto);
            if(projeto) {
                projeto.canvas = canvas;
                projeto.idCanvas = canvas.id;
                this.setState({ projetos }, () => this.filterElements());
            }
        }
    }

    renderList = () => {
        return (
            <RelatorioProjetosLista elements={this.state.filteredElements} loading={this.state.loading} isSmallScreen={this.props.isSmallScreen} filter={this.state.filter} view={this.state.view} addCanvasCallback={this.addCanvasCallback} />
        )
    }

    renderTable = () => {
        return null;
    }

    renderTimeline = () => {
        return null;
    }

    handleTipoVisaoTabs = (tab) => {
        this.setState({ activeTipoVisaoTab: tab.name });
    }

    handleTimelineTabChange = (tab) => {
        this.setState({ activeTabTimeline: tab.name });
    }

    renderContent = () => {

        const tabs = [
            { name: 'lista', title: 'Lista' },
            { name: 'tabela', title: 'Tabela' },
            { name: 'timeline', title: 'Timeline' },
        ];

        const timelineTabs = [
            { name: "ano", title: "Ano" },
            { name: 'mes', title: 'Mês' },
            { name: 'semana', title: 'Semana' },
        ];

        return(
        <>
            <div style={{...EssentialStyle.rowSpaceBetween, height: this.state.activeTipoVisaoTab == 'timeline' && this.props.isSmallScreen ? 90 : 40, minHeight: 40, backgroundColor: SessionHelper.getBackgroundColor(), width: "100%", position: "sticky", left: 0, flexWrap: "wrap", gap: 10}}>
                {/* {this.state.displayContent ? 
                    <Tabs 
                        isSmallScreen={this.props.isSmallScreen}
                        tabs={tabs}
                        activeTab={this.state.activeTipoVisaoTab}
                        onTabChange={this.handleTipoVisaoTabs}
                        style={{placeSelf: "center", marginTop: 0}}
                        tabStyle={{width: this.props.isSmallScreen ? '28vw' : 90, maxWidth: 150, height: 30, padding: 0, alignItems: "center", justifyContent: "center"}}
                    />
                : 
                    <div></div>
                } */}
                <div style={{ ...EssentialStyle.rowFlexCenter, gap: 10, marginRight: 10, position: 'sticky', left: "calc(100%)"}}>
                    {/* {this.state.activeTipoVisaoTab == 'timeline' && this.state.displayContent &&
                        <Tabs 
                            isSmallScreen={this.props.isSmallScreen}
                            tabs={timelineTabs}
                            activeTab={this.state.activeTabTimeline}
                            onTabChange={this.handleTimelineTabChange}
                            style={{placeSelf: "right", marginTop: 0}}
                            tabStyle={{width: this.props.isSmallScreen ? '28vw' : 90, maxWidth: 150, height: 30, padding: 0, alignItems: "center", justifyContent: "center"}}
                        />
                    } */}
                    <DefaultButton 
                        leftIcon={!this.state.displayContent ? <FontAwesomeIcon icon={faChevronDown} /> : <FontAwesomeIcon icon={faChevronUp} />}
                        color={"transparent"}
                        textColor={Colors.dark}
                        onClick={() => this.setState({ displayContent: !this.state.displayContent })}
                        style={{ width: 30, height: 30, alignItems: "center", justifyContent: "center", padding: 0 }}
                    />
                </div>
            </div>
            <div style={{...EssentialStyle.columnStart, borderRadius: 8, maxHeight: this.state.displayContent ? "unset" : 40 , flex: 1, backgroundColor: SessionHelper.getBackgroundColor(), transition: "all 0.5s ease"}}>
                <Collapse in={this.state.displayContent} style={{transition: "all 0.5s ease", height: "calc(100% - 40px)", maxHeight: "calc(100% - 40px)", width: "100%"}}>
                    <div>
                        {!this.props.loading ? 
                            <div>
                                {
                                this.state.activeTipoVisaoTab == 'lista' ? 
                                    this.renderList() 
                                : this.state.activeTipoVisaoTab == 'tabela' ? 
                                    this.renderTable() 
                                : 
                                    this.renderTimeline()
                                }
                            </div>
                        :   
                            <div style={{ ...EssentialStyle.columnCenter, padding: 10, width: '100%', marginTop: 10, height: "calc(100% - 40px)" }}>
                                <DefaultLoader />
                            </div>
                        }
                    </div>
                </Collapse>
            </div>
        </>
        )
    }

    render() {
        return (
        <div>
            <div className={"relatorio-projetos"} style={{...EssentialStyle.columnStart, height: `calc(100vh - ${this.props.headerSize}px)`, width: "100%", gap: 2}}>
                {this.props.loading ? 
                    <div style={{ ...EssentialStyle.columnCenter, padding: 10, width: '100%', marginTop: 10, height: "100%" }}>
                        <DefaultLoader />
                    </div> 
                : 
                <>
                    <div style={{maxHeight:"100%", width: "100%", overflowX: "auto", marginTop: 15}}>
                        <RelatorioProjetosStatus projetos={this.state.projetos} isSmallScreen={this.props.isSmallScreen} filterCallback={this.filter} filter={this.state.filter} view={this.state.view} orcamento={this.state.orcamento}/>
                        {this.props.idMapa != null && <RelatorioProjetosVinculos projetos={this.state.projetos} isSmallScreen={this.props.isSmallScreen} filterCallback={this.filter} filter={this.state.filter} view={this.state.view}/>}
                        <RelatorioProjetosColaboradores projetos={this.state.projetos} isSmallScreen={this.props.isSmallScreen} filterCallback={this.filter} filter={this.state.filter} view={this.state.view}/>
                        {/* <PlanoAcaoRelatorioDetails planos={this.props.planos} isSmallScreen={this.props.isSmallScreen} filterCallback={this.filter} filter={this.state.filter}/>
                        {!this.props.parceiro ? <PlanoAcaoRelatorioColaboradores planos={this.props.planos} isSmallScreen={this.props.isSmallScreen} filterCallback={this.filter} filter={this.state.filter}/> : <PlanoAcaoRelatorioParceiros planos={this.props.planos} isSmallScreen={this.props.isSmallScreen} filterCallback={this.filter} filter={this.state.filter}/>} */}
                        {this.renderContent()}
                    </div>
                </>
                }
            </div>
        </div>
        );
    }
}