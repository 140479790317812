import Sig from "../../api/Sig";
import moment from "moment";

import Colors from "../../constants/Colors";
import EssentialStyle from "../../style/EssentialStyle";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown, faChevronUp, faEquals } from "@fortawesome/free-solid-svg-icons";

const GMR = 1;
const PCO = 3;
const AC = 4;
const ANALISE = 5;
const FEEDBACK = 6;
const MARCO = 7;
const DIAGNOSTICO = 8;
const DIAGNOSTICO_NODE = 9;

export default class PlanoAcaoHelper {

    static getEtapaStatus() {
        return [
            { value: 0, label: 'Não Iniciada', backgroundColor: Colors.planoAcao.status.backgroundColor['Não Iniciado'], color: Colors.planoAcao.status.color['Não Iniciado'] },
            { value: 1, label: 'Em Desenvolvimento', backgroundColor: Colors.planoAcao.status.backgroundColor['Em Desenvolvimento'], color: Colors.planoAcao.status.color['Em Desenvolvimento'] },
            { value: 2, label: 'Cancelada', backgroundColor: Colors.planoAcao.status.backgroundColor['Cancelado'], color: Colors.planoAcao.status.color['Cancelado'] },
            { value: 3, label: 'Concluída', backgroundColor: Colors.planoAcao.status.backgroundColor['Concluído'], color: Colors.planoAcao.status.color['Concluído'] },
        ];
    }

    static getEtapaPrioridades() {
        return [
            { value: 3, label: 'Urgente' },
            { value: 2, label: 'Alta' },
            { value: 1, label: 'Média' },
            { value: 0, label: 'Baixa' },
        ];
    }

    static getPrioridadeIcon() {
        return {
            'Baixa': <FontAwesomeIcon icon={faChevronDown} style={{ color: Colors.homePage.atividades.baixa }} />,
            'Média': <FontAwesomeIcon icon={faEquals} style={{ color: Colors.homePage.atividades.media }} />,
            'Alta': <FontAwesomeIcon icon={faChevronUp} style={{ color: Colors.homePage.atividades.alta }} />,
            'Urgente': <div style={{ ...EssentialStyle.columnCenter, color: Colors.homePage.atividades.urgente }}><FontAwesomeIcon icon={faChevronUp} style={{ marginBottom: -8 }} /><FontAwesomeIcon icon={faChevronUp} /></div>
        }
    }

    static getVinculosTagsConfig() {
        return [
            { color: Colors.planoAcao.vinculo.backgroundColor[1], text: `GMR`, description: `Gestão de Metas e Resultados`, key: 1, textColor: Colors.light },
            { color: Colors.planoAcao.vinculo.backgroundColor[3], text: `PCO`, description: `Pesquisa Organizacional`, key: 3, textColor: Colors.light },
            { color: Colors.planoAcao.vinculo.backgroundColor[4], text: `AC`, description: `Avaliação de Competências`, key: 4, textColor: Colors.light },
            { color: Colors.planoAcao.vinculo.backgroundColor[5], text: `ANÁLISE`, description: `Análise de Problema`, key: 5, textColor: Colors.dark },
            { color: Colors.planoAcao.vinculo.backgroundColor[6], text: `FEEDBACKS`, description: `Feedbacks`, key: 6, textColor: Colors.light },
            { color: Colors.planoAcao.vinculo.backgroundColor[7], text: `MARCO`, description: `Marco de Projeto`, key: 7, textColor: Colors.light },
            { color: Colors.planoAcao.vinculo.backgroundColor[8], text: `DIAGNÓSTICO`, description: `Resultado de Diagnóstico`, key: 8, textColor: Colors.light },
        ];
    }

    static getPossiveisVinculos = async (tipoPlano = null, temVinculoAC = null) => {
        return await Sig.request("GET", "/planoAcao/getPossiveisVinculosPlanoAcao", { tipoPlano, temVinculoAC });
    }

    // Campos necessários no FRONTEND
    static getVinculoCampos(tipo) {

        const currentMonth = moment().month(); // Pega o mês atual (0-11)

        switch (parseInt(tipo)) {
            case GMR:
                return {
                    id_processo: null,
                    id_gmr_painel: null,
                    id_indicador: null,
                    meta: null,
                    data_inicio: moment().subtract(1, 'months').toDate(),
                    data_fim: currentMonth === 11 ? moment().add(1, 'year').month(11).endOf('month').toDate() : moment().month(11).endOf('month').toDate(),
                    disableDelete: false,
                }
            case PCO:
                return {
                    id_processo: null,
                    id_pco_pergunta: null,
                    meta: null,
                    disableDelete: false,
                }
            case AC:
                return {
                    id_processo: null,
                    id_comportamento: null,
                    disableDelete: false,
                    estruturaComportamento: null,
                }
            case ANALISE:
                return {
                    id_processo: null,
                    id_processo_analise: null,
                    id_analise_problema_acao: null,
                    meta: null,
                    data_inicio: moment().subtract(1, 'months').toDate(),
                    data_fim: currentMonth === 11 ? moment().add(1, 'year').month(11).endOf('month').toDate() : moment().month(11).endOf('month').toDate(),
                    resultado: null,
                    disableDelete: false,
                }
            case FEEDBACK:
                return {
                    id_feedback: null,
                    id_assunto: null,
                    disableDelete: true,
                }
            case MARCO:
                return {
                    id_pe_projeto: null,
                    id_pe_projeto_marco: null,
                    disableDelete: true,
                }
            case DIAGNOSTICO:
                return {
                    id_relatorio_recomendacao: null,
                    id_diagnostico_periodo_questionario_node: null,
                    id_diagnostico_avaliacao: null,
                    disableDelete: false,
                    disableAlter: false,
                }
            default:
                return {
                    id_processo: null,
                    id_gmr_painel: null,
                    id_indicador: null,
                    meta: null,
                    data_inicio: moment().toDate(),
                    data_fim: currentMonth === 11 ? moment().add(1, 'year').month(11).endOf('month').toDate() : moment().month(11).endOf('month').toDate(),
                    disableDelete: false,
                }
        }
    }

    // Campos obrigatórios para enviar ao BACKEND
    static getVerifyVinculoCampos(tipo = null, tipoAnalise = null) {

        // retorna os campos obrigatórios do Plano/PDI
        if (tipo == null) {
            return {
                obrigatory: [
                    "name",
                    "type",
                    "responsable",
                    "tipoProgresso",
                ],
                optional: [
                    "evaluator",
                    "participants",
                    "tags",
                    "nonCompliance",
                    "rootCause",
                    "description",
                ]
            }
        }

        // retorna os campos obrigatórios de cada vinculo
        switch (parseInt(tipo)) {
            case GMR:
                return [
                    "id_processo",
                    "id_gmr_painel",
                    "id_indicador",
                    "meta",
                    "data_inicio",
                    "data_fim"
                ]
            case PCO:
                return [
                    "id_processo",
                    "id_pco_pergunta",
                ]
            case AC:
                return [
                    "id_processo",
                    "id_comportamento",
                ]
            case ANALISE:
                if (parseInt(tipoAnalise) == 1 || parseInt(tipoAnalise) == 2) {
                    return [
                        "id_processo",
                        "id_analise_problema_acao",
                        "meta",
                        "data_inicio",
                        "data_fim",
                    ]
                } else if (parseInt(tipoAnalise) == 3) {
                    return [
                        "id_processo",
                        "id_analise_problema_acao",
                    ]
                } else {
                    return [
                        "id_analise_problema_acao",
                    ]
                }
            case FEEDBACK:
                return [
                    "id_feedback",
                    "id_assunto"
                ]
            case MARCO:
                return [
                    "id_pe_projeto_marco"
                ]
            case DIAGNOSTICO:
                return [
                    "id_relatorio_recomendacao",
                    "id_diagnostico_periodo_questionario_node",
                    "id_diagnostico_avaliacao"
                ]
            case DIAGNOSTICO_NODE:
                return [
                    "id_diagnostico_periodo_questionario_node",
                    "id_diagnostico_avaliacao"
                ]
            default:
                return [
                    "id_processo",
                    "id_gmr_painel",
                    "id_indicador",
                    "meta",
                    "data_inicio",
                    "data_fim"
                ]
        }
    }

    static getCampoLabels($campo) {
        let map = {
            id_processo: "Processo",
            id_gmr_painel: "Painel",
            id_indicador: "Indicador",
            meta: "Meta",
            data_inicio: "Data Início",
            data_fim: "Data Fim",
            id_pco_pergunta: "Pergunta",
            id_comportamento: "Comportamento",
            id_processo_analise: "Processo de Análise",
            id_analise_problema_acao: "Ação",
            resultado: "Resultado",
            id_feedback: "Feedback",
            id_assunto: "Assunto",
            id_pe_projeto: "Projeto",
            id_pe_projeto_marco: "Marco",
            id_relatorio_recomendacao: "Relatório de Recomendação",
            id_diagnostico_periodo_questionario_node: "Item de Diagnóstico",
            id_diagnostico_avaliacao: "Avaliação",
        }
        if (!map[$campo]) {
            return $campo;
        } else {
            return map[$campo];
        }
    }

    static getGmrPaineisDisponiveis = async (id_processo) => {
        return await Sig.request("GET", "/planoAcao/getGmrPaineisDisponiveis", { id_processo });
    }

    static getIndicadoresDisponiveis = async (id_processo, id_gmr_painel) => {
        return await Sig.request("GET", "/planoAcao/getIndicadoresDisponiveis", { id_processo, id_gmr_painel });
    }

    static getResultIndicadorPeriodo = async (id_indicador, id_gmr_painel, id_processo, data) => {
        return await Sig.request("GET", "/planoAcao/getResultadoIndicador", { id_indicador, id_gmr_painel, id_processo, data: moment(data).unix() });
    }

    static getPerguntas = async (id_processo) => {
        return await Sig.request("GET", "/planoAcao/getPerguntas", { id_processo });
    }

    static getResultadoPco = async (id_processo, id_pergunta) => {
        return await Sig.request("GET", "/planoAcao/getResultadoPco", { id_processo, id_pergunta });
    }

    static getAcoes = async (id_processo) => {
        return await Sig.request("GET", "/planoAcao/getAcoes", { id_processo });
    }

    static getResultadoAnalise = async (id_processo_analise, data_inicio = null) => {
        return await Sig.request("GET", "/planoAcao/getResultadoAnalise", { id_processo_analise, data_inicio: data_inicio ? moment(data_inicio).unix() : null });
    }

    static getProjetoMarco = async (id_pe_projeto_marco) => {
        return await Sig.request("GET", "/planoAcao/getProjetoMarco", { id_pe_projeto_marco });
    }

    static getRecomendacaoRelatorio = async (id_relatorio_recomendacao = null, id_diagnostico_periodo_questionario_node = null, id_diagnostico_avaliacao = null, lang = 1) => {
        return await Sig.request("GET", "/planoAcao/getRecomendacaoRelatorio", { id_relatorio_recomendacao, id_diagnostico_periodo_questionario_node, id_diagnostico_avaliacao, lang });
    }

    static getDiagnostico = async (id_diagnostico_periodo_questionario_node, id_diagnostico_avaliacao, lang = 1) => {
        return await Sig.request("GET", "/planoAcao/getDiagnostico", { id_diagnostico_periodo_questionario_node, id_diagnostico_avaliacao, lang });
    }

    static hasWarningMetodologia = async (codigo) => {
        let response = await Sig.request("GET", "/planoAcao/hasWarningMetodologia", { codigo });
        return response.warning;
    }

}