import React, { Component } from 'react';
import './UserTooltip.css';
import Sig from '../../../api/Sig';
import DefaultLoader from '../DefaultLoader';
import { Image } from 'react-bootstrap';
import SessionHelper from '../../../helper/SessionHelper';
import Colors from '../../../constants/Colors';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope, faBuilding, faLocationDot } from '@fortawesome/free-solid-svg-icons';
import EssentialStyle from '../../../style/EssentialStyle';

export default class UserCard extends Component {

    state = {
        user: {},
        loading: true,
        imageFailedToLoad: false,
    }

    async componentDidMount() {
        await this.loadUser();
    }

    async componentDidUpdate(prevProps) {
        if (prevProps.id !== this.props.id) {
            await this.loadUser();
        }
    }

    async loadUser() {
        this.setState({ loading: true });

        if(!this.props.id) {
            this.setState({ user: { nome: 'Usuário Excluído' }, loading: false });
            return;
        }

        let user = await Sig.request('GET', `config/colaborador/getColaboradorCard`, { id: this.props.id }) || {};

        this.setState({ user, loading: false });
    }

    getUserImage() {
        let size = this.props.size ? this.props.size : 64;
        return (
            <Image
                style={{
                    height: size, width: size,
                    border: this.props.selected ? '2px solid ' + SessionHelper.getColor() : '2px solid white',
                    borderRadius: '50%',
                    opacity: (this.props.editing && this.props.oculto) ? 0.5 : 1
                }}
                roundedCircle
                src={`${document.referrer}/upload/avatar/${this.state.user.avatar}`}
                onError={() => this.setState({ imageFailedToLoad: true })}
            />
        );
    }

    renderPlaceholder() {

        let split = this.state.user.nome.split(' ');
        let size = 64;
        let fontSize = 28;

        return (

            <div style={{
                height: size,
                width: size,
                minWidth: size,
                border: this.props.selected ? '2px solid ' + SessionHelper.getColor() : '2px solid white',
                borderRadius: '50%',
                backgroundColor: this.props.editing && this.props.oculto ? Colors.homePage.extraLightGrey : 'grey',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                color: 'white',
                fontWeight: 'bold',
                fontSize: fontSize
            }}>
                {(`${split[0][0]}${split[split.length - 1] ? split[split.length - 1][0] : ''}`).toUpperCase()}
            </div>
        )
    }

    render() {

        if(this.state.loading) {
            return (
                <div className='user-card-tooltip' style={{...EssentialStyle.rowFlexCenter}}>
                    <DefaultLoader/>
                </div>
            );
        }

        return (
            <div className='user-card-tooltip'>
                <div className='user-card-header' style={{backgroundColor: SessionHelper.getSecondaryColor(), color: Colors.white}}>
                    <div className='user-card-header-avatar'>
                        {!this.state.imageFailedToLoad && this.state.user.avatar ? this.getUserImage() : this.renderPlaceholder()}
                    </div>
                    <div className='user-card-header-text'>
                        <h4>{this.state.user.nome}</h4>
                        <p>{this.state.user.funcao}</p>
                    </div>
                </div>
                <div className='user-card-body'>
                    <p title={this.state.user.email}>
                        <FontAwesomeIcon style={{width: 20}} icon={faEnvelope} /> 
                        {this.state.user.email ? this.state.user.email : '-'}
                    </p>
                    <p title={this.state.user.empresa}>
                        <FontAwesomeIcon style={{width: 20}} icon={faBuilding} /> 
                        {this.state.user.empresa ? this.state.user.empresa : '-'}
                    </p>
                    <p title={this.state.user.endereco}>
                        <FontAwesomeIcon style={{width: 20}} icon={faLocationDot} />
                        {this.state.user.endereco ? this.state.user.endereco : '-'}
                    </p>
                </div>
            </div>
        );
    }
}