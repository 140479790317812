import React from "react";
import ReactDOM from "react-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft, faChevronRight, faEyeSlash, faEye } from "@fortawesome/free-solid-svg-icons";

import { Dropdown } from "react-bootstrap";
import DefaultButton from "../../../../tools/DefaultButton";
import Colors from "../../../../../constants/Colors";
import EssentialStyle from "../../../../../style/EssentialStyle";
import Constants from "../../../../../constants/Api";
import LayoutHelper from "../../../../../helper/LayoutHelper";
import Sig from "../../../../../api/Sig";
import EllipsisText from "../../../../tools/EllipsisText";

const gateway = Constants.getSigEndPoint();

export default class ParceirosSummary extends React.Component {
    state = {
        loading: true,
        loadingAdditional: false,
        parceiros: [],
        parceiroSelected: [],
        hoverParceiro: null,
        time: false,
        notas: [],
        opacity: 0,
        numberParceiros: this.props.numberParceiros || 10,
        orderTypes: {
            alfabetica: 'Ordem Alfabética',
            maiorDiagnostico: 'Maior ao menor Diagnóstico',
            menorDiagnostico: 'Menor ao maior Diagnóstico'
        },
        selectedOrder: localStorage.getItem('parceiros_order_option') || 'alfabetica',
        transform: 'translateY(+100%)',
        blocked: true,
        isSmallScreen: LayoutHelper.isSmallScreen()
    }

    async componentDidMount() {
        window.addEventListener('resize', this.verifyScreen);
        await this.loadData();
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.verifyScreen);
    }

    verifyScreen = () => {
        this.setState({ isSmallScreen: LayoutHelper.isSmallScreen() });
    }

    loadData = async () => {
        this.setState({ loading: true, loadingAdditional: false, transform: 'translateY(0)', opacity: 1 });

        let time = new Date().getTime();
        var subordinados, processoAtual;

        this.setState({ time }, async () => {
            let parceiros = await Sig.request('GET', 'config/colaborador/getColaboradorParceiros', { id_colaborador: this.props.userId });

            if (time === this.state.time || !this.state.time) {
                this.setState({ parceiros: parceiros, loading: false, loadingAdditional: true, selectedOrder: 'alfabetica' }, () => {
                    this.orderParceiros();
                    this.loadNotaParceiros(parceiros);
                });
            }
        });
    }

    loadNotaParceiros(parceiros){
        var count = 0;
        // parceiros.forEach((element) => {
        //     Sig.request('POST', 'inicio/getNotasDiagnostico', { id_empresa: element.id, id_colaborador: this.props.userId }).then((response) => {
        //         // this.setState(previousState => ({ notas: [...previousState.notas, response] }));
        //         // if(++count === subordinados.length){
        //         //     this.setState({ loadingAdditional: false });
        //         // }
        //     }).catch((error) => {
        //         // let nota = {idColaborador: element.id, resultadoGD: null, resultadoGMR: null, resultadoMedia: null, 
        //         //             processoGD: null, processoGMR: null, paineisGMR: null};
        //         // this.setState(previousState => ({ notas: [...previousState.notas, nota] }));
        //         // if(++count === subordinados.length){
        //         //     this.setState({ loadingAdditional: false });
        //         // }
        //     });
        // });
    }

    orderParceiros() {
        this.setState({ loading: true });
        let { parceiros } = this.state;

        if (parceiros.length > 1) {
            switch (this.state.selectedOrder) {
                case 'alfabetica':
                    parceiros = parceiros.sort((a, b) => a.nome.localeCompare(b.nome));
                    break;
                case 'maiorGMR':
                    parceiros = parceiros.sort((a, b) => {
                        let aValue = parseFloat(this.state.notas.find(n => n.idColaborador == a.id)?.resultadoDiagnostico?.resultado);
                        let bValue = parseFloat(this.state.notas.find(n => n.idColaborador == b.id)?.resultadoDiagnostico?.resultado);

                        if ((isNaN(aValue) && aValue !== 0) && (isNaN(bValue) && bValue !== 0)) return 0; // ambos são nulos, então são iguais
                        if (isNaN(aValue) && aValue !== 0) return 1; // a é nulo, então b vem primeiro
                        if (isNaN(bValue) && bValue !== 0) return -1; // b é nulo, então a vem primeiro

                        return bValue - aValue; // se nenhum é nulo, classifique normalmente
                    });
                    break;
                case 'menorGMR':
                    parceiros = parceiros.sort((a, b) => {
                        let aValue = parseFloat(this.state.notas.find(n => n.idColaborador == a.id)?.resultadoDiagnostico?.resultado);
                        let bValue = parseFloat(this.state.notas.find(n => n.idColaborador == b.id)?.resultadoDiagnostico?.resultado);

                        if ((isNaN(aValue) && aValue !== 0) && (isNaN(bValue) && bValue !== 0)) return 0; // ambos são nulos ou zero, então são iguais
                        if (isNaN(aValue) && aValue !== 0) return 1; // a é nulo ou zero, então b vem primeiro
                        if (isNaN(bValue) && bValue !== 0) return -1; // b é nulo ou zero, então a vem primeiro

                        return aValue - bValue; // se nenhum é nulo ou zero, classifique do menor para o maior
                    });
                    break;
            }
        }

        this.setState({ parceiros, loading: false });
    }

    handleOrderChange(key) {
        this.setState({ selectedOrder: key }, () => {
            this.orderParceiros();
        });
        window.localStorage.setItem('parceiros_order_option', key);
    }

    renderTitle() {
        const portalTarget = document.getElementById('root');
        return (
            <div style={{ ...EssentialStyle.cardTitle, height: 40, borderBottom: `1px solid ${Colors.homePage.line}` }}>
                <span style={{ ...EssentialStyle.titleBoxHomePage, whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>{"Parceiros"}</span>
                <div style={{ ...EssentialStyle.rowFlexStart }}>

                    <DefaultButton
                        leftIcon={this.state.blocked ? <FontAwesomeIcon icon={faEye} /> : <FontAwesomeIcon icon={faEyeSlash} />}
                        color={'transparent'}
                        textColor={'black'}
                        style={{ marginRight: 5, display: !this.state.expandedMinhaEquipe ? 'none' : '' }}
                        onClick={() => { this.setState({ blocked: !this.state.blocked }) }}
                        disabled={this.state.loading || this.state.loadingAdditional}
                    />

                    <Dropdown style={{ display: 'none' }}>
                        <Dropdown.Toggle
                            onChange={() => { }}
                            value={this.state.selectedOrder}
                            style={{
                                backgroundColor: Colors.secondaryButton,
                                color: Colors.dark,
                                padding: '3px 6px',
                                borderColor: 'black',
                            }}
                            disabled={this.state.loading || this.state.loadingAdditional}
                        >
                            <span style={{ marginRight: 5, fontSize: 12 }}>{this.state.orderTypes[this.state.selectedOrder]}</span>
                        </Dropdown.Toggle>
                        {ReactDOM.createPortal(<Dropdown.Menu>
                            {Object.keys(this.state.orderTypes).map(key => (
                                <Dropdown.Item
                                    key={`order-opt-${key}`}
                                    onClick={() => { this.handleOrderChange(key) }} style={key === this.state.selectedOrder ? { backgroundColor: Colors.dark } : {}}
                                    active={key === this.state.selectedOrder}
                                >
                                    <span style={{ fontSize: 12 }}>{this.state.orderTypes[key]}</span>
                                </Dropdown.Item>
                            ))}
                        </Dropdown.Menu>
                            , portalTarget)}
                    </Dropdown>

                </div>
            </div>
        )
    }

    renderLoadingPlaceholder() {
        let size = 50;

        return (
            <div>
                <div style={{
                    height: size,
                    width: '100%',
                    borderRadius: 5,
                    background: `linear-gradient(270deg, ${Colors.homePage.lightGrey}, ${Colors.homePage.extraLightGrey}, ${Colors.homePage.lightGrey})`,
                    backgroundSize: '200% 200%',
                    animation: 'gradient 2s ease infinite',
                }}>
                </div>

                <style jsx>{`
                @keyframes gradient {
                    0% {background-position: 0% 50%;}
                    50% {background-position: 100% 50%;}
                    100% {background-position: 0% 50%;}
                }
                `}</style>
            </div>
        );
    }

    renderLoading() {
        return (
            <div style={{ ...EssentialStyle.columnStart, width: '100%' }}>

                {Array.from({ length: this.state.numberParceiros }).map((elem, index) => (
                    <div key={`p-plhdr-${index}`} style={{ marginTop: 5, width: '100%' }}>
                        {this.renderLoadingPlaceholder()}
                    </div>
                ))}
            </div>
        );
    }

    renderParceiro(parceiro) {
        return (
            <div
                key={`sub-${parceiro.id}`}
                style={{
                    ...(this.state.expandedMinhaEquipe ? EssentialStyle.rowSpaceBetween : EssentialStyle.rowFlexCenter),
                    width: 'calc(100% - 20px)',
                    marginTop: 5,
                    borderRadius: 5,
                    padding: '5px 3px',
                    margin: '5px 10px 0px 10px',
                    cursor: 'not-allowed',
                    backgroundColor: this.state.parceiroSelected.includes(parceiro.id) ? Colors.homePage.lightGrey : 'transparent',
                    boxShadow: this.state.hoverParceiro === parceiro.id ? `0px 0px 5px 1px ${Colors.homePage.lightGrey}` : 'none',
                }}
                onClick={() => {  }}
                onMouseEnter={() => { this.setState({ hoverParceiro: parceiro.id }) }}
                onMouseLeave={() => { this.setState({ hoverParceiro: null }) }}
            >
                <div style={{ ...EssentialStyle.rowFlexStart, width: '100%', height: 36 }}>
                    <EllipsisText text={parceiro.nome}/>
                </div>
            </div>
        )
    }

    renderParceiros() {
        return (
            this.state.parceiros.length > 0 ?
                <div style={{ width: 'calc(100% + 20px)', margin: '0px -10px', height: 'calc(100% - 45px)', overflow: 'auto' }}>
                    {this.state.parceiros.map((parceiro) => (
                        this.renderParceiro(parceiro)
                    ))}
                </div>
                : <div style={{ ...EssentialStyle.columnCenter, width: '100%', margin: '0px -10px', height: 'calc(100% - 45px)', overflow: 'auto' }}>
                    <img
                        src={`${gateway}/img/undraw/undraw_team_up.svg`}
                        alt="Duas pessoas sentadas aguardando dados em telas de computadores"
                        style={{
                            height: '60%',
                            marginBottom: 10,
                        }}
                    />
                    <span style={{ ...EssentialStyle.titleBoxHomePage }}>Nenhum parceiro encontrado</span>
                </div>
        )
    }

    renderContent() {
        return (
            <div style={{
                ...EssentialStyle.columnStart,
                width: '100%',
                height: '100%',
            }}>
                {this.renderTitle()}
                {this.state.loading ? this.renderLoading() : this.renderParceiros()}
            </div>
        );
    }

    render() {
        return (
            <div style={{ ...EssentialStyle.rowFlexStart, width: '100%', height: '100%' }}>
                <div style={{
                    ...EssentialStyle.rowSpaceBetween,
                    transform: this.state.transform,
                    opacity: this.state.opacity,
                    transition: 'opacity .3s ease-in-out, transform .6s ease-in-out',
                    width: '100%',
                    height: '100%',
                    padding: '10px 10px 5px 10px',
                }}>
                    {this.renderContent()}
                </div>
            </div>
        )
    }
}