import { faCheckCircle, faTimes, faAdd, faTimesCircle, faPen, faInfinity } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Form, Row, Col, InputGroup } from "react-bootstrap";
import BarraDemonstracao from "./BarraDemonstracao";
import { toast } from "react-toastify";
import Colors from "../../constants/Colors";
import EssentialStyle from "../../style/EssentialStyle";
import DefaultButton from "./DefaultButton";
import { TwitterPicker } from 'react-color';

export default class IntervalSelector extends React.Component {
    state = {
        loading: true,
        intervals: this.props.value ? this.props.value : [
            { from: 0, to: 85, color: Colors.metas[9], infinito: 0 },
            { from: 85, to: 95, color: Colors.metas[5], infinito: 0 },
            { from: 95, to: 100, color: Colors.metas[3], infinito: 0 },
            
        ],
        from: {
            invalid: false,
            value: null
        },
        to: {
            invalid: false,
            value: null
        },
        canAdd: false,
        color: Colors.metas[0],
        infinito: 0,
        addingNew: false,
        edit: null,
        editFrom: 0,
        editTo: 0,
        editInfinito: 0,
        editColor: '#000000',
    }

    async componentDidMount() {

        this.props.callback(this.state.intervals);
        this.setState({ loading: false });
    }

    validateAndInsertValue(field, value) {
        let opposite = (field == "from" ? "to" : "from");

        let canAdd = false;
        let invalid = false;

        if (!value && parseInt(value) !== 0) {
            invalid = "Informe um valor de 0 a 100";
        }

        if (value < 0) {
            invalid = `Intervalo não pode ser menor que zero`;
        }

        if (isNaN(parseInt(value))) {
            invalid = "Valor deve ser númérico";
        }

        if (value == this.state[opposite].value) {
            invalid = `"De" e "Até" não podem ser iguais`;
        }

        if (this.state[opposite] && this.state[opposite].value) {
            if (field == "to" && parseInt(value) < parseInt(this.state[opposite].value)) {
                invalid = `"De" tem que ser menor do que "Até"`;
            }
        }
        if (!invalid && !this.state[opposite].invalid && value && (this.state[opposite].value || this.state.infinito == 1)) {
            canAdd = true;
        }
        
        this.setState({ [field]: { invalid, value }, canAdd });
    }

    validateInfinitoInsertValue(){

        if(!this.state.from.invalid && this.state.from.value && this.state.infinito == 1) {
            this.setState({ to: { invalid: false }, canAdd: true });
        }

        if(this.state.infinito == 0 && this.state.to){
            this.setState({ canAdd: false });
        }
    }

    pushInterval() {
        if (!this.state.to.invalid && !this.state.from.invalid) {
            
            let intervals = [...this.state.intervals, { from: this.state.from.value, to: this.state.to.value, color: this.state.color, infinito: this.state.infinito}];

            let conflictedIntervals = false;

            intervals.sort((a, b) => (b.from - a.from));

            if (intervals.length > 1) {
                for (let i = 1; i < intervals.length; i++) {
                    if (parseFloat(intervals[i - 1].to) < parseFloat(intervals[i - 1].from) && (!intervals[i - 1].infinito)) {
                        conflictedIntervals = true;
                        break;
                    }
                }
            }

            if (conflictedIntervals) {
                toast.info('Intervalos não podem ter intersecções.');
                return;
            }

            if (intervals.length > 1) {
                let listTo = intervals.map((interval) => interval.from);
                let max = Math.max(...listTo);
                intervals = intervals.map((interval) => {
                    if (interval.from != max) {
                        if (interval.infinito == 1) {
                            interval.infinito = 0;
                            interval.to = this.state.from.value;
                        }
                    }
                    return interval;
                });

            }

            this.setState({
                intervals,
                addingNew: false,
                from: {
                    invalid: false,
                    value: null
                },
                to: {
                    invalid: false,
                    value: null
                },
                canAdd: false,
                color: Colors.metas[0],
                infinito: 0,
            }, () => { this.props.callback(intervals) });

        } else {
            toast.info('Informe um valor de 0 a 100 nos campos');
        }
    }

    validAddInfinito(){
        if (this.validOptionInfinito('add')){
            this.setState({ infinito: this.state.infinito == 1 ? 0 : 1 }, () => {
                this.validateInfinitoInsertValue()
            });
        }
    }

    validOptionInfinito(str){

        if (str == 'intervalo'){
            return this.state.infinito == 0;
        } else if (str == 'add') {
            if (this.state.intervals.length < 1) return true;
            if (this.state.intervals.map((interval) => interval.infinito).includes(1)) return false;
            return this.state.editInfinito == 0;
        }

    }

    addInterval() {
        return this.state.addingNew ?
            <div style={{ marginTop: 10, display: 'flex', flexDirection: 'column', height: 200, width: 605, alignContent: 'center', justifyContent: 'center' }}>
                <Row className="w-100" style={{...EssentialStyle.rowFlexStart}}>
                    
                    <Form.Group style={{ position: 'relative', width: 'calc(105px)', height: 95 }}>
                        <Form.Label>Cor</Form.Label>
                        <div onClick={(evt) => { evt.stopPropagation(); this.setState({ showPicker: !this.state.showPicker }) }} style={{ backgroundColor: this.state.color, width: 85, height: 40, borderRadius: 8, cursor: 'pointer', position: 'relative', marginRight: 20 }}/>
                        { this.state.showPicker ? <div style={{ position: 'absolute', bottom: -90, zIndex: 999 }}><TwitterPicker onChangeComplete={(color) => { this.setState({ color: color.hex }) }} width={350} color={this.state.color} colors={Colors.metas}/></div> : null }
                    </Form.Group>
                    
                    <Form.Group style={{width: '35%', height: 95}}>
                        <Form.Label>De</Form.Label>
                        <InputGroup>
                            <Form.Control pattern="[0-9]+" type="number" value={this.state.from.value} isInvalid={this.state.from.invalid} onChange={(event) => { this.validateAndInsertValue('from', event.target.value) }} />
                            <InputGroup.Text>%</InputGroup.Text>
                            <Form.Control.Feedback
                                type="invalid"
                                role="alert"
                                data-validity={!this.state.from.invalid}
                            >
                                {this.state.from.invalid}
                            </Form.Control.Feedback>
                        </InputGroup>
                    </Form.Group>

                    <Form.Group style={{width: '35%', height: 95}}>
                        <Form.Label>Até</Form.Label>
                        <InputGroup>
                            <Form.Control 
                                {...(this.state.infinito == 1 ? {} : {pattern: "[0-9]+", type: "number"})}
                                value={ this.state.infinito == 1 ? "Infinito" : this.state.to.value }
                                disabled={ !this.state.from.value || this.state.infinito == 1 } 
                                isInvalid={this.state.to.invalid} 
                                onChange={(event) => { this.validateAndInsertValue('to', event.target.value) }}/>
                            <InputGroup.Text>%</InputGroup.Text>
                            <Form.Control.Feedback
                                type="invalid"
                                role="alert"
                                data-validity={!this.state.to.invalid}
                            >
                                {this.state.to.invalid}
                            </Form.Control.Feedback>
                        </InputGroup>
                    </Form.Group>

                    <Form.Group style={{width: '10%', height: 95}}>
                        <Form.Label>Infinito</Form.Label>
                        <div>
                            <FontAwesomeIcon
                                onClick={() => {this.validAddInfinito()}}
                                icon={faInfinity}
                                style={{ cursor: 'pointer', boxShadow: '0 20px 75px rgba(0, 0, 0, 0.13)', paddingLeft: 10 }}
                                color={ this.validOptionInfinito('add') ? Colors.success : Colors.disabled}
                                fontSize={28}
                            />
                        </div>
                    </Form.Group>
                </Row>

                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                    <DefaultButton loading={this.state.loadingUpload} color={Colors.error} leftIcon={<FontAwesomeIcon icon={faTimes} />} style={{ width: '49%' }} title={'Cancelar'} onClick={() => { this.setState({ name: '', addingNew: false }) }} />
                    <DefaultButton loading={this.state.loadingUpload} disabled={!this.state.canAdd} color={Colors.success} leftIcon={<FontAwesomeIcon icon={faCheckCircle} />} style={{ width: '49%' }} title={'Adicionar'} onClick={() => { this.pushInterval() }} />
                </div>
            </div> :
            <div style={{ marginTop: 10, display: 'flex', flexDirection: 'column', width: 50, alignContent: 'center', alignItems: 'center', justifyContent: 'center' }}>
                <div onClick={() => { this.setState({ addingNew: true }) }} style={{ borderRadius: 5, cursor: 'pointer', display: 'flex', height: 50, width: 50, marginBottom: 10, backgroundColor: Colors.info, alignContent: 'center', alignItems: 'center', justifyContent: 'center' }}>
                    <FontAwesomeIcon icon={faAdd} color={Colors.background} fontSize={25} />
                </div>
                <Form.Label style={{ marginLeft: 5 }}>Novo</Form.Label>
            </div>;
    }

    removeInterval(interval) {
        let intervals = this.state.intervals;
        let index = intervals.indexOf(interval);

        if (index > -1) {
            intervals.splice(index, 1);
            this.setState({ intervals }, () => { this.props.callback(intervals) });
        }
    }

    isMax(interval) {

        let listTo = this.state.intervals.map((interval) => interval.from);
        let max = Math.max(...listTo);
        if (interval.from >= max) {
            return true;
        }
        return false;
    }

    changeInfinito () {
        if (this.validOptionInfinito('intervalo')) {
            let intervals = this.state.intervals;
            this.setState({ editInfinito: this.state.editInfinito == 1 ? 0 : 1 });
            if (this.state.infinito == 1) {
                this.setState({ editTo: 'Infinito' });
            } else {
                let soma = parseInt(this.state.editFrom) + 1;
                soma = soma < 100 ? 100 : soma;
                this.setState({ editTo: (parseFloat(this.state.editFrom) < parseFloat(intervals[this.state.edit].to)) ? ( intervals[this.state.edit].infinito == 0 ? intervals[this.state.edit].to : soma ) : soma});
            }
        }
    }

    renderInterval(interval, key) {
        return (
            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginTop: 10, marginBottom: 10, width: 'auto' }} key={key}>
                <div style={{ padding: 10, borderRadius: 5, backgroundColor: this.state.edit !== null && this.state.edit === key ? this.state.editColor : interval.color, height: 45, width: 85, marginRight: 20, border: '0.3px solid lightgrey', position: 'relative' }}>
                    { this.state.edit !== null && this.state.edit === key ? <div style={{ position: 'absolute', bottom: -110, zIndex: 999 }}><TwitterPicker onChangeComplete={(color) => { this.setState({ editColor: color.hex }) }} width={350} color={this.state.editColor} colors={Colors.metas}/></div> : null }
                </div>
                <div style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', backgroundColor: 'white', border: '0.3px solid lightgrey', padding: 10, borderRadius: 5, width: 500 }}>
                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
                        { this.state.edit !== null && this.state.edit === key ?
                        
                            <InputGroup>
                                <Form.Control pattern="[0-9]+" type="number" value={this.state.editFrom} onChange={(event) => { this.setState({ editFrom: event.target.value }) }} />
                            </InputGroup>
                        
                        : <Form.Label style={{ marginLeft: 5, marginBottom: 0, width: 30 }}>{ interval.from }</Form.Label> }
                        <div style={{ width: interval.infinito == 1 ? '55%' : '70%', display: 'flex', justifyContent: 'center' }}>
                            ← →
                        </div>
                        { this.state.edit !== null && this.state.edit === key ?
                        
                            <InputGroup>
                                <Form.Control 
                                    {...(this.state.editInfinito == 1 ? {} : {pattern: "[0-9]+", type: "number"})}
                                    value={ this.state.editInfinito == 1 ? "Infinito" : this.state.editTo }
                                    disabled={ this.state.editInfinito == 1 } 
                                    onChange={(event) => { this.setState({ editTo: event.target.value }) }}
                                />
                            </InputGroup>

                        : <Form.Label style={{ marginLeft: 5, marginBottom: 0, width: 30 }}>{ interval.infinito == 1 ? "Infinito" : interval.to }</Form.Label> }
                        { this.state.edit !== null && this.state.edit === key ?
                        
                            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                                
                                { this.isMax(interval) ? 
                                    <FontAwesomeIcon
                                        onClick={() => {this.changeInfinito()}}
                                        icon={faInfinity}
                                        style={{ cursor: 'pointer', boxShadow: '0 20px 75px rgba(0, 0, 0, 0.13)', marginRight: 10, paddingLeft: 40 }}
                                        color={this.validOptionInfinito('intervalo') ? Colors.success : Colors.disabled}
                                        fontSize={28}
                                    /> : ''
                                }
                                
                                <FontAwesomeIcon
                                    onClick={() => { 
                                        let intervals = this.state.intervals; 
                                        intervals[this.state.edit].from = this.state.editFrom; 
                                        intervals[this.state.edit].to = this.state.editTo; 
                                        intervals[this.state.edit].color = this.state.editColor;
                                        intervals[this.state.edit].infinito = this.state.editInfinito;
                                        this.setState({ intervals, edit: false, editFrom: 0, editTo: 0 }); 
                                    }}
                                    icon={faCheckCircle}
                                    style={{ cursor: 'pointer', boxShadow: '0 20px 75px rgba(0, 0, 0, 0.13)', marginRight: 10, paddingLeft: this.isMax(interval) ? 0 : 40 }}
                                    color={Colors.success}
                                    fontSize={28}
                                />

                                <FontAwesomeIcon
                                    onClick={() => { this.setState({ edit: null, editTo: 0, editFrom: 0 }) }}
                                    icon={faTimesCircle}
                                    style={{ cursor: 'pointer', boxShadow: '0 20px 75px rgba(0, 0, 0, 0.13)' }}
                                    color={Colors.error}
                                    fontSize={28}
                                />
                            </div>
                        
                        : 
                        
                            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                                <FontAwesomeIcon
                                    onClick={() => { this.setState({ edit: key, editColor: interval.color, editFrom: interval.from, editTo: interval.to, editInfinito: interval.infinito }) }}
                                    icon={faPen}
                                    style={{ cursor: 'pointer', boxShadow: '0 20px 75px rgba(0, 0, 0, 0.13)', marginRight: 10, paddingLeft: 40 }}
                                    color={Colors.success}
                                    fontSize={25}
                                />
                                <FontAwesomeIcon
                                    onClick={() => { this.removeInterval(interval) }}
                                    icon={faTimesCircle}
                                    style={{ cursor: 'pointer', boxShadow: '0 20px 75px rgba(0, 0, 0, 0.13)' }}
                                    color={Colors.error}
                                    fontSize={28}
                                />
                            </div>
                        }
                    </div>
                </div>
            </div>
        );
    }

    renderBarraDemonstracao() {
        return (
            <BarraDemonstracao intervals={ this.state.intervals } width={'605px'}/>
        );
    }

    renderIntervals() {
        return (
            <div>
                {this.state.intervals.map((interval, key) => (this.renderInterval(interval, key)))}
                {this.renderBarraDemonstracao()}
                {this.addInterval()}
            </div>
        );
    }

    render() {
        return this.state.loading ? null : (
            <div style={{ minWidth: '80%', marginBottom: 40 }} onClick={() => { this.setState({ showPicker: this.state.showPicker ? false : this.state.showPicker }) }}>
                <Form.Label>Faixas:*</Form.Label>
                {this.renderIntervals()}
            </div>
        );
    }
}