import React from "react";
import LoadingPage from "../LoadingPage";
import EssentialStyle from "../../style/EssentialStyle";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash, faChevronLeft, faChevronDown, faChevronUp, faCog, faMinus, faBars, faFilter, faEnvelope, faInfoCircle, faQuestionCircle, faCheckCircle, faTimesCircle, faList, faCalendarAlt, faCertificate } from "@fortawesome/free-solid-svg-icons";
import { toast } from 'react-toastify';
import Colors from "../../constants/Colors";
import Sig from "../../api/Sig";
import DefaultButton from "../../components/tools/DefaultButton";
import TooManyTags from "../../components/tools/TooManyTags";
import { Collapse, Form } from "react-bootstrap";
import moment from "moment";
import UserAvatar from "../../components/tools/UserAvatar";
import TooManyAvatars from "../../components/tools/TooManyAvatars";
import DefaultTableSearch from "../../components/tools/DefaultTableSearch";
import LayoutHelper from "../../helper/LayoutHelper";
import PlanoAcaoEtapasList from "../../components/modules/planoAcao/PlanoAcaoEtapasList";
import PlanoAcaoEtapaSearch from "../../components/modules/planoAcao/PlanoAcaoEtapaSearch";

import { confirmAlert } from "react-confirm-alert";
import CustomConfirm from "../../components/tools/CustomConfirm";
import PlanoAcaoVinculos from "../../components/modules/planoAcao/PlanoAcaoVinculos";
import SessionHelper from "../../helper/SessionHelper";
import PlanoAcaoQuadro from "../../components/modules/planoAcao/PlanoAcaoQuadro";
import PlanoAcaoConfigComplexibilidade from "../../components/modules/planoAcao/PlanoAcaoConfigComplexibilidade";
import PlanoAcaoEtapaModal from "../../components/modules/planoAcao/PlanoAcaoEtapaModal";
import PlanoAcaoTimeline from "../../components/modules/planoAcao/PlanoAcaoTimeline";
import PlanoAcaoVerificacaoEficacia from "../../components/modules/planoAcao/PlanoAcaoVerificacaoEficacia/PlanoAcaoVerificacaoEficacia";
import PlanoAcaoEtapaCard from "../../components/modules/planoAcao/PlanoAcaoEtapaCard/PlanoAcaoEtapaCard";
import CustomTooltip from "../../components/tools/CustomTooltip";
import DataHelper from "../../helper/DataHelper";
import Tabs from "../../components/tools/Tabs";
import PlanoAcaoTabelaOrcamento from "../../components/modules/planoAcao/PlanoAcaoTabelaOrcamento/PlanoAcaoTabelaOrcamento";
import ProgressBarCustom from "../../components/tools/ProgressBar/ProgressBarCustom";

const TIPO_PROGRESSO_ETAPAS = 0
const TIPO_PROGRESSO_DIAS = 1
const TIPO_PROGRESSO_COMPLEXIDADE = 2

export default class PlanoAcaoDetalharPage extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            codigo: null,
            plano: {},
            countEtapas: null,
            permissions: {},
            expandedDescription: false,
            expandedVerificacaoEficacia: false,
            expandedOrcamento: false,
            verificacaoEficacia: {},
            selectedPeople: [],
            view: 'normal',
            showFilters: false,
            activeTab: 'lista',
            tabs: [
                { name: 'lista', title: 'Lista' },
                { name: 'quadro', title: 'Quadro' },
                { name: 'timeline', title: 'Timeline' },
            ],
            activeTabTimeline: 'semana',
            timelineTabs: [
                { name: "ano", title: "Ano" },
                { name: 'mes', title: 'Mês' },
                { name: 'semana', title: 'Semana' },
            ],
            search: '',
            status: [],
            prioridades: [],
            data_inicio: null,
            data_fim: null,
            headerHeight: 0,
            utilityBarHeight: 0,
            isSmallScreen: LayoutHelper.isSmallScreen(),
            expandedVinculos: false,
            showDetailsModal: false,
            shouldUpdateEtapas: false,
            lastPopState: null,
            timelineSort: 'date',
            atualizaEficacia: 0,
            atualizaProgressBar: 0
        };
        this.timeout = null;
        this.mainHeaderRef = React.createRef();
        this.utilityRef = React.createRef();
        this.observer = new ResizeObserver(this.setUtilityBarHeight);
        this.headerObserver = new ResizeObserver(this.setMainHeaderHeight);
    }

    async componentDidMount() {
        this.handleUrlParams();
        window.addEventListener('resize', this.verifyScreen);
        window.addEventListener('message', this.handleMessage);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.verifyScreen);
        window.removeEventListener('message', this.handleMessage);
    }

    handleMessage = (event) => {
        if (event.data.type === 'popstate') {
            if (event.data.url === this.state.lastPopState) {
                this.setState({ showDetailsModal: null, lastPopState: null });
                return;
            }

            this.setState({ lastPopState: event.data.url });
            this.handleUrlParams();
        }
    }

    verifyScreen = () => {
        this.setState({ isSmallScreen: LayoutHelper.isSmallScreen() }, () => {
            if (this.state.showFilters && LayoutHelper.isSmallScreen())
                this.setState({ showFilters: false });
        });
    }

    setUtilityBarHeight = () => {
        if (this.utilityRef.current && this.state.utilityBarHeight != this.utilityRef.current.clientHeight) {
            this.setState({ utilityBarHeight: this.utilityRef.current.clientHeight });
        }
    }

    setMainHeaderHeight = () => {
        if (this.mainHeaderRef.current && this.state.headerHeight != (this.mainHeaderRef.current.clientHeight - 1)) {
            this.setState({ headerHeight: (this.mainHeaderRef.current.clientHeight - 1) });
        }
    }

    changeExpandedVinculosCallback = () => {
        this.setState({ expandedVinculos: !this.state.expandedVinculos });
    }

    componentDidUpdate = (prevProps, prevState) => {
        if (prevState.showDetailsModal !== this.state.showDetailsModal) {
            if (!this.state.showDetailsModal) {
                window.parent.postMessage({ type: 'delete_url_param', param: "etapa" }, '*');
            } else {
                window.parent.postMessage({ type: 'add_url_param', param: "etapa", value: this.state.showDetailsModal }, '*');
            }
        }
    }

    handleUrlParams = () => {
        window.parent.postMessage({ type: 'get_url' }, '*');

        const listenerFunction = async (event) => {
            if (event.data.type === 'return_url') {
                const urlObj = new URL(event.data.url);
                const queryParams = new URLSearchParams(urlObj.search);
                window.removeEventListener('message', listenerFunction);

                // const tipo = queryParams.get('tipo');

                // Extract the last segment from the pathname
                const pathSegments = urlObj.pathname.split('/');
                const lastSegment = pathSegments[pathSegments.length - 1];

                const idEtapa = queryParams.get('etapa');

                this.setState({ codigo: lastSegment }, () => {
                    this.loadData(lastSegment);
                });
                if (!idEtapa) return;
                this.setState({ showDetailsModal: idEtapa });
            }
        };

        window.addEventListener('message', listenerFunction);
    }

    loadData = async () => {
        this.setState({ loading: true });

        await this.loadPlano();
        await this.loadEtapas();
        await this.loadPermissions();

        this.setState({ loading: false }, () => {
            if (this.utilityRef && this.utilityRef.current) {
                this.observer.observe(this.utilityRef.current, { attributes: true, attributeOldValue: true, attributeFilter: ['style'] });
                this.setUtilityBarHeight();
            }
            if (this.mainHeaderRef && this.mainHeaderRef.current) {
                this.headerObserver.observe(this.mainHeaderRef.current, { attributes: true, attributeOldValue: true, attributeFilter: ['style'] });
                this.setMainHeaderHeight();
            }
        });
    }

    loadPlano = async () => {
        const planoAcao = await Sig.request('POST', 'planoAcao/getPlanoAcao', { codigo: this.state.codigo });

        if (!planoAcao?.data) {
            toast.error('Não foi possível encontrar o plano de ação');
            return window.parent.postMessage({ type: 'navigate', url: `/planoAcao` }, '*');
        } else {
            this.setState({ plano: planoAcao.data });
        }
    }

    loadEtapas = async () => {
        const etapas = await Sig.request('POST', 'planoAcao/listEtapasPlano', { codigo: this.state.codigo });
        this.setState({ etapas, countEtapas: etapas.length, atualizaEficacia: this.state.atualizaEficacia + 1 });
    }

    loadPermissions = async () => {
        const permissions = await Sig.request('GET', 'planoAcao/getPermissoes', { codigo: this.state.codigo });
        this.setState({ permissions });
    }

    detailsModalCallback = (idEtapa = null) => {
        if (idEtapa) {
            this.setState({ showDetailsModal: idEtapa });
        } else {
            this.setState({ showDetailsModal: false });
        }
    }

    hasEmptyDescription = () => {
        return (!this.state.plano.descricao || this.state.plano.descricao == " " || DataHelper.removeHtmlTags(this.state.plano.descricao) == "") && 
            (!this.state.plano.causa_raiz || this.state.plano.causa_raiz == " " || DataHelper.removeHtmlTags(this.state.plano.causa_raiz) == "") &&
            (!this.state.plano.nao_conformidade || this.state.plano.nao_conformidade == " " || DataHelper.removeHtmlTags(this.state.plano.nao_conformidade) == "");
    }

    updateEtapasCallback = () => {
        this.setState({ shouldUpdateEtapas: true }, () => this.setState({ shouldUpdateEtapas: false }));
    }

    deletePlanoAcao = async () => {
        confirmAlert({
            customUI: ({ onClose }) => (
                <CustomConfirm
                    title={`Excluir este Plano de ${this.state.plano.tipoName}?`}
                    message={<p style={{ marginTop: 10, marginBottom: 10 }}>Essa ação não pode ser desfeita</p>}
                    buttons={[
                        {
                            label: 'Remover',
                            color: SessionHelper.getColor(),
                            textColor: Colors.light,
                            onClick: async () => {
                                let deletePlanoAcao = await Sig.request('POST', 'planoAcao/deletePlanoAcao', { codigo: this.state.codigo });

                                if (deletePlanoAcao.error === false) {
                                    toast.success("Plano de Ação excluído com sucesso");
                                    window.parent.postMessage({ type: 'navigate', url: `/planoAcao` }, '*');
                                } else {
                                    toast.error("Erro ao excluir Plano de Ação");
                                    this.setState({ loading: false });
                                }

                                onClose();
                            }
                        },
                        {
                            label: 'Cancelar',
                            onClick: () => { onClose(); }
                        },
                    ]}
                />
            )
        });

    }

    renderTitle() {
        return (
            <div style={{ ...EssentialStyle.rowFlexStart }}>
                <span style={{
                    marginLeft: 6,
                    fontSize: 18,
                    fontWeight: 500
                }}>
                    {this.state.plano.nome}
                </span>
            </div>
        );
    }

    renderTags() {
        return (
            <div style={{ minHeight: 28 }}>
                <TooManyTags max={4} maxWidth={this.state.isSmallScreen ? '95vw' : '40vw'} tags={this.state.plano?.tags || []} />
            </div>
        );
    }

    renderLeft(noTags = false) {
        let style = { width: '40%' };

        if (this.state.isSmallScreen) {
            style = { ...style, ...EssentialStyle.columnStart, width: '100%', maxWidth: '100%' };
        } else {
            style = { ...style, ...EssentialStyle.columnStart };
        }

        return (
            <div style={style}>
                <div style={{ ...EssentialStyle.rowSpaceBetween, paddingLeft: 3, paddingBottom: 10 }}>
                    <DefaultButton tooltip={'Voltar'} tooltipPlacement={'bottom'} leftIcon={<FontAwesomeIcon icon={faChevronLeft} />} color={Colors.secondaryButton} textColor={Colors.dark} loading={this.state.loading} onClick={() => { window.parent.postMessage({ type: 'navigate', url: `/planoAcao` }, '*'); }} style={{ marginRight: 8 }} />
                    {this.renderTitle()}
                </div>

                {!noTags ? this.renderTags() : <div />}
            </div>
        );
    }

    renderCenter() {
        var dates = "";
        if(this.state.plano?.data_inicio && this.state.plano?.data_fim){
            dates = moment(this.state.plano?.data_inicio).format('DD/MM/YY') +  " até " + moment(this.state.plano?.data_fim).format('DD/MM/YY');
        } else {
            dates = "- -";         
        }

        return (
            <div style={{ ...EssentialStyle.columnStart, width: this.state.isSmallScreen ? '100%' : '30%' }}>
                <div style={{ ...EssentialStyle.rowFlexStart, paddingLeft: 3, paddingTop: 10 }}>
                    <span style={{ fontSize: 14, color: Colors.disabled, fontWeight: 200, marginRight: 8 }}>Tipo </span>
                    <span style={{ fontSize: 14, color: Colors.dark, fontWeight: 600 }}>{this.state.plano.tipo_text}</span>
                </div>
                <div style={{ ...EssentialStyle.rowFlexStart, paddingLeft: 3, paddingTop: 10 }}>
                    <span style={{ fontSize: 14, color: Colors.disabled, fontWeight: 200, marginRight: 8 }}>Período </span>
                    <span style={{ fontSize: 14, color: Colors.dark, fontWeight: 600 }}>{dates}</span>
                </div>
                <div style={{ ...EssentialStyle.rowFlexStart, paddingLeft: 3, paddingTop: 10, width: '100%' }}>
                    <span style={{ fontSize: 14, color: Colors.disabled, fontWeight: 200, marginRight: 8 }}>Situação </span>
                    <span style={{ fontSize: 14, color: Colors.dark, fontWeight: 600, width: this.state.isSmallScreen ? 'calc(100% - 60px)' : 'calc(100% - 110px)' }}>{this.renderSituacao()}</span>
                </div>
            </div>
        );
    }

    renderRight(onlyConfig = false) {
        let rowResponsavelSuperior = {
            width: '100%',
            marginBottom: 10
        };

        if (this.state.plano?.superior?.id) {
            rowResponsavelSuperior = {
                ...rowResponsavelSuperior,
                ...EssentialStyle.rowSpaceBetween
            }
        } else {
            rowResponsavelSuperior = {
                ...rowResponsavelSuperior,
                ...EssentialStyle.rowFlexEnd
            }
        }

        let notInCompany = this.state.plano.id_empresa != SessionHelper.getData().id_empresa;

        if (onlyConfig) {
            return (
                <div style={{ ...EssentialStyle.columnCenter, width: "auto" }}>
                    <div style={{ ...EssentialStyle.rowSpaceBetween, width: '100%' }}>
                        <DefaultButton
                            tooltip={notInCompany ? 'Acesse o ambiente do parceiro para fazer modificações' : 'Configurações'}
                            tooltipPlacement={'bottom'}
                            leftIcon={<FontAwesomeIcon icon={faCog} />}
                            color={Colors.secondaryButton}
                            textColor={Colors.dark}
                            loading={this.state.loading}
                            disabled={notInCompany ? true : !this.state.permissions.canEdit}
                            onClick={() => {
                                window.parent.postMessage({ type: 'navigate', url: `/planoAcao?tipo=editar&codigo=${this.state.codigo}` }, '*');
                            }}
                        />

                        <DefaultButton
                            tooltip={notInCompany ? 'Acesse o ambiente do parceiro para fazer modificações' : 'Excluir'}
                            tooltipPlacement={'bottom'}
                            style={{ marginLeft: 8, display: this.state.permissions.canDelete ? '' : 'none' }}
                            disabled={notInCompany ? true : !this.state.permissions.canDelete}
                            leftIcon={<FontAwesomeIcon icon={faTrash} />}
                            color={Colors.secondaryButton}
                            textColor={Colors.dark}
                            loading={this.state.loading}
                            onClick={() => {
                                this.deletePlanoAcao();
                            }}
                        />

                        <DefaultButton 
                            style={{ width: 38, height: 38, marginLeft: 8}}
                            fontSize={this.state.isSmallScreen ? 14 : "unset"}
                            color={Colors.secondaryButton}
                            textColor={Colors.dark} 
                            link={'https://advis.freshdesk.com/support/solutions/articles/63000221651-planos-de-a%C3%A7%C3%A3o#detalhar'}
                        />
                    </div>
                </div>
            );
        } else {
            return (
                <div style={{ ...EssentialStyle.columnCenter, width: this.state.isSmallScreen ? '100%' : '30%' }}>
                    <div style={rowResponsavelSuperior}>
                        <div style={{ ...EssentialStyle.rowFlexStart }}>
                            <span style={{ marginRight: 8, fontSize: 14, color: Colors.dark, fontWeight: 200 }}>Responsável </span>
                            <UserAvatar id={this.state.plano.responsavel.id} />
                        </div>
                        {this.state.plano?.superior?.id &&
                            <div style={{ ...EssentialStyle.rowFlexStart }}>
                                <span style={{ marginRight: 8, fontSize: 14, color: Colors.dark, fontWeight: 200 }}>Avaliador </span>
                                <UserAvatar id={this.state.plano.superior.id} />
                            </div>
                        }
                    </div>

                    <div style={{ ...EssentialStyle.rowSpaceBetween, width: '100%', flexWrap: 'wrap' }}>
                        {this.state.plano?.participantes?.length ?
                            <div style={{ ...EssentialStyle.rowFlexStart, flex: '1 1 auto' }}>
                                <span style={{ marginRight: 8, fontSize: 14, color: Colors.dark, fontWeight: 200 }}>Participantes </span>
                                <TooManyAvatars participantsTotal={this.state.plano?.participantes?.length} participantsVisiveis={2} arrayParticipants={this.state.plano?.participantes} />
                            </div>
                            : <div style={{ flex: '1 1 auto' }} />}
                        <div style={{ ...EssentialStyle.rowFlexEnd, flex: '1 1 auto', justifyContent: 'flex-end' }}>
                            <DefaultButton
                                tooltip={notInCompany ? 'Acesse o ambiente do parceiro para fazer modificações' : 'Configurações'}
                                tooltipPlacement={'bottom'}
                                style={{ display: this.state.permissions.canEdit ? '' : 'none' }}
                                disabled={notInCompany ? true : !this.state.permissions.canEdit}
                                leftIcon={<FontAwesomeIcon icon={faCog} />}
                                color={Colors.secondaryButton}
                                textColor={Colors.dark}
                                loading={this.state.loading}
                                onClick={() => {
                                    window.parent.postMessage({ type: 'navigate', url: `/planoAcao?tipo=editar&codigo=${this.state.codigo}` }, '*');
                                }}
                            />

                            <DefaultButton
                                tooltip={notInCompany ? 'Acesse o ambiente do parceiro para fazer modificações' : 'Excluir'}
                                tooltipPlacement={'bottom'}
                                style={{ marginLeft: 8, display: this.state.permissions.canDelete ? '' : 'none' }}
                                disabled={notInCompany ? true : !this.state.permissions.canDelete}
                                leftIcon={<FontAwesomeIcon icon={faTrash} />}
                                color={Colors.secondaryButton}
                                textColor={Colors.dark}
                                loading={this.state.loading}
                                onClick={() => {
                                    this.deletePlanoAcao();
                                }} 
                            />
                            

                            <DefaultButton 
                            style={{ width: 38, height: 38, marginLeft: 8}}
                            fontSize={this.state.isSmallScreen ? 14 : "unset"}
                            color={Colors.secondaryButton}
                            textColor={Colors.dark} 
                            link={'https://advis.freshdesk.com/support/solutions/articles/63000221651-planos-de-a%C3%A7%C3%A3o#detalhar'}/>
                            
                        </div>
                    </div>
                </div>
            );
        }

    }

    renderSituacao() {

        return (
            this.state.plano.status == 'Em Desenvolvimento' || this.state.plano.status == 'Atrasado' || this.state.plano.status == 'Concluído' ?
                <ProgressBarCustom 
                    tipo={'planoAcao'} 
                    id={this.state.codigo} 
                    update={this.state.atualizaProgressBar} 
                    backgroundColor={Colors.planoAcao.status.backgroundColor[this.state.plano.status]} 
                    color={Colors.planoAcao.status.color[this.state.plano.status]} 
                    style={{width: '100%'}}
                    completeEfect={'Concluído'}
                />
                :
                <div style={{ ...EssentialStyle.rowFlexStart, marginLeft: 2 }}>
                    <div style={{ backgroundColor: Colors.planoAcao.status.backgroundColor[this.state.plano.status], color: Colors.planoAcao.status.color[this.state.plano.status], fontSize: 12, padding: 5, borderRadius: 5, marginRight: 2, fontWeight: 600 }}>{this.state.plano.status}</div>
                </div>
        )
    }

    toggleDescription = () => {
        this.setState({ expandedDescription: !this.state.expandedDescription });
    }

    toggleOcamento = () => {
        this.setState({ expandedOrcamento: !this.state.expandedOrcamento });
    }

    renderDetailedDescription() {

        let firstRowStyle = {
            ...EssentialStyle.rowSpaceBetween,
            alignItems: 'stretch',
            display: parseInt(this.state.plano.tipo) <= 2 ? 'flex' : 'none',
            padding: "10px 15px"
        };

        if (this.state.isSmallScreen) {
            firstRowStyle = { ...EssentialStyle.columnCenter, paddingTop: 20 };
        }

        return (
            <div style={{ ...EssentialStyle.columnCenter, width: '100%', height: "auto" }}>
                <div style={firstRowStyle}>
                    {this.state.plano.nao_conformidade && this.state.plano.nao_conformidade.length > 0 &&
                        <div style={{ ...EssentialStyle.columnStart, alignItems: 'center', width: this.state.plano.causa_raiz && !this.state.isSmallScreen ? '49%' : '100%', minHeight: '100%', borderRadius: 5, boxShadow: Colors.boxShadow }}>
                            <span style={{ fontSize: 14, fontWeight: 600, color: Colors.dark }}>Não Conformidade</span>
                            <div style={{ fontSize: 14, color: Colors.dark, textAlign: 'justify', width: '100%', padding: 15 }} dangerouslySetInnerHTML={{ __html: this.state.plano.nao_conformidade }}></div>
                        </div>
                    }
                    {this.state.plano.causa_raiz && this.state.plano.causa_raiz.length > 0 &&
                        <div style={{ ...EssentialStyle.columnStart, alignItems: 'center', width: this.state.plano.nao_conformidade && !this.state.isSmallScreen ? '49%' : '100%', minHeight: '100%', borderRadius: 5, boxShadow: Colors.boxShadow }}>
                            <span style={{ fontSize: 14, fontWeight: 600, color: Colors.dark }}>Causa Raiz</span>
                            <div style={{ fontSize: 14, color: Colors.dark, textAlign: 'justify', width: '100%', padding: 15 }} dangerouslySetInnerHTML={{ __html: this.state.plano.causa_raiz }}></div>
                        </div>
                    }
                </div>
                {this.state.plano.descricao && this.state.plano.descricao.length > 0  &&
                    <div style={{ ...EssentialStyle.columnCenter, width: 'calc(100% - 30px)', margin: "10px 15px", borderRadius: 5, boxShadow: Colors.boxShadow }}>
                        <span style={{ fontSize: 14, fontWeight: 600, color: Colors.dark }}>Descrição</span>
                        <div style={{ fontSize: 14, color: Colors.dark, textAlign: 'justify', width: '100%', padding: 15 }} dangerouslySetInnerHTML={{ __html: this.state.plano.descricao }}></div>
                    </div>
                }
            </div>
        );
    }

    handleSearch = async (text) => {
        if (text.length) {
            if (text.length >= 1) {
                this.setState({ search: text });
            }
        } else {
            this.setState({ search: '' });
        }
    }

    handlePersonSelect = async (id) => {
        if (this.state.selectedPeople.includes(id)) {
            this.setState({ selectedPeople: this.state.selectedPeople.filter(person => person !== id) });
        } else {
            this.setState({ selectedPeople: [...this.state.selectedPeople, id] });
        }
    }

    getPeople = () => {
        let people = [];

        if (this.state.plano.responsavel) people.push(this.state.plano.responsavel);
        if (this.state.plano.superior) people.push(this.state.plano.superior);
        if (this.state.plano.participantes) people = people.concat(this.state.plano.participantes);

        people.sort((a, b) => {
            return a.nome.localeCompare(b.nome);
        });

        return people;
    }

    renderPeopleFilter() {
        let people = this.getPeople();

        let style = { ...EssentialStyle.rowFlexStart, marginLeft: 10, overflowX: 'auto', marginRight: 8, width: people.length * 38 };

        if (this.state.isSmallScreen) {
            style = { ...style, maxWidth: '100%', marginTop: 10 };
        } else {
            style = { ...style, maxWidth: '60%' };
        }

        return (
            <div style={style}>
                {people.map((person, index) => {
                    return (
                        <div
                            key={`people-filter-${index}`}
                            style={{ ...EssentialStyle.rowFlexStart, marginRight: -8 }}
                        >
                            <UserAvatar id={person.id} onClick={() => { this.handlePersonSelect(person.id) }} selected={this.state.selectedPeople.includes(person.id)} />
                        </div>
                    );
                })}
            </div>
        );
    }

    renderSwitch() {
        return (
            <div style={{ ...EssentialStyle.rowFlexCenter }}>
                <CustomTooltip tooltip={'Visualização Normal'} placement={'top'}>
                    <FontAwesomeIcon icon={faMinus} />
                </CustomTooltip>
                <Form.Switch
                    type="switch"
                    disabled
                    checked={this.state.view === 'normal' ? false : true}
                    style={{ marginLeft: 3, marginRight: 3 }}
                />
                <CustomTooltip tooltip={'Visualização Detalhada'} placement={'top'}>
                    <FontAwesomeIcon icon={faBars} />
                </CustomTooltip>
            </div>
        )
    }

    renderTimelineSwitch() {
        return (
            <div style={{ ...EssentialStyle.rowFlexCenter }}>
                <CustomTooltip tooltip={'Ordenação por Data'} placement={'top'}>
                    <FontAwesomeIcon icon={faCalendarAlt} />
                </CustomTooltip>
                <Form.Switch
                    type="switch"
                    disabled
                    checked={this.state.timelineSort == 'date' ? false : true}
                    style={{ marginLeft: 3, marginRight: 3 }}
                />
                <CustomTooltip tooltip={'Ordenação configurada'} placement={'top'}>
                    <FontAwesomeIcon icon={faList} />
                </CustomTooltip>
            </div>
        )
    }

    renderTimelineSelectors() {
        return (
            <div style={{ ...EssentialStyle.rowSpaceBetween, marginTop: this.state.isSmallScreen ? 10 : 0, alignSelf: "center" }}>
                <DefaultButton
                    leftIcon={this.renderTimelineSwitch()}
                    color={Colors.secondaryButton}
                    textColor={Colors.dark}
                    loading={this.state.loading}
                    onClick={() => { this.setState({ timelineSort: this.state.timelineSort === 'date' ? 'user' : 'date' }) }}
                    style={{ marginRight: 8 }}
                />
                <Tabs 
                    isSmallScreen={this.state.isSmallScreen}
                    tabs={this.state.timelineTabs}
                    activeTab={this.state.activeTabTimeline}
                    onTabChange={this.handleTimelineTabChange}
                    style={{ marginTop: 0}}
                    tabStyle={{ width: this.state.isSmallScreen ? '23vw' : 90 }}
                />
            </div>
        )
    }

    handleTimelineTabChange = (tab) => {
        this.setState({ activeTabTimeline: tab.name });
    }

    handleTabChange = (tab) => {
        this.setState({ activeTab: tab.name, view: 'normal' });
    }

    handleView = () => {
        this.setState({ view: this.state.view === 'normal' ? 'detailed' : 'normal' });
    }

    toggleFilters = () => {
        this.setState({ showFilters: !this.state.showFilters });
    }

    filter = async (filter) => {
        this.setState({ status: filter.status, prioridades: filter.prioridades, data_inicio: filter.dataInicio, data_fim: filter.dataFim });
    }

    loadEtapasCallback = async () => {
        this.loadEtapas();
        this.loadPlano();
        this.loadPermissions();
        this.setState({ atualizaProgressBar: this.state.atualizaProgressBar + 1 });
    }

    handleTimelineTabChange = (tab) => {
        this.setState({ activeTabTimeline: tab.name });
    }

    renderTabs() {
        let tabs = [
            { name: 'lista', title: 'Lista' },
            { name: 'quadro', title: 'Quadro' },
            { name: 'timeline', title: 'Timeline' },
        ];

        if (this.state.plano.tipoProgresso == TIPO_PROGRESSO_COMPLEXIDADE) {
            let totalPeso = this.state.etapas.reduce((total, etapa) => total + Number(etapa.peso), 0);
            tabs.push({ name: 'complexidade', title: 'Complexidade', secondaryButton: totalPeso < 100 ? Colors.danger : Colors.secondaryButton });
        }

        if (this.state.isSmallScreen) {
            tabs = tabs.filter(tab => tab.name !== 'quadro');
        }

        var link = this.state.activeTab == 'lista' ? 
            'https://advis.freshdesk.com/support/solutions/articles/63000221651-planos-de-a%C3%A7%C3%A3o#atividades'
        : this.state.activeTab == 'quadro' ? 
            'https://advis.freshdesk.com/support/solutions/articles/63000221651-planos-de-a%C3%A7%C3%A3o#quadro'
        : 
            'https://advis.freshdesk.com/support/solutions/articles/63000221651-planos-de-a%C3%A7%C3%A3o#timeline';

        return (
            <div style={{ ...EssentialStyle.rowSpaceBetween, marginTop: this.state.isSmallScreen ? 10 : 0, width: this.state.isSmallScreen ? '100%' : '' }}>
                <Tabs 
                    isSmallScreen={this.state.isSmallScreen}
                    tabs={tabs}
                    activeTab={this.state.activeTab}
                    onTabChange={this.handleTabChange}
                    style={{ marginTop: 0 }}
                    tabStyle={{ width: this.state.isSmallScreen ? `calc(calc(100%-40px)/${this.state.activeTab.length})` : 'auto' }}
                />
                <DefaultButton 
                    style={{ width: 38, height: 38, marginLeft: 8}}
                    fontSize={this.state.isSmallScreen ? 14 : "unset"}
                    color={Colors.secondaryButton}
                    textColor={Colors.dark} 
                    link={link}
                /> 
            </div>
        );
    }

    renderPage() {
        return (
            <div style={{ ...EssentialStyle.columnCenter, width: '100%', position: "relative", marginBottom: this.state.isSmallScreen ? '6rem' : 0 }}>
                {this.renderHeader()}
                <span style={{ minHeight: this.state.isSmallScreen ? 0 : (this.state.headerHeight + 1), width: "100%" }}></span>
                {this.renderApprovalContent()}
                {this.renderOrcamentoContent()}
                {this.renderDescription()}
                {this.renderVinculos()}
                {this.renderUtilityBar()}
                {this.renderActiveTab()}
                {this.renderDetailsModal()}
            </div>
        );
    }

    renderHeader() {
        let style = { width: '100%' };
        
        if (!this.state.plano.descricao && !this.state.plano.causa_raiz && !this.state.plano.nao_conformidade) {
            var borders = { borderBottomLeftRadius: 8, borderBottomRightRadius: 8 };
        } else {
            var borders = { borderBottomLeftRadius: 0, borderBottomRightRadius: 0 };
        }

        if (this.state.isSmallScreen) {
            style = { ...style, ...EssentialStyle.columnCenter };
        } else {
            style = { ...style, ...EssentialStyle.rowSpaceBetween };
        }


        return (
            <div
                className="pa-header-component"
                ref={this.mainHeaderRef}
                style={{
                    ...EssentialStyle.columnCenter,
                    backgroundColor: SessionHelper.getBackgroundColor(),
                    width: '100%',
                    position: this.state.isSmallScreen ? 'static' : 'fixed',
                    top: 0,
                    zIndex: 2,
                }}>
                <div style={{ ...EssentialStyle.card, ...EssentialStyle.columnStart, width: '100%', padding: 10, ...borders, boxShadow: 'none' }}>
                    <div style={style}>
                        {this.renderLeft()}
                        {this.renderCenter()}
                        {this.renderRight()}
                    </div>
                </div>
            </div>
        )
    }

    renderApprovalContent() {

        let styleContainer = {
            ...EssentialStyle.columnCenter,
            minHeight: 35,
            boxShadow: `0px 1px 10px 0px  rgba(50, 50, 50, 0.1)`,
            backgroundColor: Colors.white,
            width: '100%',
            overflow: "hidden"
        }

        let styleHeader = {
            ...(EssentialStyle.rowSpaceBetween),
            flexWrap: 'wrap',
            padding: '0 10px',
            minHeight: 35,
            backgroundColor: Colors.white,
            width: '100%'
        }

        let styleTagAprroval = {
            ...EssentialStyle.rowFlexCenter,
            backgroundColor: Colors.planoAcao.statusAprovacao.backgroundColor[this.state.plano?.aprovacao?.status_text],
            color: Colors.planoAcao.statusAprovacao.color[this.state.plano?.aprovacao?.status_text],
            fontSize: 14,
            fontWeight: 'bold',
            padding: '2px 10px',
            borderRadius: 5,
            width: this.state.isSmallScreen ? '100%' : 'auto',
            marginBottom: 1
        }

        let status = this.state.plano?.aprovacao?.status;
        let titleButtomSolicitarAprovacao = `Solicitar Aprovação ${status <= 3 ? 'Novamente' : ''}`;
        let titleButtomVerificacaoEficacia = this.state.plano?.verificacao == null ? `Verificar Eficácia` : null;
        let tooltipButtomVerificacaoEficacia = this.state.plano?.verificacao == null ? null : 'Eficácia';
        let titleDialogResponse = 'Plano ' + this.state.plano?.aprovacao?.status_text;

        // Verifica se o Plano tem aprovação e se tem etapas
        const hasApproval = this.state.plano?.superior && this.state.plano?.aprovacao && this.state.countEtapas > 0;

        // Verifica se deve mostra o botão de solicitar aprovação (Necessitar de aprovação e não estar aprovado)
        const showButtomSolicitarAprovacao = hasApproval && status != 2;

        // Verifica se deve mostra o botão de verificar eficácia (Necessitar de verificação e não estar verificado)
        const showButtomVerificarEficacia = this.state.permissions?.canVerificarEficacia || this.state.plano?.verificacao != null;

        const renderButtonVerificarEficacia = () => {
            return (
                <DefaultButton className={"hoverMouse"}
                    title={titleButtomVerificacaoEficacia}
                    leftIcon={<FontAwesomeIcon icon={faCertificate} />}
                    tooltip={tooltipButtomVerificacaoEficacia}
                    style={{ padding: '0 10px', borderRadius: 5, width: this.state.isSmallScreen ? '100%' : 'auto' }}
                    color={Colors.success}
                    textColor={Colors.white}
                    loading={this.state.loading}
                    onClick={() => { this.setState({ expandedVerificacaoEficacia: !this.state.expandedVerificacaoEficacia }) }}
                />
            )
        }

        const renderButtonSolicitarAprovacao = () => {
            return (
                <DefaultButton className={"hoverMouse"}
                    title={titleButtomSolicitarAprovacao}
                    leftIcon={<FontAwesomeIcon icon={faEnvelope} />}
                    style={{ padding: '0 10px', borderRadius: 5, width: this.state.isSmallScreen ? '100%' : 'auto' }}
                    color={Colors.info}
                    textColor={Colors.white}
                    loading={this.state.loading}
                    onClick={() => {
                        confirmAlert({
                            customUI: ({ onClose }) => (
                                <CustomConfirm
                                    title={<><FontAwesomeIcon icon={faEnvelope} style={{ marginRight: 10 }} />Enviar Solicitação de Aprovação</>}
                                    message={<p style={{ marginTop: 10, marginBottom: 10 }}>Ao confirmar esta ação, será enviado notificado e e-mail ao avaliador deste Plano.</p>}
                                    buttons={[
                                        {
                                            label: 'Enviar',
                                            color: Colors.info,
                                            textColor: Colors.light,
                                            onClick: async () => {
                                                let data = await Sig.request('POST', 'planoAcao/solicitarValidacao', { codigo: this.state.codigo });

                                                if (data.enviado) {
                                                    toast.success(data.message);
                                                    this.loadData();
                                                } else {
                                                    toast.error(data.message);
                                                }

                                                onClose();
                                            }
                                        },
                                        {
                                            label: 'Cancelar',
                                            onClick: () => { onClose(); }
                                        },
                                    ]}
                                />
                            )
                        });
                    }}
                />
            )
        }

        const renderInfoTagAproval = () => {
            return (
                status > 0 && status < 4 &&
                <FontAwesomeIcon
                    className={"hoverMouse"}
                    icon={faInfoCircle}
                    style={{ marginLeft: 10 }}
                    onClick={(e) => {
                        e.preventDefault();
                        confirmAlert({
                            customUI: ({ onClose }) => (

                                <CustomConfirm
                                    title={
                                        <>
                                            <FontAwesomeIcon
                                                icon={status == 1 ? faTimesCircle : status == 2 ? faCheckCircle : faQuestionCircle}
                                                style={{ marginRight: 10, color: Colors.planoAcao.statusAprovacao.backgroundColor[this.state.plano?.aprovacao?.status_text] }}
                                            />
                                            {titleDialogResponse}
                                        </>
                                    }
                                    message={
                                        <div style={{ marginTop: 10, marginBottom: 10 }}>
                                            <p style={{ fontWeight: 'bold', fontSize: 12 }}>{this.state.plano?.aprovacao?.justificativa}</p>
                                            {this.state.etapas?.length > 0 && (
                                                <div style={{ width: '100%', maxHeight: '20rem', boxShadow: Colors.boxShadow, overflow: 'auto' }}>
                                                    {this.state.etapas.map((etapa, index) => {
                                                        return (<div key={`etapa-${etapa.id}`}>
                                                            <PlanoAcaoEtapaCard
                                                                key={`plano-acao-etapa-${etapa.id}`}
                                                                resumed={true}
                                                                dialog={true}
                                                                etapa={{ ...etapa }}
                                                                participantes={this.getPeople()}
                                                                hasAvaliador={this.state.plano?.superior}
                                                                detailsModalCallback={(id) => { onClose(); this.detailsModalCallback(id) }}
                                                                borderBottom={index == (this.state.etapas.length - 1) ? `1px solid ${Colors.border}` : 'none'}
                                                            />
                                                        </div>)
                                                    })}
                                                </div>
                                            )}
                                        </div>
                                    }
                                    buttons={[
                                        {
                                            label: 'OK',
                                            onClick: () => { onClose(); }
                                        },
                                    ]}
                                />
                            )
                        });
                    }}
                />
            );
        }

        const renderTagAproval = () => {
            return (
                <div style={styleTagAprroval}>
                    {this.state.plano?.aprovacao?.status_text}
                    {renderInfoTagAproval()}
                </div>
            )
        }

        const renderContent = () => {
            let hasOrcamento = this.state.plano?.orcamento_previsto && parseFloat(this.state.plano?.orcamento_previsto) > 0;

            if (this.state.plano?.vinculos?.length == 0 && this.hasEmptyDescription() && !hasOrcamento) {
                var borders = { borderBottomLeftRadius: 8, borderBottomRightRadius: 8};
            } else {
                var borders = { borderBottomLeftRadius: 0, borderBottomRightRadius: 0 };
            }

            return (
                <div className="pa-header-component optional" style={{ ...EssentialStyle.columnCenter, width: '100%', backgroundColor: SessionHelper.getBackgroundColor(), paddingTop: "2px", paddingBottom: this.state.plano?.vinculos?.length == 0 && this.hasEmptyDescription() ? 8 : 0}}>
                    <div style={{...styleContainer, ...borders}}>
                        <div style={styleHeader}>
                            <div style={{...EssentialStyle.rowFlexStart, gap: 5, flexWrap: 'wrap'}}>
                                {hasApproval && renderTagAproval()}
                            </div>
                            <div style={{...EssentialStyle.rowFlexEnd, gap: 5, flexWrap: 'wrap'}}>
                                {showButtomSolicitarAprovacao && renderButtonSolicitarAprovacao()}
                                {showButtomVerificarEficacia && renderButtonVerificarEficacia()}
                            </div>
                        </div>

                        <Collapse in={this.state.expandedVerificacaoEficacia} style={{ width: '100%', transition: "all 0.2s ease" }}>
                            <div>
                                <PlanoAcaoVerificacaoEficacia codigo={this.state.codigo} atualizaEficacia={this.state.atualizaEficacia} updateEficacia={() => this.loadEtapasCallback()}/>
                            </div>
                        </Collapse>
                    </div>
                </div>
            );
        }

        return ((hasApproval || showButtomSolicitarAprovacao || showButtomVerificarEficacia) && renderContent());
    }

    renderOrcamentoContent() {

        let styleContainer = {
            ...EssentialStyle.columnCenter,
            minHeight: 35,
            boxShadow: `0px 1px 10px 0px  rgba(50, 50, 50, 0.1)`,
            backgroundColor: Colors.white,
            width: '100%',
            overflow: "hidden"
        }

        let styleHeader = {
            ...EssentialStyle.rowFlexCenter,
            padding: '0 10px',
            minHeight: 35,
            backgroundColor: Colors.white,
            width: '100%',
            cursor: 'pointer'
        }

        let hasOrcamento = this.state.plano?.orcamento_previsto && parseFloat(this.state.plano?.orcamento_previsto) > 0;

        if (this.state.plano?.vinculos?.length == 0 && this.hasEmptyDescription()) {
            var borders = { borderBottomLeftRadius: 8, borderBottomRightRadius: 8};
        } else {
            var borders = { borderBottomLeftRadius: 0, borderBottomRightRadius: 0 };
        }

        if (!hasOrcamento) return <div />;
        return (
            <div 
                className="pa-header-component optional" 
                style={{ 
                    ...EssentialStyle.columnCenter, 
                    width: '100%', backgroundColor: 
                    SessionHelper.getBackgroundColor(), 
                    paddingTop: "2px", 
                    paddingBottom: this.state.plano?.vinculos?.length == 0 && this.hasEmptyDescription() ? 8 : 0,
                }}
            >
                <div style={{...styleContainer, ...borders}}>
                     <div style={styleHeader}  onClick={this.toggleOcamento} >
                        {this.state.expandedOrcamento ?
                            <FontAwesomeIcon icon={faChevronUp} style={{ marginRight: 8 }} /> :
                            <FontAwesomeIcon icon={faChevronDown} style={{ marginRight: 8 }} />
                        }
                        {
                            this.state.expandedOrcamento ? 'Ocultar Orçamento' : 'Orçamento'
                        }
                     </div>

                     <Collapse in={this.state.expandedOrcamento} style={{ width: '100%', transition: "all 0.2s ease" }}>
                         <div>
                             <PlanoAcaoTabelaOrcamento isSmallScreen={this.state.isSmallScreen} etapas={this.state.etapas} codigo={null} plano={this.state.plano} people={this.getPeople()}/>
                         </div>
                     </Collapse>
                </div>
            </div>
        );
    }

    renderDescription() {
        if (this.hasEmptyDescription()) return <div />;

        if (this.state.plano?.vinculos?.length == 0) {
            var borders = { borderBottomLeftRadius: 8, borderBottomRightRadius: 8 };
        } else {
            var borders = { borderBottomLeftRadius: 0, borderBottomRightRadius: 0 };
        }

        return (
            <div className="pa-header-component optional" style={{ ...EssentialStyle.columnCenter, width: '100%', backgroundColor: SessionHelper.getBackgroundColor(), paddingTop: "2px", paddingBottom: this.state.plano?.vinculos?.length > 0 ? "0" : "8px" }}>
                <div style={{ ...EssentialStyle.columnCenter, minHeight: 35, boxShadow: `0px 1px 10px 0px  rgba(50, 50, 50, 0.1)`, ...borders, backgroundColor: Colors.white, width: '100%' }}>
                    <div style={{ ...EssentialStyle.rowFlexCenter, cursor: 'pointer', minHeight: 35 }} onClick={this.toggleDescription} >
                        {this.state.expandedDescription ?
                            <FontAwesomeIcon icon={faChevronUp} style={{ marginRight: 8 }} /> :
                            <FontAwesomeIcon icon={faChevronDown} style={{ marginRight: 8 }} />
                        }
                        {
                            this.state.expandedDescription ? 'Ocultar Descrição' : 'Descrição'
                        }
                    </div>

                    <Collapse in={this.state.expandedDescription} style={{ width: '100%', transition: "all 0.2s ease" }}>
                        {this.renderDetailedDescription()}
                    </Collapse>
                </div>
            </div>
        );
    }

    renderVinculos() {
        return (
            this.state.plano?.vinculos?.length > 0 ?
                <PlanoAcaoVinculos 
                    className="pa-header-component optional" 
                    idPlanoAcao={this.state.plano.id} 
                    isSmallScreen={this.state.isSmallScreen} 
                    changeExpandedVinculosCallback={this.changeExpandedVinculosCallback} 
                    expandedVinculos={this.state.expandedVinculos}
                    parceiro={this.state.plano.id_empresa != SessionHelper.getData().id_empresa} />
                :
                <div />
        )
    }

    renderUtilityBar() {
        let style = { width: '100%' };
        let sectionStyle = {};

        if (this.state.isSmallScreen) {
            style = { ...style, ...EssentialStyle.columnCenter };
            sectionStyle = { ...sectionStyle, ...EssentialStyle.columnStart, width: '100%' };
        } else {
            style = { ...style, ...EssentialStyle.rowSpaceBetween };
            sectionStyle = { ...sectionStyle, ...EssentialStyle.rowFlexStart, maxWidth: '50%' };
        }

        return (
            <div
                className="pa-header-component"
                ref={this.utilityRef}
                style={{
                    ...EssentialStyle.columnCenter,
                    width: '100%',
                    paddingTop: 15,
                    paddingBottom: 6,
                    zIndex: 1,
                    position: 'sticky',
                    top: this.state.isSmallScreen ? "auto" : this.state.headerHeight,
                    backgroundColor: SessionHelper.getBackgroundColor()
                }}>
                <div style={style}>
                    <div style={{ ...sectionStyle }} >
                        <DefaultTableSearch handleSearch={this.handleSearch} onEmpty={() => { }} hideExpand={true} width={this.state.isSmallScreen ? '100%' : 250} />
                        {this.renderPeopleFilter()}
                    </div>
                    <div style={{ ...sectionStyle, maxWidth: "unset", justifyContent: "flex-end", flexWrap: "wrap", gap: 8 }} >
                        {!this.state.isSmallScreen && this.state.activeTab == 'lista' &&
                            <DefaultButton
                                leftIcon={this.renderSwitch()}
                                color={Colors.secondaryButton}
                                textColor={Colors.dark}
                                loading={this.state.loading}
                                onClick={this.handleView}
                            />
                        }
                        {!this.state.isSmallScreen && this.state.activeTab != 'complexidade' &&
                            <DefaultButton
                                title={window.innerWidth < 1000 ? '' : 'Filtros'}
                                tooltipPlacement={'left'}
                                leftIcon={<FontAwesomeIcon icon={faFilter} style={{ color: this.state.showFilters ? Colors.white : Colors.dark }} />}
                                color={this.state.showFilters ? Colors.dark : Colors.secondaryButton}
                                textColor={this.state.showFilters ? Colors.white : Colors.dark}
                                loading={this.state.loading}
                                onClick={this.toggleFilters}
                            />
                        }
                        {this.renderTabs()}
                        {this.state.activeTab == 'timeline' ?
                            this.renderTimelineSelectors()
                            :
                            null
                        }
                    </div>
                </div>
                <Collapse in={this.state.showFilters}>
                    <div style={{ width: '100%', marginTop: 10, transition: "all 0.2s ease" }}>
                        <PlanoAcaoEtapaSearch filterCallback={this.filter} />
                    </div>
                </Collapse>
            </div>
        );
    }

    renderActiveTab() {
        switch (this.state.activeTab) {
            case 'lista':
                return (
                    <PlanoAcaoEtapasList
                        codigo={this.state.plano.codigo}
                        detailed={this.state.view !== 'normal'}
                        participantes={this.getPeople()}
                        filter={{
                            people: this.state.selectedPeople,
                            search: this.state.search,
                            status: this.state.status,
                            prioridades: this.state.prioridades,
                            data_inicio: this.state.data_inicio,
                            data_fim: this.state.data_fim
                        }}
                        hasAvaliador={this.state.plano?.superior}
                        isSmallScreen={this.state.isSmallScreen}
                        headerStickyTop={this.state.isSmallScreen ? "auto" : (this.state.headerHeight + this.state.utilityBarHeight)}
                        detailsModalCallback={this.detailsModalCallback}
                        updateEtapas={() => this.loadEtapasCallback()}
                        shouldUpdateEtapas={this.state.shouldUpdateEtapas}
                    />
                );
            case 'quadro':
                return (
                    <PlanoAcaoQuadro
                        codigo={this.state.plano.codigo}
                        detailed={this.state.view !== 'normal'}
                        participantes={this.getPeople()}
                        filter={{
                            people: this.state.selectedPeople,
                            search: this.state.search,
                            status: this.state.status,
                            prioridades: this.state.prioridades,
                            data_inicio: this.state.data_inicio,
                            data_fim: this.state.data_fim
                        }}
                        detailsModalCallback={this.detailsModalCallback}
                        updateEtapas={() => this.loadEtapasCallback()}
                        shouldUpdateEtapas={this.state.shouldUpdateEtapas}
                        isSmallScreen={this.state.isSmallScreen}
                    />
                );
            case 'timeline':
                return (
                    <PlanoAcaoTimeline
                        codigo={this.state.plano.codigo}
                        detailed={this.state.view !== 'normal'}
                        participantes={this.getPeople()}
                        filter={{
                            people: this.state.selectedPeople,
                            search: this.state.search,
                            status: this.state.status,
                            prioridades: this.state.prioridades,
                            data_inicio: this.state.data_inicio,
                            data_fim: this.state.data_fim
                        }}
                        detailsModalCallback={this.detailsModalCallback}
                        updateEtapas={() => this.loadEtapasCallback()}
                        shouldUpdateEtapas={this.state.shouldUpdateEtapas}
                        isSmallScreen={this.state.isSmallScreen}
                        responsavel={this.state.plano.responsavel}
                        granularidade={this.state.activeTabTimeline}
                        timelineSort={this.state.timelineSort}
                    />
                );
            case 'complexidade':
                return (
                    <PlanoAcaoConfigComplexibilidade
                        codigo={this.state.plano.codigo}
                        filter={{
                            people: this.state.selectedPeople,
                            search: this.state.search,
                            status: this.state.status,
                            prioridades: this.state.prioridades,
                            data_inicio: this.state.data_inicio,
                            data_fim: this.state.data_fim
                        }}
                        participantes={this.getPeople()}
                        isSmallScreen={this.state.isSmallScreen}
                        detailsModalCallback={this.detailsModalCallback}
                        updateEtapas={() => this.loadEtapasCallback()}
                        shouldUpdateEtapas={this.state.shouldUpdateEtapas}
                    />
                );
            default:
                return <div />;
        }
    }

    renderDetailsModal() {

        if (!this.state.showDetailsModal) return null;

        return (
            <PlanoAcaoEtapaModal
                idEtapa={this.state.showDetailsModal}
                isSmallScreen={this.state.isSmallScreen}
                participantes={this.getPeople() || []}
                codigoPlano={this.state.plano?.codigo}
                hasApproval={this.state.plano?.superior && this.state.plano?.aprovacao && this.state.countEtapas > 0}
                detailsModalCallback={this.detailsModalCallback}
                updateEtapasCallback={this.updateEtapasCallback}
            />
        )
    }

    render() {
        return this.state.loading ? <LoadingPage /> : this.renderPage();
    }
}