import React from "react";

import Colors from "../../constants/Colors";
import EssentialStyle from "../../style/EssentialStyle";
import DefaultButton from "./DefaultButton";
import IdiomaHelper from "../../helper/IdiomaHelper";

export default class Tabs extends React.Component {
    state = {
        activeTab: this.props.activeTab,
        tabs: this.props.tabs,
        isSmallScreen: this.props.isSmallScreen
    }

    componentDidMount() {
        this.setState({ activeTab: this.props.activeTab, tabs: this.props.tabs });
    }

    componentDidUpdate(prevProps) {
        if (prevProps.isSmallScreen !== this.props.isSmallScreen) {
            this.setState({ isSmallScreen: this.props.isSmallScreen });
        }

        if(this.props.activeTab != prevProps.activeTab) {
            this.setState({ activeTab: this.props.activeTab });
        }

        if(this.props.tabs != prevProps.tabs) {
            this.setState({ tabs: this.props.tabs });
        }
    }

    handleTabChange = (tab) => {
        if(this.state.activeTab === tab.name) return;
        this.setState({ activeTab: tab.name }, () => {
            this.props.onTabChange(tab);
        });
    }

    renderTabs() {
        return (
            <div style={{ ...EssentialStyle.rowSpaceBetween, backgroundColor: Colors.secondaryButton, borderRadius: 5, marginTop: this.state.isSmallScreen ? 10 : 0, ...this.props.style}}>
                {this.state.tabs.map((tab, index) => {
                    var title = null;
                    var color = null;

                    if (typeof tab.title === 'object' && tab.title !== null && tab.title[IdiomaHelper.getIdioma()]) {
                        title = this.props.lang ? tab.title[this.props.lang] : tab.title[IdiomaHelper.getIdioma()];
                    } else {
                        if(typeof tab.title === 'string') title = tab.title;
                    }

                    if (this.state.activeTab === tab.name) {
                        color = this.props.primaryBackgroundColor || Colors.dark;
                        color = tab.primaryButton || color;
                    } else {
                        color = this.props.secondaryBackgroundColor || Colors.secondaryButton;
                        color = tab.secondaryButton || color;
                    }

                    return (
                        <DefaultButton
                            tooltip={this.props.onlyIcon && title}
                            key={`tab-${index}`}
                            title={!this.props.onlyIcon && title}
                            leftIcon={tab.icon || null} 
                            color={color}
                            textColor={this.state.activeTab === tab.name ? this.props.primaryColor || Colors.white : this.props.secondaryColor || Colors.dark}
                            onClick={() => { this.handleTabChange(tab) }}
                            style={{...this.props.tabStyle, ...(tab.name == this.state.activeTab && this.props.activeTabStyle)}}
                        />
                    );
                })}
            </div>
        )
    }

    render() {
        return this.renderTabs();
    }
}