import React from "react";

import Select from 'react-select';
import { components } from "react-select";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faCheck } from '@fortawesome/free-solid-svg-icons';
import { toast } from "react-toastify";

import EssentialStyle from "../../../style/EssentialStyle";
import DataHelper from "../../../helper/DataHelper";
import Sig from "../../../api/Sig";
import DefaultButton from "../../tools/DefaultButton";
import Colors from "../../../constants/Colors";
import CustomConfirm from "../../tools/CustomConfirm";
import { confirmAlert } from "react-confirm-alert";
import SessionHelper from "../../../helper/SessionHelper";

export default class PlanoAcaoEtapaDependenciaAdd extends React.Component {
    state = {
        loading: true,
        tipos: this.props.tipos,
        selectedTipo: this.props.defaultTipo,
        selectedDependecia: null,
        dependenciasPossiveis: [],
    }

    async componentDidMount() {
        await this.loadDependenciasPossiveis();
    }

    async loadDependenciasPossiveis() {
        this.setState({ loading: true });

        let dependencias = await Sig.request('POST', 'planoAcao/listDependenciasPossiveis', { id: this.props.id || 0 });

        this.setState({ dependenciasPossiveis: dependencias }, () => { this.setState({ loading: false }) });
    }

    renderSelectTipo = () => {
        return (
            <div
                style={{
                    ...EssentialStyle.rowFlexStart,
                    width: '202px',
                    fontSize: 14
                }}
            >
                <Select
                    ref={ref => this.selectPrioridade = ref}
                    styles={{
                        container: (base, state) => ({
                            ...base,
                            width: '100%',
                        }),
                        control: (base, state) => ({
                            ...base,
                            ...EssentialStyle.titleBoxHomePage,
                            width: '100%',
                            backgroundColor: "transparent"
                        })
                    }}
                    value={this.state.selectedTipo}
                    options={this.state.tipos}
                    placeholder={''}
                    noOptionsMessage={DataHelper.getSelectEmptyMessage}
                    isSearchable={false}
                    closeMenuOnSelect={true}
                    components={{ IndicatorSeparator: null }}
                    onChange={async (value) => {
                        this.setState({
                            selectedTipo: value
                        }, () => {
                            this.props.changeCallback(this.state.selectedTipo, this.state.selectedDependecia);
                        });
                    }}
                />

            </div>
        );
    }

    renderSelectDepedencia = () => {
        var options = this.state.dependenciasPossiveis.filter(dep => dep.tiposRestritos == null || !dep.tiposRestritos.includes(this.state.selectedTipo.value));

        return (
            <div
                style={{
                    ...EssentialStyle.rowFlexStart,
                    width: 'calc(100% - 202px)',
                    paddingLeft: 6,
                    fontSize: 14
                }}
            >
                <Select
                    ref={ref => this.selectedDependecia = ref}
                    styles={{
                        container: (base, state) => ({
                            ...base,
                            width: '100%',
                        }),
                        control: (base, state) => ({
                            ...base,
                            ...EssentialStyle.titleBoxHomePage,
                            width: '100%',
                            backgroundColor: "transparent"
                        })
                    }}
                    value={this.state.selectedDependecia}
                    options={DataHelper.formatSelectData(options, 'id', 'atividade')}
                    formatOptionLabel={({ label, value }) => (
                        <div style={{
                            ...EssentialStyle.rowFlexStart,
                            paddingLeft: 8,
                            fontWeight: 400,
                            width: '100%',
                            cursor: 'pointer'
                        }}>
                            {DataHelper.removeHtmlTags(label)}
                        </div>
                    )}
                    placeholder={'Selecione a Dependência'}
                    noOptionsMessage={DataHelper.getSelectEmptyMessage}
                    isSearchable={true}
                    closeMenuOnSelect={true}
                    components={{ IndicatorSeparator: null }}
                    onChange={async (value) => {
                        this.setState({
                            selectedDependecia: value
                        }, () => {
                            this.props.changeCallback(this.state.selectedTipo, this.state.selectedDependecia);
                        });
                    }}
                />
            </div>
        );
    }

    blockWarningModal = () => { 
        return (
            confirmAlert({
                customUI: ({ onClose }) => (
                    <CustomConfirm
                        title={'Deseja adicionar essa dependência entre etapas?'}
                        message={<p style={{ marginTop: 10, marginBottom: 10 }}>As datas da etapa dependente serão alteradas automaticamente de acordo com alterações de datas na etapa que a bloqueia.</p>}
                        buttons={[
                            {
                                label: 'Adicionar',
                                color: SessionHelper.getColor(),
                                textColor: Colors.light,
                                onClick: () => { this.saveDependencia(); onClose(); }
                            },
                            {
                                label: 'Cancelar',
                                onClick: () => { onClose(); }
                            },
                        ]}
                    />
                )
            })
        )
    }

    validateDependencia = () => {
        if (!this.state.selectedTipo?.value < 0 || !this.state.selectedDependecia?.value) return toast.info('Selecione a depedência');
        
        if(this.state.selectedTipo.value == 0 || this.state.selectedTipo.value == 1){
            this.blockWarningModal();
            return;
        }

        this.saveDependencia();
    }

    saveDependencia = async () => {
        this.setState({ loading: true });

        var tipo = this.state.selectedTipo.value

        await Sig.request('POST', 'planoAcao/saveEtapaDependencia', { 
            id_plano_acao_etapa_origem: this.props.id || 0, 
            id_plano_acao_etapa_alvo: this.state.selectedDependecia.value, 
            tipo: tipo
        });

        this.setState({ addingNew: false, selectedDependecia: null }, () => { 
            this.setState({ loading: false });
            this.props.saveCallback(tipo != 0); 
        });
    }

    render() {
        return (
            <div style={{ ...EssentialStyle.columnCenter, width: '100%' }}>
                <div style={{ ...EssentialStyle.rowSpaceBetween, width: '100%' }}>
                    {this.renderSelectTipo()}
                    {!this.state.loading && this.renderSelectDepedencia()}
                </div>
                <div style={{ ...EssentialStyle.rowSpaceBetween, width: '100%', marginTop: 10 }}>
                    <DefaultButton
                        title={'Cancelar'}
                        color={Colors.error}
                        textColor={Colors.white}
                        leftIcon={<FontAwesomeIcon icon={faTimes} />}
                        loading={this.state.loading}
                        style={{ width: '49%' }}
                        onClick={() => {
                            this.setState({ addingNew: false, addingSelectedDependencia: null });
                            this.props.saveCallback(true);
                        }}
                    />
                    <DefaultButton
                        title={'Adicionar'}
                        color={Colors.success}
                        textColor={Colors.white}
                        leftIcon={<FontAwesomeIcon icon={faCheck} />}
                        loading={this.state.loading}
                        style={{ width: '49%' }}
                        onClick={this.validateDependencia}
                    />
                </div>
            </div>
        )
    }
}