import React from "react";
import ReactDOM from 'react-dom';

import Sig from "../../../../../api/Sig";
import EssentialStyle from "../../../../../style/EssentialStyle";
import Colors from "../../../../../constants/Colors";
import moment from 'moment';

import UserAvatar from "../../../../tools/UserAvatar";
import DefaultLoader from "../../../../tools/DefaultLoader";
import CustomTooltip from "../../../../tools/CustomTooltip";
import CustomConfirm from "../../../../tools/CustomConfirm";
import DefaultButton from "../../../../tools/DefaultButton";
import TooManyAvatars from "../../../../tools/TooManyAvatars";
import ProgressBarCustom from "../../../../tools/ProgressBar/ProgressBarCustom";

import { toast } from "react-toastify";
import { confirmAlert } from "react-confirm-alert";
import { Collapse, Form, Dropdown } from "react-bootstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faEye, faPlusSquare, faEllipsis, faCalendar, faEdit, faTimes, faCheck, faChevronDown, faChevronUp, faExclamationTriangle, faAdd, faStar } from '@fortawesome/free-solid-svg-icons';

import DataHelper from "../../../../../helper/DataHelper";
import SessionHelper from "../../../../../helper/SessionHelper";
import ProjetoHelper from "../../../../../helper/pe/ProjetoHelper";

import './ProjetoGestaoView.css';

import DescricaoProjeto from "../DescricaoProjeto/DescricaoProjeto";
import GraficosProjeto from "../GraficosProjeto/GraficosProjeto";
import IndicadoresObjetivo from "../Objetivos/IndicadoresObjetivo/IndicadoresObjetivo";
import DetalhamentoMarcosCollapsable from "../DetalhamentoMarcosCollapsable/DetalhamentoMarcosCollapsable";
import ModalVincularProjetoMapa from "../ModalVincularProjetoMapa/ModalVincularProjetoMapa";



export default class ProjetoGestaoView extends React.Component {

    state = {
        loading: true,
        id: this.props.id,
        isSmallScreen: this.props.isSmallScreen,

        showDescricao: false,
        showGraficos: false,
        showIndicadores: false,
        showDetalhamento: false,

        activeTab: 'lista',
        activeTimelineTab: 'ano',
        modalAddPlano: false,
        creatingCanvas: false,
        showModalVincularMapa: false,
    }

    async componentDidMount() {
        await this.loadProjeto();
    }

    async componentDidUpdate(prevProps) {
        if (prevProps.id !== this.props.id) {
            this.setState({ id: this.props.id }, async () => {
                await this.loadProjeto();
            });
        }

        if (prevProps.isSmallScreen !== this.props.isSmallScreen) {
            this.setState({ isSmallScreen: this.props.isSmallScreen });
        }
    }

    async loadProjeto() {
        this.setState({ loading: true });

        let { projeto, status_text, dataInicio, dataFim, porcentagemConclusao, equipe, objetivo, canvas, marcosCanvas, orcamento, podeFavoritarProjeto, podeCriaCanvas, podeVisualizarCanvas, tiposProgresso } = (await Sig.request('GET', 'pe/projeto/getProjeto', { id: this.state.id, relations: 1 }));
        let progressGut = await ProjetoHelper.getGutItemValue(this.state.id);
        canvas = canvas?.length > 0 ? canvas[0] : false;
        this.setState({
            progressGut,
            porcentagemConclusao,
            dataInicio,
            dataFim,
            status: status_text,
            participantes: equipe || [],
            projeto,
            objetivo,
            canvas,
            marcosCanvas,
            orcamento,
            podeFavoritarProjeto,
            podeCriaCanvas,
            podeVisualizarCanvas,
            tiposProgresso,
            loading: false
        });
    }

    async modifyProgressPlanos(){
        let response = await Sig.request('POST', 'pe/projeto/modifyProgressPlanos', { id: this.state.projeto.id });

        if (response.success) {
            await this.loadProjeto();
            return toast.success('Planos modificados com sucesso');
        } else {
            return toast.error('Erro ao modificar Planos');
        }
    }

    toggleFavorite = async () => {
        let { projeto } = await Sig.request('POST', 'pe/projeto/toggleFavorito', { id: this.state.projeto.id });

        if (projeto.id) {
            this.setState({ projeto });
        } else {
            toast.error('Erro ao marcar projeto como favorito');
        }
    }

    hasWarning() {
        let hasWarning = false;
    
        if (this.state.canvas && this.state.marcosCanvas && this.state.marcosCanvas.length > 0) {
            for (let i = 0; i < this.state.marcosCanvas.length; i++) {
                let marco = this.state.marcosCanvas[i];
                if (marco.planoAcao && marco.planoAcao.tipoProgresso !== this.state.projeto.tipo_progresso) {
                    hasWarning = <span>Divergências nas metodologias de progresso no seu Projeto! <hr/><strong>Clique para utilizar somente a metodologia do seu Projeto</strong></span>;
                    break;
                }
            }
        }
    
        return hasWarning;
    }

    vinculaProjeto(id) {
        this.setState({ showModalVincularMapa: this.state.showModalVincularMapa == id ? false : id });
    }

    renderHeader() {

        const renderPeriodo = () => {
            return (
                <div style={{ ...EssentialStyle.rowFlexStart, marginLeft: 2, backgroundColor: Colors.tag, color: Colors.dark, fontSize: 12, padding: 5, borderRadius: 5, fontWeight: 600 }}>
                    <FontAwesomeIcon icon={faCalendar} style={{ color: Colors.dark, fontSize: 14, marginRight: 4 }} />
                    <span>{this.state.dataInicio != 0 ? moment(this.state.dataInicio).format('DD/MM/YY') : "- -"}{this.state.dataFim ? `~ ${moment(this.state.dataFim).format('DD/MM/YY')}` : ``}</span>
                </div>
            );
        }

        const renderStatus = () => {

            let status = this.state.status || 'Não Iniciado';
            return (
                status == 'Em Desenvolvimento' || status == 'Atrasado' || status == 'Concluído' ?
                    <ProgressBarCustom 
                        tipo={'projeto'} 
                        id={this.state.id} 
                        backgroundColor={Colors.planoAcao.status.backgroundColor[status]} 
                        color={Colors.planoAcao.status.color[status]} 
                        style={{width: '100%', fontWeight: 'bold'}}
                        completeEfect={'Concluído'}
                    />
                    :
                    <>
                        Status 
                        <div style={{ ...EssentialStyle.rowFlexStart, marginLeft: 2 }}>
                            <div style={{ backgroundColor: Colors.planoAcao.status.backgroundColor[status], color: Colors.planoAcao.status.color[status], fontSize: 12, padding: 5, borderRadius: 5, marginRight: 2, fontWeight: 600 }}>{status}</div>
                        </div>
                    </>
                    
            );

            
        }

        const renderPeople = () => {
            return (
                <div style={{ ...EssentialStyle.rowFlexStart, gap: 4 }}>
                    <UserAvatar id={this.state.projeto?.id_responsavel} showName={true} />
                    {this.state.participantes?.length ?
                        <>
                            <span style={{ borderRight: `1px solid ${Colors.dark}`, height: 25, width: 1 }}></span>
                            <div style={{ ...EssentialStyle.rowFlexStart, marginLeft: 5 }}>
                                <TooManyAvatars participantsTotal={this.state.participantes?.length} participantsVisiveis={5} arrayParticipants={this.state.participantes} />
                            </div>
                        </>
                        : null}
                </div>
            )
        }

        const renderPriorizacao = () => {
            return (
                <div style={{...EssentialStyle.rowFlexCenter, gap: 10}}>
                    <div style={{ ...EssentialStyle.rowFlexStart, gap: 5 }}>
                        <div style={{ ...EssentialStyle.rowFlexStart, gap: 5 }}>
                            <div style={{ fontSize: 16, color: Colors.homePage.grey, fontWeight: 400 }}>Pontuação: {this.state.progressGut}</div>
                        </div>
                    </div>
                    |
                    <CustomTooltip placement={"left"} tooltip={!this.state.podeFavoritarProjeto ? "Sem Permissão Para Favoritar Projetos" : (this.state.projeto?.favorito == 1 ? 'Remover prioridade' : 'Marcar como prioritário') }>
                        <FontAwesomeIcon
                            icon={faStar}
                            onClick={(e) => {
                                e.stopPropagation();
                                if (this.state.podeFavoritarProjeto) {
                                    this.toggleFavorite();
                                }
                                    
                            }}
                            style={{
                                color: this.state.projeto?.favorito == 1 ? Colors.danger : Colors.disabled,
                                cursor: 'pointer',
                                fontSize: 24
                            }}
                        />
                    </CustomTooltip>
                </div>
            )
        }

        const renderOptions = () => {
            const portalTarget = document.getElementById('root');
            return (
                <Dropdown className="optionsProjeto" onClick={(e) => { e.stopPropagation() }}>
                    <CustomTooltip style={{ height: 'auto' }} placement={"auto"} tooltip={"Opções do Projeto"}>
                        <Dropdown.Toggle
                            onChange={() => {}}
                            role="button"
                            style={{ 
                                    backgroundColor: Colors.secondaryButton, 
                                    color: Colors.dark, 
                                    border: 'none',
                                    zIndex: 25
                            }}
                            drop={'top'}
                        > 
                            <FontAwesomeIcon icon={faEllipsis} />
                        </Dropdown.Toggle>
                    </CustomTooltip>
                    {ReactDOM.createPortal(
                        <Dropdown.Menu style={{padding: 2, color: Colors.dark}}> 
                            <Dropdown.Item 
                                key={`delete-projeto-btn`} 
                                onClick={ this.renderDeleteProjetoModal }
                            >
                                    <span style={{ fontSize: 14 }}>Excluir Projeto</span>
                            </Dropdown.Item>
                            {
                                !this.state.objetivo &&
                                <Dropdown.Item 
                                    key={`add-vinculpo-btn`} 
                                    onClick={ () => {this.vinculaProjeto(this.state.id)} }
                                >
                                        <span style={{ fontSize: 14 }}>Vincular com Mapa Estratégico</span>
                                </Dropdown.Item>
                            }
                        </Dropdown.Menu>
                    , portalTarget)}
                </Dropdown>
            );
        }

        const renderCanvas = () => {
            return (
                <div style={{...EssentialStyle.rowFlexEnd, gap: 10}}>
                    {this.state.creatingCanvas ?
                    <div style={{ ...EssentialStyle.rowFlexStart,}}>
                        <Form.Label>Criar canvas?</Form.Label>
                        <div style={{ ...EssentialStyle.rowFlexStart, marginLeft: 20, width: 85, justifyContent: 'space-between' }}>
                            <DefaultButton
                                tooltip={'Adicionar'}
                                color={Colors.success}
                                leftIcon={<FontAwesomeIcon icon={faCheck} />}
                                onClick={(e) => {
                                    e.stopPropagation(); 
                                    this.addCanvas();
                                }}
                            />
                            <DefaultButton
                                tooltip={'Cancelar'}
                                textColor={Colors.dark}
                                color={Colors.secondaryButton}
                                leftIcon={<FontAwesomeIcon icon={faTimes} />}
                                onClick={(e) => { 
                                    e.stopPropagation();
                                    this.setState({ creatingCanvas: false }) 
                                }}
                            />
                        </div>
                    </div>
                    :
                    <DefaultButton
                        color={this.state.canvas ? Colors.dark : Colors.success}
                        title={this.state.canvas ? "Ver Canvas" : "Criar Canvas"}
                        tooltip={this.state.canvas ? (!this.state.podeVisualizarCanvas ? "Sem Permissão para Visualizar Canvas do Projeto" : "") : (!this.state.podeCriaCanvas ?  "Sem Permissão para Criar Canvas de Projeto" : "")}
                        leftIcon={this.state.canvas ? <FontAwesomeIcon icon={faEye} /> : <FontAwesomeIcon icon={faPlusSquare} />}
                        disabled={this.state.canvas ? !this.state.podeVisualizarCanvas : !this.state.podeCriaCanvas}
                        onMouseDown={(e) => {
                            if (e.button == 1 && this.state.canvas) {
                                e.stopPropagation();
                                e.preventDefault();
                                window.parent.postMessage({ type: 'navigate', url: `/pe/canvas?id=${this.state.canvas.id}` }, '*');
                            }  
                        }}
                        onClick={(e) => {
                            e.stopPropagation();
                            if (this.state.canvas) {
                                if(e.ctrlKey || e.metaKey) {
                                    window.parent.postMessage({ type: 'navigate', url: `/pe/canvas?id=${this.state.canvas.id}` }, '*');
                                }
                                else {
                                    window.parent.postMessage({ type: '_blank', url: `/pe/canvas?id=${this.state.canvas.id}` }, '*');
                                }
                            } else {
                                this.setState({ creatingCanvas: true });
                            }
                        }}
                    />}
                    {renderOptions()}
                </div>
            )
        }

        const renderWarning = () => {
            return (
                this.hasWarning() ?
                    <CustomTooltip tooltip={this.hasWarning()} placement="left">
                        <FontAwesomeIcon 
                            icon={faExclamationTriangle} 
                            style={{ marginRight: 10, cursor: 'pointer' }} 
                            className={'icon warning'}
                            onClick={(e) => {
                                e.preventDefault();
                                renderConfirmModifyProgressPlanos()
                            }} />
                    </CustomTooltip>
                    :
                    null
            )
        }

        const renderConfirmModifyProgressPlanos = () => {

            let message = "Ao aceitar, os Planos de Ação vinculados aos marcos adotarão a mesma metodologia padrão de progresso do projeto, que, por sua vez, segue a metodologia do Mapa Estratégico (se houver um Mapa Estratégico).";
            return (
                confirmAlert({
                    customUI: ({ onClose }) => (
                        <CustomConfirm
                            title={'Alterar Metodologias de Progresso?'}
                            message={<p style={{ marginTop: 10, marginBottom: 10 }}>{message}</p>}
                            buttons={[
                                {
                                    label: 'Aceitar',
                                    color: SessionHelper.getColor(),
                                    textColor: Colors.light,
                                    onClick: () => { this.modifyProgressPlanos(); onClose(); }
                                },
                                {
                                    label: 'Cancelar',
                                    onClick: () => { onClose(); }
                                },
                            ]}
                        />
                    )
                })
            )
        }

        const renderLeft = () => {
            return (
                <div className={'column'} style={{ width: this.state.isSmallScreen ? '100%' : 'calc(40%)' }}>
                    <div style={{ ...EssentialStyle.rowFlexStart, gap: 10 }}>
                        <DefaultButton tooltipPlacement={'bottom'} leftIcon={<FontAwesomeIcon icon={faChevronLeft} />} color={Colors.secondaryButton} textColor={Colors.dark} loading={this.state.loading} onClick={() => { window.parent.postMessage({ type: 'navigate', url: `/pe/projeto/detalhar` }, '*'); }} />
                        <span className={"title"}>{this.state.projeto?.nome}</span>
                    </div>

                    <span className={"subTitle"}>
                        {this.state.objetivo ?
                            DataHelper.removeHtmlTags(this.state.objetivo?.valor)
                            :
                            'Sem vínculo com Mapa Estratégico'
                        }
                    </span>

                    <div className={"colaboradores"}>
                        {renderPeople()}
                    </div>
                </div>
            )
        }

        const renderCenter = () => {
            return (
                <div className={'column'} style={{ width: this.state.isSmallScreen ? '100%' : 'calc(30%)', height: '100%' }}>
                    <span className={"date"}>Período {renderPeriodo()}</span>
                    <span className={"metodologia"}>Metodologia <span style={{fontWeight: 'bold'}}>{this.state.tiposProgresso[Number(this.state.projeto.tipo_progresso)]}</span></span>
                    <span className={"status"}>{renderStatus()}</span>
                </div>
            )
        }

        const renderRight = () => {
            return (
                <div className={'column'} style={{ width: this.state.isSmallScreen ? '100%' : 'calc(30%)', height: '100%', alignItems: 'end' }}>
                    <div className={'canvas'}>{renderCanvas()}</div>
                    {renderWarning()}
                    <div className={'priorizacao'}>{renderPriorizacao()}</div>
                </div>
            )
        }

        return (
            <div className={"ProjetoGestaoView_headerCard"} style={{ ...(this.state.isSmallScreen ? EssentialStyle.columnStart : EssentialStyle.rowSpaceBetween), alignItens: 'end' }}>
                {this.state.loading ?
                    <div className={"loading"}><DefaultLoader /></div>
                    :
                    <>
                        {renderLeft()}
                        {renderCenter()}
                        {renderRight()}
                    </>}
            </div>
        )
    }

    renderGraficos() {
        return (
            <div className={"ProjetoGestaoView_graficos"}>
                <div
                    style={{ ...EssentialStyle.rowFlexCenter, cursor: 'pointer', gap: 10 }}
                    onClick={() => { this.setState({ showGraficos: !this.state.showGraficos }) }}
                >
                    <FontAwesomeIcon icon={this.state.showGraficos ? faChevronUp : faChevronDown} />
                    <span>Gráficos</span>
                </div>

                <Collapse in={this.state.showGraficos}>
                    <div style={{width: '100%'}}>
                        <GraficosProjeto 
                            id={this.state.id} 
                            isSmallScreen={this.state.isSmallScreen}
                            style={{width: '100%', height: '15rem'}}
                        />
                    </div>
                </Collapse>
            </div>
        )
    }

    renderDescricao() {
        return (
            <div className={"ProjetoGestaoView_descricao"}>
                <div
                    style={{ ...EssentialStyle.rowFlexCenter, cursor: 'pointer', gap: 10 }}
                    onClick={() => { this.setState({ showDescricao: !this.state.showDescricao }) }}
                >
                    <FontAwesomeIcon icon={this.state.showDescricao ? faChevronUp : faChevronDown} />
                    <span>Descrição</span>
                </div>

                <Collapse in={this.state.showDescricao}>
                    <div style={{width: '100%'}}>
                        <DescricaoProjeto id={this.state.id} isSmallScreen={this.state.isSmallScreen} />
                    </div>
                </Collapse>
            </div>
        )
    }

    renderIndicadores() {
        return (
            <div className={"ProjetoGestaoView_indicadores"}>
                <div
                    style={{ ...EssentialStyle.rowFlexCenter, cursor: 'pointer', gap: 10 }}
                    onClick={() => { this.setState({ showIndicadores: !this.state.showIndicadores }) }}
                >
                    <FontAwesomeIcon icon={this.state.showIndicadores ? faChevronUp : faChevronDown} />
                    <span>Indicadores</span>
                </div>

                <Collapse in={this.state.showIndicadores}>
                    <div style={{width: '100%'}}>
                        <IndicadoresObjetivo 
                            idProjeto={this.state.id}
                            isSmallScreen={this.state.isSmallScreen} 
                        />
                    </div>
                </Collapse>
            </div>
        )
    }

    renderDetalhamento() {

        if (this.state.loading) {
            return (
                <div className={"ProjetoGestaoView_detalhamento"} onClick={e => e.stopPropagation()}>
                    <div className={"loading"}><DefaultLoader /></div>
                </div>
            )
        }

        return (
            <div className={"ProjetoGestaoView_detalhamento"} onClick={e => e.stopPropagation()}>
                <DetalhamentoMarcosCollapsable
                    loading={false}
                    projeto={this.state.projeto}
                    marcosCanvas={this.state.marcosCanvas}
                    canvas={this.state.canvas}
                    orcamento={this.state.orcamento}
                    activeTab={this.state.activeTab}
                    activeTimelineTab={this.state.activeTimelineTab}
                    callbakLoadProjeto={() => {}}
                    hasMarginBottom={true}
                    isSmallScreen={this.props.isSmallScreen}
                />
            </div>
        )
    }

    render() {
        return (
            <div style={{ ...EssentialStyle.columnStart, with: '100vw', height: '100vh', padding: 7 }}>
                {this.renderHeader()}
                {this.renderGraficos()}
                {this.renderDescricao()}
                {this.renderIndicadores()}
                {this.renderDetalhamento()}
                {
                    this.state.showModalVincularMapa &&
                    <ModalVincularProjetoMapa
                        idProjeto={this.state.showModalVincularMapa}
                        closeModal={() => { this.vinculaProjeto(this.state.showModalVincularMapa) }}
                        reloadPage={() => { this.loadProjeto(); }}
                    />
                }
            </div>
        )
    }
}