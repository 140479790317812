import React from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { toast } from 'react-toastify';
import { faTimes, faCheck, faPencil, faPlusSquare } from '@fortawesome/free-solid-svg-icons'
import DefaultButton from "../../../tools/DefaultButton";
import Colors from "../../../../constants/Colors";
import { Form, Row, Col } from "react-bootstrap";
import DefaultLoader from "../../../tools/DefaultLoader";
import Sig from "../../../../api/Sig";
import EssentialStyle from "../../../../style/EssentialStyle";
import CustomTooltip from "../../../tools/CustomTooltip";
import SessionHelper from "../../../../helper/SessionHelper";



export default class MetaPlurianual extends React.Component {
    state = {
        id: null,
        ano: null,
        id_objetivo: null,
        id_indicador: null,
        id_gmr_painel: null,
        id_gmr_painel: null,
        metaNumerador: null,
        metaDenominador: null,
        metaValor: null,
        metaValorFormatado: null,

        metaNumeradorEditing: null,
        metaDenominadorEditing: null,


        inputs: null,

        loading: true,
        editing: false,

        hoverEdit: false,
    }

    componentDidMount() {
        this.loadData();
    }

    async loadData () {
        this.setState({loading: true});

        let meta = await Sig.request('GET', `pe/mapa/getMeta`, { id: (this.props.id ? this.props.id : null), ano: this.props.ano, id_objetivo: this.props.id_objetivo, id_indicador: this.props.id_indicador, id_gmr_painel: this.props.id_gmr_painel });

        if(meta) {
            this.setState({
                id: meta.id, 
                ano: meta.ano,
                id_objetivo: meta.id_objetivo,
                id_indicador: meta.id_indicador,
                id_gmr_painel: meta.id_gmr_painel,
                metaNumerador: meta.metaNumerador,
                metaNumeradorEditing: meta.metaNumerador,
                metaDenominador: meta.metaDenominador,
                metaDenominadorEditing: meta.metaDenominador,
                metaValor: meta.metaValor,
                metaValorFormatado: meta.metaValorFormatado,
                inputs: meta.inputs
            }, () => {this.setState({loading: false})});
        } else {
            this.setState({loading: false});
        }
    }

    async updateData() {

        if (this.state.inputs == 1 && (this.state.metaNumeradorEditing === null || this.state.metaNumeradorEditing === '')) {
            toast.error('Preencha o campo');
            return;
        } else if (this.state.inputs == 2 && (this.state.metaNumeradorEditing === null || this.state.metaNumeradorEditing === '' || this.state.metaDenominadorEditing === null || this.state.metaDenominadorEditing === '')) {
            toast.error('Preencha os campos');
            return;
        }

        this.setState({loading: true});

        let meta = await Sig.request('POST', `pe/mapa/updateMeta`, { id: this.state.id, metaNumerador: this.state.metaNumeradorEditing, metaDenominador: this.state.metaDenominadorEditing });

        if(meta) {
            this.setState({
                id: meta.id,
                metaNumerador: meta.metaNumerador,
                metaDenominador: meta.metaDenominador,
                metaValor: meta.metaValor,
                metaValorFormatado: meta.metaValorFormatado,
                editing: false,
                hoverEdit: false,
            }, () => {this.setState({loading: false})});

        } else {
            toast.error('Erro ao atualizar meta');
            this.setState({loading: false});
        }

        this.setState({loading: false});
    }

    handleEdit(type) {

        this.setState({loading: true}, () => {
            this.setState({
                editing: !this.state.editing, 
                metaNumeradorEditing: (type == 'cancel' ? this.state.metaNumerador : this.state.metaNumeradorEditing), 
                metaDenominadorEditing: (type == 'cancel' ? this.state.metaDenominador : this.state.metaDenominadorEditing), 
            }, () => {
                this.setState({loading: false, hoverEdit: false});
            });
        });

    }

    renderEditingSingle() {
        return(
            <div style={styles.container}>
                <div style={{ ...EssentialStyle.rowFlexCenter, marginBottom: 10 }}>
                    <CustomTooltip tooltip={'Valor'} placement={'bottom'} >
                        <spam style={styles.flag}>V</spam>
                    </CustomTooltip>
                    <Form.Control
                        type="text"
                        style={ styles.input }
                        value={this.state.metaNumeradorEditing}
                        onChange={(e) => {
                            const re = /^\d*\.?\d{0,2}$/;
                            if (e.target.value === '' || re.test(e.target.value)) {
                                this.setState({metaNumeradorEditing: e.target.value})
                            }
                        }}
                    />
                </div>

                <div style={{ ...EssentialStyle.rowFlexEnd, width: '100%', height: 25}}>
                    
                    <div
                        style={{ ...styles.buttomConfirm, backgroundColor: Colors.danger, marginRight: 5 }}
                        onClick={() => { this.handleEdit('cancel') }}
                    >
                        <FontAwesomeIcon
                            icon={faTimes}
                            style={{ color: 'white' }}
                        />
                    </div>

                    <div
                        style={{ ...styles.buttomConfirm, backgroundColor: Colors.success}}
                        onClick={() => { this.updateData() }}
                    >
                        <FontAwesomeIcon
                            icon={faCheck}
                            style={{ color: 'white' }}
                        />
                    </div>
                </div>
            </div>
        )
    }

    renderEditingMultiple() {
        return(
            <div style={styles.container}>
                <div style={{ ...EssentialStyle.rowFlexCenter, marginBottom: 3 }}>
                    <CustomTooltip tooltip={'Numerador'} placement={'bottom'} >
                        <spam style={styles.flag}>N</spam>
                    </CustomTooltip>
                    <Form.Control
                        type="text"
                        style={ styles.input }
                        value={this.state.metaNumeradorEditing}
                        onChange={(e) => {
                            const re = /^\d*\.?\d{0,2}$/;
                            if (e.target.value === '' || re.test(e.target.value)) {
                                this.setState({metaNumeradorEditing: e.target.value})
                            }
                        }}
                    />
                </div>

                <div style={{ ...EssentialStyle.rowFlexCenter, marginBottom: 10 }}>
                    <CustomTooltip tooltip={'Denominador'} placement={'top'} >
                        <spam style={styles.flag}>D</spam>
                    </CustomTooltip>
                    <Form.Control
                        type="text"
                        style={ styles.input }
                        value={this.state.metaDenominadorEditing}
                        onChange={(e) => {
                            const re = /^\d*\.?\d{0,2}$/;
                            if (e.target.value === '' || re.test(e.target.value)) {
                                this.setState({metaDenominadorEditing: e.target.value})
                            }
                        }}
                    />
                </div>

                <div style={{ ...EssentialStyle.rowFlexEnd, width: '100%', height: 25}}>
                    
                    <div
                        style={{ ...styles.buttomConfirm, backgroundColor: Colors.danger, marginRight: 5 }}
                        onClick={() => { this.handleEdit('cancel')}}
                    >
                        <FontAwesomeIcon
                            icon={faTimes}
                            style={{ color: 'white' }}
                        />
                    </div>

                    <div
                        style={{ ...styles.buttomConfirm, backgroundColor: Colors.success}}
                        onClick={() => { this.updateData() }}
                    >
                        <FontAwesomeIcon
                            icon={faCheck}
                            style={{ color: 'white' }}
                        />
                    </div>
                </div>
            </div>
        )
    }

    renderEditing() {
        return this.state.inputs == 1 ? this.renderEditingSingle() : this.renderEditingMultiple();
    }

    renderEmpty() {
        return(
            <div style={styles.container}>
                <DefaultButton
                    style={{ ...styles.buttom, minWidth: 60, minHeight: 30 }}
                    onClick={() => { this.handleEdit("edit") }}
                    leftIcon={<FontAwesomeIcon icon={faPlusSquare} />}
                    title="Meta" 
                    color={Colors.success}
                    loading={this.state.loading}
                />
            </div>
        )
    }

    renderView() {

        if (this.state.metaValor === null) return this.renderEmpty();

        return(
            <div 
                key={`${this.state.id}-meta`}
                style={{ ...styles.container, ...EssentialStyle.rowFlexCenter }}
                onMouseEnter={() => this.setState({hoverEdit: true})}
                onMouseLeave={() => this.setState({hoverEdit: false})}
            >
                <div>
                    {this.state.metaValorFormatado}
                </div>

                {
                    this.state.hoverEdit ? 
                    <div key={`${this.state.id}-buttom-edit`} style={{ marginLeft: 3 }} >
                        <div 
                            style={styles.buttomEditar}
                            onClick={() => { this.handleEdit("edit") }}
                        >
                            <CustomTooltip tooltip={'Editar'} placement={'top'} >
                                <div style={{ ...styles.buttomEditarIcon, backgroundColor: SessionHelper.getColor() }}>
                                    <FontAwesomeIcon icon={faPencil} style={{ color: 'white', fontSize: 14 }} />
                                </div>
                            </CustomTooltip>
                        </div>
                    </div> 
                    : null
                }
                <div>

                </div>
            </div>
        )
    }

    renderContent() {
        return(
            this.state.editing ? this.renderEditing() : this.renderView()
        )
    }

    render() {
        return (
            this.state.loading ? <DefaultLoader /> : this.renderContent()
        );
    }
}

const styles = {
    container: {
        ...EssentialStyle.columnCenter,
        padding: '10px 0px',
    },
    buttom: {
        ...EssentialStyle.rowFlexCenter,
        fontSize: 14,
    },
    buttomConfirm: {
        ...EssentialStyle.rowFlexCenter,
        fontSize: 14,
        width: 25, 
        height: 25,
        borderRadius: '50%', 
        cursor: 'pointer'
    },
    buttomEditar: {
        ...EssentialStyle.columnCenter, 
        backgroundColor: 'white', 
        borderRadius: '50%', 
        width: 30, 
        height: 30,
        cursor: 'pointer'
    },
    buttomEditarIcon: {
        ...EssentialStyle.columnCenter, 
        borderRadius: '50%', 
        width: 30, 
        height: 30,
    },
    flag: {
        ...EssentialStyle.rowFlexCenter,
        backgroundColor: Colors.homePage.extraLightGrey,
        borderRadius: 5, 
        width: 25, 
        height: 30,
        fontSize: 18
    },
    input: {
        marginLeft: 1,
        width: 'calc(100% - 26px)',
        height: 30,
        textAlign: 'center',
    }
}