import React from "react";

import DiagnosticoHelper from "../../../../../helper/diagnostico/DiagnosticoHelper";
import DefaultButton from "../../../../tools/DefaultButton";
import Colors from "../../../../../constants/Colors";
import { Form, Col, Dropdown, Button, ButtonGroup, Spinner } from 'react-bootstrap';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight, faExternalLinkAlt, faPlusSquare, faTimes } from "@fortawesome/free-solid-svg-icons";

import DefaultLoader from "../../../../tools/DefaultLoader";

import IdiomaHelper from "../../../../../helper/IdiomaHelper";
import CustomTooltip from "../../../../tools/CustomTooltip";
import moment from "moment";

import './RelatorioObservacao.css';
import UserAvatar from "../../../../tools/UserAvatar";
import TextEditor from "../../../../tools/TextEditor/TextEditor";
import { confirmAlert } from "react-confirm-alert";
import CustomConfirm from "../../../../tools/CustomConfirm";

export default class RelatorioObservacao extends React.Component {

    state = {

        lang: this.props.lang || IdiomaHelper.getIdioma(),
        isSmallScreen: this.props.isSmallScreen || false,

        loading: true,

        idObservacao: this.props.idObservacao || null,
        observacao: this.props.observacao || null,
        ColumnObservations: this.props.ColumnObservations || 1,
        edit: false,
        hoverEdit: false,
        timer: null,
        text: '',
    }

    componentDidMount() {
        this.loadData();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.ColumnObservations !== this.props.ColumnObservations) {
            this.setState({ ColumnObservations: this.props.ColumnObservations });
        }
    }

    loadData = async () => {

        this.setState({ loading: true });
        let text = '';

        if (!this.state.observacao) {
            // Buscar a recomendação pelo Id
        } else {
            text = this.state.observacao.text;
        }

        this.setState({ text, loading: false });
    }

    saveEditObservation = async () => {

        this.setState({ edit: false });

        let data = {
            ...this.state.observacao,
            text: this.state.text,
        }

        let observacao = await DiagnosticoHelper.editObservation(data);

        this.setState({ observacao });
    }

    deleteObservation = async () => {
        let message = IdiomaHelper.getStruct({
            'pt': {
                title: 'Excluir Observação',
                message: 'Deseja realmente excluir esta observação? Essa ação não pode ser desfeita',
                confirm: 'Excluir',
                cancel: 'Cancelar',
            },
            'en': {
                title: 'Delete Observation',
                message: 'Do you really want to delete this observation? This action cannot be undone',
                confirm: 'Delete',
                cancel: 'Cancel',
            },
            'es': {
                title: 'Eliminar Observación',
                message: '¿Realmente deseas eliminar esta observación? Esta acción no se puede deshacer',
                confirm: 'Eliminar',
                cancel: 'Cancelar',
            }
        });
        
        confirmAlert({
            customUI: ({ onClose }) => (
                <CustomConfirm
                    title={message[this.state.lang].title}
                    message={<p style={{ marginTop: 10, marginBottom: 10}}>{message[this.state.lang].message}</p>}
                    buttons={[
                        {
                            label: message[this.state.lang].confirm,
                            color: Colors.error,
                            textColor: Colors.light,
                            onClick: async () => {
                                
                                this.props.deleteObservation(this.state.observacao.id);
                                onClose();
                            }
                        },
                        {
                            label: message[this.state.lang].cancel,
                            onClick: () => { onClose(); }
                        },
                    ]}
                />
            )
        });
    }

    handleMouseEnter = () => {
        const timer = setTimeout(() => {
            this.setState({ hoverEdit: true });
        }, 800);
        this.setState({ timer });
    }

    handleMouseLeave = () => {
        clearTimeout(this.state.timer);
        this.setState({ hoverEdit: false, timer: null });
    }

    renderLoading() {

        let observacao = this.state.observacao;
        return(
            <Form.Group key={observacao.id} as={Col} md={12 / this.state.ColumnObservations} style={{ margin: 0, height: 180, padding: '0px 10px 10px 0px' }}>
                <div className="RelatorioObservacao observacao">
                    <DefaultLoader />
                </div>
            </Form.Group>
        )
    }

    renderCard() {

        let observacao = this.state.observacao;

        let message = IdiomaHelper.getStruct({
            'pt': {
                edited: 'Editado em', midle: 'às', edit: 'Editar', save: 'salvar', cancel: 'Cancelar', observationPlaceholder: 'Escreva sua observação', delete: 'Excluir',
            },
            'en': {
                edited: 'Edited in', midle: 'at', edit: 'Edit', save: 'Save', cancel: 'Cancel', observationPlaceholder: 'Write your observation', delete: 'Delete',
            },
            'es': {
                edited: 'Editado en', midle: 'a las', edit: 'Editar', save: 'Salvar', cancel: 'Cancelar', observationPlaceholder: 'Escribe tu observación', delete: 'Eliminar',
            }
        });

        const renderEditButton = () => {
            if ((this.state.hoverEdit && this.state.edit) || this.state.edit) {
                return (
                    <>
                        <DefaultButton
                            disabled={this.state.text == observacao.text}
                            title={message[this.state.lang].save}
                            color={Colors.success}
                            style={{ padding: '3px 10px' }}
                            onClick={() => { this.saveEditObservation() }}
                        />

                        <DefaultButton
                            title={message[this.state.lang].cancel}
                            color={Colors.error}
                            textColor={Colors.white}
                            style={{ padding: '3px 10px' }}
                            onClick={() => { this.setState({text: observacao.text, edit: false}) }}
                        />
                    </>
                )
            } else if (this.state.hoverEdit) {
                return (
                    <>
                        <DefaultButton
                            title={message[this.state.lang].delete}
                            color={Colors.error}
                            textColor={Colors.white}
                            style={{ padding: '3px 10px' }}
                            onClick={() => { this.deleteObservation() }}
                        />

                        <DefaultButton
                            title={message[this.state.lang].edit}
                            color={Colors.disabled}
                            textColor={Colors.white}
                            style={{ padding: '3px 10px' }}
                            onClick={() => { this.setState({ edit: true }) }}
                        />
                    </>
                )

            }
        }
        
        return (
            <Form.Group key={observacao.id} as={Col} md={12 / this.state.ColumnObservations} style={{ margin: 0, padding: '0px 10px 10px 0px', ...this.props.style }}>
                <div 
                    className="RelatorioObservacao observacao" 
                    key={observacao.id}
                    onMouseEnter={() => { this.props.canEdit && this.handleMouseEnter()}}
                    onMouseLeave={() => { this.props.canEdit && this.handleMouseLeave()}}
                >

                    <div className={"infos"}>
                        <UserAvatar id={observacao.id_colaborador} showName />
                        
                        <div className="info">

                            {renderEditButton()}

                            {observacao.data_edicao != null ? (
                                message[this.props.lang].edited + ' ' + moment(observacao.data_edicao).format(`D/MM/YYYY [${message[this.props.lang].midle}] HH:mm`)
                            ) : (
                                moment(observacao.data_criacao).format(`D/MM/YYYY [${message[this.props.lang].midle}] HH:mm`)
                            )}
                        </div>
                    </div>

                    {this.state.edit ? 
                        <TextEditor
                            defaultValue={this.state.text || ""}
                            onChange={(value) => { this.setState({ text: value }) }}
                            placeholder={message[this.props.lang].observationPlaceholder}
                        />
                        :
                        <div className={"descricao"}>
                            <div dangerouslySetInnerHTML={{ __html: observacao.text }} />
                        </div>
                    }
                    
                </div>
            </Form.Group>
        )
    }

    render() {
        if (this.state.loading) return this.renderLoading();
        return this.renderCard();
    }
}