import React from "react";
import ReactDOM from "react-dom";
import { forwardRef } from "react";
import Sig from "../../../api/Sig";
import moment from "moment";

import Highcharts from "highcharts/highcharts.js";
import accessibility from 'highcharts/modules/accessibility';
import HighchartsReact from "highcharts-react-official";

import './HistoryIndicador.css';
import Colors from "../../../constants/Colors";
import Tabs from "../Tabs";
import EssentialStyle from "../../../style/EssentialStyle";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChartLine, faTable, faChevronLeft, faChevronRight } from "@fortawesome/free-solid-svg-icons";
import { Form, Dropdown } from "react-bootstrap";
import DatePicker from "react-datepicker";
import Constants from "../../../constants/Api";
import SessionHelper from "../../../helper/SessionHelper";
import DefaultLoader from "../DefaultLoader";
import EllipsisText from "../EllipsisText";


const gateway = Constants.getSigEndPoint();

export default class HistoryIndicador extends React.Component {

    state = {
        loading: true,
        id_indicador: this.props.id_indicador,
        id_gmr_painel: this.props.id_gmr_painel,
        data_inicio: this.props.data_inicio,
        data_fim: this.props.data_fim,
        acumulado: this.props.acumulado,
        indicadorExpandedHistory: null,
        graphicOptions: [
            {
                value: 'ocultar',
                label: 'Valor Oculto',
            },
            {
                value: 'barra',
                label: 'Barra',
            },
            {
                value: 'linha',
                label: 'Linha',
            },
        ],
        configureGraphic: {
            metaRealizado: "barra",
            performance: "linha",
        },
        showEvolucaoResultado: this.props.showEvolucaoResultado,
    }

    async componentDidMount() {
        this.loadData();
    }

    async componentDidUpdate(prevProps) {
        if (prevProps.id_indicador !== this.props.id_indicador
            || prevProps.id_gmr_painel !== this.props.id_gmr_painel
            || prevProps.data_inicio !== this.props.data_inicio
            || prevProps.data_fim !== this.props.data_fim
            // || prevProps.acumulado !== this.props.acumulado
        ) {
            this.setState({
                id_indicador: this.props.id_indicador,
                id_gmr_painel: this.props.id_gmr_painel,
                data_inicio: this.props.data_inicio,
                data_fim: this.props.data_fim,
                // acumulado: this.props.acumulado,
            }, () => { this.loadData() });
        }

        if (prevProps.isSmallScreen !== this.props.isSmallScreen) {
            this.setState({ isSmallScreen: this.props.isSmallScreen });
        }

        if (prevProps.showEvolucaoResultado !== this.props.showEvolucaoResultado) {
            this.setState({ showEvolucaoResultado: this.props.showEvolucaoResultado });
        }
    }

    async loadData() {

        this.setState({ loading: true });
        var data_inicio = moment(this.state.data_inicio).format('YYYY-MM-DD HH:mm:ss');
        var data_fim = moment(this.state.data_fim).format('YYYY-MM-DD HH:mm:ss');

        let { indicadorExpandedHistory } = await Sig.request('POST', 'config/colaborador/getIndicadorHistory', {
            id_indicador: this.state.id_indicador,
            id_gmr_painel: this.state.id_gmr_painel,
            acumulado: this.state.acumulado,
            data_inicio,
            data_fim,
        });

        this.setState({ indicadorExpandedHistory, data_inicio, data_fim }, () => { this.calculaGrafico()});
    }

    calculaGrafico() {
        let unidade = this.state.indicadorExpandedHistory?.unidade;
        let moeda = this.state.indicadorExpandedHistory?.moedaSimbolo;
        let hasMetaAjustada = this.state.indicadorExpandedHistory?.hasMetaAjustada;

        let yAxis = [];
        let xAxis = [];
        let type = null;
        let series = [];
        let spacing = [0, 0, 0, 0];
        let tipoPerformance = this.state.configureGraphic.performance;
        let tipoMetaRealizado = this.state.configureGraphic.metaRealizado;

        if ((tipoMetaRealizado == "barra" || tipoPerformance == "barra") && (tipoMetaRealizado == "linha" || tipoPerformance == "linha")) {
            type = 'xy';
            yAxis = [
                {// Primary yAxis
                    title: {
                        text: tipoMetaRealizado == "barra" ? "Meta e Realizado" : "Performance",
                        style: {
                            color: Colors.dark
                        }
                    },
                    labels: {
                        formatter: function () {
                            if (tipoMetaRealizado == "barra") {
                                if (unidade == 0 || unidade == 1) {
                                    return moeda + " " + this.value.toLocaleString('pt-BR', { minimumFractionDigits: 2 });
                                } else if (unidade == 2 || unidade == 3) {
                                    return this.value + '%';
                                } else if (unidade == 6 || unidade == 7) {
                                    return this.value + ' Dias';
                                } else if (unidade == 8 || unidade == 9) {
                                    return this.value + ' Horas';
                                } else {
                                    return this.value;
                                }
                            } else {
                                return this.value + '%';
                            }
                        },
                        style: {
                            color: Colors.dark
                        }
                    }
                },
                { // Secondary yAxis
                    title: {
                        text: tipoMetaRealizado == "linha" ? "Meta e Realizado" : "Performance",
                        style: {
                            color: Colors.dark
                        }
                    },
                    labels: {
                        formatter: function () {
                            if (tipoMetaRealizado == "linha") {
                                if (unidade == 0 || unidade == 1) {
                                    return moeda + " " + this.value.toLocaleString('pt-BR', { minimumFractionDigits: 2 });
                                } else if (unidade == 2 || unidade == 3) {
                                    return this.value + '%';
                                } else if (unidade == 6 || unidade == 7) {
                                    return this.value + ' Dias';
                                } else if (unidade == 8 || unidade == 9) {
                                    return this.value + ' Horas';
                                } else {
                                    return this.value;
                                }
                            } else {
                                return this.value + '%';
                            }
                        },
                        style: {
                            color: Colors.dark
                        }
                    },
                    opposite: true
                }]
        } else if ((tipoMetaRealizado == "linha" && tipoPerformance == "linha") || (tipoMetaRealizado == "barra" && tipoPerformance == "barra")) {
            type = tipoMetaRealizado == "linha" ? 'spline' : 'column';
            yAxis = [
                { // Primary yAxis Meta e Realizado
                    labels: {
                        formatter: function () {
                            if (unidade == 0 || unidade == 1) {
                                return moeda + " " + this.value.toLocaleString('pt-BR', { minimumFractionDigits: 2 });
                            } else if (unidade == 2 || unidade == 3) {
                                return this.value + '%';
                            } else if (unidade == 6 || unidade == 7) {
                                return this.value + ' Dias';
                            } else if (unidade == 8 || unidade == 9) {
                                return this.value + ' Horas';
                            } else {
                                return this.value;
                            }
                        },
                        style: {
                            color: Colors.dark
                        }
                    },
                    title: {
                        text: "Meta e Realizado",
                        style: {
                            color: Colors.dark
                        }
                    }
                }, { // Secondary yAxis Performance
                    title: {
                        text: "Performance",
                        style: {
                            color: Colors.dark
                        }
                    },
                    labels: {
                        format: "{value}%",
                        style: {
                            color: Colors.dark
                        }
                    },
                    opposite: true
                }]
        } else {
            type = tipoMetaRealizado == "linha" || tipoPerformance == "linha" ? 'spline' : 'column';
            yAxis = [{ // Primary yAxis Meta e Realizado
                labels: {
                    formatter: function () {
                        if (tipoMetaRealizado == "linha" || tipoMetaRealizado == "barra") {
                            if (unidade == 0 || unidade == 1) {
                                return moeda + " " + this.value.toLocaleString('pt-BR', { minimumFractionDigits: 2 });
                            } else if (unidade == 2 || unidade == 3) {
                                return this.value + '%';
                            } else if (unidade == 6 || unidade == 7) {
                                return this.value + ' Dias';
                            } else if (unidade == 8 || unidade == 9) {
                                return this.value + ' Horas';
                            } else {
                                return this.value;
                            }
                        } else {
                            return this.value + '%';
                        }
                    },
                    style: {
                        color: Colors.dark
                    }
                },
                title: {
                    text: tipoMetaRealizado == "barra" || tipoMetaRealizado == "linha" ? "Meta e Realizado" : "Performance",
                    style: {
                        color: Colors.dark
                    }
                }
            }]
        }

        if (tipoMetaRealizado != 'ocultar') {
            series.push(this.getSerieData('Meta', tipoMetaRealizado, 0, 0, this.state.indicadorExpandedHistory?.performanceMetaPeriodos, 'meta', 'metaValue', 10));
            hasMetaAjustada && series.push(this.getSerieData('Meta Ajustada', tipoMetaRealizado, 0, 0, this.state.indicadorExpandedHistory?.performanceMetaAjustadaPeriodos, 'metaAjustada', 'metaAjustadaValue', 0));
            series.push(this.getSerieData('Realizado', tipoMetaRealizado, 0, 0, this.state.indicadorExpandedHistory?.performanceRealizadoPeriodos, 'realizado', 'realizadoValue', 28));

        }

        if (tipoPerformance != 'ocultar') {
            series.push(this.getSerieData('Performance', tipoPerformance, tipoMetaRealizado == 'ocultar' ? 0 : 1, 0, this.state.indicadorExpandedHistory?.performancePeriodos, 'performance', 'performanceValue', 1));
        }

        xAxis.push(
            {
                categories: this.state.indicadorExpandedHistory?.periodo.map((key) => (key.mes + "/" + String(key.ano).slice(-2))),
                accessibility: {
                    description: 'Períodos do Painel no GMR'
                }
            }
        )

        let indicadorGraphicOptions = {
            chart: {
                type: type,
                backgroundColor: 'transparent',
                height: null,
                spacing: spacing,
            },
            title: {
                text: '',
                y: 9,
            },
            xAxis: xAxis,
            yAxis: yAxis,
            legend: {
                enabled: true,
                align: 'center',
                verticalAlign: 'top',
                layout: 'horizontal',
            },
            tooltip: {
                shared: true,
                useHTML: true,
                headerFormat: '<span style="font-size: 15px">{point.key}</span><hr/><table>',
                pointFormat: '<tr><span style="color:{point.color}">\u25CF</span> {series.name}: <b>{point.yFormated}</b></tr><br/>',
                footerFormat: '</table>',
            },
            plotOptions: {
                spline: {
                    marker: {
                        radius: 4,
                    },
                    lineWidth: 2,
                    dashStyle: 'none'
                }
            },
            series: series,
            credits: {
                enabled: false
            }
        }

        this.setState({ indicadorGraphicOptions });
        this.setState({ loading: false });
    }

    getSerieData = (name, tipoMetaRealizado, yAxis, xAxis, serie, field, fieldValue, idColor) => {

        let serieObj = {
            name,
            type: tipoMetaRealizado == "barra" ? 'column' : 'spline',
            yAxis,
            xAxis,
            data: serie.map((key) => ({
                y: parseFloat(key[field]),
                yFormated: key[fieldValue],
                marker: {
                    fillColor: key['color'], // Função para obter a cor do marcador para cada período
                    radius: 6,
                }
            })),
            color: Colors.graphics[idColor],
            lineWidth: 4,
        };

        return serieObj;
    }

    renderEvolucaoResultado() {

        var revertLine = {
            display: "revert",
            fontSize: "revert",
            fontWeight: "revert",
            textAlign: "center",
            width: 150,
            minWidth: 150,
            maxWidth: 150,
            margin: 0,
        };

        var optionGraphicIsNull = () => {
            let response = this.state.configureGraphic.metaRealizado != 'ocultar' || this.state.configureGraphic.performance != 'ocultar';

            return !response;
        }

        var grafico = () => {
            return (
                <div style={{ width: "100%", height: "100%", ...EssentialStyle.rowFlexStart }}>
                    {renderControls()}
                    {renderGrafico()}
                </div>
            );
        }

        var renderGrafico = () => {
            return (
                <div className={"grafico"}>
                    {
                        optionGraphicIsNull() ?

                            <div style={{ ...EssentialStyle.rowSpaceEvenly }}>
                                <img
                                    src={`${gateway}/img/undraw/undraw_setup_analytics.svg`}
                                    alt="Pessoa de costas configurando um gráfico em um quadro"
                                    style={{
                                        height: '90%',
                                        maxHeight: 150
                                    }}
                                />
                                <div style={{
                                    ...EssentialStyle.columnCenter,
                                    alignItems: 'flex-start',
                                    alignContent: 'flex-start',
                                    width: '40%'
                                }}>
                                    <span style={{
                                        fontSize: 16,
                                        color: Colors.dark,
                                        marginBottom: 10
                                    }}><strong>Valores ocultos</strong>, reveja suas seleções de dados e tente novamente.</span>
                                </div>
                            </div>
                            :
                            <>
                                <div
                                    id="indicadorGraphicOptions"
                                    style={{
                                        display: 'flex',
                                        maxHeight: "inherit",
                                        width: "100%",
                                        height: "100%",
                                    }}
                                >
                                    <HighchartsReact
                                        highcharts={Highcharts}
                                        constructorType={'chart'}
                                        containerProps={{ style: { maxHeight: "100%", width: "inherit", maxWidth: "inherit" } }}
                                        options={this.state.indicadorGraphicOptions}
                                    />
                                </div>
                            </>
                    }
                </div>
            )
        }

        var renderControls = () => {
            return (
                <div className={"controller"}>
                    <div className={"controller-title"}>
                        <span className={"title"}>
                            <EllipsisText text={this.state.indicadorExpandedHistory?.indicadorNome} />
                        </span>
                    </div>
                    {renderControlsIndicadores()}
                </div>
            );
        }

        var renderControlsIndicadores = () => {

            const portalTarget = document.getElementById('root');
            return (
                <div style={{ ...EssentialStyle.columnStart, width: '100%', marginBottom: 5 }}>

                    <div style={{ ...EssentialStyle.columnStart, alignItems: 'start', width: "100%" }}>
                        <span style={{ fontSize: 14, fontWeight: 300, color: Colors.dark }}>Meta e Realizado:</span>

                        <Dropdown style={{ width: '100%' }}>
                            <Dropdown.Toggle
                                style={{
                                    width: '100%',
                                    fontSize: 14,
                                    fontWeight: 'bold',
                                    backgroundColor: Colors.homePage.lightGrey,
                                    color: Colors.dark,
                                    padding: '1px 10px',
                                    border: 'none',
                                }}

                            >
                                <span style={{ marginRight: 5, marginLeft: 10, fontSize: 12 }}>{this.state.graphicOptions.find(option => option.value === this.state.configureGraphic.metaRealizado).label}</span>
                            </Dropdown.Toggle>
                            {ReactDOM.createPortal(
                                <Dropdown.Menu>
                                    {this.state.graphicOptions.map(option => (
                                        <Dropdown.Item
                                            key={`visualization-opt-${option.value}`}
                                            onClick={() => {
                                                this.setState(prevState => ({ configureGraphic: { ...prevState.configureGraphic, metaRealizado: option.value } }), () => {
                                                    this.calculaGrafico();
                                                })
                                            }}
                                            style={option.value === this.state.configureGraphic.metaRealizado ? { backgroundColor: Colors.dark } : {}}
                                            active={option.value === this.state.configureGraphic.metaRealizado}
                                        >
                                            <span style={{ fontSize: 12 }}>{option.label}</span>
                                        </Dropdown.Item>
                                    ))}
                                </Dropdown.Menu>
                                , portalTarget)}
                        </Dropdown>
                    </div>

                    <div style={{ ...EssentialStyle.columnStart, alignItems: 'start', width: "100%" }}>
                        <span style={{ fontSize: 14, fontWeight: 300, color: Colors.dark, }}>Performance:</span>
                        <Dropdown style={{ width: '100%' }}>
                            <Dropdown.Toggle
                                style={{
                                    minWidth: '100%',
                                    fontSize: 14,
                                    fontWeight: 'bold',
                                    backgroundColor: Colors.homePage.lightGrey,
                                    color: Colors.dark,
                                    padding: '1px 10px',
                                    border: 'none',
                                }}
                            >
                                <span style={{ marginRight: 5, marginLeft: 10, fontSize: 12 }}>{this.state.graphicOptions.find(option => option.value === this.state.configureGraphic.performance).label}</span>
                            </Dropdown.Toggle>
                            {ReactDOM.createPortal(
                                <Dropdown.Menu>
                                    {this.state.graphicOptions.map(option => (
                                        <Dropdown.Item
                                            key={`visualization-opt-${option.value}`}
                                            onClick={() => {
                                                this.setState(prevState => ({ configureGraphic: { ...prevState.configureGraphic, performance: option.value } }), () => {
                                                    this.calculaGrafico();
                                                })
                                            }}
                                            style={option.value === this.state.configureGraphic.performance ? { backgroundColor: Colors.dark } : {}}
                                            active={option.value === this.state.configureGraphic.performance}
                                        >
                                            <span style={{ fontSize: 12 }}>{option.label}</span>
                                        </Dropdown.Item>
                                    ))}
                                </Dropdown.Menu>
                                , portalTarget)}
                        </Dropdown>
                    </div>

                </div>
            )
        }

        var tabela = () => {

            let hasMetaAjustada = this.state.indicadorExpandedHistory?.hasMetaAjustada;
            return (
                <div style={{ height: 225, maxWidth: "100%", width: "auto", margin: 2, borderRadius: 5, overflowY: "hidden", overflowX: "auto", border: `1px solid ${Colors.lightGrey}`, borderCollapse: "separate" }}>
                    <table style={{ width: "auto", minHeight: "100%", fontSize: 14, color: Colors.dark, fontWeight: 500, position: "relative", borderCollapse: "colapse" }}>
                        <thead style={{ all: this.state.isSmallScreen ? "revert" : "revert" }}>
                            <tr style={{ all: this.state.isSmallScreen ? "revert" : null }}>
                                <th style={{ ...(this.state.isSmallScreen ? revertLine : null), padding: 8, border: `1px solid ${Colors.lightGrey}`, width: 50, minWidth: 50, maxWidth: 50, position: "sticky", left: -1 }}></th>
                                {
                                    this.state.indicadorExpandedHistory ?
                                        this.state.indicadorExpandedHistory?.periodo.map((periodo, index) => {
                                            return (
                                                <th key={`periodo-${index}`} style={{ ...(this.state.isSmallScreen ? revertLine : null), padding: 8, border: `1px solid ${Colors.lightGrey}`, maxWidth: 150, minWidth: 150 }}>{periodo.nome}</th>
                                            );
                                        }) : null
                                }
                            </tr>
                        </thead>
                        <tbody style={{ fontWeight: 500 }}>
                            <tr style={{ all: this.state.isSmallScreen ? "revert" : null }}>
                                <th style={{ ...(this.state.isSmallScreen ? revertLine : null), padding: 8, border: `1px solid ${Colors.lightGrey}`, position: "sticky", left: -1, maxWidth: 50, width: 50 }}>M</th>
                                {
                                    this.state.indicadorExpandedHistory ?
                                        this.state.indicadorExpandedHistory?.performanceMetaPeriodos.map((periodo, index) => {
                                            return (
                                                <td key={`meta-${index}`} style={{ ...(this.state.isSmallScreen ? revertLine : null), padding: 8, border: `1px solid ${Colors.lightGrey}`, maxWidth: 150 }}>{periodo.metaValue}</td>
                                            );
                                        }) : null
                                }
                            </tr>
                            {hasMetaAjustada && <tr style={{ all: this.state.isSmallScreen ? "revert" : null }}>
                                <th style={{ ...(this.state.isSmallScreen ? revertLine : null), padding: 8, border: `1px solid ${Colors.lightGrey}`, position: "sticky", left: -1, maxWidth: 50, width: 50 }}>MA</th>
                                {
                                    this.state.indicadorExpandedHistory ?
                                        this.state.indicadorExpandedHistory?.performanceMetaAjustadaPeriodos.map((periodo, index) => {
                                            return (
                                                <td key={`metaAjustada-${index}`} style={{ ...(this.state.isSmallScreen ? revertLine : null), padding: 8, border: `1px solid ${Colors.lightGrey}`, maxWidth: 150 }}>{periodo.metaAjustadaValue}</td>
                                            );
                                        }) : null
                                }
                            </tr>}
                            <tr style={{ all: this.state.isSmallScreen ? "revert" : null }}>
                                <th style={{ ...(this.state.isSmallScreen ? revertLine : null), padding: 8, border: `1px solid ${Colors.lightGrey}`, position: "sticky", left: -1, maxWidth: 50, width: 50 }}>R</th>
                                {
                                    this.state.indicadorExpandedHistory ?
                                        this.state.indicadorExpandedHistory?.performanceRealizadoPeriodos.map((periodo, index) => {
                                            return (
                                                <td key={`realizado-${index}`} style={{ ...(this.state.isSmallScreen ? revertLine : null), padding: 8, border: `1px solid ${Colors.lightGrey}`, maxWidth: 150 }}>{periodo.realizadoValue}</td>
                                            );
                                        }) : null
                                }
                            </tr>
                            <tr style={{ all: this.state.isSmallScreen ? "revert" : null }}>
                                <th style={{ ...(this.state.isSmallScreen ? revertLine : null), padding: 8, border: `1px solid ${Colors.lightGrey}`, position: "sticky", left: -1, maxWidth: 50, width: 50 }}>%</th>
                                {
                                    this.state.indicadorExpandedHistory ?
                                        this.state.indicadorExpandedHistory?.performancePeriodos.map((periodo, index) => {
                                            return (
                                                <td key={`performance-${index}`} style={{ ...(this.state.isSmallScreen ? revertLine : null), padding: 8, border: `1px solid ${Colors.lightGrey}`, backgroundColor: periodo.color, color: Colors.white, maxWidth: 150 }}>{periodo.performanceValue}</td>
                                            );
                                        }) : null
                                }
                            </tr>
                        </tbody>
                    </table>
                </div>
            )
        }

        return (
            <div className={"box-History"}>
                <div className={"grafico-History"}>
                    {
                        this.state.showEvolucaoResultado == "grafico" ?
                            grafico()
                            : this.state.showEvolucaoResultado == "tabela" ?
                                tabela()
                                : null
                    }
                </div>
            </div>
        );
    }

    renderControls() {
        return (
            <div style={{
                ...EssentialStyle.rowSpaceAround,
                margin: 5,
                width: 'calc(100% - 10px)',
                height: 25,
                overflow: 'hidden',
            }}>
                <div style={{
                    ...EssentialStyle.rowSpaceBetween,
                    flexWrap: `${this.state.isSmallScreen ? 'unset' : 'wrap'}`,
                    overflowY: `${this.state.isSmallScreen ? 'auto' : 'hidden'}`,
                    overflowX: 'hidden',
                    width: '100%',
                }}>
                    <FontAwesomeIcon icon={faChevronLeft} style={{ color: SessionHelper.getColor(), cursor: 'pointer' }} onClick={() => { this.changePage(null, false) }} />

                    <div style={EssentialStyle.rowFlexCenter}>
                        {
                            this.state.vinculos.map((_, i) => (
                                <div
                                    style={{
                                        width: i === this.state.vinculoAtual ? 8 : 10,
                                        height: i === this.state.vinculoAtual ? 8 : 10,
                                        borderRadius: `50%`,
                                        backgroundColor: i === this.state.vinculoAtual ? SessionHelper.getColor() : Colors.notSelected,
                                        cursor: i === this.state.vinculoAtual ? 'not-allowed' : 'pointer',
                                        margin: '2px 5px'
                                    }}
                                    onClick={() => { this.changePage(i) }}
                                    key={`dot-page-${i}`}
                                >
                                </div>
                            ))
                        }
                    </div>

                    <FontAwesomeIcon icon={faChevronRight} style={{ color: SessionHelper.getColor(), cursor: 'pointer' }} onClick={() => { this.changePage(null, true) }} />

                </div>
            </div>
        );
    }

    renderLoading() {
        return (
            <div className={"box-loading"}>
                <DefaultLoader />
            </div>
        )
    }

    render() {
        if (this.state.loading) return this.renderLoading();
        return this.renderEvolucaoResultado();
    }
}
