import moment from "moment";
import Sig from "../api/Sig";
import SessionHelper from "./SessionHelper";

export default class DataHelper {
    static formatSelectData(dbData, valueField, labelField, customRender = null) {
        if (dbData === undefined) {
            return [];
        }

        let selectFormattedData = [];
        
        dbData.forEach(row => {
            selectFormattedData.push({ value: row[valueField] ?? null, label: customRender ? customRender(row) : row[labelField] || `` });
        });

        return selectFormattedData;
    }

    static formatSelectDataLang(dbData, valueField, labelField, lang = 1, labelFieldText = null) {
        if (dbData === undefined) {
            return [];
        }

        let selectFormattedData = [];

        if (labelFieldText == null) {
            labelFieldText = labelField;
        }
        
        dbData.forEach(row => {
            selectFormattedData.push({ value: row[valueField] ?? null, label: row[labelField].find((item) => item.id_idioma == lang)[labelFieldText] || `` });
        });

        return selectFormattedData;
    }

    static formatMentionData(dbData, valueField, labelField) {
        if (dbData === undefined) {
            return [];
        }

        let selectFormattedData = [];
        
        let userId = SessionHelper.getData().id_usuario || null;

        if(userId) {
            dbData = dbData.filter(row => row[valueField] != userId);
        }

        dbData.forEach(row => {
            selectFormattedData.push({ id: row[valueField] ?? null, value: row[labelField] || ``, original: row });
        });

        return selectFormattedData;
    }

    static transformArrayToSelectData(array) {
        if (array === undefined) {
            return [];
        }

        let selectFormattedData = [];
        
        array.forEach((row, index) => {
            selectFormattedData.push({ value: index, label: row });
        });

        return selectFormattedData;
    }

    static formatSelectedData(dbData, valueField, labelField, customRender = null) {
        if(dbData?.[valueField]) {
            return ({ value: dbData[valueField] || null, label: customRender ? customRender(dbData) : dbData[labelField] || `` });
        } else {
            return null;
        }
    }

    static async formatSelectIdUserData(listIds, valueField, labelField, customRender = null) {
        try {
            const colabs = await Sig.request('GET', 'config/colaborador/getColaboradoresListId', { ids: listIds });
            let selectFormattedData = [];
            
            colabs.forEach(row => {
                selectFormattedData.push({ value: row[valueField] || null, label: customRender ? customRender(row) : row[labelField] || `` });
            });
            
            return selectFormattedData;
        } catch (error) {
            console.error(error);
            return [];
        }
    }

    static async formatSelectIdIndicadorData(listIds, valueField, labelField, customRender = null) {
        try {
            let indicador = await Sig.request('GET', 'indicador/getIndicadores', { ids: listIds });
            let selectFormattedData = [];
            
            indicador.forEach(row => {
                selectFormattedData.push({ value: row[valueField] || null, label: customRender ? customRender(row) : row[labelField] || `` });
            });

            return selectFormattedData;
        } catch (error) {
            console.error(error);
            return [];
        }
    }

    static async formatSelectIdPainelData(listIds, valueField, labelField, customRender = null) {
        try {
            let paineis = await Sig.request('GET', 'helper/getPaineis', { ids: listIds });
            let selectFormattedData = [];
        
            paineis.forEach(row => {
                selectFormattedData.push({ value: row[valueField] || null, label: customRender ? customRender(row) : row[labelField] || `` });
            });

            return selectFormattedData;
        } catch (error) {
            console.error(error);
            return [];
        }
    }

    static getSelectEmptyMessage() {
        return 'Nenhuma opção encontrada';
    }

    static getDefaultDbDateFormat(date) {
        return date ? moment(date).format('YYYY-MM-DD HH:mm:ss') : moment().format('YYYY-MM-DD HH:mm:ss');
    }

    static dateSort(a, b, field) {

        return moment(b[field]).toDate() - moment(a[field]).toDate();
    }

    static multiArraySort(a, b, field) {

        if (a[field] && b[field]) {

            let aArray = [];
            let bArray = [];
    
            for (let index = 0; index < a[field].length; index++) {
                
                if (a[field][index]) {
                    aArray.push(a[field][index].nome);
                }

                if (b[field][index]) {
                    bArray.push(b[field][index].nome);
                }
            }
    
            return aArray.join(' ') > bArray.join(' ');
        }
    }

    static isDateString(string) {

        return moment(string).isValid();
    }

    static capitalizeFirstLetter(string) {
        return string.charAt(0).toUpperCase() + string.slice(1);
    }
   
    static removeHtmlTags(str) {
        if (str === null) {
            return '--';
        }
        // Remove HTML tags
        str = str.replace(/<\/?[^>]+(>|$)/g, "");
        // Remove HTML entities
        str = str.replace(/&[^;]+;/g, "");
        return str;
    }

    static removeHtmlAndReplaceListItems(html) {
        html = html || "";
        html = html.replace(/<ol>([\s\S]*?)<\/ol>/g, function (match, content) {
            let counter = 0;
            var response = "";
            response = content.replace(/<li>(.*?)<\/li>/g, function (match, content) {
                counter++;
                return ` ${counter}. ${content}`;
            });
            return response;
        });

        html = html.replace(/<ul>([\s\S]*?)<\/ul>/g, function (match, content) {
            var response = "";
            response = content.replace(/<li>(.*?)<\/li>/g, function (match, content) {
                return ` ● ${content}`;
            });
            return response;
        });

        html = html.replace(/<\/?[^>]+(>|$)/g, ' ');
        html = html.replace(/&[^;]+;/g, " ");

        return html.trim();
    }
        

    static limitarCaracteres(str, limite) {
        if (str?.length > limite) {
            return str.substring(0, limite) + "...";
        } else {
            return str;
        }
    }

    static removeColaborador(participantes, id_responsavel){

        if(id_responsavel === null){
            return participantes;
        }else{
            let Array = participantes;
            Array = Array.filter((item) => item.value !== id_responsavel);
            return Array;
        }   

    }

    static sortObjectsByName(objects) {
        return objects.sort((a, b) => {
            if (a.nome < b.nome) {
                return -1;
            } else if (a.nome > b.nome) {
                return 1;
            } else {
                return 0;
            }
        });
    }

    static getErrorDictionary() {
        let dictionary = [
            {
                keywords: ['CSRF'],
                message: 'Sua sessão expirou, por favor, faça login novamente.' 
            },
            {
                keywords: ['SQL', 'database', 'DB'],
                message: 'Erro ao executar a operação no banco de dados. Tente novamente mais tarde ou contate o suporte.' 
            },
            {
                keywords: ['undefined', 'exception', 'null', 'unknown'],
                message: 'Erro desconhecido. Tente novamente mais tarde ou contate o suporte.'
            },
            {
                keywords: ['timeout', 'timed out'],
                message: 'Tempo de espera excedido. Tente novamente mais tarde.'
            },
            {
                keywords: ['network', 'connection', 'offline'],
                message: 'Erro de conexão. Verifique sua conexão com a internet e tente novamente.'
            },
            {
                keywords: ['object', 'array', 'index', 'objects', 'match', 'expects'],
                message: 'Erro ao acessar um objeto ou propriedade. Contate o suporte.'
            },
            {
                keywords: ['syntax', 'parse', 'unexpected'],
                message: 'Erro de sintaxe no código. Contate o suporte.'
            },
            {
                keywords: ['fatal', 'error', 'critical'],
                message: 'Erro fatal encontrado. Tente novamente mais tarde ou contate o suporte.'
            },
            {
                keywords: ['warning', 'notice'],
                message: 'Aviso: Algo não está certo. Verifique o código ou contate o suporte.'
            },
            {
                keywords: ['Yii', 'CException', 'CErrorHandler'],
                message: 'Erro no framework Yii. Verifique o log de erros para mais detalhes.'
            },
            {
                keywords: ['CActiveRecord', 'model', 'save', 'validate'],
                message: 'Erro ao salvar ou validar o modelo. Verifique os dados e tente novamente.'
            },
            {
                keywords: ['CHttpException', '404', 'not found'],
                message: 'Página não encontrada. Verifique o URL e tente novamente.'
            },
            {
                keywords: ['CWebUser', 'login', 'authentication'],
                message: 'Erro de autenticação. Por favor, faça login novamente.'
            },
            {
                keywords: ['CFileCache', 'cache'],
                message: 'Erro de cache. Tente limpar o cache e tente novamente.'
            },
            {
                keywords: ['CGridView', 'widget', 'render'],
                message: 'Erro ao renderizar o widget. Verifique a configuração do widget.'
            },
            {
                keywords: ['CClientScript', 'script', 'register'],
                message: 'Erro ao registrar o script. Verifique o código JavaScript e tente novamente.'
            },
            {
                keywords: ['CUrlManager', 'route', 'url'],
                message: 'Erro de roteamento. Verifique as regras de URL e tente novamente.'
            },
            {
                keywords: ['CActiveForm', 'form', 'submit'],
                message: 'Erro ao enviar o formulário. Verifique os dados e tente novamente.'
            }
        ];

        return dictionary;
    }
}