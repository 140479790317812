/*
    lib: react-data-table-component
    code: https://github.com/jbetancur/react-data-table-component
    docs: https://react-data-table-component.netlify.app/?path=/docs/api-columns--page#column-definitions-api
*/

import React, { Component, forwardRef } from 'react';
import DataTable from 'react-data-table-component';
import Constants from '../../constants/Api';

const gateway = Constants.getSigEndPoint();
const paginationComponentOptions = {
    rowsPerPageText: 'Linhas por página',
    rangeSeparatorText: 'de',
    selectAllRowsItem: true,
    selectAllRowsItemText: 'Todas',
};

export default class    DefaultTable extends Component {
    state = {

    };

    renderEmpty = () => {
        return (
            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', height: '100%', width: '100%' }}>
                <img
                    src={`${gateway}/img/undraw/undraw_empty_street.svg`}
                    alt="Pessoa em uma rua vazia"
                    style={{
                        height: 150,
                        marginTop: 20,
                    }}
                />
                <div style={{ color: 'gray', margin: 20 }}>Nenhum registro encontrado</div>
            </div>
        )
    }

    render() {
        return (
            <DataTable
                theme="default"
                title={this.props.title || ``}
                columns={this.props.columns || {}}
                data={this.props.data || []}
                paginationComponentOptions={paginationComponentOptions}
                noDataComponent={this.renderEmpty()}
                {...this.props}
            />
        )
    }
}