import React from "react";
import ReactDOM from 'react-dom';
import { forwardRef } from "react";
import { Form, Dropdown, Collapse, Row, Col } from "react-bootstrap";
import Sig from "../../../api/Sig";
import moment from "moment";
import EssentialStyle from "../../../style/EssentialStyle";
import DefaultLoader from "../../tools/DefaultLoader";
import Colors from "../../../constants/Colors";
import SessionHelper from "../../../helper/SessionHelper";
import EllipsisText from "../../tools/EllipsisText";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowUp,
         faArrowDown,
         faChevronLeft, 
         faChevronRight,
         faMinus,
         faChevronDown,
         faChevronUp,
         faTable,
         faChartLine,
         faLink,
} from "@fortawesome/free-solid-svg-icons";
import CustomTooltip from "../../tools/CustomTooltip";
import Highcharts from "highcharts/highcharts.js";
import accessibility from 'highcharts/modules/accessibility';
import HighchartsReact from "highcharts-react-official";
import DatePicker from 'react-datepicker';
import Constants from "../../../constants/Api";
import Tabs from "../../tools/Tabs";
import PlanoAcaoHelper from "../../../helper/planoAcao/PlanoAcaoHelper";

accessibility(Highcharts);

const gateway = Constants.getSigEndPoint();

export default class PlanoAcaoVinculos extends React.Component {
    constructor(props) {
        super(props);	
        this.state = {
            transform: 'translateX(-100%)',
            opacity: 0,
            vinculos: null,
            vinculoAtual: 0,
            idPlanoAcao: this.props.idPlanoAcao,
            isSmallScreen: this.props.isSmallScreen,
            loading: true,
            loadingIndicador: false,
            indicadorExpandedHistory: null,
            showEvolucaoResultado: false,
            indicadorGraphicOptions: null,
            graphicOptions: [
                {
                    value: 'ocultar',
                    label: 'Valor Oculto',
                },
                {
                    value: 'barra',
                    label: 'Barra',
                },
                {
                    value: 'linha',
                    label: 'Linha',
                },
            ],
            configureGraphic: {
                metaRealizado: "barra",
                performance: "linha",
            },
            performanceDataInicio: null,
            performanceDataFim: null,
            expandedVinculos: this.props.expandedVinculos,
        }
    }

    async componentDidMount() {
        this.setState({ 
            loading: true, 
            idPlanoAcao: this.props.idPlanoAcao,
            isSmallScreen: this.props.isSmallScreen,
            indicadorExpandedHistory: false,
            indicadorGraphicOptions: null,
            expandedVinculos: this.props.expandedVinculos,
        });
        await this.loadData();
        this.triggerAnimations();
    }

    componentDidUpdate(prevProps) {
        if(this.props.isSmallScreen !== prevProps.isSmallScreen) {
            this.setState({ isSmallScreen: this.props.isSmallScreen });
        }
        if(this.props.expandedVinculos !== prevProps.expandedVinculos) {
            this.setState({ expandedVinculos: this.props.expandedVinculos });
        }
    }

    async loadData(){
        var {vinculos} = await Sig.request('POST', `/planoAcao/getVinculos`, { idPlano: this.state.idPlanoAcao, detailed: true });
        if(vinculos.length > 0) {
            this.setState({vinculos, loading: false}, async () => {
                if(this.state.vinculos[this.state.vinculoAtual].resultadoVinculado?.periodos?.length > 0) {
                    await this.getResultadoIndicador();
                } else {
                    this.setState({ indicadorExpandedHistory: null });
                }
            });
        } else {
            this.setState({loading: false, vinculos: null, indicadorExpandedHistory: null});
        }
    }

    async getResultadoIndicador() {
        this.setState({ loadingIndicador: true });

        var vinculo = this.state.vinculos[this.state.vinculoAtual];
        var data_inicio = moment(vinculo.inicio_ano + '-' + vinculo.inicio_mes.toString().padStart(2, '0') + '-01').startOf('month').format('YYYY-MM-DD HH:mm:ss');
        var data_fim = moment(vinculo.fim_ano + '-' + vinculo.fim_mes.toString().padStart(2, '0') + '-01').endOf('month').format('YYYY-MM-DD HH:mm:ss');

        let id_indicador =  vinculo.id_indicador;
        let id_gmr_painel = vinculo.id_gmr_painel;

        if (vinculo.tipoCode == 5) {
            id_indicador = vinculo.resultadoVinculado.id_indicador;
            id_gmr_painel = vinculo.resultadoVinculado.id_gmr_painel;
        }

        let { indicadorExpandedHistory } = await Sig.request('POST', 'config/colaborador/getIndicadorHistory', {
            id_indicador,
            id_gmr_painel,
            data_inicio,
            data_fim,
            acumulado: false,
        });
        this.setState({ indicadorExpandedHistory,performanceDataInicio: data_inicio, performanceDataFim: data_fim }, () => {this.calculaGrafico()});

    }

    getTendenciaIcons() {
        return {
            'Tendência de Ineficácia': <FontAwesomeIcon icon={faArrowDown} style={{ color: Colors.error }} />,
            'Inalterado': <FontAwesomeIcon icon={faMinus} style={{ color: Colors.homePage.grey }} />,
            'Tendência de Eficácia': <FontAwesomeIcon icon={faArrowUp} style={{ color: Colors.success }} />
        }
    }

    getAnimations() {
        return {
            opacity: this.state.opacity,
            transform: this.state.transform,
            transition: 'opacity .3s ease-in-out, transform .6s ease-in-out'
        }
    }

    triggerAnimations() {
        this.setState({ opacity: 1, transform: 'translateX(0)' });
    }

    changePage(pageNumber, soma = null) {
        var page = 0;
        var increase = true
        if(pageNumber !== null) {
            page = pageNumber;
            increase = pageNumber > this.state.vinculoAtual;
        } else {
            page = this.state.vinculoAtual + (soma ? 1 : -1);
            page = page > (this.state.vinculos.length-1) ? 0 : page;
            page = page < 0 ? (this.state.vinculos.length-1) : page;
        }

        if(page === this.state.vinculoAtual) return;

        this.fadeAnimations(increase);
        setTimeout(() => { 
            this.fadeAnimations(increase, false);
            setTimeout(() => {
                this.setState({ vinculoAtual: page, showEvolucaoResultado: false }, () => {
                    this.triggerAnimations();
                    if (this.state.vinculos[this.state.vinculoAtual].resultadoVinculado?.periodos?.length > 0) {
                        this.getResultadoIndicador();
                    } else {
                        this.setState({ indicadorExpandedHistory: null });
                    }
                });
            }, 200);
        }, 200);
        
    }

    calculaGrafico() {
        let unidade = this.state.indicadorExpandedHistory?.unidade;
        let moeda = this.state.indicadorExpandedHistory?.moedaSimbolo;
        let hasMetaAjustada = this.state.indicadorExpandedHistory?.hasMetaAjustada;

        let yAxis = [];
        let xAxis = [];
        let type = null;
        let series = [];
        let spacing = [0, 0, 0, 0];
        let tipoPerformance = this.state.configureGraphic.performance;
        let tipoMetaRealizado = this.state.configureGraphic.metaRealizado;

        if ((tipoMetaRealizado == "barra" || tipoPerformance == "barra") && (tipoMetaRealizado == "linha" || tipoPerformance == "linha")) {
            type = 'xy';
            yAxis = [
                {// Primary yAxis
                    title: {
                        text: tipoMetaRealizado == "barra" ? "Meta e Realizado" : "Performance",
                        style: {
                            color: Colors.dark
                        }
                    },
                    labels: {
                        formatter: function () {
                            if(tipoMetaRealizado == "barra") {
                                if (unidade == 0 || unidade == 1) {
                                    return moeda + " " + this.value.toLocaleString('pt-BR', { minimumFractionDigits: 2 });
                                } else if (unidade == 2 || unidade == 3) {
                                    return this.value + '%';
                                } else if (unidade == 6 || unidade == 7) {
                                    return this.value + ' Dias';
                                } else if (unidade == 8 || unidade == 9) {
                                    return this.value + ' Horas';
                                } else {
                                    return this.value;
                                }
                            } else {
                                return this.value + '%';
                            }
                        },
                        style: {
                            color: Colors.dark
                        }
                    }
                },
                { // Secondary yAxis
                    title: {
                        text: tipoMetaRealizado == "linha" ? "Meta e Realizado" : "Performance",
                        style: {
                            color: Colors.dark
                        }
                    },
                    labels: {
                        formatter: function () {
                            if(tipoMetaRealizado == "linha") {
                                if (unidade == 0 || unidade == 1) {
                                    return moeda + " " + this.value.toLocaleString('pt-BR', { minimumFractionDigits: 2 });
                                } else if (unidade == 2 || unidade == 3) {
                                    return this.value + '%';
                                } else if (unidade == 6 || unidade == 7) {
                                    return this.value + ' Dias';
                                } else if (unidade == 8 || unidade == 9) {
                                    return this.value + ' Horas';
                                } else {
                                    return this.value;
                                }
                            } else {
                                return this.value + '%';
                            }
                        },
                        style: {
                            color: Colors.dark
                        }
                    },
                    opposite: true
                }]
        } else if ((tipoMetaRealizado == "linha" && tipoPerformance == "linha") || (tipoMetaRealizado == "barra" && tipoPerformance == "barra")) {
            type = tipoMetaRealizado == "linha" ? 'spline' : 'column';
            yAxis = [
                { // Primary yAxis Meta e Realizado
                    labels: {
                        formatter: function () {
                            if (unidade == 0 || unidade == 1) {
                                return moeda + " " + this.value.toLocaleString('pt-BR', { minimumFractionDigits: 2 });
                            } else if (unidade == 2 || unidade == 3) {
                                return this.value + '%';
                            } else if (unidade == 6 || unidade == 7) {
                                return this.value + ' Dias';
                            } else if (unidade == 8 || unidade == 9) {
                                return this.value + ' Horas';
                            } else {
                                return this.value;
                            }
                        },
                        style: {
                            color: Colors.dark
                        }
                    },
                    title: {
                        text: "Meta e Realizado",
                        style: {
                            color: Colors.dark
                        }
                    }
                }, { // Secondary yAxis Performance
                    title: {
                        text: "Performance",
                        style: {
                            color: Colors.dark
                        }
                    },
                    labels: {
                        format: "{value}%",
                        style: {
                            color: Colors.dark
                        }
                    },
                    opposite: true
                }]
        } else {
            type = tipoMetaRealizado == "linha" || tipoPerformance == "linha" ? 'spline' : 'column';
            yAxis = [{ // Primary yAxis Meta e Realizado
                labels: {
                    formatter: function () {
                        if(tipoMetaRealizado == "linha" || tipoMetaRealizado == "barra") {
                            if (unidade == 0 || unidade == 1) {
                                return moeda + " " + this.value.toLocaleString('pt-BR', { minimumFractionDigits: 2 });
                            } else if (unidade == 2 || unidade == 3) {
                                return this.value + '%';
                            } else if (unidade == 6 || unidade == 7) {
                                return this.value + ' Dias';
                            } else if (unidade == 8 || unidade == 9) {
                                return this.value + ' Horas';
                            } else {
                                return this.value;
                            }
                        } else {
                            return this.value + '%';
                        }
                    },
                    style: {
                        color: Colors.dark
                    }
                },
                title: {
                    text: tipoMetaRealizado == "barra" || tipoMetaRealizado == "linha" ? "Meta e Realizado" : "Performance",
                    style: {
                        color: Colors.dark
                    }
                }
            }]
        }

        if (tipoMetaRealizado != 'ocultar') {
            series.push(this.getSerieData('Meta', tipoMetaRealizado, 0, 0, this.state.indicadorExpandedHistory?.performanceMetaPeriodos, 'meta', 'metaValue', 10));
            hasMetaAjustada && series.push(this.getSerieData('Meta Ajustada', tipoMetaRealizado, 0, 0, this.state.indicadorExpandedHistory?.performanceMetaAjustadaPeriodos, 'metaAjustada', 'metaAjustadaValue', 0));
            series.push(this.getSerieData('Realizado', tipoMetaRealizado, 0, 0, this.state.indicadorExpandedHistory?.performanceRealizadoPeriodos, 'realizado', 'realizadoValue', 28));

        }

        if (tipoPerformance != 'ocultar') {
            series.push(this.getSerieData('Performance', tipoPerformance, tipoMetaRealizado == 'ocultar' ? 0 : 1, 0, this.state.indicadorExpandedHistory?.performancePeriodos, 'performance', 'performanceValue', 1));
        }

        xAxis.push(
            {
                categories: this.state.indicadorExpandedHistory?.periodo.map((key) => (key.mes + "/" + String(key.ano).slice(-2))),
                accessibility: {
                    description: 'Períodos do Painel no GMR'
                }
            }
        )

        let indicadorGraphicOptions = {
            chart: {
                type: type,
                backgroundColor: 'transparent',
                height: null,
                spacing: spacing,
            },
            title: {
                text: '',
                y: 9,
            },
            xAxis: xAxis,
            yAxis: yAxis,
            legend: {
                enabled: true,
                align: 'center',
                verticalAlign: 'top',
                layout: 'horizontal',
            },
            tooltip: {
                shared: true,
                useHTML: true,
                headerFormat: '<span style="font-size: 15px">{point.key}</span><hr/><table>',
                pointFormat: '<tr><span style="color:{point.color}">\u25CF</span> {series.name}: <b>{point.yFormated}</b></tr><br/>',
                footerFormat: '</table>',
            },
            plotOptions: {
                spline: {
                    marker: {
                        radius: 4,
                    },
                    lineWidth: 2,
                    dashStyle: 'none'
                }
            },
            series: series,
            credits: {
                enabled: false
            }
        }

        this.setState({ indicadorGraphicOptions });
        this.setState({ loadingIndicador: false });
    }

    getSerieData = (name, tipoMetaRealizado, yAxis, xAxis, serie, field, fieldValue, idColor) => {

        let serieObj = {
            name,
            type: tipoMetaRealizado == "barra" ? 'column' : 'spline',
            yAxis,
            xAxis,
            data: serie.map((key) => ({
                y: parseFloat(key[field]),
                yFormated: key[fieldValue],
                marker: {
                    fillColor: key['color'], // Função para obter a cor do marcador para cada período
                    radius: 6,
                }
            })),
            color: Colors.graphics[idColor],
            lineWidth: 4,
        };

        return serieObj;
    }

    fadeAnimations(swipeLeft = true, transition = true) {
        if(transition)
            this.setState({ opacity: 0, transform: `translateX(${swipeLeft ? `-100%` : `+100%`})` });
        else
            this.setState({ opacity: 0, transform: `translateX(${swipeLeft ? `+100%` : `-100%`})`});
    }

    renderVinculoCard(vinculoAtual) {
        let vinculos = PlanoAcaoHelper.getVinculosTagsConfig();
        let showVinculo = null

        showVinculo = vinculos.find(v => v.key == vinculoAtual);

        if(showVinculo)
            return (
                <div style={{ ...EssentialStyle.rowFlexEnd, minHeight: 28 }}>
                    <CustomTooltip key={vinculoAtual.key + Math.random()} placement={'bottom'} tooltip={showVinculo.description}>
                        <div style={{ ...EssentialStyle.rowFlexStart, marginLeft: 2 }}>
                            <div style={{ backgroundColor: showVinculo.color, color: showVinculo.textColor, fontSize: 14, padding: "2px 10px", borderRadius: 5, marginRight: 10, fontWeight: 600 }}>{showVinculo.text}</div>
                        </div>
                    </CustomTooltip>
                </div>
            );
    }

    renderVinculo(){

        const renderGmr = () => {

            let performanceFinal = this.state.vinculos[this.state.vinculoAtual].resultadoVinculado?.performanceFinal ? true : false;
    
            return (
                <div style={{...this.getAnimations()}}>
                    <div style={{...EssentialStyle.rowSpaceBetween, maxWidth: 'calc(100%)', height: '25%', fontSize: 14, overflow: "hidden", color: Colors.dark}}>
                        <div style={EssentialStyle.rowFlexStart}>
                            {this.renderVinculoCard(this.state.vinculos[this.state.vinculoAtual].tipoCode)}
                            <div style={{maxWidth: 'calc(100%)', maxHeight: "2rem", fontSize: 16, overflow: "hidden", color: Colors.dark, fontWeight: 500}}>
                                <EllipsisText text={this.state.vinculos[this.state.vinculoAtual].resultadoVinculado?.processo} align={"center"} />
                            </div>
                        </div>
    
                        <div style={{...EssentialStyle.rowFlexEnd, alignItems: "center", color: SessionHelper.getColor()}} >
                            <a
                                onClick={() => { window.parent.postMessage({ type: '_blank', url: `/acompanhamentoResultados/gerenciar/${this.state.vinculos[this.state.vinculoAtual].id_gmr_painel}?processo=${this.state.vinculos[this.state.vinculoAtual].id_processo}` }, '*') }}
                                style={{cursor: 'pointer', fontSize: 12, display: this.props.parceiro ? 'none' : '' }}>
                                Ir ao painel <FontAwesomeIcon icon={faLink} style={{marginLeft: 5, marginRight: 10}}/>
                            </a>
                        </div>
                    </div>
    
                    <div style={{...(this.state.isSmallScreen ? EssentialStyle.columnSpaceBetween : EssentialStyle.rowSpaceBetween ), width: '100%', height: "calc(100% - 20px)", overflow: 'hidden', padding: "10px 0px"}}>
                        <div style={{...EssentialStyle.columnCenter, width: (this.state.isSmallScreen ? '100%' : '' ), flexGrow: 1, height: "100%", alignItems: "flex-start", justifyContent: "center", padding: '0px 10px', marginBottom: 5}}>
                            <div style={{display: "flex", maxWidth: 'calc(100%)', height: '37.5%', fontSize: 14, overflow: "hidden", color: Colors.dark}}>
                                <span style={{fontWeight: 500, textWrap: "nowrap", paddingRight: 5, position: "absolute"}}>Painel de Controle:</span>
                                <div style={{display: "flex", maxWidth: 'calc(100%)', height: '100%', maxHeight: "3rem", fontSize: 14, overflow: "hidden"}}>
                                    <EllipsisText text={this.state.vinculos[this.state.vinculoAtual].resultadoVinculado?.painel} align={"flex-start"} tab={33} />
                                </div>
                            </div>
                            <div style={{display: "flex", maxWidth: 'calc(100%)', height: '37.5%', fontSize: 14, overflow: "hidden", color: Colors.dark}}>
                                <span style={{fontWeight: 500, textWrap: "nowrap", paddingRight: 5, position: "absolute"}}>Indicador:</span>
                                <div style={{display: "flex", maxWidth: 'calc(100%)', maxHeight: "3rem", height: '100%', fontSize: 14, overflow: "hidden"}}>
                                    <EllipsisText text={this.state.vinculos[this.state.vinculoAtual].resultadoVinculado?.indicador} align={"flex-start"} tab={18}/>
                                </div>
                            </div>
                        </div>
    
                        <div style={{...EssentialStyle.columnCenter, minHeight: "100%", width: (this.state.isSmallScreen ? '100%' : '' ), padding: "0px 10px"}}>
                            <div style={{...(this.state.isSmallScreen ? EssentialStyle.columnCenter :EssentialStyle.rowFlexCenter), height: "75%", width: (this.state.isSmallScreen ? "100%" : ""), gap: 5}}>
                                <div style={{...EssentialStyle.columnSpaceBetween, minWidth: "300px", maxWidth: (this.state.isSmallScreen ? "100%" : "400px"), width: "100%", height: "90px"}}>
                                    <div style={{...EssentialStyle.rowFlexCenter, width: "100%", height: "42.5px", backgroundColor: Colors.homePage.extraLightGrey, borderRadius: 10, padding: "0px 0px 0px 10px", fontSize: 18}}>
                                        <div style={{width: "30%", fontWeight: 500}}>
                                            Referência
                                        </div>
                                        <div style={{width: "40%"}}>
                                            {this.state.vinculos[this.state.vinculoAtual].resultadoVinculado?.periodoReferencia}
                                        </div>
                                        <div style={{...EssentialStyle.columnSpaceBetween, color: 'white', minHeight: "100%", width: "30%", textAlign: "center", padding: "5px 0px", borderRadius: 10, backgroundColor: this.state.vinculos[this.state.vinculoAtual].resultadoVinculado?.performanceColor != "#FFF" ? this.state.vinculos[this.state.vinculoAtual].resultadoVinculado?.performanceColor : "#EAEAEA"}}>
                                            <span style={{fontSize: 13, lineHeight: "100%"}}>Performance</span>
                                            <span style={{fontSize: 18, lineHeight: "100%", fontWeight: "bolder"}}>{this.state.vinculos[this.state.vinculoAtual].resultadoVinculado?.performance == "" ? "--%" : this.state.vinculos[this.state.vinculoAtual].resultadoVinculado.performance}</span>
                                        </div>
                                    </div>
                                    <div style={{...EssentialStyle.rowFlexCenter, width: "100%", height: "42.5px", backgroundColor: Colors.homePage.extraLightGrey, borderRadius: 10, padding: "0px 0px 0px 10px", fontSize: 18}}>
                                        <div style={{width: "30%", fontWeight: 500}}>
                                            Verificação
                                        </div>
                                        <div style={{width: "40%"}}>
                                            {this.state.vinculos[this.state.vinculoAtual].resultadoVinculado?.periodoVerificacao}
                                        </div>
                                        <div style={{...EssentialStyle.columnSpaceBetween, color: (performanceFinal ? 'white' : 'black'), minHeight: "100%", width: "30%", textAlign: "center", padding: "5px 0px", borderRadius: 10, backgroundColor: this.state.vinculos[this.state.vinculoAtual].resultadoVinculado?.performanceColorFinal ?  this.state.vinculos[this.state.vinculoAtual].resultadoVinculado?.performanceColorFinal : "#EAEAEA"}}>
                                            <span style={{fontSize: 13, lineHeight: "100%"}}>{performanceFinal ? 'Performance' : 'Meta'}</span>
                                            <span style={{fontSize: 18, lineHeight: "100%", fontWeight: "bolder"}}>
                                                {performanceFinal ?  this.state.vinculos[this.state.vinculoAtual].resultadoVinculado?.performanceFinal : this.state.vinculos[this.state.vinculoAtual].resultadoVinculado.meta+"%"}
                                            </span>
                                        </div>
                                    </div>
                                </div>
    
                                <div style={{...EssentialStyle.columnCenter, width: (this.state.isSmallScreen ? "100%" : "275px"), height: "90px", backgroundColor: Colors.homePage.extraLightGrey, borderRadius: 10, padding: "2px 10px"}}>
                                    <span style={{width: "100%", fontWeight: 500, color: Colors.dark, fontSize: 18, lineHeight: "100%", textAlign: "left"}}>
                                        Situação    
                                    </span>
                                    <div style={{...EssentialStyle.columnCenter, width: '100%', height: '50%', fontSize: "45px"}}>
                                        {this.state.vinculos[this.state.vinculoAtual].resultadoVinculado?.tendencia?.msg ? 
                                        this.getTendenciaIcons()[this.state.vinculos[this.state.vinculoAtual].resultadoVinculado?.tendencia?.msg] : null}
                                    </div>
                                    <div style={{...EssentialStyle.columnCenter, fontWeight: 500, color: Colors.dark, width: '100%', height: '20%', fontSize: 15, textAlign: "center"}}>
                                        {this.state.vinculos[this.state.vinculoAtual].resultadoVinculado?.tendencia?.msg}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            );
        }
    
        const renderAnalise = () => {
    
            const renderAnaliseDescricao = () => {
                return (
                    <Form style={{margin: '0 0.5rem', flexWrap: 'wrap'}}>
                        <Row>
                            <Form.Group as={Col} xs={12} md={4} style={{padding: '0 0.1rem', display: 'flex', flexDirection: 'column'}}>
                                <div style={{...EssentialStyle.columnStart, alignItems: 'center', width: '100%', borderRadius: 5, overflow: "hidden", boxShadow: Colors.boxShadow, marginBottom: '0.5rem', flex: 1}}>
                                    <div style={{width: '100%', backgroundColor: 'black', color: 'white', fontSize: 14, textAlign: 'center'}}>
                                        Causa
                                    </div>
                                    <div style={{backgroundColor: 'white', color: 'black', fontSize: 12, padding: 5}}>
                                        <div dangerouslySetInnerHTML={{ __html: this.state.vinculos[this.state.vinculoAtual].analiseProblemaAcao?.causa }}/>
                                    </div>
                                </div>
                            </Form.Group>
        
                            <Form.Group as={Col} xs={12} md={4} style={{padding: '0 0.1rem', display: 'flex', flexDirection: 'column'}}>
                                <div style={{...EssentialStyle.columnStart, alignItems: 'center', width: '100%', borderRadius: 5, overflow: "hidden", boxShadow: Colors.boxShadow, marginBottom: '0.5rem', flex: 1}}>
                                    <div style={{width: '100%', backgroundColor: 'black', color: 'white', fontSize: 14, textAlign: 'center'}}>
                                        Desdobramento
                                    </div>
                                    <div style={{backgroundColor: 'white', color: 'black', fontSize: 12, padding: 5}}>
                                        <div dangerouslySetInnerHTML={{ __html: this.state.vinculos[this.state.vinculoAtual].analiseProblemaAcao?.desdobramento }}/>
                                    </div>
                                </div>
                            </Form.Group>
        
                            <Form.Group as={Col} xs={12} md={4} style={{padding: '0 0.1rem', display: 'flex', flexDirection: 'column'}}>
                                <div style={{...EssentialStyle.columnStart, alignItems: 'center', width: '100%', borderRadius: 5, overflow: "hidden", boxShadow: Colors.boxShadow, marginBottom: '0.5rem', flex: 1}}>
                                    <div style={{width: '100%', backgroundColor: 'black', color: 'white', fontSize: 14, textAlign: 'center'}}>
                                        Ação
                                    </div>
                                    <div style={{backgroundColor: 'white', color: 'black', fontSize: 12, padding: 5}}>
                                        <div dangerouslySetInnerHTML={{ __html: this.state.vinculos[this.state.vinculoAtual].analiseProblemaAcao?.acao }}/>
                                    </div>
                                </div>
                            </Form.Group>
                        </Row>
                    </Form>
                )
            }
    
            const renderAnaliseGMR = () => {
    
                let performanceFinal = this.state.vinculos[this.state.vinculoAtual].resultadoVinculado?.performanceFinal ? true : false;
    
                return (
                    <div style={{...this.getAnimations()}}>
                        <div style={{ ...EssentialStyle.rowSpaceBetween, maxWidth: 'calc(100%)', fontSize: 14, overflow: "hidden", color: Colors.dark}}>
                            <div style={EssentialStyle.rowFlexStart}>
                                {this.renderVinculoCard(this.state.vinculos[this.state.vinculoAtual].tipoCode)}
                                <div style={{maxWidth: 'calc(100%)', maxHeight: "2rem", fontSize: 16, overflow: "hidden", color: Colors.dark, fontWeight: 500}}>
                                    <EllipsisText text={this.state.vinculos[this.state.vinculoAtual].analiseProblemaAcao?.nome} align={"center"} />
                                </div>
                            </div>
    
                            <div style={{...EssentialStyle.rowFlexEnd, alignItems: "center", color: SessionHelper.getColor()}} >
                                <a
                                    onClick={() => { window.parent.postMessage({ type: '_blank', url: `/analiseProblema/detalhar/${this.state.vinculos[this.state.vinculoAtual].analiseProblemaAcao.id}`}, '*') }}
                                    style={{cursor: 'pointer', fontSize: 12, display: this.props.parceiro ? 'none' : '' }}>
                                    Ir à Analise de Problemas <FontAwesomeIcon icon={faLink} style={{marginLeft: 5, marginRight: 15}}/>
                                </a>
    
                                <a
                                    onClick={() => { window.parent.postMessage({ type: '_blank', url: `/acompanhamentoResultados/gerenciar/${this.state.vinculos[this.state.vinculoAtual].resultadoVinculado.id_gmr_painel}?processo=${this.state.vinculos[this.state.vinculoAtual].resultadoVinculado.id_processo}` }, '*') }}
                                    style={{cursor: 'pointer', fontSize: 12, display: this.props.parceiro ? 'none' : '' }}>
                                    Ir ao Painel <FontAwesomeIcon icon={faLink} style={{marginLeft: 5, marginRight: 10}}/>
                                </a>
                            </div>
                        </div>
    
                        <div style={{ ...(this.state.isSmallScreen ? EssentialStyle.columnSpaceBetween : EssentialStyle.rowSpaceBetween ), width: '100%', overflow: 'hidden', padding: "0px 0px 10px 0px"}}>
                            <div style={{...EssentialStyle.columnCenter, width: (this.state.isSmallScreen ? '100%' : '' ), flexGrow: 1, alignItems: "flex-start", justifyContent: "center", padding: '0px 10px'}}>
        
                                <div style={{display: "flex", maxWidth: 'calc(100%)', fontSize: 14, overflow: "hidden", color: Colors.dark}}>
                                    <span style={{fontWeight: 500, textWrap: "nowrap", paddingRight: 5, position: "absolute"}}>Processo:</span>
                                    <div style={{display: "flex", maxWidth: 'calc(100%)', maxHeight: "3rem", fontSize: 14, overflow: "hidden"}}>
                                        <EllipsisText text={this.state.vinculos[this.state.vinculoAtual].resultadoVinculado?.processo} align={"flex-start"} tab={32} />
                                    </div>
                                </div>
        
                                <div style={{display: "flex", maxWidth: 'calc(100%)', fontSize: 14, overflow: "hidden", color: Colors.dark}}>
                                    <span style={{fontWeight: 500, textWrap: "nowrap", paddingRight: 5, position: "absolute"}}>Painel:</span>
                                    <div style={{display: "flex", maxWidth: 'calc(100%)', maxHeight: "3rem", fontSize: 14, overflow: "hidden"}}>
                                        <EllipsisText text={this.state.vinculos[this.state.vinculoAtual].resultadoVinculado?.painel} align={"flex-start"} tab={32} />
                                    </div>
                                </div>
        
                                <div style={{display: "flex", maxWidth: 'calc(100%)', fontSize: 14, overflow: "hidden", color: Colors.dark}}>
                                    <span style={{fontWeight: 500, textWrap: "nowrap", paddingRight: 5, position: "absolute"}}>Indicador:</span>
                                    <div style={{display: "flex", maxWidth: 'calc(100%)', maxHeight: "3rem", fontSize: 14, overflow: "hidden"}}>
                                        <EllipsisText text={this.state.vinculos[this.state.vinculoAtual].resultadoVinculado?.indicador} align={"flex-start"} tab={32} />
                                    </div>
                                </div>
                            </div>
        
                            <div style={{...EssentialStyle.columnCenter, padding: "0px 10px", width: (this.state.isSmallScreen ? '100%' : '' )}}>
                                <div style={{...(this.state.isSmallScreen ? EssentialStyle.columnCenter :EssentialStyle.rowFlexCenter), height: "75%", width: (this.state.isSmallScreen ? "100%" : ""), gap: 5}}>
                                    <div style={{...EssentialStyle.columnSpaceBetween, minWidth: "300px", maxWidth: (this.state.isSmallScreen ? "100%" : "400px"), width: "100%", height: "90px"}}>
                                        <div style={{...EssentialStyle.rowFlexCenter, width: "100%", height: "42.5px", backgroundColor: Colors.homePage.extraLightGrey, borderRadius: 10, padding: "0px 0px 0px 10px", fontSize: 18}}>
                                            <div style={{width: "30%", fontWeight: 500}}>
                                                Referência
                                            </div>
                                            <div style={{width: "40%"}}>
                                                {this.state.vinculos[this.state.vinculoAtual].resultadoVinculado?.periodoReferencia}
                                            </div>
                                            <div style={{...EssentialStyle.columnSpaceBetween, color: "white", minHeight: "100%", width: "30%", textAlign: "center", padding: "5px 0px", borderRadius: 10, backgroundColor: this.state.vinculos[this.state.vinculoAtual].resultadoVinculado?.performanceColor != "#FFF" ? this.state.vinculos[this.state.vinculoAtual].resultadoVinculado?.performanceColor : "#EAEAEA"}}>
                                                <span style={{fontSize: 13, lineHeight: "100%"}}>Performance</span>
                                                <span style={{fontSize: 18, lineHeight: "100%", fontWeight: "bolder"}}>{this.state.vinculos[this.state.vinculoAtual].resultadoVinculado?.performance == "" ? "--%" : this.state.vinculos[this.state.vinculoAtual].resultadoVinculado.performance}</span>
                                            </div>
                                        </div>
                                        <div style={{...EssentialStyle.rowFlexCenter, width: "100%", height: "42.5px", backgroundColor: Colors.homePage.extraLightGrey, borderRadius: 10, padding: "0px 0px 0px 10px", fontSize: 18}}>
                                            <div style={{width: "30%", fontWeight: 500}}>
                                                Verificação
                                            </div>
                                            <div style={{width: "40%"}}>
                                                {this.state.vinculos[this.state.vinculoAtual].resultadoVinculado?.periodoVerificacao}
                                            </div>
                                            <div style={{...EssentialStyle.columnSpaceBetween, color: (performanceFinal ? "white" : "black"), minHeight: "100%", width: "30%", textAlign: "center", padding: "5px 0px", borderRadius: 10, backgroundColor: this.state.vinculos[this.state.vinculoAtual].resultadoVinculado?.performanceColorFinal ?  this.state.vinculos[this.state.vinculoAtual].resultadoVinculado?.performanceColorFinal : "#EAEAEA"}}>
                                                <span style={{fontSize: 13, lineHeight: "100%"}}>{ performanceFinal ? 'Performance' : 'Meta'}</span>
                                                <span style={{fontSize: 18, lineHeight: "100%", fontWeight: "bolder"}}>
                                                    {performanceFinal ?  this.state.vinculos[this.state.vinculoAtual].resultadoVinculado?.performanceFinal : this.state.vinculos[this.state.vinculoAtual].resultadoVinculado.meta+"%"}
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                    
                                    <div style={{...EssentialStyle.columnCenter, width: (this.state.isSmallScreen ? "100%" : "275px"), height: "90px", backgroundColor: Colors.homePage.extraLightGrey, borderRadius: 10, padding: "2px 10px"}}>
                                        <span style={{width: "100%", fontWeight: 500, color: Colors.dark, fontSize: 18, lineHeight: "100%", textAlign: "left"}}>
                                            Situação    
                                        </span>
        
                                        <div style={{...EssentialStyle.columnCenter, width: '100%', height: '50%', fontSize: "45px"}}>
                                            {this.state.vinculos[this.state.vinculoAtual].resultadoVinculado?.tendencia?.msg ? 
                                            this.getTendenciaIcons()[this.state.vinculos[this.state.vinculoAtual].resultadoVinculado?.tendencia?.msg] : null}
                                        </div>
        
                                        <div style={{...EssentialStyle.columnCenter, fontWeight: 500, color: Colors.dark, width: '100%', height: '20%', fontSize: 15, textAlign: "center"}}>
                                            {this.state.vinculos[this.state.vinculoAtual].resultadoVinculado?.tendencia?.msg}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
        
                        {renderAnaliseDescricao()}
                    </div>
                )
            }
    
            const renderAnalisePCO = () => {
                return (
                    <div style={{...this.getAnimations()}}>
                        <div style={{ ...(this.state.isSmallScreen ? EssentialStyle.columnSpaceBetween : EssentialStyle.rowSpaceBetween ), width: '100%', overflow: 'hidden', padding: "0px 0px 10px 0px"}}>
                            <Form style={{margin: '0 0.5rem', width: '100%'}}>
                                <Row>
                                    <Form.Group as={Col} xs={12} md={12}>
                                        <div style={{ ...EssentialStyle.rowSpaceBetween, maxWidth: 'calc(100%)', fontSize: 14, overflow: "hidden", color: Colors.dark, marginBottom: '0.5rem'}}>
                                            <div style={EssentialStyle.rowFlexStart}>
                                                {this.renderVinculoCard(this.state.vinculos[this.state.vinculoAtual].tipoCode)}
                                                <div style={{maxWidth: 'calc(100%)', maxHeight: "2rem", fontSize: 16, overflow: "hidden", color: Colors.dark, fontWeight: 500}}>
                                                    <EllipsisText text={this.state.vinculos[this.state.vinculoAtual].analiseProblemaAcao?.nome} align={"center"} />
                                                </div>
                                            </div>
    
                                            <div style={{...EssentialStyle.rowFlexEnd, alignItems: "center", color: SessionHelper.getColor()}} >
                                                <a
                                                    onClick={() => { window.parent.postMessage({ type: '_blank', url: `/analiseProblema/detalhar/${this.state.vinculos[this.state.vinculoAtual].analiseProblemaAcao.id}`}, '*') }}
                                                    style={{cursor: 'pointer', fontSize: 12, display: this.props.parceiro ? 'none' : '' }}>
                                                    Ir à Analise de Problemas <FontAwesomeIcon icon={faLink} style={{marginLeft: 5, marginRight: 15}}/>
                                                </a>
                                                <a
                                                    onClick={() => { window.parent.postMessage({ type: '_blank', url: `/pco/pesquisa/editar/${this.state.vinculos[this.state.vinculoAtual].resultadoVinculado.id_pco}` }, '*') }}
                                                    style={{cursor: 'pointer', fontSize: 12, display: this.props.parceiro ? 'none' : '' }}>
                                                    Ir à Pesquisa <FontAwesomeIcon icon={faLink} style={{marginLeft: 5, marginRight: 10}}/>
                                                </a>
                                            </div>
                                        </div>
                                    </Form.Group>
                                </Row>
        
                                <Row>
                                    <Form.Group as={Col} xs={12} md={4}>
                                    <Form.Label style={{fontSize: 12}}>Pesquisa Organizacional</Form.Label>
                                        <div style={{display: "flex", maxWidth: 'calc(100%)', fontSize: 14, overflow: "hidden", color: Colors.dark, marginBottom: '0.5rem'}}>
                                            <EllipsisText text={this.state.vinculos[this.state.vinculoAtual].resultadoVinculado?.pco} align={"center"} />
                                        </div>
                                    </Form.Group>
        
                                    <Form.Group as={Col} xs={12} md={6}>
                                        <Form.Label style={{fontSize: 12}}>Pergunta</Form.Label>
                                        <div style={{display: "flex", maxWidth: 'calc(100%)', fontSize: 14, overflow: "hidden", color: Colors.dark, marginBottom: '0.5rem' }}>
                                            <EllipsisText text={this.state.vinculos[this.state.vinculoAtual].resultadoVinculado?.pergunta} align={"center"} />
                                        </div>
                                    </Form.Group>
        
                                    <Form.Group as={Col} xs={12} md={2}>
                                        <div style={{...EssentialStyle.columnSpaceBetween, color: "white", height: "3rem", width: "100%", textAlign: "center", padding: "5px 0px", borderRadius: 10, backgroundColor: this.state.vinculos[this.state.vinculoAtual].resultadoVinculado?.cor ? this.state.vinculos[this.state.vinculoAtual].resultadoVinculado?.cor : "#EAEAEA" }}>
                                            <span style={{fontSize: 13, lineHeight: "100%"}}>Resultado</span>
                                            <span style={{fontSize: 18, lineHeight: "100%", fontWeight: "bolder"}}>
                                                {this.state.vinculos[this.state.vinculoAtual].resultadoVinculado?.resultado+"%"}
                                            </span>
                                        </div>
                                    </Form.Group>
                                </Row>
                            </Form>
                        </div>
        
                        {renderAnaliseDescricao()}
                    </div>
                )
            }
    
            const renderAnaliseSemVinculo = () => {
                
                return (
                    <div style={{...this.getAnimations()}}>
                        <div style={{ ...EssentialStyle.rowSpaceBetween, maxWidth: 'calc(100%)', fontSize: 14, overflow: "hidden", color: Colors.dark, marginBottom: '0.5rem'}}>
                            <div style={EssentialStyle.rowFlexStart}>
                                {this.renderVinculoCard(this.state.vinculos[this.state.vinculoAtual].tipoCode)}
                                <div style={{maxWidth: 'calc(100%)', maxHeight: "2rem", fontSize: 16, overflow: "hidden", color: Colors.dark, fontWeight: 500}}>
                                    <EllipsisText text={this.state.vinculos[this.state.vinculoAtual].analiseProblemaAcao?.nome} align={"center"} />
                                </div>
                            </div>
    
                            <div style={{...EssentialStyle.rowFlexEnd, alignItems: "center", color: SessionHelper.getColor()}} >
                                <a
                                    onClick={() => { window.parent.postMessage({ type: '_blank', url: `/analiseProblema/detalhar/${this.state.vinculos[this.state.vinculoAtual].analiseProblemaAcao.id}`}, '*') }}
                                    style={{cursor: 'pointer', fontSize: 12, display: this.props.parceiro ? 'none' : '' }}>
                                    Ir à Analise de Problemas <FontAwesomeIcon icon={faLink} style={{marginLeft: 5, marginRight: 10}}/>
                                </a>
                            </div>
                        </div>
        
                        {renderAnaliseDescricao()}
                    </div>
                )
            }
    
            let tipo = this.state.vinculos[this.state.vinculoAtual].analiseProblemaAcao?.tipoVinculo;
    
            if (tipo == 1 || tipo == 2) { // vinculo com GMR e antigo PEG
                return renderAnaliseGMR();
            } else if (tipo == 3) {
                return renderAnalisePCO(); // vinculo com PCO
            } else {
                return renderAnaliseSemVinculo(); // sem vinculo
            }
    
        }
    
        const renderPco = () => {
    
            return (
    
                <div style={{ ...this.getAnimations(), ...(this.state.isSmallScreen ? EssentialStyle.columnSpaceBetween : EssentialStyle.rowSpaceBetween ), width: '100%', overflow: 'hidden', padding: "0px 0px 10px 0px"}}>
                    <Form style={{margin: '0 0.5rem', width: '100%'}}>
                        <Row>
                            <Form.Group as={Col} xs={12} md={12}>
                                <div style={{ ...EssentialStyle.rowSpaceBetween, maxWidth: 'calc(100%)', fontSize: 14, overflow: "hidden", color: Colors.dark, marginBottom: '0.5rem'}}>
                                    <div style={EssentialStyle.rowFlexStart}>
                                        {this.renderVinculoCard(this.state.vinculos[this.state.vinculoAtual].tipoCode)}
                                        <div style={{maxWidth: 'calc(100%)', maxHeight: "2rem", fontSize: 16, overflow: "hidden", color: Colors.dark, fontWeight: 500}}>
                                            <EllipsisText text={this.state.vinculos[this.state.vinculoAtual].resultadoVinculado?.processo} align={"center"} />
                                        </div>
                                    </div>
    
                                    <div style={{...EssentialStyle.rowFlexEnd, alignItems: "center", color: SessionHelper.getColor()}} >
                                        <a
                                            onClick={() => { window.parent.postMessage({ type: '_blank', url: `/pco/pesquisa/editar/${this.state.vinculos[this.state.vinculoAtual].id_processo}` }, '*') }}
                                            style={{cursor: 'pointer', fontSize: 1, display: this.props.parceiro ? 'none' : '' }}>
                                            Ir à Pesquisa Organizacional <FontAwesomeIcon icon={faLink} style={{marginLeft: 5, marginRight: 10}}/>
                                        </a>
                                    </div>
                                </div>
                            </Form.Group>
                        </Row>
    
                        <Row>
                            <Form.Group as={Col} xs={12} md={4}>
                                <Form.Label style={{fontSize: 12}}>Grupo</Form.Label>
                                <div style={{display: "flex", maxWidth: 'calc(100%)', fontSize: 14, overflow: "hidden", color: Colors.dark, marginBottom: '0.5rem' }}>
                                    <EllipsisText text={this.state.vinculos[this.state.vinculoAtual].resultadoVinculado?.grupo} align={"center"} />
                                </div>
                            </Form.Group>
    
                            <Form.Group as={Col} xs={12} md={6}>
                                <Form.Label style={{fontSize: 12}}>Pergunta</Form.Label>
                                <div style={{display: "flex", maxWidth: 'calc(100%)', fontSize: 14, overflow: "hidden", color: Colors.dark, marginBottom: '0.5rem' }}>
                                    <EllipsisText text={this.state.vinculos[this.state.vinculoAtual].resultadoVinculado?.pergunta} align={"center"} />
                                </div>
                            </Form.Group>
    
                            <Form.Group as={Col} xs={12} md={2}>
                                <div style={{...EssentialStyle.columnSpaceBetween, color: "white", height: "3rem", width: "100%", textAlign: "center", padding: "5px 0px", borderRadius: 10, backgroundColor: this.state.vinculos[this.state.vinculoAtual].resultadoVinculado?.color ? this.state.vinculos[this.state.vinculoAtual].resultadoVinculado?.color : "#EAEAEA" }}>
                                    <span style={{fontSize: 13, lineHeight: "100%"}}>Resultado</span>
                                    <span style={{fontSize: 18, lineHeight: "100%", fontWeight: "bolder"}}>
                                        {this.state.vinculos[this.state.vinculoAtual].resultadoVinculado?.resultado}
                                    </span>
                                </div>
                            </Form.Group>
                        </Row>
                    </Form>
                </div>
            )
        }
    
        const renderFeedbacks = () => {
            return (
                <div style={{ ...this.getAnimations(), ...(this.state.isSmallScreen ? EssentialStyle.columnSpaceBetween : EssentialStyle.rowSpaceBetween ), width: '100%', overflow: 'hidden', padding: "0px 0px 10px 0px"}}>
                    <Form style={{margin: '0 0.5rem', width: '100%'}}>
                        <Row>
                            <Form.Group as={Col} xs={12} md={12}>
                                <div style={{ ...EssentialStyle.rowFlexStart, maxWidth: 'calc(100%)', fontSize: 14, overflow: "hidden", color: Colors.dark, marginBottom: '0.5rem'}}>
                                    {this.renderVinculoCard(this.state.vinculos[this.state.vinculoAtual].tipoCode)}
                                </div>
                            </Form.Group>
                        </Row>
    
                        <Row>
                            <Form.Group as={Col} xs={12} md={6}>
                                <Form.Label style={{fontSize: 12}}>Feedback Contínuo</Form.Label>
                                <div>
                                    {this.state.vinculos[this.state.vinculoAtual].resultadoVinculado?.feedback}
                                </div>
                            </Form.Group>
    
                            <Form.Group as={Col} xs={12} md={6}>
                                <Form.Label style={{fontSize: 12}}>Assunto</Form.Label>
                                <div>
                                    {this.state.vinculos[this.state.vinculoAtual].resultadoVinculado?.assunto}
                                </div>
                            </Form.Group>
                        </Row>
                    </Form>
                </div>
            )
        }
    
        const renderAc = () => {
            return (
                <div style={{...this.getAnimations()}}>
                    
    
                    <Form style={{margin: '0 0.5rem', width: '100%'}}>
                        <Row>
                            <div style={{...EssentialStyle.rowSpaceBetween, maxWidth: 'calc(100%)', height: '25%', fontSize: 14, overflow: "hidden", color: Colors.dark}}>
                                <div style={EssentialStyle.rowFlexStart}>
                                    {this.renderVinculoCard(this.state.vinculos[this.state.vinculoAtual].tipoCode)}
                                    <div style={{maxWidth: 'calc(100%)', maxHeight: "2rem", fontSize: 16, overflow: "hidden", color: Colors.dark, fontWeight: 500}}>
                                        <EllipsisText text={this.state.vinculos[this.state.vinculoAtual].resultadoVinculado?.processo} align={"center"} />
                                    </div>
                                </div>
    
                                <div style={{...EssentialStyle.rowFlexEnd, alignItems: "center", color: SessionHelper.getColor()}} >
                                    <a
                                        onClick={() => { window.parent.postMessage({ type: '_blank', url: `/avaliacaoCompetencias/?id_colaborador=${this.state.vinculos[this.state.vinculoAtual].id_responsavel}&id_periodo_avaliacao=${this.state.vinculos[this.state.vinculoAtual].id_processo}}` }, '*') }}
                                        style={{cursor: 'pointer', fontSize: 12, display: this.props.parceiro ? 'none' : '' }}>
                                        Ir para Avaliação de Compentências <FontAwesomeIcon icon={faLink} style={{marginLeft: 5, marginRight: 10}}/>
                                    </a>
                                </div>
                            </div>
                        </Row>
    
                        <Row style={{margin: '5px 0px 10px 0'}}>
                            <Form.Group as={Col} xs={12} md={5}>
                                <Form.Label style={{fontSize: 12}}>Pilar</Form.Label>
                                <div>
                                    {this.state.vinculos[this.state.vinculoAtual].resultadoVinculado?.pilar}
                                </div>
                            </Form.Group>
    
                            <Form.Group as={Col} xs={12} md={5}>
                                <Form.Label style={{fontSize: 12}}>Competência</Form.Label>
                                <div>
                                    {this.state.vinculos[this.state.vinculoAtual].resultadoVinculado?.competencia}
                                </div>
                            </Form.Group>
    
                            <Form.Group as={Col} xs={12} md={2}>
                                <div style={{...EssentialStyle.columnSpaceBetween, color: "white", height: "3rem", width: "100%", textAlign: "center", padding: "5px 0px", borderRadius: 10, backgroundColor: this.state?.vinculos[this.state.vinculoAtual].resultadoVinculado?.resultado?.color ? this.state?.vinculos[this.state.vinculoAtual].resultadoVinculado?.resultado?.color : "#EAEAEA" }}>
                                    <span style={{fontSize: 13, lineHeight: "100%"}}>Resultado</span>
                                    <span style={{fontSize: 18, lineHeight: "100%", fontWeight: "bolder"}}>
                                        {this.state.vinculos[this.state.vinculoAtual].resultadoVinculado?.resultado?.media || '--' +"%"}
                                    </span>
                                </div>
                            </Form.Group>
    
                            <Form.Group as={Col} xs={12} md={12}>
                                <Form.Label style={{fontSize: 12}}>Comportamento</Form.Label>
                                <div>
                                    {this.state.vinculos[this.state.vinculoAtual].resultadoVinculado?.comportamento}
                                </div>
                            </Form.Group>
                        </Row>
                    </Form>
                </div>
            )
        }
    
        const renderMarco = () => {
            return (
                <div style={{ ...this.getAnimations(), ...(this.state.isSmallScreen ? EssentialStyle.columnSpaceBetween : EssentialStyle.rowSpaceBetween ), width: '100%', overflow: 'hidden', padding: "0px 0px 10px 0px"}}>
                    <Form style={{margin: '0 0.5rem', width: '100%'}}>
    
                        <Row>
                            <Form.Group as={Col} md={12} xl={12} className="mb-1" >
                                <div style={{ ...EssentialStyle.rowFlexCenter, color: Colors.white, backgroundColor: SessionHelper.getColor(), borderRadius: 5, fontSize: 14}}>
                                    <strong>Marco de Projeto</strong>
                                </div>
                            </Form.Group>
                        </Row>
                        
                        {/* <Row>
                            <Form.Group as={Col} xs={12} md={12}>
                                <div style={{ ...EssentialStyle.rowFlexStart, maxWidth: 'calc(100%)', fontSize: 14, overflow: "hidden", color: Colors.dark, marginBottom: '0.5rem'}}>
                                    {this.renderVinculoCard(this.state.vinculos[this.state.vinculoAtual].tipoCode)}
                                </div>
                            </Form.Group>
                        </Row> */}
    
                        <Row>
                            <Form.Group as={Col} xs={12} md={12} xl={6}>
                                <Form.Label style={{fontSize: 12}}>Projeto</Form.Label>
                                <div>
                                    {this.state.vinculos[this.state.vinculoAtual].resultadoVinculado?.projeto.nome}
                                </div>
                            </Form.Group>
    
                            <Form.Group as={Col} xs={12} md={12} xl={6}>
                                <Form.Label style={{fontSize: 12}}>Marco</Form.Label>
                                <div>
                                    {this.state.vinculos[this.state.vinculoAtual].resultadoVinculado?.marco.descricao}
                                </div>
                            </Form.Group>
                        </Row>
                    </Form>
                </div>
            )
        }
    
        const renderDiagnosticoRecomendacao = () => {

            let nTree = this.state.vinculos[this.state.vinculoAtual].diagnosticoTree?.length ?? 0;
            let itemsPerLine = 4;
            let nline = Math.ceil(nTree / itemsPerLine);
            let tamanhoItem = Math.ceil(12 / ( Math.ceil(nTree / nline)));
    
    
            return (
                <div style={{ ...this.getAnimations(), ...(this.state.isSmallScreen ? EssentialStyle.columnSpaceBetween : EssentialStyle.rowSpaceBetween ), width: '100%', overflow: 'hidden', padding: "0px 0px 10px 0px"}}>
                    <Form style={{margin: '0 0.5rem', width: '100%'}}>
                        
                        <Row>
                            <div style={{...EssentialStyle.rowSpaceBetween, maxWidth: 'calc(100%)', height: '25%', fontSize: 14, overflow: "hidden", color: Colors.dark}}>
                                <div style={EssentialStyle.rowFlexStart}>
                                    {this.renderVinculoCard(this.state.vinculos[this.state.vinculoAtual].tipoCode)}
                                    <div style={{maxWidth: 'calc(100%)', maxHeight: "2rem", fontSize: 16, overflow: "hidden", color: Colors.dark, fontWeight: 500}}>
                                        <EllipsisText text={this.state.vinculos[this.state.vinculoAtual].diagnosticoRecomendacao?.relatorio?.nome ?? ''} align={"center"} />
                                    </div>
                                </div>
    
                                {/* <div style={{...EssentialStyle.rowFlexEnd, alignItems: "center", color: SessionHelper.getColor()}} >
                                    <a
                                        onClick={() => { window.parent.postMessage({ type: '_blank', url: `/avaliacaoCompetencias/?id_colaborador=${this.state.vinculos[this.state.vinculoAtual].id_responsavel}&id_periodo_avaliacao=${this.state.vinculos[this.state.vinculoAtual].id_processo}}` }, '*') }}
                                        style={{cursor: 'pointer', fontSize: 12, display: this.props.parceiro ? 'none' : '' }}>
                                        Ir para Avaliação de Compentências <FontAwesomeIcon icon={faLink} style={{marginLeft: 5, marginRight: 10}}/>
                                    </a>
                                </div> */}
                            </div>
                        </Row>
    
                        <Row>
                            {this.state.vinculos[this.state.vinculoAtual].diagnosticoTree?.map((item, index) => {
                                return (
                                    <Form.Group key={`item-tree-${item.id}`} as={Col} md={tamanhoItem} xxl={tamanhoItem/2} className="mb-3">
                                        <Form.Label style={{ fontSize: 12 }}><div>{item.coluna}</div></Form.Label>
                                        <div style={{maxHeight: '5rem'}}><EllipsisText text={item.descricao} style={{maxHeight: '5rem'}}/></div>
                                    </Form.Group>
                                )
                            }) ?? null}
    
                            <Form.Group as={Col} xs={12} md={9} xxl={5} className="mb-3">
                                <Form.Label style={{fontSize: 12}}>Recomendação</Form.Label>
                                <div dangerouslySetInnerHTML={{ __html: this.state.vinculos[this.state.vinculoAtual].diagnosticoRecomendacao?.relatorioRecomendacao?.descricao ?? 'Não Existente'}} />
                            </Form.Group>
    
                            <Form.Group as={Col} xs={12} md={3} xxl={1} className="mb-3">
                                <Form.Label style={{fontSize: 12}}>Resultado</Form.Label>
                                <div style={{ padding: '4px 8px', backgroundColor: this.state.vinculos[this.state.vinculoAtual].diagnosticoResultado?.cor, color: Colors.white, borderRadius: 5, textAlign: 'center', fontWeight: '600' }}>
                                    {this.state.vinculos[this.state.vinculoAtual].diagnosticoResultado?.valor}%
                                </div>
                            </Form.Group>
                            
                        </Row>
                    </Form>
                </div>
            )
        }

        const renderDiagnostico = () => {
    
            let nTree = this.state.vinculos[this.state.vinculoAtual].diagnosticoTree?.length ?? 0;
            let itemsPerLine = 4;
            let nline = Math.ceil(nTree / itemsPerLine);
            let tamanhoItem = Math.ceil(12 / ( Math.ceil(nTree / nline)));
    
    
            return (
                <div style={{ ...this.getAnimations(), ...(this.state.isSmallScreen ? EssentialStyle.columnSpaceBetween : EssentialStyle.rowSpaceBetween ), width: '100%', overflow: 'hidden', padding: "0px 0px 10px 0px"}}>
                    <Form style={{margin: '0 0.5rem', width: '100%'}}>
                        
                        <Row>
                            <div style={{...EssentialStyle.rowSpaceBetween, maxWidth: 'calc(100%)', height: '25%', fontSize: 14, overflow: "hidden", color: Colors.dark}}>
                                <div style={EssentialStyle.rowFlexStart}>
                                    {this.renderVinculoCard(this.state.vinculos[this.state.vinculoAtual].tipoCode)}
                                </div>
    
                                <Form.Group as={Col} xs={12} md={3} xxl={1} className="mb-3">
                                    <Form.Label style={{fontSize: 12}}>Resultado</Form.Label>
                                    <div style={{ padding: '4px 8px', backgroundColor: this.state.vinculos[this.state.vinculoAtual].diagnosticoResultado?.cor, color: Colors.white, borderRadius: 5, textAlign: 'center', fontWeight: '600' }}>
                                        {this.state.vinculos[this.state.vinculoAtual].diagnosticoResultado?.valor}%
                                    </div>
                                </Form.Group>
                            </div>
                        </Row>
    
                        <Row>
    
                            {this.state.vinculos[this.state.vinculoAtual].diagnosticoTree?.map((item, index) => {
                                return (
                                    <Form.Group key={`item-tree-${item.id}`} as={Col} md={tamanhoItem} xxl={tamanhoItem/2} className="mb-3">
                                        <Form.Label style={{ fontSize: 12 }}><div>{item.coluna}</div></Form.Label>
                                        <div style={{maxHeight: '5rem'}}><EllipsisText text={item.descricao} style={{maxHeight: '5rem'}}/></div>
                                    </Form.Group>
                                )
                            }) ?? null}
                        </Row>
                    </Form>
                </div>
            )
        }
        
        switch(this.state.vinculos[this.state.vinculoAtual].tipo) {
            case "Gestão de Metas e Resultados": 
                return renderGmr();
            case "Planejamento Estratégico de Gestão":
                return renderGmr();
            case "Análise de Problema":
                return renderAnalise();
            case "Pesquisa Organizacional":
                return renderPco();
            case "Feedback Contínuo":
                return renderFeedbacks();
            case "Avaliação de Competências":
                return renderAc();
            case "Marco de Projeto":
                return renderMarco();
            case "Resultado de Diagnóstico":
                return renderDiagnosticoRecomendacao();
            default:
                return "Vinculo Não Encontrado";
        }
    }

    renderEvolucaoResultado() {

        var revertLine = {
            display: "revert",
            fontSize: "revert", 
            fontWeight: "revert", 
            textAlign: "center",
            width: 150,
            minWidth: 150,
            maxWidth: 150,
            margin: 0,
        };

        const tabs = [
            { name: 'grafico', title: 'Gráfico', icon: <FontAwesomeIcon icon={faChartLine} /> },
            { name: 'tabela', title: 'Tabela', icon: <FontAwesomeIcon icon={faTable} /> },
        ];

        var buttons = () => {
            return (
                <Tabs 
                    isSmallScreen={this.state.isSmallScreen}
                    tabs={tabs}
                    onTabChange={handleTabChange}
                    activeTab={this.state.showEvolucaoResultado}
                    style={{...(this.state.isSmallScreen ? EssentialStyle.rowFlexCenter : EssentialStyle.columnCenter)}}
                    tabStyle={{width: this.state.isSmallScreen ? '33vw' : 150, textAlign: 'center', padding: 5, borderRadius: 5}}
                />
            );
        }

        var handleTabChange = (tab) => {
            this.setState({ showEvolucaoResultado: tab.name })
        }

        var renderGrafico = () => {
            return (
                <div style={{
                    ...EssentialStyle.columnCenter,
                    position: 'relative',
                    width: "100%",
                    height: "100%",
                    padding: "15px 10px",
                    minHeight: 265,
                    maxHeight: this.state.isSmallScreen? 300 : 265,
                }}>
                    {
                        optionGraphicIsNull() ?
                        
                        <div style={{
                            ...EssentialStyle.rowSpaceEvenly
                        }}>
                            <img
                                src={`${gateway}/img/undraw/undraw_setup_analytics.svg`}
                                alt="Pessoa de costas configurando um gráfico em um quadro"
                                style={{
                                    height: '90%',
                                    maxHeight: 150
                                }}
                            />
                            <div style={{
                                ...EssentialStyle.columnCenter,
                                alignItems: 'flex-start',
                                alignContent: 'flex-start',
                                width: '40%'
                            }}>
                                <span style={{
                                    fontSize: 16,
                                    color: Colors.dark,
                                    marginBottom: 10
                                }}><strong>Valores ocultos</strong>, reveja suas seleções de dados e tente novamente.</span>
                            </div>
                        </div>
                        :
                        <>
                            <div
                                id="indicadorGraphicOptions"
                                style={{
                                    display: 'flex',
                                    maxHeight: "inherit",
                                    width: "100%",
                                    height: "100%",
                                }}
                            >
                                <HighchartsReact
                                    highcharts={Highcharts}
                                    constructorType={'chart'}
                                    containerProps={{ style: { maxHeight: "100%", width: "inherit", maxWidth: "inherit" } }}
                                    options={this.state.indicadorGraphicOptions}
                                />
                            </div>
                        </>
                    }
                </div>
            )
        }

        var optionGraphicIsNull = () => {
            let response = this.state.configureGraphic.metaRealizado != 'ocultar' || this.state.configureGraphic.performance != 'ocultar';
            
            return !response;
        }

        var grafico = () => {
            return (
                <div style={{ ...(this.state.isSmallScreen ? EssentialStyle.columnCenter : EssentialStyle.rowFlexCenter), padding: 5, width: "100%" }}>
                    {renderGrafico()}
                    {renderControls()}
                </div>
            );
        }
        
        var renderControls = () => {
            return (
                <div style={{
                    ...EssentialStyle.columnCenter, 
                    height: 180,
                    width: '100%',
                    maxWidth: 300,
                    backgroundColor: Colors.homePage.extraLightGrey, 
                    borderRadius: 5, 
                    padding: 8,
                    marginTop: 5,
                    minWidth: 270, 
                }}>
                    <div style={{
                        ...EssentialStyle.rowFlexCenter,
                        width: 'calc(100% - 16px)',
                        margin: '0px 8px 10px 8px',
                        padding: '0px 8px 8px 8px',
                        borderBottom: `0.5px solid ${Colors.homePage.grey}`,
                        display: 'flex',
                    }}>
                        <span style={{ fontWeight: 'bold', fontSize: 14, textAlign: 'center', marginRight: 5 }}>
                            {this.state.vinculos[this.state.vinculoAtual].resultadoVinculado?.indicador}
                        </span>
                    </div>
                    { renderPeriodoIndicadorExpanded('performanceDataInicio', 'performanceDataFim') }
                    { renderControlsIndicadores() }
                </div>
            );
        }
        
        var renderPeriodoIndicadorExpanded = (dataInicio, dataFim) => {

            return (
                <div style={{ ...EssentialStyle.rowSpaceBetween, width: '100%' }}>
                    <span style={{ fontSize: 14, fontWeight: 'bold', color: Colors.dark }}>Período:</span>
                    <div style={{ ...EssentialStyle.rowFlexEnd }}>
                        {renderDatePicker(dataInicio)}
                        {renderDatePicker(dataFim)}
                    </div>
                </div>
            )
        }

        var renderControlsIndicadores = () => {

            const portalTarget = document.getElementById('root');
            return (
                <div style={{ ...EssentialStyle.columnStart, width: '100%', marginBottom: 5 }}>
                    
                    <div style={{ ...EssentialStyle.columnStart, alignItems: 'start', width: "100%" }}>
                        <span style={{ fontSize: 14, fontWeight: 300, color: Colors.dark }}>Meta e Realizado:</span>
    
                        <Dropdown style={{ width: '100%' }}>
                            <Dropdown.Toggle
                                style={{
                                    width: '100%',
                                    fontSize: 14,
                                    fontWeight: 'bold',
                                    backgroundColor: Colors.homePage.lightGrey,
                                    color: Colors.dark,
                                    padding: '1px 10px',
                                    border: 'none',
                                }}
    
                            >
                                <span style={{ marginRight: 5, marginLeft: 10, fontSize: 12 }}>{this.state.graphicOptions.find(option => option.value === this.state.configureGraphic.metaRealizado).label}</span>
                            </Dropdown.Toggle>
                            {ReactDOM.createPortal(
                                <Dropdown.Menu>
                                    {this.state.graphicOptions.map(option => (
                                        <Dropdown.Item
                                            key={`visualization-opt-${option.value}`}
                                            onClick={() => { 
                                                this.setState(prevState => ({ configureGraphic: { ...prevState.configureGraphic, metaRealizado: option.value } }), () => { 
                                                    this.calculaGrafico();
                                                })
                                            }}
                                            style={option.value === this.state.configureGraphic.metaRealizado ? { backgroundColor: Colors.dark } : {}}
                                            active={option.value === this.state.configureGraphic.metaRealizado}
                                        >
                                            <span style={{ fontSize: 12 }}>{option.label}</span>
                                        </Dropdown.Item>
                                    ))}
                                </Dropdown.Menu>
                            , portalTarget)}
                        </Dropdown>
                    </div>
    
                    <div style={{ ...EssentialStyle.columnStart, alignItems: 'start', width: "100%" }}>
                        <span style={{ fontSize: 14, fontWeight: 300, color: Colors.dark, }}>Performance:</span>
                        <Dropdown style={{ width: '100%' }}>
                            <Dropdown.Toggle
                                style={{
                                    minWidth: '100%',
                                    fontSize: 14,
                                    fontWeight: 'bold',
                                    backgroundColor: Colors.homePage.lightGrey,
                                    color: Colors.dark,
                                    padding: '1px 10px',
                                    border: 'none',
                                }}
                            >
                                <span style={{ marginRight: 5, marginLeft: 10, fontSize: 12 }}>{this.state.graphicOptions.find(option => option.value === this.state.configureGraphic.performance).label}</span>
                            </Dropdown.Toggle>
                            {ReactDOM.createPortal(
                                <Dropdown.Menu>
                                    {this.state.graphicOptions.map(option => (
                                        <Dropdown.Item
                                            key={`visualization-opt-${option.value}`}
                                            onClick={() => { 
                                                this.setState(prevState => ({ configureGraphic: { ...prevState.configureGraphic, performance: option.value } }), () => { 
                                                    this.calculaGrafico();
                                                })
                                            }}
                                            style={option.value === this.state.configureGraphic.performance ? { backgroundColor: Colors.dark } : {}}
                                            active={option.value === this.state.configureGraphic.performance}
                                        >
                                            <span style={{ fontSize: 12 }}>{option.label}</span>
                                        </Dropdown.Item>
                                    ))}
                                </Dropdown.Menu>
                            , portalTarget)}
                        </Dropdown>
                    </div>
    
                </div>
            )
        }
    
        var renderDatePicker = (stateProp, callback = () => { }) => {
            const DatePickerComponent = forwardRef(({ value, onClick }, ref) => (
                <Form.Control
                    onChange={() => { }}
                    onClick={onClick}
                    ref={ref}
                    style={{ marginLeft: 5, fontSize: 14, color: Colors.dark, fontWeight: 'bold', cursor: 'unset', boxShadow: 'none', backgroundColor: Colors.homePage.lightGrey, padding: 1, height: '100%', width: 90, textAlign: 'center', zIndex: 1 }}
                    value={value} 
                    disabled={true} 
                    >
                </Form.Control>
            ));
    
            return (
                <div style={{
                    ...EssentialStyle.rowFlexStart
                }}>
                    <DatePicker
                        selected={this.state[stateProp]}
                        type="date"
                        dateFormat="MM/yyyy"
                        showMonthYearPicker
                        customInput={<DatePickerComponent />}
                        popperPlacement="top-start"
                    />
                </div>
            )
        }            

        var tabela = () => {

            let hasMetaAjustada = this.state.indicadorExpandedHistory?.hasMetaAjustada;
            return (
                <div style={{ height: 225, maxWidth: "100%", width: "auto", margin: 2, borderRadius: 5, overflowY: "hidden", overflowX: "auto", border: `1px solid ${Colors.lightGrey}`, borderCollapse: "separate"}}>
                    <table style={{ width: "auto", minHeight: "100%", fontSize: 14, color: Colors.dark, fontWeight: 500, position: "relative", borderCollapse: "colapse" }}>
                        <thead style={{all: this.state.isSmallScreen ? "revert" : "revert"}}>
                            <tr style={{ all: this.state.isSmallScreen ? "revert" : null }}>
                                <th style={{ ...(this.state.isSmallScreen ? revertLine : null ), padding: 8, border: `1px solid ${Colors.lightGrey}`, width: 50, minWidth: 50, maxWidth: 50, position: "sticky", left: -1 }}></th>
                                { 
                                    this.state.indicadorExpandedHistory ? 
                                    this.state.indicadorExpandedHistory?.periodo.map((periodo, index) => {
                                        return (
                                            <th key={`periodo-${index}`} style={{ ...(this.state.isSmallScreen ? revertLine : null ), padding: 8, border: `1px solid ${Colors.lightGrey}`, maxWidth: 150, minWidth: 150 }}>{periodo.nome}</th>
                                        );
                                    }) : null
                                }
                            </tr>
                        </thead>
                        <tbody style={{fontWeight: 500}}>
                            <tr style={{ all: this.state.isSmallScreen ? "revert" : null }}>
                                <th style={{ ...(this.state.isSmallScreen ? revertLine : null ), padding: 8, border: `1px solid ${Colors.lightGrey}`, position: "sticky", left: -1, maxWidth: 50, width: 50 }}>M</th>
                                {
                                    this.state.indicadorExpandedHistory ? 
                                    this.state.indicadorExpandedHistory?.performanceMetaPeriodos.map((periodo, index) => {
                                        return (
                                            <td key={`meta-${index}`} style={{ ...(this.state.isSmallScreen ? revertLine : null ), padding: 8, border: `1px solid ${Colors.lightGrey}`, maxWidth: 150 }}>{periodo.metaValue}</td>
                                        );
                                    }) : null
                                }       
                            </tr>
                            { hasMetaAjustada && <tr style={{ all: this.state.isSmallScreen ? "revert" : null }}>
                                <th style={{ ...(this.state.isSmallScreen ? revertLine : null ), padding: 8, border: `1px solid ${Colors.lightGrey}`, position: "sticky", left: -1, maxWidth: 50, width: 50 }}>MA</th>
                                {
                                    this.state.indicadorExpandedHistory ? 
                                    this.state.indicadorExpandedHistory?.performanceMetaAjustadaPeriodos.map((periodo, index) => {
                                        return (
                                            <td key={`metaAjustada-${index}`} style={{ ...(this.state.isSmallScreen ? revertLine : null ), padding: 8, border: `1px solid ${Colors.lightGrey}`, maxWidth: 150 }}>{periodo.metaAjustadaValue}</td>
                                        );
                                    }) : null
                                }       
                            </tr> }
                            <tr style={{ all: this.state.isSmallScreen ? "revert" : null }}>
                                <th style={{ ...(this.state.isSmallScreen ? revertLine : null ), padding: 8, border: `1px solid ${Colors.lightGrey}`, position: "sticky", left: -1, maxWidth: 50, width:  50 }}>R</th>
                                {
                                    this.state.indicadorExpandedHistory ? 
                                    this.state.indicadorExpandedHistory?.performanceRealizadoPeriodos.map((periodo, index) => {
                                        return (
                                            <td key={`realizado-${index}`} style={{ ...(this.state.isSmallScreen ? revertLine : null ), padding: 8, border: `1px solid ${Colors.lightGrey}`, maxWidth: 150 }}>{periodo.realizadoValue}</td>
                                        );
                                    }) : null
                                }
                            </tr>
                            <tr style={{ all: this.state.isSmallScreen ? "revert" : null }}>
                                <th style={{ ...(this.state.isSmallScreen ? revertLine : null ), padding: 8, border: `1px solid ${Colors.lightGrey}`, position: "sticky", left: -1, maxWidth: 50, width: 50 }}>%</th>
                                {
                                    this.state.indicadorExpandedHistory ? 
                                    this.state.indicadorExpandedHistory?.performancePeriodos.map((periodo, index) => {
                                        return (
                                            <td key={`performance-${index}`} style={{ ...(this.state.isSmallScreen ? revertLine : null ), padding: 8, border: `1px solid ${Colors.lightGrey}`, backgroundColor: periodo.color, color: Colors.white, maxWidth: 150 }}>{periodo.performanceValue}</td>
                                        );
                                    }) : null
                                }
                            </tr>
                        </tbody>
                    </table>
                </div>
            )
        }

        return(
            <div style={{...(this.state.isSmallScreen ? EssentialStyle.columnCenter : EssentialStyle.rowFlexStart), width: "100%", height: "auto", overflow: "hidden" }}>
                <div style={{...EssentialStyle.columnCenter, overflow: "hidden", padding: "50px 40px", borderRight: this.state.isSmallScreen ? "unset" : `1px solid ${Colors.homePage.lightGrey}`, borderBottom: this.state.isSmallScreen ? `1px solid ${Colors.homePage.lightGrey}` : "unset", boxSizing: "border-box" }}>
                    {buttons()}
                </div>
                <div style={{...EssentialStyle.columnCenter, width: "100%", overflow: "hidden", padding: "15px 20px", height: this.state.isSmallScreen ? "unset" : 265}}>
                    {
                        this.state.showEvolucaoResultado == "grafico" ? 
                            grafico()
                        : this.state.showEvolucaoResultado == "tabela" ?
                            tabela()
                        : null
                    }
                </div>
            </div>
        );
    }

    renderControls() {
        return (
            <div style={{
                ...EssentialStyle.rowSpaceAround,
                margin: 5,
                width: 'calc(100% - 10px)',
                height: 25,
                overflow: 'hidden',
            }}>
                <div style={{
                    ...EssentialStyle.rowSpaceBetween,
                    flexWrap: `${this.state.isSmallScreen ? 'unset' : 'wrap'}`,
                    overflowY: `${this.state.isSmallScreen ? 'auto' : 'hidden'}`,
                    overflowX: 'hidden',
                    width: '100%',
                }}>
                    <FontAwesomeIcon icon={faChevronLeft} style={{ color: SessionHelper.getColor(), cursor: 'pointer' }} onClick={() => { this.changePage(null, false) }}/>

                    <div style={EssentialStyle.rowFlexCenter}>
                        {
                            this.state.vinculos.map( (_, i) => (
                                <div
                                    style={{
                                        width: i === this.state.vinculoAtual ? 8 : 10,
                                        height: i === this.state.vinculoAtual ? 8 : 10,
                                        borderRadius: `50%`,
                                        backgroundColor: i === this.state.vinculoAtual ? SessionHelper.getColor() : Colors.notSelected,
                                        cursor:  i === this.state.vinculoAtual ? 'not-allowed' : 'pointer',
                                        margin: '2px 5px'
                                    }}
                                    onClick={() => { this.changePage(i) }}
                                    key={`dot-page-${i}`}
                                >
                                </div>
                            ))
                        }
                    </div>

                    <FontAwesomeIcon icon={faChevronRight} style={{ color: SessionHelper.getColor(), cursor: 'pointer' }} onClick={() => { this.changePage(null, true) }}/>

                </div>
            </div>
        );
    }

    render(){
        
        var borderRadius = {borderBottomRightRadius: 8, borderBottomLeftRadius: 8};

        if (!this.state.loadingIndicador && this.state.indicadorExpandedHistory && this.state.expandedVinculos) {
            borderRadius = {borderBottomRightRadius: 0, borderBottomLeftRadius: 0};
        }

        if(this.state.vinculos && this.state.vinculos.length > 0) {
            return (
                <div className="pa-header-component optional" style={{...EssentialStyle.columnStart, width: "100%", paddingBottom: 8, paddingTop: "2px", backgroundColor: SessionHelper.getBackgroundColor()}}>
                    {
                        this.state.loading ? 
                            <DefaultLoader color={Colors.dark} size={32}/>
                        :
                        <>
                            <div style={{...EssentialStyle.columnCenter, width: "100%", height: "100%", boxShadow: `0px 1px 10px 0px  rgba(50, 50, 50, 0.1)`, ...borderRadius, overflow: "hidden", border: `0.3px solid ${Colors.lightGrey}`, backgroundColor: Colors.white, minHeight: '35px', justifyContent: "center", padding: "0px 10px"}}>
                                <div style={{ ...EssentialStyle.rowFlexCenter, cursor: 'pointer', backgroundColor: Colors.white, minHeight: 35 }} onClick={() => {this.props.changeExpandedVinculosCallback()}} >
                                    {this.state.expandedVinculos ?
                                        <FontAwesomeIcon icon={faChevronUp} style={{ marginRight: 8 }} /> :
                                        <FontAwesomeIcon icon={faChevronDown} style={{ marginRight: 8 }} />
                                    }
                                    {
                                        this.state.expandedVinculos ? 'Ocultar Vínculos' : 'Vínculos'
                                    }
                                </div>
                                <Collapse in={this.state.expandedVinculos} style={{ width: '100%', transition: "all 0.2s ease" }}>
                                    <div style={{ width: '100%' }}>
                                        { this.state.vinculos && this.state.vinculos.length > 1 ? this.renderControls(): null}
                                        {this.renderVinculo()}
                                    </div>
                                </Collapse>
                            </div>
                            
                            {!this.state.loadingIndicador && this.state.indicadorExpandedHistory && this.state.expandedVinculos ?        
                                <div style={{...EssentialStyle.columnCenter, marginBottom: 2, width: "100%", height: "100%", boxShadow: `0px 5px 5px 0px  rgba(50, 50, 50, 0.2)`, borderBottomLeftRadius: 8, borderBottomRightRadius: 8, overflow: "hidden", border: `0.3px solid ${Colors.lightGrey}`, backgroundColor: Colors.white, minHeight: '35px', justifyContent: "center", padding: "0px 10px" }}>
                                    <div style={{ ...EssentialStyle.rowFlexCenter, cursor: 'pointer', backgroundColor: Colors.white, minHeight: 35 }} onClick={() => {this.setState({showEvolucaoResultado: this.state.showEvolucaoResultado ? false : "grafico"})}} >
                                        {this.state.showEvolucaoResultado ?
                                            <FontAwesomeIcon icon={faChevronUp} style={{ marginRight: 8 }} /> :
                                            <FontAwesomeIcon icon={faChevronDown} style={{ marginRight: 8 }} />
                                        }
                                        {
                                            this.state.showEvolucaoResultado ? 'Ocultar Evolução de Resultados' : 'Evolução de Resultados'
                                        }
                                    </div>
                                    <Collapse in={this.state.showEvolucaoResultado} style={{ width: '100%', transition: 'all 0.2s ease' }}>
                                        <div style={{...EssentialStyle.columnCenter, maxHeight: this.state.isSmallScreen ? "auto" : "220px", height: "220px", width: "100%", boxShadow: `0px 1px 10px 0px  rgba(50, 50, 50, 0.1)` , borderRadius: 8, overflow: "hidden", border: `0.3px solid ${Colors.lightGrey}`, maxHeight: this.state.isSmallScreen ? "100%" : "220px", borderRadius: 10, backgroundColor: Colors.white}}>
                                            {this.renderEvolucaoResultado()}
                                        </div>
                                    </Collapse>
                                </div>
                            :
                                null
                            }
                        </>
                    }
                </div>
            );
        } else {
            return null;
        }
    }
}