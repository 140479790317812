import Sig from "../../api/Sig";

export default class ConfigHelper {
    static getPagesInicio() {
        return {
            'resumo': { title: 'Meus Indicadores | Minhas Atividades', key: 'resumo' },
            'meus_planos': { title: 'Meus Planos de Ação', key: 'meus_planos' },
            'minha_equipe': { title: 'Minha Equipe', key: 'minha_equipe' },
            'planejamento_estrategico': { title: 'Planejamento Estratégico', key: 'planejamento_estrategico' },
            'parceiros': { title: 'Meus Parceiros', key: 'parceiros' },
            'analytics': { title: 'Estatísticas do SIG', key: 'analytics' }
        }
    }

    static async getMyPagesAvailable(userId, viewUserConfig) {
        let { isAdmin, showParceiros } = await Sig.request('GET', 'inicio/getInicioPermission');
        let { ordem } = await Sig.request('GET', 'inicio/getInicioConfigPaginaOrdem', { colaboradorAlvo: userId, viewUserConfig: viewUserConfig });

        let pagesAvaliable = ConfigHelper.getPagesInicio();

        let pages = [
            pagesAvaliable['resumo'],
            pagesAvaliable['meus_planos'],
            pagesAvaliable['minha_equipe'],
            pagesAvaliable['planejamento_estrategico']
        ];

        if (showParceiros == true) pages.push(pagesAvaliable['parceiros']);
        if (isAdmin == true) pages.push(pagesAvaliable['analytics']);

        let pushFirst = [];
        let pushLast = [];

        for (let ordemElem of ordem) {
            let page = pages.find(item => item.key === ordemElem.pagina);
            if (page) {
                pushFirst.push(page);
            }
        }

        for (let page of pages) {
            let findInOrdem = ordem.find(item => item.pagina === page.key);
            if (!findInOrdem) {
                pushLast.push(page);
            }
        }

        const orderedPages = [...pushFirst, ...pushLast];

        return orderedPages;
    }
}