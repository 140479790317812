import React from "react";
import Sig from "../../../../../api/Sig";
import Colors from "../../../../../constants/Colors";
import DataHelper from "../../../../../helper/DataHelper";
import EssentialStyle from "../../../../../style/EssentialStyle";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStar } from "@fortawesome/free-solid-svg-icons";
import { toast } from "react-toastify";

import UserAvatar from "../../../../tools/UserAvatar";
import EllipsisText from "../../../../tools/EllipsisText";
import DefaultLoader from "../../../../tools/DefaultLoader";
import CustomTooltip from "../../../../tools/CustomTooltip";


import './ProjetoObjetivoCard.css';

export default class ProjetoObjetivoCard extends React.Component {
    state = {
        loading: true,
        objetivo: null,
        temIniciativa: false,
    }

    async componentDidMount() {
        await this.loadItem();
    }

    async loadItem(callbeck = true) {
        this.setState({ loading: true });
        
        let objetivo = callbeck ? this.props.objetivo : this.state.objetivo;
        objetivo.responsaveis = await Sig.request('GET', `pe/mapa/getResponsaveisObjetivos`, { id: this.props.objetivo.id });
        let { temIniciativa } = await Sig.request('POST', `pe/projeto/temIniciativa`, { id: this.props.objetivo.id });

        this.setState({ objetivo, selected: this.props.selected, temIniciativa, loading: false });
    }

    async savePrioridade(element, prioridade){

        let req = await Sig.request('POST', `pe/mapa/alterarPrioridade`, { id: element.id, prioridade });
        req.status != 200 
            ? toast.error('Erro ao alterar prioridade') 
            : this.setState({ objetivo: { ...this.state.objetivo, prioritario: prioridade } });
    }

    renderParticipants = (participants) => {
        
        if (participants.length > 0) {
            return (
                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', paddingLeft: 5 }}>
                    {participants.length > 1 ?
                        participants.map((participant, key) => {
                            return (

                                <div style={{ marginLeft: -10 }}>
                                    <UserAvatar id={participant.id} size={25} font={11} />
                                </div>
                            )

                        }) : <div style={{ marginLeft: -10, fontSize: 15}}><UserAvatar size={25} font={11} id={participants[0].id} /></div>
                    }
                </div>
            )
        } else {
            return <div></div>
        }
    }

    renderStar(element) {
        let colorStar = element.prioritario == 1 ? Colors.projeto.starOn : Colors.projeto.starOff;
        return (
            <FontAwesomeIcon 
                icon={faStar} 
                style={{ 
                    color: colorStar,
                    fontSize: '1.5em',
                    transition: 'color 0.15s ease-in-out',
                    cursor: this.props.onSelect ? 'pointer' : 'default'
                }} 
                onClick={ (event) => {
                    event.stopPropagation();
                    this.props.onSelect && this.savePrioridade(element, element.prioritario == 1 ? 0 : 1)
                }} 
            />
        );
    }

    render() {

        if (this.state.loading) return <div className={'objetivoCard objetivoCardNotSelected'} style={{ ...EssentialStyle.rowFlexCenter, height: 92.5, cursor: this.props.onSelect ? 'pointer' : 'default' }}><DefaultLoader /></div>
        return (
            <div 
                style={{ 
                    cursor: this.props.onSelect ? 'pointer' : 'default',
                    ...(this.props.style ? this.props.style : {})
                }}
                className={`objetivoCard ${ this.props.onSelect && (this.props.selected == this.state.objetivo.id ? 'objetivoCardSelected' : 'objetivoCardNotSelected')}`} 
                onClick={ () => { this.props.onSelect && this.props.onSelect( this.props.selected == this.state.objetivo.id, this.state.objetivo.id ); } }
            >
                <div style={{ height: '2em', display: 'flex', justifyContent: 'end', width: '100%' }} >
                    { this.renderStar(this.state.objetivo) }
                </div>

                <div style={{ height: 'calc(100% - 4em)', maxHeight: 'calc(100% - 4em)', minHeight: 'calc(100% - 4em)', display: 'flex', justifyContent: 'center', alignItems: 'center', textAlign: 'center' }} >
                    <EllipsisText text={DataHelper.removeHtmlTags(this.state.objetivo.valor)}/> 
                </div>

                <div style={{ height: '2em', display: 'flex', justifyContent: 'space-between', alignItems: 'end', paddingLeft: 8, width: '100%'}} >
                    { this.renderParticipants(this.state.objetivo.responsaveis) }
                    {this.state.temIniciativa ? (
                        <CustomTooltip placement={'right'} tooltip={'Iniciativas Vinculadas'}>
                            <div style={{ width: 18, height: 18, backgroundColor: '#DF5E5E', borderRadius: '50%'}} ></div>
                        </CustomTooltip>
                    ) : null}
                </div>
            </div>
        );
    }
}