import React from "react";

import DiagnosticoHelper from "../../../../../helper/diagnostico/DiagnosticoHelper";
import DefaultButton from "../../../../tools/DefaultButton";
import Colors from "../../../../../constants/Colors";
import { Form, Col, Dropdown, Button, ButtonGroup, Spinner } from 'react-bootstrap';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight, faExternalLinkAlt, faPlusSquare, faTimes } from "@fortawesome/free-solid-svg-icons";

import DefaultLoader from "../../../../tools/DefaultLoader";

import IdiomaHelper from "../../../../../helper/IdiomaHelper";
import CustomTooltip from "../../../../tools/CustomTooltip";
import { confirmAlert } from "react-confirm-alert";
import CustomConfirm from "../../../../tools/CustomConfirm";

import './RelatorioRecomendacao.css';
import TextEditor from "../../../../tools/TextEditor/TextEditor";

export default class RelatorioRecomendacao extends React.Component {

    state = {

        lang: this.props.lang || IdiomaHelper.getIdioma(),
        isSmallScreen: this.props.isSmallScreen || false,

        loading: true,
        loadingPlanos: true,

        idRecomendacao: this.props.idRecomendacao || null,
        recomendacao: this.props.recomendacao || null,
        planos: this.props.planos || (this.props.canCreatePlanoAcao ? null : []),
        ColumnRecomendacoes: this.props.ColumnRecomendacoes || 1,
        edit: false,
        hoverEdit: false,
        timer: null,
        descricao: '',
    }

    componentDidMount() {
        this.loadData();
        this.loadPlanos();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.ColumnRecomendacoes !== this.props.ColumnRecomendacoes) {
            this.setState({ ColumnRecomendacoes: this.props.ColumnRecomendacoes });
        }
    }

    loadData = async () => {

        this.setState({ loading: true });
        let descricao;
        if (!this.state.recomendacao) {
            // Buscar a recomendação pelo Id
        } else {
            descricao = this.state.recomendacao.descricao;
        }

        this.setState({ descricao, loading: false });
    }

    loadPlanos = async () => {
        this.setState({ loadingPlanos: true });

        if (!this.state.planos && this.props.canCreatePlanoAcao) {
            let planos = await DiagnosticoHelper.getPlanosAcaoByRecomendacao(this.state.recomendacao.id);
            this.setState({ planos });
        }

        this.setState({ loadingPlanos: false });
    }

    handleMouseEnter = () => {
        const timer = setTimeout(() => {
            this.setState({ hoverEdit: true });
        }, 800);
        this.setState({ timer });
    }

    handleMouseLeave = () => {
        clearTimeout(this.state.timer);
        this.setState({ hoverEdit: false, timer: null });
    }

    deleteRecomendation = async () => {
        let message = IdiomaHelper.getStruct({
            'pt': {
                title: 'Excluir Recomendação',
                message: 'Deseja realmente excluir esta recomendação? Essa ação não pode ser desfeita',
                confirm: 'Excluir',
                cancel: 'Cancelar',
            },
            'en': {
                title: 'Delete Recomendation',
                message: 'Do you really want to delete this recomendation? This action cannot be undone',
                confirm: 'Delete',
                cancel: 'Cancel',
            },
            'es': {
                title: 'Eliminar Recomendación',
                message: '¿Realmente deseas eliminar esta recomendación? Esta acción no se puede deshacer',
                confirm: 'Eliminar',
                cancel: 'Cancelar',
            }
        });
        
        confirmAlert({
            customUI: ({ onClose }) => (
                <CustomConfirm
                    title={message[this.state.lang].title}
                    message={<p style={{ marginTop: 10, marginBottom: 10}}>{message[this.state.lang].message}</p>}
                    buttons={[
                        {
                            label: message[this.state.lang].confirm,
                            color: Colors.error,
                            textColor: Colors.light,
                            onClick: async () => {
                                this.props.deleteRecomendacao(this.state.recomendacao.id)
                                onClose();
                            }
                        },
                        {
                            label: message[this.state.lang].cancel,
                            onClick: () => { onClose(); }
                        },
                    ]}
                />
            )
        });
    }

    saveEditRecomendation = async () => {

        this.setState({ edit: false });

        let data = {
            ...this.state.recomendacao,
            descricao: this.state.descricao,
        }

        let recomendacao = await DiagnosticoHelper.editRecomendation(data);

        this.setState({ recomendacao: {...this.state.recomendacao, ...recomendacao} });
    }

    renderLoading() {

        let recomendacao = this.state.recomendacao;
        return(
            <Form.Group key={recomendacao.id} as={Col} md={12 / this.state.ColumnRecomendacoes} style={{ margin: 0, height: 180, padding: '0px 10px 10px 0px' }}>
                <div className="recomendacao">
                    <DefaultLoader />
                </div>
            </Form.Group>
        )
    }

    renderCard() {

        let recomendacao = this.state.recomendacao;
        let message = IdiomaHelper.getStruct({
            'pt': {
                save: 'Salvar',
                cancel: 'Cancelar',
                delete: 'Excluir',
                edit: 'Editar',
                addPaTooltip: 'Criar Plano de Ação vinculado com Recomendação',
                addPa: 'Adicionar Plano',
                recomendationPlaceholder: 'Escreva sua recomendação',
            },
            'en': {
                save: 'Save',
                cancel: 'Cancel',
                delete: 'Delete',
                edit: 'Edit',
                addPaTooltip: 'Create an Action Plan linked to Recommendation',
                addPa: 'Add Plan',
                recomendationPlaceholder: 'Write your recommendation',
            },
            'es': {
                save: 'Salvar',
                cancel: 'Cancelar',
                delete: 'Eliminar',
                edit: 'Editar',
                addPaTooltip: 'Crear un Plan de Acción vinculado a la Recomendación',
                addPa: 'Agregar Plan',
                recomendationPlaceholder: 'Escribe tu recomendación',
            }
        });

        const renderEditButton = () => {
            if (this.props.canEdit && ((this.state.hoverEdit && this.state.edit) || this.state.edit)) {
                return (
                    <>
                        <DefaultButton
                            disabled={this.state.descricao == recomendacao.descricao}
                            title={message[this.state.lang].save}
                            color={Colors.success}
                            style={{ padding: '3px 10px' }}
                            onClick={() => { this.saveEditRecomendation() }}
                        />

                        <DefaultButton
                            title={message[this.state.lang].cancel}
                            color={Colors.error}
                            textColor={Colors.white}
                            style={{ padding: '3px 10px' }}
                            onClick={() => { this.setState({descricao: recomendacao.descricao, edit: false}) }}
                        />
                    </>
                )
            } else if (this.props.canEdit && this.state.hoverEdit) {
                return (
                    <>
                        <DefaultButton
                            title={message[this.state.lang].delete}
                            color={Colors.error}
                            textColor={Colors.white}
                            loading={this.state.loading}
                            style={{ padding: '3px 10px' }}
                            onClick={() => { this.deleteRecomendation() }}
                        />

                        <DefaultButton
                            title={message[this.state.lang].edit}
                            color={Colors.disabled}
                            textColor={Colors.white}
                            style={{ padding: '3px 10px' }}
                            onClick={() => { this.setState({ edit: true }) }}
                        />
                    </>
                )
            }
        }
        
        return (
            <Form.Group key={recomendacao.id} as={Col} md={12 / this.state.ColumnRecomendacoes} style={{ margin: 0, padding: '0px 10px 10px 0px' }}>
                <div 
                    className="recomendacao" 
                    key={recomendacao.id}
                    onMouseEnter={() => { this.props.canEdit && this.handleMouseEnter()}}
                    onMouseLeave={() => { this.props.canEdit && this.handleMouseLeave()}}
                >

                    {recomendacao.nodes.tree.map((item, index) => {
                        return (
                            <div key={`item-tree-${item.id}`} style={{ fontWeight: 'bold', marginLeft: `${index * 15}px` }}><FontAwesomeIcon icon={faChevronRight} style={{ margin: '0 5px' }} />{item.descricao}</div>
                        )
                    })}

                    {this.state.edit ? 
                        <TextEditor
                            defaultValue={this.state.descricao || ""}
                            onChange={(value) => { this.setState({ descricao: value }) }}
                            placeholder={message[this.props.lang].recomendationPlaceholder}
                        />
                        :
                        <div className={"descricao"}>
                            <div dangerouslySetInnerHTML={{ __html: recomendacao.descricao }} />
                        </div>
                    }

                    <div className="actions">

                        <div 
                            style={{ padding: '4px 8px', backgroundColor: recomendacao.resultado.cor, color: Colors.white, borderRadius: 5, textAlign: 'center', fontWeight: '600' }}
                        >
                            {recomendacao.resultado.valor}%
                        </div>

                        <div className="buttons">

                            {renderEditButton()}

                            {this.props.canCreatePlanoAcao &&

                                <Dropdown as={ButtonGroup}>
                                    
                                    <Button 
                                        onClick={() => { window.parent.postMessage({ type: '_blank', url: `/planoAcao?tipo=novo&modulo=8&recomendacao=${recomendacao.id}` }, '*'); }}
                                        style={{ backgroundColor: Colors.success, color: Colors.white, border: 'none', width: 150, display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', gap: 10, borderRight: `0.3px solid ${Colors.dark}` }}
                                    >
                                        <CustomTooltip tooltip={message[this.state.lang].addPaTooltip} style={{display: 'flex', justifyContent: 'center', alignItems: 'center', gap: 10}}>
                                            <FontAwesomeIcon icon={faPlusSquare} />
                                            {message[this.state.lang].addPa}
                                        </CustomTooltip>
                                    </Button>

                                    <Dropdown.Toggle split style={{ backgroundColor: Colors.success, color: Colors.white, border: 'none', borderLeft: `0.3px solid ${Colors.white}` }}/>
                                    { this.renderPlanos() }
                                </Dropdown>
                            }
                        </div>
                    </div>
                </div>
            </Form.Group>
        )
    }

    renderPlanos() {
        return (
            <Dropdown.Menu>
                { this.state.loadingPlanos ? <Dropdown.Item style={{ textAlign: 'center', color: 'grey', fontSize: 12 }}><Spinner animation="border" size="sm" /></Dropdown.Item> : null }
                { !this.state.loadingPlanos && !this.state.planos.length ? <Dropdown.Item style={{ textAlign: 'center', color: 'grey', fontSize: 12 }}>{'Nenhum Plano de Ação Criado.'}</Dropdown.Item> : null }
                {
                    !this.state.loadingPlanos && !this.state.loadingPlanos && this.state.planos.map((plano, key) => {

                        return (
                            <Dropdown.Item
                                key={plano.codigo}
                                style={{ 
                                    display: 'flex', 
                                    flexDirection: 'row', 
                                    alignItems: 'center', 
                                    justifyContent: 'space-between', 
                                    fontSize: 12, 
                                    minWidth: 400,
                                    maxWidth: 400,
                                    wordWrap: 'break-word',
                                    whiteSpace: 'normal'
                                }} 
                                onClick={() => { 
                                    window.parent.postMessage({ type: '_blank', url: `/planoAcao/detalhar/${plano.codigo}` }, '*');
                                }}
                            >
                                <span>{plano.nome}<FontAwesomeIcon icon={faExternalLinkAlt} style={{marginLeft: 10}}/></span>
                            </Dropdown.Item>
                        )
                    })
                }
            </Dropdown.Menu>
        )
    }

    render() {
        if (this.state.loading) return this.renderLoading();
        return this.renderCard();
    }
}