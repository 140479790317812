import React from "react";
import './PlanoAcaoCard.css';

import EssentialStyle from "../../../../style/EssentialStyle";
import TooManyTags from "../../../tools/TooManyTags";
import Tag from "../../../tools/Tag";
import UserAvatar from "../../../tools/UserAvatar";
import Colors from "../../../../constants/Colors";
import TooManyAvatars from "../../../tools/TooManyAvatars";
import CustomTooltip from "../../../tools/CustomTooltip";
import ProgressBarCustom from "../../../tools/ProgressBar/ProgressBarCustom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEquals, faChevronUp, faChevronDown, faCalendar, faExclamationTriangle } from '@fortawesome/free-solid-svg-icons'
import moment from 'moment';
import LayoutHelper from "../../../../helper/LayoutHelper";
import Sig from "../../../../api/Sig";
import SessionHelper from "../../../../helper/SessionHelper";
import PlanoAcaoHelper from "../../../../helper/planoAcao/PlanoAcaoHelper";

export default class PlanoAcaoCard extends React.Component {
    state = {
        loading: true,
        etapas: null,
        progressBarHovered: false,
        isSmallScreen: LayoutHelper.isSmallScreen()
    }

    async componentDidMount() {
        await this.loadData();
    }


    async loadData() {
        this.setState({ loading: true });
        let etapas = await Sig.request('POST', 'planoAcao/listEtapasPlano', { codigo: this.props.plano.codigo });
        this.setState({ etapas, loading: false });
    }

    renderTendencia() {
        if (this.props.plano?.tendencia) {
            let icon = null;

            if (this.props.plano?.tendencia?.value == -1) icon = <FontAwesomeIcon icon={faChevronDown} style={{ color: this.props.plano?.tendencia?.color, fontSize: 18, marginRight: 4 }} />
            if (this.props.plano?.tendencia?.value == 1) icon = <FontAwesomeIcon icon={faChevronUp} style={{ color: this.props.plano?.tendencia?.color, fontSize: 18, marginRight: 4 }} />
            if (this.props.plano?.tendencia?.value == null) icon = <FontAwesomeIcon icon={faEquals} style={{ color: this.props.plano?.tendencia?.color, fontSize: 18, marginRight: 4 }} />

            return (
                <CustomTooltip tooltip={this.props.plano?.tendencia?.msg} style={{ marginLeft: 8 }}>
                    {icon}
                </CustomTooltip>
            );
        }
    }


    renderTitle() {

        let hasEtapas = this.state.loading || this.state.etapas.length > 0;

        return (
            <div style={{ ...EssentialStyle.rowFlexStart, paddingLeft: 3, paddingBottom: 10 }}>
                <Tag tag={{ id: `${this.props.plano.codigo}-pa-code`, nome: this.props.plano.codigo }} />
                {!hasEtapas &&
                    <CustomTooltip tooltip={"Plano de Ação sem Etapas definidas"} placement="right">
                        <FontAwesomeIcon icon={faExclamationTriangle} style={{ marginLeft: 10 }} className={'icon warning'} />
                    </CustomTooltip>
                }
                {this.renderTendencia()}
                <span style={{
                    marginLeft: 6,
                    fontSize: 18,
                    fontWeight: 500,
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    maxWidth: this.state.isSmallScreen ? '70vw' : '50vw'
                }}>
                    {this.props.plano.nome}
                </span>
            </div>
        );
    }

    renderTags() {
        return (
            <div style={{ minHeight: 28 }}>
                <TooManyTags max={4} maxWidth={this.state.isSmallScreen ? '85vw' : '50vw'} tags={this.props.plano?.tags || []} />
            </div>
        );
    }

    renderPeople() {
        let spacing = 6;
        let style = { paddingLeft: 3, paddingTop: 10 };

        if (this.state.isSmallScreen) {
            spacing = 8;
            style = { ...EssentialStyle.columnStart, ...style };
        } else {
            style = { ...EssentialStyle.rowFlexStart, ...style };
        }


        return (
            <div style={style}>
                <div style={{ ...EssentialStyle.rowFlexStart }}>
                    <span style={{ marginRight: spacing, fontSize: 14, color: Colors.dark }}>Responsável </span>
                    <UserAvatar id={this.props.plano.responsavel.id} />
                    {!this.state.isSmallScreen && <span style={{ height: 20, width: 1, backgroundColor: Colors.dark, marginLeft: spacing * 2 }}></span>}
                </div>
                {this.props.plano?.superior?.id &&
                    <div style={{ ...EssentialStyle.rowFlexStart, marginLeft: !this.state.isSmallScreen ? spacing * 2 : 0 }}>
                        <span style={{ marginRight: spacing, fontSize: 14, color: Colors.dark }}>Avaliador </span>
                        <UserAvatar id={this.props.plano.superior.id} />
                        {!this.state.isSmallScreen && <span style={{ height: 20, width: 1, backgroundColor: Colors.dark, marginLeft: spacing * 2 }}></span>}
                    </div>
                }
                {this.props.plano?.participantes?.length ?
                    <div style={{ ...EssentialStyle.rowFlexStart, marginLeft: !this.state.isSmallScreen ? spacing * 2 : 0 }}>
                        <span style={{ marginRight: spacing * 2, fontSize: 14, color: Colors.dark }}>Participantes </span>
                        <TooManyAvatars participantsTotal={this.props.plano?.participantes?.length} participantsVisiveis={5} arrayParticipants={this.props.plano?.participantes} />
                    </div>
                    : null}
            </div>
        );
    }

    renderCompany() {
        if(!this.props.parceirosChecked || this.props.plano?.id_empresa == SessionHelper.getData().id_empresa) return null;

        return (
            <div style={{ ...EssentialStyle.rowFlexStart, marginLeft: 2, marginBottom: 8 }}>
                <div style={{ backgroundColor: Colors.tag, color: Colors.dark, fontSize: 12, padding: 5, borderRadius: 5, fontWeight: 600 }}>{this.props.plano?.nome_empresa}</div>
            </div>
        );
    }

    renderLeft() {
        return (
            <div style={{ ...EssentialStyle.columnStart }}>
                {this.renderTitle()}
                {this.renderCompany()}
                {this.renderTags()}
                {this.renderPeople()}
            </div>
        );
    }

    renderStatus() {
        let propStatus = this.props.plano?.status || 'Não Iniciado';

        return (
            <div style={{ ...EssentialStyle.rowFlexStart, marginLeft: 2 }}>
                <div style={{ backgroundColor: Colors.planoAcao.status.backgroundColor[propStatus], color: Colors.planoAcao.status.color[propStatus], fontSize: 12, padding: 5, borderRadius: 5, marginRight: 2, fontWeight: 600 }}>{this.props.plano?.status}</div>
            </div>
        );
    }

    renderVinculos() {
        let vinculos = PlanoAcaoHelper.getVinculosTagsConfig();
        let vinculosPlano = this.props.plano?.vinculos || [];
        let showVinculos = [];

        if (vinculosPlano.length > 1) {
            vinculosPlano.sort((a, b) => {
                return a.tipo - b.tipo;
            });
        }

        vinculosPlano.forEach(vinculo => {
            let found = vinculos.find(v => v.key == vinculo.tipo);
            if (found) {
                if (!showVinculos.includes(found)) showVinculos.push(found);
            }
        });

        return (
            <div style={{ ...EssentialStyle.rowFlexEnd, minHeight: 28 }}>
                {showVinculos.length ?
                    <span style={{ fontSize: 14, color: Colors.dark, marginRight: 4 }}>
                        {showVinculos.length > 1 ? 'Vínculos: ' : 'Vínculo: '}
                    </span>
                    :
                    null
                }
                {showVinculos.map((vinculo, key) => {
                    return (
                        <CustomTooltip key={key + Math.random()} placement={'bottom'} tooltip={vinculo.description}>
                            <div style={{ ...EssentialStyle.rowFlexStart, marginLeft: 2 }}>
                                <div style={{ backgroundColor: vinculo.color, color: vinculo.textColor, fontSize: 12, padding: 5, borderRadius: 5, marginRight: 2, fontWeight: 600 }}>{vinculo.text}</div>
                            </div>
                        </CustomTooltip>

                    );
                })}
            </div>
        );
    }

    renderPeriodo() {
        return (
            <div style={{ ...EssentialStyle.rowFlexStart, marginLeft: 2, backgroundColor: Colors.tag, color: Colors.dark, fontSize: 12, padding: 5, borderRadius: 5, fontWeight: 600 }}>
                <FontAwesomeIcon icon={faCalendar} style={{ color: Colors.dark, fontSize: 14, marginRight: 4 }} />
                <span>{this.props.plano?.data_inicio != 0 ? moment(this.props.plano?.data_inicio).format('DD/MM/YY') : "- -"}{this.props.plano?.data_fim ? `~ ${moment(this.props.plano?.data_fim).format('DD/MM/YY')}` : ``}</span>
            </div>
        );
    }

    renderRight() {
        let style = { height: 110 };

        if (this.state.isSmallScreen) {
            style = { ...EssentialStyle.columnSpaceBetween, alignItems: 'flex-start', marginTop: 20, ...style, width: '100%' };
        } else {
            style = { ...EssentialStyle.columnSpaceBetween, alignItems: 'flex-end', ...style };
        }

        return (
            <div style={style}>
                {this.renderVinculos()}
                <div style={{ ...EssentialStyle.rowFlexEnd }}>
                    {this.renderStatus()}
                    {this.renderPeriodo()}
                </div>
                {!this.state.loading && <ProgressBarCustom tipo={'planoAcao'} id={this.props.plano.codigo} style={{width: this.state.isSmallScreen ? '92vw' : 286}} />}
            </div>
        );
    }

    renderCard() {
        let cardStyle = {
            ...EssentialStyle.card,
            marginTop: 10,
            padding: 10,
            cursor: 'pointer'
        };

        if (this.state.isSmallScreen) {
            cardStyle = {
                ...cardStyle,
                ...EssentialStyle.columnStart,
            };
        } else {
            cardStyle = {
                ...cardStyle,
                ...EssentialStyle.rowSpaceBetween,
            }
        }

        return (
            <div
                onClick={(e) => {
                    if(this.props.onClick){
                        e.stopPropagation();
                        if(e.ctrlKey || e.metaKey){
                            this.props.onClick(this.props.plano, false);
                        }
                        this.props.onClick(this.props.plano, true);
                    }
                }}
                onMouseDown={(e) => {
                    if (e.button == 1 && this.props.onClick) {
                        e.stopPropagation();
                        e.preventDefault();
                        this.props.onClick(this.props.plano, false);
                    }
                }}
                style={cardStyle}
                className="plano-acao-card"
            >
                {this.renderLeft()}
                {this.renderRight()}
            </div>
        );
    }

    render() {
        return this.renderCard();
    }
}